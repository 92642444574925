import React, { useState, Fragment } from 'react'

import { FormField as DefaultFormField } from 'crm-components/form'

import { useMutationLoading, useQueryLoading } from './hooks/fields'

import { Col } from 'react-styled-flexboxgrid'

export default ({ fullWidth, withoutContainer, ...props }) => {
    const [hide, setHide] = useState(false)

    const [queryLoading] = useQueryLoading()

    const [mutationLoading] = useMutationLoading()

    const Container =
        hide || withoutContainer
            ? ({ children }) => <Fragment>{children}</Fragment>
            : FormFieldContainer

    return (
        <Container fullWidth={fullWidth}>
            <DefaultFormField
                onHideChange={setHide}
                queryLoading={queryLoading}
                mutationLoading={mutationLoading}
                {...props}
            />
        </Container>
    )
}

export const FormFieldContainer = ({ children, fullWidth }) => (
    <Col xs={12} md={fullWidth ? 12 : 6}>
        {children}
    </Col>
)
