import React from 'react'

import Page from './page'

import ContextProvider from './provider'

import Field from './field'

import Fields from './fields'

import FieldContainer from './field-container'

import FormTab from './tab'

export const FormField = Field

export const FormFieldContainer = FieldContainer

export const FormFields = Fields

export const Tab = FormTab

export default ({
    deleteQuery,
    query,
    mutation,
    mutationVariableName,
    children,
    record,
    redirectTo = null,
    pageTitle,
    noQueryVariables,
    isSuccess,
    autoRedirectToUpdateAfterCreate = true,
    excludeFromInput,
    isKeyDirty,
    renderMenu,
    showSaveStatus = true,
    idFromRouteParams = true,
    id,
    localStorageKey,
}) => {
    return (
        <ContextProvider>
            <Page
                deleteQuery={deleteQuery}
                query={query}
                mutation={mutation}
                mutationVariableName={mutationVariableName}
                children={children}
                record={record}
                redirectTo={redirectTo}
                pageTitle={pageTitle}
                noQueryVariables={noQueryVariables}
                isSuccess={isSuccess}
                autoRedirectToUpdateAfterCreate={
                    autoRedirectToUpdateAfterCreate
                }
                excludeFromInput={excludeFromInput}
                isKeyDirty={isKeyDirty}
                renderMenu={renderMenu}
                showSaveStatus={showSaveStatus}
                idFromRouteParams={idFromRouteParams}
                id={id}
                localStorageKey={localStorageKey}
            />
        </ContextProvider>
    )
}
