import React, { useEffect } from 'react'

import { Route } from 'system/core/route'

import { useAccountModuleContext } from './'

const AccountRoute = ({ backButton, children }) => {
    const [, set] = useAccountModuleContext()

    useEffect(() => {
        set(state => ({ ...state, showBackButton: backButton }))
        return () => {
            set(state => ({ ...state, showBackButton: false }))
        }
    }, [backButton, set])
    return children
}

export default props => {
    return (
        <Route {...props}>
            <AccountRoute {...props} />
        </Route>
    )
}
