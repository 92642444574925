import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const PROPERTY_LOCATION_FRAGMENT = /* GraphQL */ `
    fragment propertyLocationFields on PropertyLocation {
        _id
        city
        community
        subCommunity
        property
        country
    }
`

const SAVE_PROPERTY_LOCATION = /* GraphQL */ `
    mutation SavePropertyLocation($propertyLocation: PropertyLocationInput) {
        savePropertyLocation(propertyLocation: $propertyLocation) {
            ...propertyLocationFields
        }
    }
    ${PROPERTY_LOCATION_FRAGMENT}
`

const GET_PROPERTY_LOCATION = /* GraphQL */ `
    query GetPropertyLocation($id: ID!) {
        propertyLocation(id: $id) {
            ...propertyLocationFields
        }
    }
    ${PROPERTY_LOCATION_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_PROPERTY_LOCATION}
            mutation={SAVE_PROPERTY_LOCATION}
            mutationVariableName="propertyLocation"
            record="PropertyLocation"
        >
            <Tab title="Details">
                <Section title="Property location details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Archived"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
