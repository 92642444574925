import React, { useContext, useEffect, memo } from 'react'

import { isEqual, isFunction, isString } from 'lodash-es'

const compare = (e1, e2) =>
    e1.sortOrder - e2.sortOrder === 0 ? 0 : e1.sortOrder > e2.sortOrder ? 1 : -1

export const MenuItem = memo(({ context: Context, menuName, item }) => {
    const [, set] = useContext(Context)

    useEffect(() => {
        set(state => ({
            ...state,
            [menuName]: [...state[menuName], item].sort(compare),
        }))

        return () => {
            set(state => {
                const menu = state[menuName].filter(stateItem => {
                    return !isEqual(stateItem, item)
                })

                return { ...state, [menuName]: menu.sort(compare) }
            })
        }
    }, [set, menuName, item])

    return null
})

function getKey(item) {
    if (isString(item.render)) return item.render + '-' + item.sortOrder
    return item.sortOrder
}

function renderProp(render) {
    if (isString(render)) return render
    if (isFunction(render)) return render()
    return null
}

export const Menu = memo(
    ({ context: Context, menuName, component: Component, renderItem }) => {
        const [state] = useContext(Context)

        return state[menuName].map(item => {
            if (isFunction(renderItem))
                return renderItem({
                    ...item,
                    key: getKey(item),
                    render: renderProp(item.render),
                })
            return (
                <Component
                    key={getKey(item)}
                    onClick={item.onClick}
                    to={item.to}
                >
                    {renderProp(item.render)}
                </Component>
            )
        })
    }
)
