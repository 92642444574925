import React, { useContext, useCallback } from 'react'

import { isFunction, get } from 'lodash-es'

import { ListContext } from './provider'

import { useCan } from 'system/account/acl'

import Select from 'ui-components/select'

import Input from 'ui-components/input'

import CollectionSelect from 'crm-components/collection-select'

import DateRangePicker from 'ui-components/date-range-picker'

import NumberRange from 'ui-components/number-range'

import { Col } from 'react-styled-flexboxgrid'

export default ({
    type = 'text',
    name,
    disabled,
    hideIfCant = {},
    component,
    ...props
}) => {
    const [state, set] = useContext(ListContext)

    const can = useCan(hideIfCant)

    const onChange = useCallback(
        value => {
            if (type === 'collectionSelect') return
            set(state => {
                const setter = isFunction(value) ? value : () => value

                return {
                    ...state,
                    search: {
                        ...state.search,
                        [name]: setter(state.search[name]),
                    },
                }
            })
        },
        [set, name, type]
    )

    const onValueChange = useCallback(
        value => {
            set(state => ({
                ...state,
                search: {
                    ...state.search,
                    [name]: value,
                },
            }))
        },
        [set, name]
    )

    const Component =
        component ||
        {
            text: Input,
            email: Input,
            number: Input,
            select: Select,
            collectionSelect: CollectionSelect,
            dateRangePicker: DateRangePicker,
            numberRange: NumberRange,
        }[type]

    if (!Component) {
        console.error('Form field type not found')
        return null
    }

    const value = get(state, `search.${name}`) || ''

    if (can === false) return null

    return (
        <Col xs={12} sm={6} md={4}>
            <Component
                {...props}
                value={value}
                type={type}
                onChange={onChange}
                onValueChange={onValueChange}
            />
        </Col>
    )
}
