import React, { useCallback, useState } from 'react'

import { string, object, ref } from 'yup'

import { get } from 'lodash-es'

import AccountPage from './account-page'

import FormField from 'crm-components/form/field'

import Route from './route'

// import Form from 'crm-components/form'

import AccountForm from './account-form'

import { useHistory, useParams } from 'react-router-dom'

const CHANGE_PASSWORD = /* GraphQL */ `
    mutation ChangePassword($passwordResetCode: String, $password: String) {
        changePassword(
            passwordResetCode: $passwordResetCode
            password: $password
        ) {
            done
        }
    }
`

const validationSchema = object().shape({
    password: string()
        .required('Password is required')
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i,
            'Password is not strong enough'
        )
        .typeError('Password is required'),
    passwordConfirmation: string()
        .required('Password confirmation is required')
        .oneOf([ref('password'), null], 'Passwords must match')
        .typeError('Password is required'),
})

function isSuccess({ mutationData }) {
    return get(mutationData, 'done')
}

const Page = () => {
    const history = useHistory()
    const { passwordResetCode } = useParams()

    const onSuccess = useCallback(() => history.push('/password-changed'), [
        history,
    ])

    const [data] = useState({ passwordResetCode })

    return (
        <AccountPage title="Reset password" subtitle={`Change your password`}>
            <AccountForm
                mutation={CHANGE_PASSWORD}
                onSuccess={onSuccess}
                validationSchema={validationSchema}
                buttonTitle="Continue"
                isSuccess={isSuccess}
                appendSaveData={data}
                noRemoteData
            >
                <FormField
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="New password"
                    instructions="At least 8 characters, includes 1 capital letter, 1 small letter, and 1 number."
                    immediate
                />
                <FormField
                    type="password"
                    name="passwordConfirmation"
                    label="Confirm password"
                    placeholder="Confirm your new password"
                    immediate
                />
            </AccountForm>
        </AccountPage>
    )
}

export default () => {
    return (
        <Route path="/reset-password/:passwordResetCode" fullScreen>
            <Page />
        </Route>
    )
}
