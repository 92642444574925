import React, { useEffect, useState, useCallback } from 'react'

import { useEmblaCarousel } from 'embla-carousel/react'

import styled, { createGlobalStyle } from 'styled-components'

import { FaAngleUp } from 'react-icons/fa'

const ViewPortStyles = createGlobalStyle`
    .slider_viewport.is-draggable {
        cursor: move;
        cursor: grab;
    }

    .slider_viewport.is-dragging {
            cursor: grabbing;
    }
`

const Container = styled.div`
    display: flex;
    > * {
        min-width: 100%;
    }
`

const SliderWrap = styled.div`
    position: relative;
`

const ButtonContainer = styled.div`
    display: flex;
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: ${props =>
        props.navDir === 'left' ? '0 1rem 0 0.5rem' : '0 0.5rem 0 1rem'};
    background: linear-gradient(
        ${props => (props.navDir === 'left' ? 270 : 90)}deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 50%,
        rgba(0, 0, 0, 0.3) 100%
    );

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    font-size: 1.5rem;

    left: ${props => (props.navDir === 'left' ? 0 : 'unset')};
    right: ${props => (props.navDir === 'right' ? 0 : 'unset')};

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
`

const ButtonIcon = styled.div`
    display: flex;
    transform: rotate(${props => (props.navDir === 'left' ? 270 : 90)}deg);
`

const NavButton = ({ navDir, onClick, disabled }) => (
    <ButtonContainer navDir={navDir} onClick={onClick} disabled={disabled}>
        <ButtonIcon navDir={navDir}>
            <FaAngleUp />
        </ButtonIcon>
    </ButtonContainer>
)

export default ({ children }) => {
    const [EmblaCarouselReact, embla] = useEmblaCarousel({ loop: false })

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)

    const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])

    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

    const onSelect = useCallback(() => {
        if (!embla) return
        setPrevBtnEnabled(embla.canScrollPrev())
        setNextBtnEnabled(embla.canScrollNext())
    }, [embla])

    useEffect(() => {
        if (!embla) return

        embla.on('select', onSelect)

        onSelect()

        return () => {
            embla.off('select', onSelect)
        }
    }, [embla, onSelect])

    return (
        <SliderWrap>
            <ViewPortStyles />
            <EmblaCarouselReact className="slider_viewport">
                <Container>{children}</Container>
            </EmblaCarouselReact>
            <NavButton
                navDir="left"
                onClick={scrollPrev}
                disabled={!prevBtnEnabled}
            />
            <NavButton
                navDir="right"
                onClick={scrollNext}
                disabled={!nextBtnEnabled}
            />
        </SliderWrap>
    )
}
