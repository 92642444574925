import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const LISTING_PLATFORM_FRAGMENT = /* GraphQL */ `
    fragment listingPlatformFields on ListingPlatform {
        _id
        name
        slug
    }
`

const SAVE_LISTING_PLATFORM = /* GraphQL */ `
    mutation SaveListingPlatform($listingPlatform: ListingPlatformInput) {
        saveListingPlatform(listingPlatform: $listingPlatform) {
            ...listingPlatformFields
        }
    }
    ${LISTING_PLATFORM_FRAGMENT}
`

const GET_LISTING_PLATFORM = /* GraphQL */ `
    query GetListingPlatform($id: ID!) {
        listingPlatform(id: $id) {
            ...listingPlatformFields
        }
    }
    ${LISTING_PLATFORM_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_LISTING_PLATFORM}
            mutation={SAVE_LISTING_PLATFORM}
            mutationVariableName="listingPlatform"
            record="ListingPlatform"
        >
            <Tab title="Details">
                <Section title="Listing platform details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Archived"
                        />
                        <FormField label="Slug" name="slug" disabled />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
