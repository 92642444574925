import React, { useEffect, useContext } from 'react'

import './table.scss'

import { ListContext } from './provider'

import { useAdditionalCols } from './hooks'

import Header from './header'

import Body from './body'

import Provider from './provider'

import { useIsMobile, useMatchMedia } from 'system/ui/match-media'

const Table = ({ children }) => {
    const hoverDetected = useMatchMedia(`(hover)`)

    const isMobile = useIsMobile()

    return (
        <div className="table-responsive-vertical">
            <table
                className={
                    'table table-bordered ' +
                    (hoverDetected && !isMobile ? 'table-hover' : '')
                }
            >
                {children}
            </table>
        </div>
    )
}

const List = () => {
    const [{ expandable }] = useContext(ListContext)

    const [, setAddtionalCols] = useAdditionalCols()

    useEffect(() => {
        if (expandable) {
            setAddtionalCols(3)
        } else {
            setAddtionalCols(2)
        }
    }, [expandable, setAddtionalCols])

    return (
        <Table>
            <Header />
            <Body />
        </Table>
    )
}

export default props => {
    return (
        <Provider {...props}>
            <List />
        </Provider>
    )
}
