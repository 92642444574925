import React from 'react'

import Select from 'ui-components/select'

import CollectionFetcher from 'crm-components/collection-fetcher'

const CollectionSelect = props => {
    return (
        <CollectionFetcher {...props} render={props => <Select {...props} />} />
    )
}

export const ValueSelect = ({ onChange, ...props }) => (
    <CollectionSelect {...props} onValueChange={onChange} />
)

export default CollectionSelect
