import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'
import { empty } from 'crm-components/data-helpers'

const GET_PROPERTY_TRANSITIONS = /* GraphQL */ `
    query GetPropertyTransitions(
        $search: PropertyTransitionSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        propertyTransitions(
            search: $search
            pagination: $pagination
            sort: $sort
        ) {
            totalPages
            count
            nodes {
                ... on PropertyTransition {
                    _id
                    name
                    createdAt
                    sourceStatuses {
                        name
                    }
                    destinationStatus {
                        name
                    }
                    sortOrder
                }
            }
        }
    }
`

const DELETE_PROPERTY_TRANSITIONS = /* GraphQL */ `
    mutation DeletePropertyTransitions($ids: [ID!]!) {
        deletePropertyTransitions(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    if (col.key === 'sourceStatuses' && !empty(row[col.key])) {
        return row[col.key].map(status => status.name).join(', ')
    }
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'From status',
                key: 'sourceStatuses',
            },
            {
                name: 'To status',
                key: 'destinationStatus',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
            {
                name: 'Sort order',
                key: 'sortOrder',
                sortKey: 'sortOrder',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_PROPERTY_TRANSITIONS}
            deleteQuery={DELETE_PROPERTY_TRANSITIONS}
            pageTitle={'Property transition'}
            entity="propertyTransition"
            cols={cols}
            record="PropertyTransition"
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
