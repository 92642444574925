import React, { useCallback } from 'react'

import { successToast } from 'ui-components/toast'

import { useHistory } from 'react-router-dom'

export default ({ form: Form, ...props }) => {
    const history = useHistory()

    const onSuccess = useCallback(() => {
        successToast('Saved successfully')

        setTimeout(() => {
            history.goBack()
        }, 350)
    }, [history])

    return <Form mode="update" onSuccess={onSuccess} {...props} />
}
