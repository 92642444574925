import React, { useCallback, useState, useEffect } from 'react'

import Route from './route'

import logo from 'images/logo.svg'

import background from 'images/login-background.jpg'

import styled, { createGlobalStyle } from 'styled-components'

import { IoIosMail, IoMdKey } from 'react-icons/io'

import FormButton from 'crm-components/form/button'

import { Link as DefaultLink, useHistory } from 'react-router-dom'

import Seo from 'ui-components/seo'

import { useLogin } from './hooks'

import Form from 'crm-components/form'

import FormField from 'crm-components/form/field'

import { useAccount } from 'system/account'

import { useQueryParam, StringParam } from 'use-query-params'

import { empty } from 'crm-components/data-helpers'

const HtmlBackground = createGlobalStyle`
    html {
        background-image: url(${background});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100%;
    }
    body {
        background-color: transparent;
    }
`

const FormContainer = styled.div`
    max-width: 450px;
    display: block;
    margin: auto;
    padding: 1rem;
    margin-top: 10%;
    position: relative;
    z-index: 2;
`

const Logo = styled.img`
    display: block;
    max-width: 100%;
    height: 30px;
    margin: auto;
    margin: 2rem auto;
`

const MailIcon = styled(IoIosMail)`
    width: 35px;
    height: 35px;
    color: #d6d6d6;
`

const KeyIcon = styled(IoMdKey)`
    width: 35px;
    height: 35px;
    color: #d6d6d6;
`
const Overlay = styled.div`
    position: absolute;
    z-index: 1;
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const SubmitRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Link = styled(DefaultLink)`
    color: ${props => props.theme.blue};
    font-size: 0.8rem;
`

const Links = styled.div`
    display: flex;
    align-items: center;
`
const Sep = styled.span`
    opacity: 0.5;
    display: block;
    margin: 0 0.5rem;
`

const Page = () => {
    const [redirectTo] = useQueryParam('redirectTo', StringParam)

    const { token, login, loading: loginLoading, cacheValue } = useLogin()

    const { loading: accountLoading, account } = useAccount({
        autoSync: true,
    })

    const history = useHistory()

    const [data, setData] = useState({})

    const [didSubmit, setDidSubmit] = useState(false)

    const submit = useCallback(() => {
        if (!loginLoading) {
            login(data)
        }
        setDidSubmit(true)
    }, [login, loginLoading, data])

    const loading = loginLoading || (accountLoading && didSubmit)

    const disabled = loginLoading || accountLoading

    useEffect(() => {
        const next = empty(redirectTo) ? '/dashboard' : redirectTo

        if (!empty(account)) {
            history.push(next, { from: '/login' })
        }
    }, [account, token, history, redirectTo])

    return (
        <>
            <HtmlBackground />
            <Seo title="Login" />
            <Overlay />
            <FormContainer>
                <Form
                    submit={submit}
                    loading={loading}
                    disabled={disabled}
                    mutationCacheValue={cacheValue}
                    onDataChange={setData}
                    noRemoteData
                >
                    <Logo src={logo} />

                    <FormField
                        label="Email"
                        name="email"
                        type="email"
                        icon={<MailIcon />}
                        placeholder="Please enter your email"
                    />

                    <FormField
                        label="Password"
                        name="password"
                        icon={<KeyIcon />}
                        placeholder="Please enter your password"
                        type="password"
                        immediate
                    />

                    <SubmitRow>
                        <FormButton>Login</FormButton>
                        <Links>
                            <Link to="/sign-up">Sign up</Link>
                            <Sep>|</Sep>
                            <Link to="/forgot-password">
                                Forgot my password
                            </Link>
                        </Links>
                    </SubmitRow>
                </Form>
            </FormContainer>
        </>
    )
}

export default () => {
    return (
        <Route path="/login" fullScreen>
            <Page />
        </Route>
    )
}
