import React from 'react'

import UserProfileForm from './user-profile-form'

import Route from './route'

import UpdateForm from './update-form'

const Page = () => {
    return <UpdateForm form={UserProfileForm} />
}

export default () => {
    return (
        <Route
            path="/user-profile"
            fullScreen
            backButton
            permission={{
                record: 'UserProfile',
                method: 'READ',
                accessModifier: 'OWN',
            }}
        >
            <Page />
        </Route>
    )
}
