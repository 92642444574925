import React, { useEffect, useState, forwardRef } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { isNumber, isEmpty, isFunction } from 'lodash-es'

export default forwardRef(
    (
        {
            onChange,
            value: defaultValue,
            wait = 500,
            immediate,
            component: Component,
            readOnly,
            disabled,
            ...props
        },
        ref
    ) => {
        const [value, setValue] = useState(defaultValue || '')

        const [debouncedOnChange] = useDebouncedCallback(
            value => {
                if (isFunction(onChange)) onChange(value)
            },
            immediate ? 100 : wait,
            true
        )

        useEffect(() => {
            if (!isEmpty(defaultValue) || isNumber(defaultValue)) {
                setValue(defaultValue)
            } else {
                setValue('')
            }
        }, [defaultValue])

        const componentProps = {
            ...props,
            value,
            onChange: e => {
                setValue(e.target.value)
                debouncedOnChange(e.target.value)
            },
            ref,
        }

        return Component ? (
            <Component {...componentProps} disabled={disabled || readOnly} />
        ) : (
            <input {...componentProps} disabled={disabled || readOnly} />
        )
    }
)
