import React, { useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useAccount } from 'system/account'
import { empty } from 'crm-components/data-helpers'
import { useAlert } from 'system/layout/alert'
import { useMutation } from 'system/network/graphql'
import Loader from 'ui-components/loader'
import { successToast } from 'ui-components/toast'

const RESEND_ACTIVATION_EMAIL = /* GraphQL */ `
    mutation {
        resendActivationEmail {
            done
        }
    }
`

const Resend = styled.div`
    ${'' /* color: ${props => props.theme.primary}; */}
    margin: 0 0 0 1rem;
    display: inline-flex;
    font-weight: bold;
`

const Container = styled.div`
    font-size: 0.8rem;
    cursor: pointer;
`

const Message = () => {
    const { load, loading, cacheValue } = useMutation({
        operation: {
            query: RESEND_ACTIVATION_EMAIL,
        },
    })

    const resend = useCallback(() => {
        load()
    }, [load])

    useEffect(() => {
        if (loading === false && !empty(cacheValue))
            successToast('Activation email resent')
    }, [loading, cacheValue])

    return (
        <Container onClick={resend}>
            We sent you an email to confirm your account
            <Resend>Did not receive?</Resend> {loading && <Loader />}
        </Container>
    )
}

// when to reload the account

// On sign in.

// On companies form.

// On form submit (of the account verification form)

export default () => {
    const { account } = useAccount()

    const { show, hide } = useAlert()

    const confirmed = useMemo(() => {
        if (empty(account)) return undefined

        if (account.isSuperUser) return true

        return !empty(account.emailConfirmedAt)
    }, [account])

    useEffect(() => {
        let handle = null

        if (confirmed === false) {
            handle = setTimeout(
                () =>
                    show({
                        message: <Message />,
                    }),
                500
            )
        } else {
            hide()
        }

        return () => {
            clearTimeout(handle)
        }
    }, [confirmed, show, hide])

    return null
}
