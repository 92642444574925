import React from 'react'
import Section from 'ui-components/section'
import { FiPaperclip } from 'react-icons/fi'
import { FormField } from 'crm-components/crud-form'
import { Container } from 'ui-components/icon-box-selector'
import PropertyCategorySelector from 'modules/property/property-category-selector'
import PropertyOfferingSelector from 'modules/property/property-offering-selector'

export default () => {
    return (
        <Section title="Offering" icon={FiPaperclip}>
            <Container>
                <FormField
                    name="categoryId"
                    withoutContainer
                    component={PropertyCategorySelector}
                />
                <FormField
                    name="propertyOfferingId"
                    withoutContainer
                    component={PropertyOfferingSelector}
                />
            </Container>
        </Section>
    )
}
