import React from 'react'
import CollectionSelect from './collection-select'

export default ({ onChange, ...props }) => {
    return (
        <CollectionSelect
            {...props}
            customQuery={
                /* GraphQL */ `
                    {
                        countries(
                            pagination: { pageSize: 1000 }
                            sort: { key: "sortOrder", order: 1 }
                        ) {
                            nodes {
                                ... on Country {
                                    _id
                                    name
                                    dialCode
                                }
                            }
                        }
                    }
                `
            }
            onValueChange={onChange}
        />
    )
}
