import React, { useState, useMemo, useEffect } from 'react'

import { FiUser } from 'react-icons/fi'

import { useQuery, useMutation } from 'system/network/graphql'

import { Row, Col } from 'react-styled-flexboxgrid'

import { get } from 'lodash-es'

import styled from 'styled-components'

import DefaultButton from 'ui-components/button'

import { TiPlus } from 'react-icons/ti'

import { FiSend } from 'react-icons/fi'

import { MdDelete } from 'react-icons/md'

import Section from 'ui-components/section'

import ExpandAll from 'ui-components/section/expand-all'

import Input from 'ui-components/input'

import Select from 'ui-components/select'

import { string, object } from 'yup'

import { successToast } from 'ui-components/toast'

import { useHistory } from 'react-router-dom'

import { getGraphqlErrors, empty } from 'crm-components/data-helpers'

import PageTitle from 'ui-components/page-title'

const MAXIMUM_USERS = 15

const GET_ROLES = /* GraphQL */ `
    query GetRoles($search: RoleSearch, $pagination: PaginationInput) {
        roles(search: $search, pagination: $pagination) {
            nodes {
                ... on Role {
                    _id
                    name
                }
            }
        }
    }
`

const INVITE = /* GraphQL */ `
    mutation SaveInvites($invites: [InviteInput]) {
        saveInvites(invites: $invites) {
            _id
        }
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: -0.5rem 0;
    position: relative;
    @media ${props => props.theme.media.md} {
        flex-direction: row;
    }
`

const Button = styled(DefaultButton)`
    margin: 0.5rem 0 1rem;
    min-width: 175px;
    @media ${props => props.theme.media.md} {
        min-width: initial;
    }
`

const InvitedSoMany = styled.div`
    font-size: 0.8rem;
    color: ${props => props.theme.gray2};
    position: absolute;
    left: 0;
    top: 3rem;
`

const Fix = styled.div`
    overflow: hidden;
`

const RemoveUserRow = styled.div`
    display: inline-flex;
    align-items: center;
    color: ${props => props.theme.gray2};
    font-size: 0.8rem;
    margin-bottom: 1rem;
    user-select: none;
    cursor: pointer;

    &:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const RemoveIcon = styled.div`
    display: flex;
    margin-right: 0.5rem;
`

const userShape = object().shape({
    email: string().email().required(),
    roleId: string().required(),
})

function invalidUsers({ users, ignoreMax }) {
    if (empty(users)) return null

    const invalid = !users.reduce((carry, user) => {
        return carry && userShape.isValidSync(user)
    }, true)

    if (ignoreMax) return invalid

    return invalid || users.length >= MAXIMUM_USERS
}

const UserSection = ({ user, i, onChange, onRemove }) => {
    const updateUser = data => {
        const newUser = { ...user, ...data }
        onChange({
            user: newUser,
            i,
        })
    }

    const { cacheValue: rolesCacheValue } = useQuery({
        operation: {
            query: GET_ROLES,
            variables: {
                pagination: { pageNumber: 1, pageSize: 100 },
            },
        },
    })

    const roles = get(rolesCacheValue, 'data.roles.nodes') || []

    return (
        <Section title="User" subTitle={user.email} icon={FiUser}>
            <Row>
                <Col xs={12} md={6}>
                    <Input
                        label="Email"
                        name="email"
                        value={user.email}
                        onChange={email => {
                            updateUser({ email })
                        }}
                    />
                </Col>

                <Col xs={12} md={6}>
                    <Select
                        label="Role"
                        value={user.roleId}
                        options={roles}
                        name="roleId"
                        onValueChange={roleId => {
                            updateUser({ roleId })
                        }}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <RemoveUserRow
                        onClick={() => onRemove({ i, user })}
                        tabIndex={0}
                    >
                        <RemoveIcon>
                            <MdDelete />
                        </RemoveIcon>
                        Remove user
                    </RemoveUserRow>
                </Col>
            </Row>
        </Section>
    )
}

const DEFAULT_USERS = [
    {
        email: '',
    },
]

export default () => {
    const history = useHistory()

    const { loading, load: saveInvites, cacheValue } = useMutation({
        operation: {
            query: INVITE,
        },
    })

    const [users, setUsers] = useState(DEFAULT_USERS)

    const addAnotherDisabled = useMemo(() => invalidUsers({ users }), [users])

    const sendIvitationDisabled = useMemo(
        () => invalidUsers({ users, ignoreMax: true }),
        [users]
    )

    const updateUser = ({ user: updatedUser, i: key }) => {
        setUsers(users =>
            users.map((user, i) => {
                if (i === key) return updatedUser
                return user
            })
        )
    }

    const removeUser = ({ i }) => {
        setUsers(users => {
            if (users.length > 1) {
                const newUsers = Array.from(users)
                newUsers.splice(i, 1)
                return newUsers
            } else {
                setUsers(DEFAULT_USERS)
            }
        })
    }

    const submit = () => {
        if (invalidUsers({ users, ignoreMax: true })) return

        if (!loading) saveInvites({ invites: users })
    }

    useEffect(() => {
        if (empty(cacheValue)) return

        const errors = getGraphqlErrors(cacheValue)

        if (empty(errors)) {
            successToast('Invitation sent successfully')
            setTimeout(() => {
                history.push('/getting-started')
            }, 100)
        }
    }, [cacheValue, history])

    return (
        <>
            <PageTitle>Invite users</PageTitle>
            <ExpandAll />
            {users.map((user, i) => (
                <UserSection
                    key={i}
                    i={i}
                    user={user}
                    onChange={change => updateUser(change)}
                    onRemove={change => removeUser(change)}
                />
            ))}

            <ButtonContainer>
                <Button
                    disabled={addAnotherDisabled}
                    icon={<TiPlus />}
                    onClick={() => setUsers([...users, {}])}
                >
                    Add another
                </Button>

                {users.length === MAXIMUM_USERS && (
                    <InvitedSoMany>
                        You have invited so many users
                    </InvitedSoMany>
                )}
                <Button
                    loading={loading}
                    disabled={sendIvitationDisabled || loading}
                    success
                    icon={<FiSend />}
                    onClick={submit}
                >
                    Send invitation
                </Button>
            </ButtonContainer>
            <Fix />
        </>
    )
}
