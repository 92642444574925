import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_PROPERTY_TYPES = /* GraphQL */ `
    query GetPropertyTypes(
        $search: PropertyTypeSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        propertyTypes(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on PropertyType {
                    _id
                    name
                    category {
                        name
                    }
                    propertyFinderValue
                    bayutValue
                }
            }
        }
    }
`

const DELETE_PROPERTY_TYPES = /* GraphQL */ `
    mutation DeletePropertyTypes($ids: [ID!]!) {
        deletePropertyTypes(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Category',
                key: 'category',
                sortKey: 'category',
            },
            {
                name: 'Property finder',
                key: 'propertyFinderValue',
                sortKey: 'propertyFinderValue',
            },
            {
                name: 'Bayut value',
                key: 'bayutValue',
                sortKey: 'bayutValue',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_PROPERTY_TYPES}
            deleteQuery={DELETE_PROPERTY_TYPES}
            pageTitle={'Property types'}
            entity="propertyType"
            cols={cols}
            record="PropertyType"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
