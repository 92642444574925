import React, { useEffect, useRef, useCallback } from 'react'

import styled, { keyframes } from 'styled-components'

import { IoMdCloseCircleOutline } from 'react-icons/io'

import DefaultButton from './button'

import { get, isFunction } from 'lodash-es'

import Portal from './portal'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const Button = styled(DefaultButton)`
    margin-left: 1rem;
    display: block;
`

const Close = styled.div`
    font-size: 1.5rem;
    padding: 0.25rem;
    cursor: pointer;
    opacity: ${props => (props.disabled ? 0.1 : 0.5)};
    display: flex;
`

const BackDrop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 0;
    animation: ${fadeIn} 0.5s ease both;
`

const ViewPort = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`

const Modal = styled.div`
    width: 100%;
    background-color: white;
    max-width: 450px;
    z-index: 1;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    display: flex;
    padding: 1rem;
    border-bottom: 2px solid ${props => props.theme.gray};
    justify-content: space-between;
    align-items: center;
    user-select: none;
`
const Body = styled.div`
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    ${'' /* line-height: 140%; */}
    ${'' /* display: none; */}
    max-height: 500px;
    overflow: auto;
`

const Title = styled.h3`
    font-size: 1.3rem;
    font-weight: normal;
    margin: 0;
`

const FooterContainer = styled.div`
    display: flex;
    border-top: 2px solid ${props => props.theme.gray};
    padding: 1rem;
    justify-content: center;
    user-select: none;
    @media ${props => props.theme.media.sm} {
        justify-content: flex-end;
    }
`

const defaultOnClose = () => null

const Footer = ({ children, component: Component, buttonProps }) => {
    if (isFunction(Component)) {
        return (
            <FooterContainer>
                <Component {...buttonProps} />
            </FooterContainer>
        )
    }

    return <FooterContainer>{children}</FooterContainer>
}

const ModalComponent = ({
    title,
    open = true,
    onClose: componentOnClose = defaultOnClose,
    children,
    onAffirmativeClose,
    affirmativeText = 'OK',
    cancelText = 'Cancel',
    closeOnClickOutside = true,
    renderAffirmativeButton: AffirmativeButton,
    renderCancelButton: CancelButton,
    disabled,
    buttonProps: componentButtonProps,
    loading = false,
    renderFooter: FooterComponent,
    modalStyle,
}) => {
    const affirmativeButtonRef = useRef({})

    const onClose = useCallback(() => {
        if (disabled) return
        componentOnClose()
    }, [componentOnClose, disabled])

    useEffect(() => {
        if (open) {
            document.body.classList.add('is-modal-open')
        } else {
            document.body.classList.remove('is-modal-open')
        }

        const onKeyPress = e => {
            if (e.key === 'Escape' && !disabled) onClose()
        }

        document.addEventListener('keyup', onKeyPress)

        setTimeout(() => {
            const focus = get(affirmativeButtonRef, 'current.focus')
            if (focus) {
                affirmativeButtonRef.current.focus()
            }
        }, 300)

        return () => {
            document.body.classList.remove('is-modal-open')
            document.removeEventListener('keyup', onKeyPress)
        }
    }, [open, onClose, disabled])

    const buttonProps = {
        ...componentButtonProps,
        onClose,
        loading,
        disabled,
    }

    return (
        open && (
            <Portal>
                <ViewPort>
                    <BackDrop
                        onClick={() => closeOnClickOutside && onClose()}
                    />
                    <Modal style={modalStyle}>
                        <Header>
                            <Title>{title}</Title>
                            <Close onClick={onClose} disabled={disabled}>
                                <IoMdCloseCircleOutline />
                            </Close>
                        </Header>
                        <Body>{children}</Body>

                        <Footer
                            component={FooterComponent}
                            buttonProps={buttonProps}
                        >
                            {isFunction(CancelButton) ? (
                                <CancelButton {...buttonProps} />
                            ) : (
                                <Button
                                    onClick={onClose}
                                    neutral
                                    disabled={disabled}
                                >
                                    {cancelText}
                                </Button>
                            )}

                            {isFunction(AffirmativeButton) ? (
                                <AffirmativeButton {...buttonProps} />
                            ) : (
                                <Button
                                    onClick={onAffirmativeClose}
                                    loading={loading}
                                    ref={affirmativeButtonRef}
                                    disabled={disabled}
                                >
                                    {affirmativeText}
                                </Button>
                            )}
                        </Footer>
                    </Modal>
                </ViewPort>
            </Portal>
        )
    )
}

export default ModalComponent
