import React, { useMemo } from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import CheckBoxOptions from 'crm-components/checkbox-options'

const TENANT_STATUS_FRAGMENT = /* GraphQL */ `
    fragment tenantStatusFields on TenantStatus {
        _id
        name
        isHidden
        showPredefinedQuery
        createdAt
    }
`

const SAVE_TENANT_STATUS = /* GraphQL */ `
    mutation SaveTenantStatus($tenantStatus: TenantStatusInput) {
        saveTenantStatus(tenantStatus: $tenantStatus) {
            ...tenantStatusFields
        }
    }
    ${TENANT_STATUS_FRAGMENT}
`

const GET_TENANT_STATUS = /* GraphQL */ `
    query GetTenantStatus($id: ID!) {
        tenantStatus(id: $id) {
            ...tenantStatusFields
        }
    }
    ${TENANT_STATUS_FRAGMENT}
`

const DELETE_TENANT_STATUSES = /* GraphQL */ `
    mutation DeleteTenantStatuses($ids: [ID!]!) {
        deletetenantStatuses(ids: $ids)
    }
`

export default () => {
    const fields = useMemo(
        () => [
            {
                path: 'isHidden',
                name: 'Is hidden',
            },
            {
                path: 'showPredefinedQuery',
                name: 'Show predefined query',
            },
        ],
        []
    )

    return (
        <CRUDForm
            query={GET_TENANT_STATUS}
            mutation={SAVE_TENANT_STATUS}
            mutationVariableName="tenantStatus"
            record="TenantStatus"
            deleteQuery={DELETE_TENANT_STATUSES}
        >
            <Tab title="Details">
                <Section title="Tenant status details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            label="Options"
                            component={CheckBoxOptions}
                            fields={fields}
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
