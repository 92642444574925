import React from 'react'

import Section from 'ui-components/section'

import { FaMapSigns } from 'react-icons/fa'

import { FormFields, FormField } from 'crm-components/crud-form'

export default () => {
    return (
        <Section title="Property address" icon={FaMapSigns}>
            <FormFields>
                <FormField label="Project name" name="projectName" />
                <FormField label="Street address" name="streetAddress" />
                <FormField label="Street number" name="streetNumber" />
                <FormField label="Unit number" name="unitNumber" />
            </FormFields>
        </Section>
    )
}
