import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const PROPERTY_STATUS_FRAGMENT = /* GraphQL */ `
    fragment propertyStatusFields on PropertyStatus {
        _id
        name
    }
`

const SAVE_PROPERTY_STATUS = /* GraphQL */ `
    mutation SavePropertyStatus($propertyStatus: PropertyStatusInput) {
        savePropertyStatus(propertyStatus: $propertyStatus) {
            ...propertyStatusFields
        }
    }
    ${PROPERTY_STATUS_FRAGMENT}
`

const GET_PROPERTY_STATUS = /* GraphQL */ `
    query GetPropertyStatus($id: ID!) {
        propertyStatus(id: $id) {
            ...propertyStatusFields
        }
    }
    ${PROPERTY_STATUS_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_PROPERTY_STATUS}
            mutation={SAVE_PROPERTY_STATUS}
            mutationVariableName="propertyStatus"
            record="PropertyStatus"
        >
            <Tab title="Details">
                <Section title="Property status details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Archived"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
