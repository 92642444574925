import React, { useCallback, useState, useMemo, useEffect } from 'react'

import PropTypes from 'prop-types'

import { string, object } from 'yup'

import { get, isEmpty, isEqual } from 'lodash-es'

import AccountPage from './account-page'

import FormField from 'crm-components/form/field'

import AccountForm from './account-form'

import { useAccount } from 'system/account'

import Input from 'ui-components/input'

import { useCan } from 'system/account/acl'

import Seo from 'ui-components/seo'

const SAVE_USER_PROFILE = /* GraphQL */ `
    mutation SaveUserProfile($userProfile: UserProfileInput!) {
        saveUserProfile(userProfile: $userProfile) {
            _id
            firstName
            lastName
            companyRole
            mobile
            brn
            createdAt
            updatedAt
            photoId
        }
    }
`

const validationSchema = object().shape({
    firstName: string()
        .required('First name is required')
        .typeError('First name is required'),
    lastName: string()
        .required('Last name is required')
        .typeError('Last name is required'),
    companyRole: string()
        .required('Role is required')
        .typeError('Role is required'),
    mobile: string()
        .required('Mobile is required')
        .typeError('Mobile is required'),
    brn: string().required('BRN is required').typeError('BRN is required'),
})

const noop = {}

const UserProfileForm = ({ mode, onSuccess }) => {
    const { loading: accountIsLoading, account } = useAccount({
        autoSync: true,
    })

    const [mutationData, setMutationData] = useState({})

    const [profile, setProfile] = useState(noop)

    const canWrite = useCan({
        record: 'UserProfile',
        method: 'WRITE',
        accessModifier: 'OWN',
    })

    useEffect(() => {
        const accountProfile = get(account, 'profile')

        if (isEmpty(accountProfile) || isEqual(accountProfile, profile)) {
            return
        }

        setProfile(accountProfile)
    }, [account, profile])

    const title = mode === 'register' ? `Tell us about you` : `Your profile`

    const subtitle =
        mode === 'register'
            ? `This information will help us serve you better.`
            : `Update or view your profile`

    const isSuccess = useCallback(() => {
        return !!get(mutationData, '_id')
    }, [mutationData])

    const isLoading = useCallback(({ saveLoading }) => {
        return saveLoading
    }, [])

    const isDisabled = useCallback(
        ({ saveLoading }) => {
            if (mode === 'update' && canWrite === false) return true

            return saveLoading || accountIsLoading
        },
        [accountIsLoading, mode, canWrite]
    )

    const onMutationDataChange = useCallback(mutationData => {
        if (!isEmpty(mutationData)) {
            setMutationData(mutationData)
        }
    }, [])

    const removeFields = useMemo(() => ['photo'], [])

    return (
        <AccountPage title={title} subtitle={subtitle}>
            <Seo title="Profile" />
            <AccountForm
                remoteData={profile}
                mutationVariableName="userProfile"
                mutation={SAVE_USER_PROFILE}
                onSuccess={onSuccess}
                validationSchema={validationSchema}
                buttonTitle="Save details"
                isSuccess={isSuccess}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onMutationDataChange={onMutationDataChange}
                removeFields={removeFields}
                saveChangesToLocalStorage={mode === 'update'}
                noRemoteData={mode !== 'update'}
            >
                {mode === 'update' && (
                    <>
                        <Input
                            disabled
                            value={account.email}
                            label="Email"
                            instructions="Email cannot be changed"
                        />
                        <Input
                            disabled
                            value={get(account, 'role.name')}
                            label="Role"
                        />
                        <FormField
                            circle
                            type="image"
                            name="photoId"
                            label="Personal photo"
                            parentCollection="userProfiles"
                            parentId={get(account, 'companyId')}
                        />
                    </>
                )}

                <FormField
                    type="text"
                    name="firstName"
                    label="First name"
                    placeholder="Jane"
                    immediate
                />
                <FormField
                    type="text"
                    name="lastName"
                    label="Last name"
                    placeholder="Doe"
                    immediate
                />
                <FormField
                    type="text"
                    name="companyRole"
                    label="Company role"
                    placeholder="CEO"
                    immediate
                />
                <FormField
                    type="tel"
                    name="mobile"
                    label="Mobile"
                    placeholder="Mobile number with country code"
                    immediate
                />
                <FormField
                    pattern="\d*"
                    novalidate
                    name="brn"
                    label="Broker registration number"
                    placeholder="39120"
                />
            </AccountForm>
        </AccountPage>
    )
}

UserProfileForm.propTypes = {
    mode: PropTypes.oneOf(['register', 'update']),
}

export default UserProfileForm
