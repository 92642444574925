import fecha from 'fecha'

import {
    get,
    isEmpty,
    isString,
    isNumber,
    isDate as _isDate,
    isArray,
    isObject,
    isNull,
    isUndefined,
    isEqual,
    isBoolean,
    isFunction,
} from 'lodash-es'

import doPluralize from 'pluralize'

import slugifyString from 'slugify'

function safeArray(arg) {
    return isArray(arg) ? arg : []
}

export function getGraphqlErrors(response) {
    const errors = get(response, 'graphQLErrors')

    const internalErrors = get(response, 'errors')

    const allErrors = safeArray(errors).concat(safeArray(internalErrors))

    const authenticationErrors = allErrors.filter(e => {
        return get(e, 'extensions.code') === 'UNAUTHENTICATED'
    })

    if (!isEmpty(authenticationErrors)) {
        console.warn('un authenticated')
    }

    if (internalErrors) {
        // Do some error reporting.
        // Show that we got internal server error
    }

    if (isEmpty(allErrors)) return []

    const inputErrors = allErrors.reduce((acc, error) => {
        if (isArray(error.inner)) return acc.concat(error.inner)
        return acc.concat([error])
    }, [])

    return inputErrors
}

export function empty(value) {
    if (isNull(value) || isUndefined(value)) return true

    if (isNumber(value)) {
        return !value
    }

    if (isDate(value)) return !value

    if (value instanceof File) {
        return isEmpty(get(value, 'name'))
    }

    if (isString(value) || isArray(value) || isObject(value)) {
        return isEmpty(value)
    }
}

export function emptyValues(value) {
    if (isObject(value)) {
        return Object.keys(value).reduce(
            (emptyValues, key) => emptyValues && empty(value[key]),
            true
        )
    }
    return empty(value)
}

export function getDataKey(cacheValue) {
    const none = 'none-existing-key-' + new Date()

    const data = get(cacheValue, 'data')

    if (isEmpty(data)) return none

    const key = Object.keys(data)[0]
    return key
}

export function getDataObject(cacheValue, optionalPath = null) {
    return get(
        cacheValue,
        'data.' +
            getDataKey(cacheValue) +
            (optionalPath ? '.' + optionalPath : '')
    )
}

export function getDataNodes(cacheValue) {
    return get(cacheValue, `data.${getDataKey(cacheValue)}.nodes`)
}

export function prepareFormData(_data) {
    const data = { ..._data }

    for (const key of Object.keys(data)) {
        if (isString(data[key])) {
            data[key] = data[key].trim()
        }
        // set empty fields to null
        // What if we sent a null as a value, and then the backend ignores nulls,
        // and then we can never clear any optional value after being set
        if (
            isEmpty(data[key]) &&
            !isNumber(data[key]) &&
            !isDate(data[key]) &&
            !isBoolean(data[key])
        ) {
            data[key] = null
        }
    }

    return data
}

export function isDate(value) {
    if (_isDate(value)) return true

    if (!isString(value)) return false

    const maybeDate = Date.parse(value)

    if (!isNaN(maybeDate) && new Date(maybeDate).toJSON() === value) {
        return true
    }

    return false
}

export function equal(value, other) {
    if (isDate(value) && isDate(other)) {
        const date1 = new Date(value)
        const date2 = new Date(other)
        return date1.getTime() === date2.getTime()
    }

    return isEqual(value, other)
}

export function formatValue(value, { noTime } = {}) {
    if (!isDate(value)) return value

    let pattern = 'YYYY-MM-DD hh:mm A'

    if (noTime) pattern = 'YYYY-MM-DD'

    return fecha.format(new Date(value), pattern)
}

export function getUserName(user) {
    let name =
        (get(user, 'profile.firstName') || '') +
        ' ' +
        (get(user, 'profile.lastName') || '')

    name = name.trim()

    name = empty(name) ? get(user, 'email') : name

    return name
}

export function camelCaseToSentenceCase(str) {
    if (!str) return ''
    return str
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .trim()
}

export function pluralize(str, count) {
    if (empty(str)) return null

    const sentence = str.match(' ') ? str : camelCaseToSentenceCase(str)

    const split = sentence.toLowerCase().split(' ')

    if (split.length >= 2) {
        return split[0] + ' ' + doPluralize(split[1], count)
    }

    return doPluralize(str, count)
}

function getLastWord({ resolveType }) {
    const sentence = camelCaseToSentenceCase(resolveType)

    if (sentence.match(/ /)) {
        return sentence[sentence.length - 1]
    }

    return resolveType
}

export function getVariableNamePluralized({ resolveType }) {
    if (empty(resolveType)) return resolveType

    const lastWord = getLastWord({ resolveType })

    const variableNamePluralized =
        resolveType.replace(new RegExp(lastWord, 'i'), '') +
        ucFirst(doPluralize(lastWord))

    return lcFirst(variableNamePluralized)
}

export function slugify(value) {
    if (!isString(value)) return undefined

    return slugifyString(value, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
    })
}

export function ucFirst(str) {
    if (empty(str)) return str
    return str[0].toUpperCase() + str.slice(1)
}

export function lcFirst(str) {
    if (empty(str)) return str
    return str[0].toLowerCase() + str.slice(1)
}

export async function sleep(wait) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, wait)
    })
}

export function getOptionValue({ option }) {
    return get(option, 'value') || get(option, '_id')
}

export async function waitFor(callback) {
    while (!callback()) {
        await sleep(200)
    }
}

export function yesNo({ value }) {
    if (typeof value === 'undefined') return undefined
    return value === true ? 'Yes' : 'No'
}

export function constantCaseToSentenceCase({ value = '' }) {
    return ucFirst(value.replace(/_/g, ' ').toLowerCase())
}

export function escapeRegexPattern(string) {
    if (empty(string)) return ''

    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function isChildOf({ node: triggerNode, parentSelector }) {
    let node = triggerNode

    while (node.parentNode && isFunction(node.parentNode.matches)) {
        if (node.parentNode.matches(parentSelector)) return true

        node = node.parentNode
    }

    return false
}
