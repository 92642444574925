import React from 'react'

import ThemeProvider from 'system/layout/theme'

import TopBarProvider from 'system/layout/topbar'

import SidebarProvider from 'system/layout/sidebar'

export default ({ children }) => {
    return (
        <ThemeProvider>
            <SidebarProvider>
                <TopBarProvider>{children}</TopBarProvider>
            </SidebarProvider>
        </ThemeProvider>
    )
}
