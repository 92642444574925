import React, { useState } from 'react'

import styled, { keyframes, createGlobalStyle } from 'styled-components'

import DatePicker from 'react-date-picker'

import { isDate, empty } from 'crm-components/data-helpers'

import SaveStatus from 'ui-components/save-status'

import CalendarStyles from './calendar-styles'

const DatePickerStyles = createGlobalStyle`
    .react-date-picker {
        display: block;
        margin-top: 0.5rem;
    }
    .react-date-picker__wrapper {
        border: 0;
    }

    .react-date-picker input {
        box-shadow: none;
        outline: 0;
        ${'' /* margin-top: 0.5rem; */}
        border: 0;
        color: ${props => props.theme.dark};
        ::placeholder {
            color: ${props => props.theme.gray2};
            font-style: italic;
        }

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }

    .react-date-picker button {
        outline: 0;
    }

    .react-date-picker__button {
        opacity: .3;
        padding: 0;
    }

    .react-date-picker__button:hover {
        opacity: 1;
    }
    
    .react-date-picker__button svg {
        width: 13px;
        height: 13px;
    }

    .react-date-picker__clear-button {
        margin-right: .5rem;
    }

    .react-date-picker__inputGroup {
        height: 20px;
    }

    .react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
        stroke: ${props => props.theme.primary};
    }

    .react-date-picker__calendar {
        z-index: 30;
    }

    .react-date-picker__inputGroup input::placeholder,
    .react-date-picker__inputGroup__divider {
        color: ${props => (props.empty ? props.theme.gray2 : props.theme.dark)};
    }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const InputContainer = styled.div`
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    box-shadow: 0 0 1px 2px
        ${props =>
            props.error
                ? props.theme.danger
                : props.focused
                ? props.theme.gray2
                : props.theme.gray};
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.2s ease;
    position: relative;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const Label = styled.label`
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.theme.primary};
`

export const Error = styled.div`
    color: ${props => props.theme.danger};
    position: absolute;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 400;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    text-transform: lowercase;
    animation: ${fadeIn} 0.5s ease;
`

export default ({
    value,
    onChange,
    error,
    label,
    isDirty,
    showSaveStatus,
    onDiscard,
    ...rest
}) => {
    const [focused, setFocus] = useState(false)

    let parsed

    if (isDate(value)) {
        parsed = new Date(value)
    }

    return (
        <>
            <CalendarStyles />
            <DatePickerStyles empty={empty(parsed)} />
            <InputContainer
                focused={focused ? 1 : 0}
                error={error}
                disabled={rest.disabled ? 1 : 0}
            >
                <Label>{label}</Label>
                {error && <Error>{error.message}</Error>}
                <DatePicker
                    value={parsed}
                    onChange={onChange}
                    onCalendarOpen={() => setFocus(true)}
                    onCalendarClose={() => setFocus(false)}
                    format="dd/MM/y"
                />
                <SaveStatus
                    isDirty={isDirty}
                    value={value}
                    showSaveStatus={showSaveStatus}
                    onDiscard={onDiscard}
                />
            </InputContainer>
        </>
    )
}
