import React, { useMemo, useCallback, useContext } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

import { pluralize, getDataObject } from 'crm-components/data-helpers'

import { get } from 'lodash-es'
import { useAffirmativeConfirm } from 'crm-components/modal'
import { operate } from 'system/network/graphql'

import { successToast } from 'ui-components/toast'
import { GraphQLContext } from 'graphql-react'

const GET_QUERY = /* GraphQL */ `
    query GetRoles(
        $search: RoleSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        roles(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Role {
                    _id
                    name
                    createdAt
                    permissions {
                        code
                    }
                }
            }
        }
    }
`

const DELETE_QUERY = /* GraphQL */ `
    mutation DeleteRoles($ids: [ID!]!) {
        deleteRoles(ids: $ids)
    }
`

async function duplicateRoles({ selectedRows }) {
    const { cacheValuePromise } = await operate({
        operation: {
            query: /* GraphQL */ `
                mutation DuplicateRoles($ids: [ID!]!) {
                    duplicateRoles(ids: $ids)
                }
            `,
            variables: {
                ids: selectedRows.map(row => row._id),
            },
        },
    })

    return getDataObject(await cacheValuePromise)
}

const Cell = ({ row, col }) => {
    if (col.key === 'permissions') {
        const count = get(row, 'permissions.length') || 0

        return count + ' ' + pluralize('permission', count)
    }
}

export default () => {
    const graphql = useContext(GraphQLContext)

    const confirm = useAffirmativeConfirm()

    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Permissions',
                key: 'permissions',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    const onDuplicateClick = useCallback(
        async ({ selectedRows }) => {
            await confirm({
                message: `Are you sure you want to duplicate ${
                    selectedRows.length
                } record${selectedRows.length > 1 ? 's' : ''}?`,
                onAffirmative: async () => {
                    const message = await duplicateRoles({ selectedRows })
                    successToast(message)
                    graphql.reload()
                },
            })
        },
        [confirm, graphql]
    )

    const isDuplicateDisabled = useCallback(({ selectedRows }) => {
        return !selectedRows.length
    }, [])

    const actions = useMemo(
        () => [
            {
                name: 'Duplicate',
                onClick: onDuplicateClick,
                isDisabled: isDuplicateDisabled,
            },
        ],
        [onDuplicateClick, isDuplicateDisabled]
    )

    return (
        <List
            getQuery={GET_QUERY}
            deleteQuery={DELETE_QUERY}
            pageTitle={'Roles'}
            entity="role"
            record={'Role'}
            cols={cols}
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                    </>
                )
            }}
            actions={actions}
        />
    )
}
