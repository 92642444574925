import React, { useMemo } from 'react'

import QuickView from './quick-view'

import List from 'crm-components/list'

import ListSearch from './list-search'

import { useContactPredefindQueries } from 'crm-components/contact-predefined-queries'

import ListActions from './list-actions'

import { getUserName } from 'crm-components/data-helpers'

import { COLUMN_PRIORITY_LOW } from 'ui-components/list/common'

const GET_TENANTS = /* GraphQL */ `
    query GetTenants(
        $search: TenantSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        tenants(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Tenant {
                    _id
                    name
                    email
                    mobile
                    phone
                    officialDocumentNumber
                    officialDocumentExpiryDate
                    address
                    city
                    tenantStatus {
                        name
                    }
                    assignedTo {
                        profile {
                            firstName
                            lastName
                        }
                    }
                    country {
                        name
                    }
                    createdAt
                }
            }
        }
    }
`

const DELETE_TENANTS = /* GraphQL */ `
    mutation DeleteTenants($ids: [ID!]!) {
        deletetenants(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    switch (row[col.key]) {
        case 'assignedTo':
            return getUserName(row.assignedTo)
        default:
            return
    }
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Email',
                key: 'email',
                sortKey: 'email',
            },
            {
                name: 'Mobile',
                key: 'mobile',
                sortKey: 'mobile',
            },
            {
                name: 'Status',
                key: 'tenantStatus',
                priority: COLUMN_PRIORITY_LOW,
            },
            {
                name: 'Assigned',
                key: 'assignedTo',
                priority: COLUMN_PRIORITY_LOW,
            },
        ],
        []
    )

    const predefinedQueries = useContactPredefindQueries({
        resolveType: 'Tenant',
    })

    return (
        <List
            getQuery={GET_TENANTS}
            deleteQuery={DELETE_TENANTS}
            pageTitle={'Tenants'}
            entity="tenant"
            cols={cols}
            record="Tenant"
            renderCell={Cell}
            renderActions={ListActions}
            renderSearchSection={ListSearch}
            predefinedQueries={predefinedQueries}
            showQuickFiltersLoader
            renderExpandedRow={QuickView}
            expandable
        />
    )
}
