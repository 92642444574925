import React from 'react'

import Section from 'ui-components/section'

import { FormFields, FormField } from 'crm-components/crud-form'

export default ({ id }) => {
    return (
        <Section title="Activities">
            <FormFields>
                <FormField
                    fullWidth
                    name="activityIds"
                    type="activities"
                    parentCollection={'properties'}
                    parentId={id}
                    parentRecordName="Property"
                />
            </FormFields>
        </Section>
    )
}
