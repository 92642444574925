import { empty, emptyValues } from 'crm-components/data-helpers'
import { get } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { useData, useExcludeFromInput, useStaticFormFields } from './fields'
import { useLocalStorage } from './local-storage'

import { excludeFields } from '../data-processors'
import useRemoteData from './use-remote-data'

export default () => {
    const [localStorageState] = useLocalStorage()

    const [remoteData] = useRemoteData()

    const [data, setData] = useData()

    const [excludeFromInput] = useExcludeFromInput()

    const [staticFormFields] = useStaticFormFields()

    const autoLoadData = useCallback(
        data => {
            // console.log('auto loading data')
            setData(data)
        },
        [setData]
    )

    useEffect(() => {
        const visibleData = excludeFields({
            data,
            excludeFromInput: [...excludeFromInput, ...staticFormFields],
        })

        const visibleLocalStorageData = excludeFields({
            data: localStorageState,
            excludeFromInput: [...excludeFromInput, ...staticFormFields],
        })

        if (emptyValues(visibleData) && !emptyValues(visibleLocalStorageData)) {
            autoLoadData(localStorageState)
        } else if (empty(get(data, '_id')) && !empty(get(remoteData, '_id'))) {
            autoLoadData(remoteData)
        }
    }, [
        data,
        excludeFromInput,
        staticFormFields,
        remoteData,
        autoLoadData,
        localStorageState,
    ])
}
