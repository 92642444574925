import React, { useEffect } from 'react'

import { useExpanded } from './hooks'

import { SectionContext } from './provider'

import { useExpandSections } from 'system/ui/sections'

import useBindPropContextValue from 'helpers/use-bind-prop-context-value'

import styled from 'styled-components'

const Container = styled.section`
    background-color: ${props => props.theme.gray};
    margin: 0 0 1rem 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
`

export default ({ children, expanded = true, className }) => {
    const [expandSections] = useExpandSections()

    const [, setExpanded] = useExpanded()

    useBindPropContextValue({ expanded }, SectionContext)

    useEffect(() => {
        if (typeof expandSections === 'undefined') return

        setExpanded(expanded => {
            if (expandSections === expanded) return expanded

            return expandSections
        })
    }, [expandSections, setExpanded])

    return <Container className={className}>{children}</Container>
}
