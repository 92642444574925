import React, { useCallback, useEffect } from 'react'

import { useMutation, useQuery } from 'system/network/graphql'

import styled from 'styled-components'

import { isEmpty, isEqual } from 'lodash-es'

import { getDataObject, empty } from 'crm-components//data-helpers'

import NotSavedAlert from 'crm-components/not-saved-alert'

import DefaultSaveStatus from 'ui-components/save-status'

import DropZone from 'ui-components/dropzone'

import { GET_FILES, SAVE_FILES } from './queries'

import Loader from './loader'

import FileElement from './file-element'

import {
    useBindPropContextValue,
    useFilteredFiles,
    useRemoteFiles,
} from './hooks'

const Container = styled.div`
    margin-bottom: 1rem;
`

const FileContainer = styled.div`
    padding: 1rem;
    min-height: 58px;
    background-color: white;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex: 1;
`

const NoFilesMessage = styled(FileContainer)`
    margin-bottom: 1rem;
    color: ${props => props.theme.gray2};
`

const SaveStatus = styled(DefaultSaveStatus)`
    margin: 0.5rem 0 1rem 0;
`

const noopArray = []

export default ({
    value,
    onChange,
    parentCollection,
    parentId,
    disabled,
    isDirty,
    showSaveStatus,
    onDiscard,
    parentRecordName,
    loading: parentLoading,
}) => {
    const [remoteFiles, setRemoteFiles] = useRemoteFiles([])

    const [filteredFiles, setFilteredFiles] = useFilteredFiles()

    useBindPropContextValue({ parentLoading })

    const {
        loading: saveLoading,
        load: saveFiles,
        cacheValue: saveFilesValue,
    } = useMutation({
        operation: {
            query: SAVE_FILES,
        },
    })

    useBindPropContextValue({ saveLoading })

    const { loading: getLoading, cacheValue: getFilesValue } = useQuery({
        operation: {
            query: GET_FILES,
            variables: { ids: value },
        },
        loadOnMount: !empty(value),
    })

    useBindPropContextValue({ getLoading })

    const deleteRemoteFiles = ids => {
        onChange(value.filter(_id => ids.indexOf(_id) < 0))
    }

    const uploadDocuments = useCallback(
        uploads => {
            if (isEmpty(uploads)) return

            const files = uploads.map(() => ({
                parentCollection,
                parentId,
            }))

            saveFiles({ files, uploads })
        },
        [parentCollection, parentId, saveFiles]
    )

    useEffect(() => {
        const response = getDataObject(saveFilesValue)

        if (!isEmpty(response)) {
            onChange(value => {
                const _value = value || noopArray
                const newValue = [..._value, ...response.map(file => file._id)]
                if (isEqual(value, newValue)) return value

                return newValue
            })
        }
    }, [saveFilesValue, onChange])

    useEffect(() => {
        const remoteFiles = getDataObject(getFilesValue) || noopArray

        setRemoteFiles(remoteFiles)
    }, [getFilesValue, setRemoteFiles])

    useEffect(() => {
        if (typeof filteredFiles === 'undefined' && !empty(remoteFiles)) {
            setFilteredFiles(remoteFiles)
        }
    }, [filteredFiles, remoteFiles, setFilteredFiles])

    const uploadDisabled =
        isEmpty(parentId) || getLoading || saveLoading || disabled

    return (
        <Container>
            <DropZone
                onChange={uploadDocuments}
                disabled={uploadDisabled}
                loading={saveLoading}
            />

            {getLoading && isEmpty(remoteFiles) ? (
                <NoFilesMessage>
                    <Loader />
                </NoFilesMessage>
            ) : !isEmpty(filteredFiles) ? (
                filteredFiles.map(file => (
                    <FileElement
                        disabled={disabled}
                        key={file._id}
                        file={file}
                        onDelete={file => deleteRemoteFiles([file._id])}
                    />
                ))
            ) : (
                <NoFilesMessage>
                    {isEmpty(remoteFiles)
                        ? 'No documents uploaded'
                        : 'No search result'}
                </NoFilesMessage>
            )}

            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />
            <NotSavedAlert
                parentId={parentId}
                parentRecordName={parentRecordName}
                loading={parentLoading}
                currentRecordName="Document"
            />
        </Container>
    )
}
