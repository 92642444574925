import React from 'react'

import FormField from 'crm-components/form/field'

import ImageGallery from 'crm-components/image-gallery'

import { useParams } from 'react-router-dom'

import HelpSection from 'ui-components/help-section'

import { useCan } from 'system/account/acl'

import { useAccount } from 'system/account'

import { empty } from 'crm-components/data-helpers'

import { get } from 'lodash-es'

import Link from 'ui-components/link'

export default () => {
    const { id } = useParams()

    const canWriteCompany = useCan({
        record: 'Company',
        method: 'WRITE',
        accessModifier: 'OWN',
    })

    const { account } = useAccount()

    const logoUploaded = !empty(get(account, 'company.logoId'))

    return (
        <>
            {canWriteCompany && !logoUploaded && (
                <HelpSection title="Protip">
                    Upload your company logo from{' '}
                    <Link to="/company-details" target={'_blank'}>
                        company details
                    </Link>{' '}
                    to add the water mark automatically
                </HelpSection>
            )}
            <FormField
                withoutContainer
                component={ImageGallery}
                name="imageIds"
                parentCollection="properties"
                parentId={id}
                disabled={!id}
                parentRecordName="Property"
            />
        </>
    )
}
