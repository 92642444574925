import { camelCaseToSentenceCase, empty } from 'crm-components/data-helpers'
import { useRemoteData } from './hooks/logic'
import { isFunction } from 'lodash-es'
import React from 'react'

import styled from 'styled-components'

import PageTitle from 'ui-components/page-title'

import {
    useFormId,
    useMutationVariableName,
    usePageTitle,
    useRenderMenu,
} from './hooks/fields'

const PageHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    ${PageTitle} {
        margin-bottom: 0;
    }
`

// const RecordStatus = styled.div`
//     text-transform: uppercase;
//     font-size: 0.8rem;
//     color: ${props => props.theme.gray2};
//     margin-right: 1rem;
// `

const HeaderGroup = styled.div`
    display: flex;
    align-items: center;
`

export default () => {
    const [id] = useFormId()

    const [getDefaultPageTitle] = usePageTitle()

    const [mutationVariableName] = useMutationVariableName()

    const [renderMenu] = useRenderMenu()

    const remoteData = useRemoteData()

    const defaultPageTitle = isFunction(getDefaultPageTitle)
        ? getDefaultPageTitle({ id })
        : getDefaultPageTitle

    const pageTitle =
        defaultPageTitle ||
        `${empty(id) ? 'Create' : 'Edit'} ${camelCaseToSentenceCase(
            mutationVariableName
        )}`

    const MenuRenderer = isFunction(renderMenu) ? renderMenu : () => null

    return (
        <PageHeader>
            <PageTitle>{pageTitle}</PageTitle>
            <HeaderGroup>
                {/* <RecordStatus>{recordStatus}</RecordStatus> */}
                <MenuRenderer remoteData={remoteData} />
            </HeaderGroup>
        </PageHeader>
    )
}
