import React from 'react'
import styled from 'styled-components'
import Slider from 'ui-components/slider'
import { empty } from 'crm-components/data-helpers'
import { getWaterMarkedUrl } from 'system/core/file'

const Container = styled.div`
    position: relative;
    background-color: ${props => props.theme.gray2};
`

const Image = styled.img`
    object-fit: contain;
    object-position: center;
    display: block;
    height: 200px;
    width: 100%;
`

const Link = styled.a.attrs({ target: '_blank' })`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: inherit;
`

export default ({ images }) => {
    return (
        !empty(images) && (
            <Slider>
                {images.map(image => (
                    <Container key={getWaterMarkedUrl({ file: image })}>
                        <Image
                            src={getWaterMarkedUrl({ file: image })}
                            alt={'Property preview'}
                        />
                        <Link href={getWaterMarkedUrl({ file: image })} />
                    </Container>
                ))}
            </Slider>
        )
    )
}
