import React from 'react'

import styled from 'styled-components'

import { MdErrorOutline } from 'react-icons/md'

const Container = styled.div`
    display: flex;
    color: ${props => props.theme.danger};
    font-size: 0.8rem;
    align-items: center;
    margin-bottom: 1rem;
`

const Text = styled.span``

const Icon = styled.div`
    display: flex;
    margin-right: 0.5rem;
    font-size: 1rem;
`

export default ({ message }) => (
    <Container>
        <Icon>
            <MdErrorOutline />
        </Icon>
        <Text>{message}</Text>
    </Container>
)
