import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_AMENITIES = /* GraphQL */ `
    query GetAmenities(
        $search: AmenitySearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        amenities(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Amenity {
                    _id
                    name
                    propertyFinderValue
                    bayutValue
                    category {
                        name
                    }
                    createdAt
                }
            }
        }
    }
`

const DELETE_AMENITIES = /* GraphQL */ `
    mutation DeleteAmenities($ids: [ID!]!) {
        deleteAmenities(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Property finder',
                key: 'propertyFinderValue',
                sortKey: 'propertyFinderValue',
            },
            {
                name: 'Bayut value',
                key: 'bayutValue',
                sortKey: 'bayutValue',
            },
            {
                name: 'Category',
                key: 'category',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_AMENITIES}
            deleteQuery={DELETE_AMENITIES}
            pageTitle={'Amenities'}
            entity="amenity"
            cols={cols}
            record="Amenity"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
