import React from 'react'

import DefaultCheckBox from './checkbox'

import {
    InputContainer,
    Label as DefaultLabel,
    InnerInputContainer,
} from './input'

import { isArray, isFunction } from 'lodash-es'

import styled from 'styled-components'

import ContentLoader from 'react-content-loader'

import { noop } from 'lodash-es'

const LoadingContainer = styled.div`
    width: 250px;
    margin-bottom: 0.5rem;
    height: 16px;
`

const Label = styled(DefaultLabel)`
    margin-bottom: 1rem;
`

const CheckBox = styled(DefaultCheckBox)`
    margin-bottom: 0.5rem;
`

const emptyArray = []

function getArray(value) {
    return !value || !isArray(value) ? emptyArray : value
}

const Loader = ({ rows }) => {
    return Array.from({ length: rows }).map((_, i) => (
        <LoadingContainer key={i}>
            <ContentLoader
                speed={2}
                width={'100%'}
                height={17}
                viewBox="0 0 250 16"
                preserveAspectRatio="none"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="3" ry="3" width="250" height="14" />
            </ContentLoader>
        </LoadingContainer>
    ))
}

export default ({
    renderLabel: LabelRenderer,
    label,
    options: componentOptions,
    onOptionChange = noop,
    isOptionSelected = noop,
    disabled,
    loading,
    loadingRows = 3,
    renderSaveStatus = noop,
    renderOption,
}) => {
    const options = getArray(componentOptions)

    return (
        <InputContainer disabled={disabled} marginBottom>
            <InnerInputContainer>
                {isFunction(LabelRenderer) ? (
                    <LabelRenderer />
                ) : (
                    <Label>{label}</Label>
                )}

                {loading ? (
                    <Loader rows={loadingRows} />
                ) : (
                    options.map(option => {
                        return isFunction(renderOption) ? (
                            renderOption({ option })
                        ) : (
                            <CheckBox
                                key={option.name}
                                label={option.name}
                                value={isOptionSelected(option)}
                                onChange={selected =>
                                    onOptionChange({ option, selected })
                                }
                                disabled={disabled}
                            />
                        )
                    })
                )}

                {renderSaveStatus()}
            </InnerInputContainer>
        </InputContainer>
    )
}
