import React from 'react'

import { useParams } from 'react-router-dom'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import { GoLaw } from 'react-icons/go'

import { FaPhone } from 'react-icons/fa'

import { MdDateRange } from 'react-icons/md'

import DocumentsTab from 'crm-components/documents-tab'

const COMPANY_FRAGMENT = /* GraphQL */ `
    fragment companyFields on Company {
        _id
        name
        phone
        website
        address
        orn
        licenseNumber
        trn
        purchasedAt
        demoExpiresAt
        createdAt
        updatedAt
        createdBy {
            profile {
                firstName
                lastName
            }
        }
        activityIds
        documentIds
        logoId
    }
`

const SAVE_COMPANY = /* GraphQL */ `
    mutation SaveCompany($company: CompanyInput) {
        saveCompany(company: $company) {
            ...companyFields
        }
    }
    ${COMPANY_FRAGMENT}
`

const GET_COMPANY = /* GraphQL */ `
    query GetCompany($id: ID!) {
        company(id: $id) {
            ...companyFields
        }
    }
    ${COMPANY_FRAGMENT}
`

export default () => {
    const { id } = useParams()

    return (
        <CRUDForm
            query={GET_COMPANY}
            mutation={SAVE_COMPANY}
            mutationVariableName="company"
            record="Company"
        >
            <Tab title="Details">
                <Section title="Legal details" icon={GoLaw}>
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            type="number"
                            name="orn"
                            label="Office registration number"
                        />
                        <FormField
                            name="licenseNumber"
                            label="License number"
                        />
                        <FormField
                            name="trn"
                            label="TRN"
                            placeholder="1110101010010"
                        />
                    </FormFields>
                </Section>
                <Section title="Contact details" icon={FaPhone}>
                    <FormFields>
                        <FormField name="phone" label="Phone" />
                        <FormField name="website" label="Website" />
                        <FormField name="address" label="Address" />
                    </FormFields>
                </Section>
                <Section title="CRM License" icon={MdDateRange}>
                    <FormFields>
                        <FormField
                            name="demoExpiresAt"
                            label="Demo expiration date"
                            type="datepicker"
                        />
                        <FormField
                            name="purchasedAt"
                            type="datepicker"
                            label="Purchase data date"
                        />
                    </FormFields>
                </Section>
            </Tab>
            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'companies'}
                            parentId={id}
                            parentRecordName="Company"
                        />
                    </FormFields>
                </Section>
            </Tab>

            <DocumentsTab parentResolveType="Company" />
        </CRUDForm>
    )
}
