import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react'

import { useRouteMatch } from 'react-router-dom'

import { get, isEmpty } from 'lodash-es'

const LOCAL_STORAGE_KEY = 'convertProCrm:sections'

function loadDefaultState() {
    let string = localStorage[LOCAL_STORAGE_KEY]

    if (isEmpty(string)) {
        string = '{}'
    }

    return JSON.parse(string)
}

const SectionsContext = createContext(loadDefaultState())

export function useExpandSections() {
    const [state, set] = useContext(SectionsContext)

    const { url } = useRouteMatch()

    const setExpand = useCallback(
        expand => set(state => ({ ...state, [url]: expand })),
        [set, url]
    )

    useEffect(() => {
        localStorage[LOCAL_STORAGE_KEY] = JSON.stringify(state)
    }, [state])

    return [get(state, url), setExpand]
}

export default ({ children }) => {
    return (
        <SectionsContext.Provider value={useState(loadDefaultState())}>
            {children}
        </SectionsContext.Provider>
    )
}
