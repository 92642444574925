import React, { useMemo, useCallback, Fragment } from 'react'

import styled from 'styled-components'

import {
    InputContainer,
    makeRandomName,
    InputStyles,
    Label,
    InnerInputContainer,
} from './input'

import { get } from 'lodash-es'

import DebouncedInput from './debounced-input'

const Input = styled(DebouncedInput)`
    ${InputStyles}
`

const InputsRow = styled.div`
    display: flex;
    align-items: center;
`

const Separator = styled.div`
    margin: 0 0.5rem;
    color: ${props => props.theme.gray2};
    width: 1rem;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        transform: translateY(50%);
        background-color: ${props => props.theme.gray2};
    }
`

const emptyArray = []

export default ({ label, onChange, value, wait = 500, immediate }) => {
    const range = useMemo(() => [0, 1], [])

    const onInputValueChange = useCallback(
        ({ i, value }) => {
            onChange(prevValue => {
                const nextValue = [...(prevValue || emptyArray)]

                nextValue[i] = Number(value)

                return nextValue
            })
        },
        [onChange]
    )

    const inputs = useMemo(() => {
        return range.map(i => ({
            name: makeRandomName(),
            id: makeRandomName(),
            onChange: value => onInputValueChange({ i, value }),
            type: 'number',
            placeholder: i === 0 ? 'From' : 'To',
        }))
    }, [range, onInputValueChange])

    return (
        <InputContainer marginBottom>
            <InnerInputContainer>
                <Label htmlFor={inputs[0].id}>{label}</Label>
                <InputsRow>
                    {inputs.map((props, i) => (
                        <Fragment key={props.name}>
                            <Input
                                {...props}
                                value={get(value, i) || ''}
                                wait={wait}
                                immediate={immediate}
                            />

                            {i === 0 && <Separator></Separator>}
                        </Fragment>
                    ))}
                </InputsRow>
            </InnerInputContainer>
        </InputContainer>
    )
}
