import React, { useRef, useEffect } from 'react'

import { useQuery } from 'system/network/graphql'

import { isFunction, get } from 'lodash-es'

import { empty } from 'crm-components/data-helpers'

import Select from 'ui-components/select'

const QUERY = /* GraphQL */ `
    query GetValues($name: String!) {
        __type(name: $name) {
            name
            enumValues {
                name
            }
        }
    }
`

const noop = []

export default ({
    disabled,
    onLoadingChange,
    onLoadOptions,
    onChange,
    enumName: name,
    ...rest
}) => {
    const { loading, cacheValue } = useQuery({
        operation: {
            query: QUERY,
            variables: { name },
        },
    })

    const onLoadOptionsCalled = useRef(false)

    const options = (
        get(cacheValue, `data.__type.enumValues`) || noop
    ).map(({ name }) => ({ _id: name, name }))

    useEffect(() => {
        if (isFunction(onLoadingChange)) onLoadingChange(loading)
    }, [loading, onLoadingChange])

    useEffect(() => {
        if (onLoadOptionsCalled.current) return

        if (empty(options)) return

        if (!isFunction(onLoadOptions)) return

        onLoadOptions(options)

        onLoadOptionsCalled.current = true
    }, [options, onLoadOptions])

    return (
        <Select
            options={options}
            disabled={disabled || loading}
            onValueChange={onChange}
            {...rest}
        />
    )
}
