import { useEffect, useRef, useMemo } from 'react'

import { useQuery } from 'system/network/graphql'

import { isFunction } from 'lodash-es'

import { empty, getDataNodes } from 'crm-components/data-helpers'

const OPTION_QUERY_TEMPLATE = /* GraphQL */ `
    query Get($search: RECORD_NAMESearch) {
        QUERY_NAME(
            pagination: { pageNumber: 1, pageSize: 50000 }
            search: $search
        ) {
            nodes {
                ... on RECORD_NAME {
                    FRAGMENT_FIELDS
                }
            }
        }
    }
`

function makeQuery({
    queryName,
    recordName,
    customQuery,
    fragmentFields: defaultFragmentFields,
}) {
    if (!empty(customQuery)) return customQuery

    let fragmentFields = empty(defaultFragmentFields)
        ? '_id, name'
        : defaultFragmentFields

    let result = OPTION_QUERY_TEMPLATE.replace('QUERY_NAME', queryName)
        .replace(/RECORD_NAME/g, recordName)
        .replace(/FRAGMENT_FIELDS/, fragmentFields)

    return result
}

const noopObject = {}

export default ({
    customQuery,
    disabled,
    queryName,
    onLoadingChange,
    recordName,
    onLoadOptions,
    render,
    search = noopObject,
    fragmentFields,
    optionMapper,
    ...rest
}) => {
    const previousCacheValue = useRef()

    const { loading, cacheValue } = useQuery({
        loadOnMount: empty(getDataNodes(previousCacheValue.current)),
        loadOnReload: false,
        operation: {
            query: makeQuery({
                queryName,
                recordName,
                customQuery,
                fragmentFields,
            }),
            variables: { search },
        },
    })

    previousCacheValue.current = cacheValue

    const onLoadOptionsCalled = useRef(false)

    const options = useMemo(() => {
        const options = getDataNodes(cacheValue)

        if (!empty(options)) {
            if (isFunction(optionMapper)) {
                return options.map(optionMapper)
            }
        }

        return options
    }, [cacheValue, optionMapper])

    useEffect(() => {
        if (isFunction(onLoadingChange)) onLoadingChange(loading)
    }, [loading, onLoadingChange])

    useEffect(() => {
        if (onLoadOptionsCalled.current) return

        if (empty(options)) return

        if (!isFunction(onLoadOptions)) return

        onLoadOptions(options)

        onLoadOptionsCalled.current = true
    }, [options, onLoadOptions])

    return render({
        ...rest,
        options,
        loading,
        disabled: disabled || (empty(options) && loading),
    })
}
