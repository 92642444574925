import React, { useCallback } from 'react'
import { isFunction } from 'lodash-es'
import { useDisabled, useLoading } from './hooks/fields'
import DefaultButton from 'ui-components/button'
import useFormSubmit from './hooks/use-form-submit'

export default ({
    enableLoading = true,
    type = 'submit',
    onClick,
    disabled,
    ...props
}) => {
    const [loading] = useLoading()

    const [formDisabled] = useDisabled()

    const submit = useFormSubmit()

    const onButtonClick = useCallback(
        async e => {
            if (type === 'submit') {
                await submit()
            }
            if (isFunction(onClick)) onClick(e)
        },
        [type, onClick, submit]
    )

    return (
        <DefaultButton
            {...props}
            onClick={onButtonClick}
            disabled={disabled || formDisabled}
            loading={enableLoading && loading}
        />
    )
}
