import useContextField from 'helpers/use-context-field'

import { FormContext } from '../provider'

const emptyArray = []

export const useDisabled = () => useContextField('disabled', FormContext)

export const useLoading = () => useContextField('loading', FormContext)

export const useDirtyData = () => useContextField('dirtyData', FormContext)

export const useFieldDirty = name =>
    useContextField(`dirtyData.${name}`, FormContext)

export const useData = () => useContextField('data', FormContext)

export const useFieldData = name => useContextField(`data.${name}`, FormContext)

export const useExcludeFromInput = () => {
    const [state, set] = useContextField('excludeFromInput', FormContext)

    return [state || emptyArray, set]
}

export const useSaveChangesToLocalStorage = () =>
    useContextField('saveChangesToLocalStorage', FormContext)

export const useStaticFormFields = () => {
    const [state, set] = useContextField('staticFormFields', FormContext)

    return [state || emptyArray, set]
}

export const useFormName = () => useContextField('formName', FormContext)

export const useValidationSchema = () =>
    useContextField('validationSchema', FormContext)

export const useSubmitHandler = () => useContextField('submit', FormContext)

export const useOnValidationErrorsChange = () =>
    useContextField('onValidationErrorsChange', FormContext)

export const useOnDataChange = () =>
    useContextField('onDataChange', FormContext)

export const useOnDirtyChange = () =>
    useContextField('onDirtyChange', FormContext)

export const useSaveOnEnter = () => useContextField('saveOnEnter', FormContext)

export const useNoRemoteData = () =>
    useContextField('noRemoteData', FormContext)

export const useMutationCacheValue = () =>
    useContextField('mutationCacheValue', FormContext)

export const useQueryCacheValue = () =>
    useContextField('queryCacheValue', FormContext)
