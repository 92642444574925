import React, { useContext, useCallback, useEffect } from 'react'

import Section from 'ui-components/section'

import { useToggleSection } from 'ui-components/section/hooks'

import { Fix } from 'ui-components/list/common'

import { isFunction, isEqual } from 'lodash-es'

import { Row, Col } from 'react-styled-flexboxgrid'

import { ListContext } from './provider'

import SearchActions from 'crm-components/search-actions'

import { BiSearchAlt2 } from 'react-icons/bi'

export default () => {
    const [
        {
            renderSearchSection: SearchRenderer,
            renderStaticSearch: StaticSearch,
            ...state
        },
        set,
    ] = useContext(ListContext)

    const clearSearch = useCallback(() => {
        set(state => {
            const defaultSearch = state.staticSearchFields.reduce(
                (search, field) => {
                    search[field] = state.search[field]
                    return search
                },
                {}
            )
            return { ...state, search: defaultSearch }
        })
    }, [set])

    const toggle = useToggleSection()

    useEffect(() => {
        set(state => {
            const newState = {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageNumber: 1,
                },
            }

            if (!isEqual(state, newState)) {
                return newState
            }

            return state
        })
    }, [state.search, set])

    if (!isFunction(SearchRenderer)) return null

    return (
        <>
            <Section expanded={false} title="Search" icon={BiSearchAlt2}>
                <Row>
                    <SearchRenderer />
                    <Col xs={12}>
                        <SearchActions onClear={clearSearch} onClose={toggle} />
                    </Col>
                </Row>
                <Fix />
            </Section>
            {isFunction(StaticSearch) && <StaticSearch />}
        </>
    )
}
