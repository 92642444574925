import React, { useContext, useCallback } from 'react'

import ModalComponent from 'ui-components/modal'

import { ModalContext } from 'system/ui/modal'
import { isFunction } from 'lodash-es'

const MODAL_CLOSED_EVENT = 'MODAL_CLOSED_EVENT'

const useConfirmModal = () => {
    const [state, set] = useContext(ModalContext)

    const setConfirmModal = useCallback(
        confirmModal => {
            set(state => ({
                ...state,
                confirmModal: isFunction(confirmModal)
                    ? confirmModal(state.confirmModal)
                    : confirmModal,
            }))
        },
        [set]
    )

    return [state.confirmModal, setConfirmModal]
}

/**
 * await confirm({ message: 'sure?', onAffirmative: save })
 */

export const useConfirm = () => {
    const [, setConfirmModal] = useConfirmModal()

    const confirm = message => {
        setConfirmModal({
            message,
            open: true,
        })

        return new Promise(resolve => {
            document.addEventListener(MODAL_CLOSED_EVENT, e => {
                resolve(e.detail)
                setConfirmModal(state => ({
                    ...state,
                    open: false,
                }))
            })
        })
    }

    return confirm
}

export const useAffirmativeConfirm = () => {
    const [, setConfirmModal] = useConfirmModal()

    const confirm = ({ message, title, onAffirmative }) => {
        setConfirmModal({
            title,
            message,
            open: true,
            loading: false,
        })

        return new Promise(resolve => {
            const onClose = async e => {
                if (!e.detail) {
                    resolve(e.detail)
                    return setConfirmModal(state => ({ ...state, open: false }))
                }

                setConfirmModal(state => ({
                    ...state,
                    loading: true,
                }))

                await onAffirmative()

                resolve(e.detail)

                setConfirmModal(state => ({
                    ...state,
                    open: false,
                    loading: false,
                }))

                document.removeEventListener(MODAL_CLOSED_EVENT, onClose)
            }

            document.addEventListener(MODAL_CLOSED_EVENT, onClose)
        })
    }

    return confirm
}

const DEFAULT_TITLE = 'Confirmation'

export const ConfirmModal = () => {
    const [confirmModal] = useConfirmModal()

    const close = useCallback((result = false) => {
        document.dispatchEvent(
            new CustomEvent(MODAL_CLOSED_EVENT, { detail: result })
        )
    }, [])

    const affirmativeClose = useCallback(() => close(true), [close])

    return (
        <ModalComponent
            title={confirmModal.title || DEFAULT_TITLE}
            open={confirmModal.open}
            onClose={close}
            loading={confirmModal.loading}
            disabled={confirmModal.loading}
            onAffirmativeClose={affirmativeClose}
        >
            {isFunction(confirmModal.message) ? (
                <confirmModal.message />
            ) : (
                confirmModal.message
            )}
        </ModalComponent>
    )
}
