import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_QUERY = /* GraphQL */ `
    query GetCompanies(
        $search: CompanySearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        companies(search: $search, sort: $sort, pagination: $pagination) {
            totalPages
            count
            nodes {
                ... on Company {
                    _id
                    name
                    website
                    phone
                    purchasedAt
                    demoExpiresAt
                    createdAt
                }
            }
        }
    }
`

const DELETE_QUERY = /* GraphQL */ `
    mutation DeleteCompanies($ids: [ID!]!) {
        deleteCompanies(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Purchase date',
                key: 'purchasedAt',
                sortKey: 'purchasedAt',
            },
            {
                name: 'Demo expiration',
                key: 'demoExpiresAt',
                sortKey: 'demoExpiresAt',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_QUERY}
            deleteQuery={DELETE_QUERY}
            pageTitle={'Companies'}
            entity="company"
            cols={cols}
            record="Company"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField name="phone" label="Phone" />
                        <SearchField name="website" label="Website" />
                        <SearchField
                            name="purchasedAt"
                            label="Purchase date"
                            type="dateRangePicker"
                        />
                        <SearchField
                            name="demoExpiresAt"
                            label="Demo expiration"
                            type="dateRangePicker"
                        />
                        <SearchField
                            name="createdAt"
                            label="Created at"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
