import React, { useCallback, useEffect, useState } from 'react'

import Modal from 'ui-components/modal'

import Form from 'modules/lead/form'

import { camelCaseToSentenceCase } from 'crm-components/data-helpers'

export default ({ open, onChange, resolveType = 'Lead' }) => {
    const [modalOpen, setModalOpen] = useState(open)

    const close = useCallback(() => setModalOpen(false), [])

    useEffect(() => {
        setModalOpen(open)
    }, [open])

    return (
        <Modal
            modalStyle={{ maxWidth: 800 }}
            open={modalOpen}
            onClose={close}
            title={'Add ' + camelCaseToSentenceCase(resolveType)}
            affirmativeText="Save"
        >
            <Form />
        </Modal>
    )
}
