import React from 'react'
import { get } from 'lodash-es'
import useRemoteData from 'crm-components/form/hooks/use-remote-data'
import UserSelect from './user-select'
import styled from 'styled-components'
import Input from 'ui-components/input'
import Section from 'ui-components/section'
import { formatValue } from './data-helpers'
import { FaRegQuestionCircle } from 'react-icons/fa'

import { FormFields, FormFieldContainer } from 'crm-components/crud-form'

import HistoryViewer from './history-viewer'

import { useResolveType } from './crud-form/hooks/fields'

const Fix = styled.div`
    overflow: hidden;
`

export default () => {
    const data = useRemoteData()

    const [resolveType] = useResolveType()

    const createdAt = formatValue(get(data, 'createdAt'))

    const createdBy = get(data, 'createdById')

    const updatedAt = formatValue(get(data, 'updatedAt'))

    const assignedTo = get(data, 'assignedToId')

    return (
        <Section icon={FaRegQuestionCircle} title="Record details">
            <FormFields>
                <FormFieldContainer>
                    <Input label="Created at" value={createdAt} readOnly />
                </FormFieldContainer>
                <FormFieldContainer>
                    <UserSelect label="Created by" value={createdBy} readOnly />
                </FormFieldContainer>
                <FormFieldContainer>
                    <Input label="Updated at" value={updatedAt} readOnly />
                </FormFieldContainer>
                {assignedTo && (
                    <FormFieldContainer>
                        <UserSelect
                            label="Assigned to"
                            value={assignedTo}
                            readOnly
                        />
                    </FormFieldContainer>
                )}
                <HistoryViewer
                    resolveType={resolveType}
                    recordId={get(data, '_id')}
                />
            </FormFields>
            <Fix />
        </Section>
    )
}
