import React, { useContext } from 'react'

import { isFunction } from 'lodash-es'

import styled from 'styled-components'

import Queries from './queries'

import { ListContext } from './provider'

import { empty } from 'crm-components/data-helpers'

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
`
const Push = styled.div``

export default () => {
    const [
        { predefinedQueries, renderActions: HeaderActions, selectedRows },
    ] = useContext(ListContext)

    if (empty(predefinedQueries) && !isFunction(HeaderActions)) return null

    return (
        <HeaderContainer>
            <Queries />

            {isFunction(HeaderActions) ? (
                <>
                    <Push />
                    <HeaderActions selectedRows={selectedRows} />
                </>
            ) : null}
        </HeaderContainer>
    )
}
