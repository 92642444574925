import { isEqual, pick } from 'lodash-es'
import { useContext, useEffect, useRef, useState } from 'react'

/**
 * Usage: useBindPropContextValue({ someValue })
 *
 * @param {[fieldName]: fieldValue} data
 *
 */

export default (data, Context) => {
    const [, set] = useContext(Context)

    const [keys, setKeys] = useState([])

    const [values, setValues] = useState([])

    useEffect(() => {
        setKeys(keys => {
            const newKeys = Object.keys(data)

            if (isEqual(newKeys, keys)) return keys

            return newKeys
        })
    }, [data])

    useEffect(() => {
        setValues(values => {
            const newValues = Object.keys(data).map(key => data[key])

            if (isEqual(newValues, values)) return values

            return newValues
        })
    }, [data])

    useEffect(() => {
        set(state => {
            const newState = keys.reduce((data, key, i) => {
                data[key] = values[i]

                return data
            }, {})

            const stateValues = pick(state, keys)

            if (isEqual(newState, stateValues)) return state

            return { ...state, ...newState }
        })
    }, [keys, values, set])
}
