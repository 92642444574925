import React, { useMemo } from 'react'

import QuickView from './quick-view'

import List from 'crm-components/list'

import ListSearch from './list-search'

import ListActions from './list-actions'

import { COLUMN_PRIORITY_LOW } from 'ui-components/list/common'

import { useContactPredefindQueries } from 'crm-components/contact-predefined-queries'
import { getUserName } from 'crm-components/data-helpers'

const GET_OWNERS = /* GraphQL */ `
    query GetOwners(
        $search: OwnerSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        owners(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Owner {
                    _id
                    name
                    email
                    mobile
                    phone
                    officialDocumentNumber
                    officialDocumentExpiryDate
                    address
                    city
                    ownerStatus {
                        name
                    }
                    country {
                        name
                    }
                    assignedTo {
                        profile {
                            firstName
                            lastName
                        }
                    }
                    createdAt
                }
            }
        }
    }
`

const DELETE_OWNERS = /* GraphQL */ `
    mutation DeleteOwners($ids: [ID!]!) {
        deleteowners(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    switch (row[col.key]) {
        case 'assignedTo':
            return getUserName(row.assignedTo)
        default:
            return
    }
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Email',
                key: 'email',
                sortKey: 'email',
            },
            {
                name: 'Mobile',
                key: 'mobile',
                sortKey: 'mobile',
            },
            {
                name: 'Status',
                key: 'ownerStatus',
                priority: COLUMN_PRIORITY_LOW,
            },
            {
                name: 'Assigned',
                key: 'assignedTo',
                priority: COLUMN_PRIORITY_LOW,
            },
        ],
        []
    )

    const predefinedQueries = useContactPredefindQueries({
        resolveType: 'Owner',
    })

    return (
        <List
            getQuery={GET_OWNERS}
            deleteQuery={DELETE_OWNERS}
            pageTitle={'Owners'}
            entity="owner"
            cols={cols}
            record="Owner"
            renderCell={Cell}
            renderActions={ListActions}
            renderSearchSection={ListSearch}
            predefinedQueries={predefinedQueries}
            showQuickFiltersLoader
            renderExpandedRow={QuickView}
            expandable
        />
    )
}
