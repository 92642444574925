import React, {
    useState,
    createContext,
    useContext,
    useCallback,
    useMemo,
} from 'react'

import { useAlert } from './alert'

import styled from 'styled-components'

import { useCan } from 'system/account/acl'

import { useFullScreen } from '../core/route'

import { NavLink as RouterLink } from 'react-router-dom'

import { Menu, MenuItem as DefaultMenuItem } from './menu-item'

const Icon = styled.div`
    margin-right: 0.5rem;
    opacity: 0.5;
    display: inline-block;
`

const SidebarContainer = styled.nav`
    position: absolute;
    top: ${props => (props.alertVisible ? props.theme.headerHeight : 0)};
    left: 0;
    bottom: 0;
    width: ${props => props.theme.sidebarWidth};
    box-shadow: 0 0 8px 0 rgba(96, 103, 112, 0.32);
    transition: transform ease-in-out 0.2s;
    transform: ${props =>
        props.open ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'};
    @media ${props => props.theme.media.md} {
        transform: translate3d(0, 0, 0);
    }
    z-index: 100;
`

const Content = styled.div`
    position: absolute;
    top: calc(${props => props.theme.headerHeight});
    background-color: white;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    overflow: scroll;
`

const StyledLink = styled(RouterLink)`
    text-decoration: none;
    color: black;
    font-weight: 200;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0 0;
    padding: 0.5rem;
    user-select: none;
    outline: 0;
    background-color: ${props =>
        props.hover ? props.theme.gray : 'transparent'};
    &.active {
        background-color: ${props => props.theme.gray};
    }
`

const SidebarMenu = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: scroll;
`

const StyledMenuItem = styled.li``

const Overlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    background-color: rgba(0, 0, 0, 0.8);
`

const SidebarContentContainer = styled.main`
    position: absolute;
    top: calc(
        ${props => props.theme.headerHeight}
            ${props => (props.alertVisible ? ' * 2 ' : '')} + 0.5rem
    );
    left: 0.5rem;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: white;
    padding: 1rem;
    z-index: 1;
    overflow: auto;

    @media ${props => props.theme.media.md} {
        transform: translate3d(0, 0, 0);
        left: calc(${props => props.theme.sidebarWidth} + 0.5rem);
    }
`

const DEFAULT_STATE = {
    open: false,
    visible: true,
    menu: [],
}

const SidebarContext = createContext([DEFAULT_STATE])

export function useSidebarOpen() {
    const [state, set] = useContext(SidebarContext)

    return [
        state.open,
        useCallback(open => set(state => ({ ...state, open })), [set]),
    ]
}

const Link = ({ children, ...props }) => {
    const [hover, setHover] = useState(false)
    return (
        <StyledLink
            {...props}
            hover={hover ? 1 : 0}
            onFocus={() => setHover(true)}
            onBlur={() => setHover(false)}
        >
            {children}
        </StyledLink>
    )
}

export const MenuItem = ({
    to,
    icon,
    render,
    permission,
    sortOrder,
    isActive,
}) => {
    const item = useMemo(() => {
        return { icon, to, sortOrder, render, isActive, permission }
    }, [to, sortOrder, render, icon, isActive, permission])

    const can = useCan(permission)

    if (!permission) {
        return (
            <DefaultMenuItem
                menuName={'menu'}
                context={SidebarContext}
                item={item}
            />
        )
    }

    return can ? (
        <DefaultMenuItem
            menuName={'menu'}
            context={SidebarContext}
            item={item}
        />
    ) : null
}

const SidebarMenuItem = ({ children }) => {
    const [, setOpen] = useSidebarOpen()
    return (
        <StyledMenuItem onClick={() => setOpen(false)}>
            {children}
        </StyledMenuItem>
    )
}

const Sidebar = () => {
    const [state, set] = useContext(SidebarContext)

    const [fullScreen] = useFullScreen()

    const { alert } = useAlert()

    return !fullScreen ? (
        <>
            <SidebarContainer open={state.open} alertVisible={alert.visible}>
                <Content>
                    <SidebarMenu>
                        <Menu
                            menuName="menu"
                            context={SidebarContext}
                            renderItem={({
                                key,
                                to,
                                isActive,
                                icon,
                                render,
                            }) => (
                                <SidebarMenuItem key={key}>
                                    <Link to={to} isActive={isActive}>
                                        <Icon>{icon}</Icon>
                                        {render}
                                    </Link>
                                </SidebarMenuItem>
                            )}
                        />
                    </SidebarMenu>
                </Content>
            </SidebarContainer>

            {state.open && (
                <Overlay
                    onClick={() => set(state => ({ ...state, open: false }))}
                />
            )}
        </>
    ) : null
}

export default ({ children }) => {
    const [state, set] = useState(DEFAULT_STATE)

    return (
        <SidebarContext.Provider value={[state, set]}>
            <Sidebar />
            {children}
        </SidebarContext.Provider>
    )
}

export const SidebarContent = ({ children }) => {
    const [fullScreen] = useFullScreen()

    const { alert } = useAlert()

    return fullScreen ? (
        children
    ) : (
        <SidebarContentContainer alertVisible={alert.visible}>
            {children}
        </SidebarContentContainer>
    )
}
