import React from 'react'

import { empty } from './data-helpers'

import IconBoxSelector, { IconBoxLoader } from 'ui-components/icon-box-selector'

import CollectionFetcher from 'crm-components/collection-fetcher'

export default ({ loaderCount, ...props }) => {
    return (
        <CollectionFetcher
            {...props}
            render={({ loading, options, ...props }) =>
                empty(options) ? (
                    <IconBoxLoader count={loaderCount} />
                ) : (
                    <IconBoxSelector {...props} options={options} />
                )
            }
        />
    )
}
