import React, {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'

import { empty, getDataKey } from 'crm-components/data-helpers'

import { isEqual, get, isArray } from 'lodash-es'

import { ListContext } from './provider'

import { operate } from 'system/network/graphql'

import Queries from 'ui-components/list/queries'

export default () => {
    const [
        { predefinedQueries, search, getQuery, showQuickFiltersLoader },
        set,
    ] = useContext(ListContext)

    const [predefinedQueriesResult, setPredefinedQueriesResult] = useState([])

    const queryResultsAreReady = useMemo(() => {
        return predefinedQueriesResult.reduce(
            (ready, result) =>
                ready &&
                typeof result.count !== 'undefined' &&
                predefinedQueriesResult.length === predefinedQueries.length,
            !empty(predefinedQueriesResult)
        )
    }, [predefinedQueries, predefinedQueriesResult])

    const [
        activePredefinedQueryResult,
        setActivePredefinedQueryResult,
    ] = useState(null)

    useEffect(() => {
        if (!isArray(predefinedQueries)) return

        const predefinedQuery = predefinedQueries.find(query =>
            isEqual(query.filter, search)
        )

        if (empty(predefinedQuery)) {
            setActivePredefinedQueryResult(null)
        } else {
            setActivePredefinedQueryResult(
                predefinedQueriesResult.find(result => {
                    return result.name === predefinedQuery.name
                })
            )
        }
    }, [search, predefinedQueries, predefinedQueriesResult])

    const onQueryClick = useCallback(
        ({ name }) => {
            const search = predefinedQueries.find(query => query.name === name)
                .filter
            set(state => ({ ...state, search }))
        },
        [set, predefinedQueries]
    )

    useEffect(() => {
        if (!isArray(predefinedQueries)) return

        const promises = predefinedQueries.map(query => {
            const { cacheValuePromise } = operate({
                operation: {
                    query: getQuery,
                    variables: {
                        search: query.filter,
                        pagination: { pageSize: 1 },
                    },
                },
            })
            return cacheValuePromise
        })

        Promise.all(promises).then(results => {
            setPredefinedQueriesResult(
                predefinedQueries.map((query, i) => {
                    const key = getDataKey(results[i])
                    const count = get(results[i], `data.${key}.count`)
                    return {
                        name: query.name,
                        count: count,
                    }
                })
            )
        })
    }, [predefinedQueries, getQuery, predefinedQueriesResult.length])

    const isActive = useCallback(
        query => {
            return activePredefinedQueryResult === query
        },
        [activePredefinedQueryResult]
    )

    return (
        <Queries
            loading={showQuickFiltersLoader && !queryResultsAreReady}
            queries={predefinedQueriesResult}
            onQueryClick={onQueryClick}
            isActive={isActive}
        />
    )
}
