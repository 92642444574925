import {
    useData,
    useOnDataChange,
    useOnDirtyChange,
    useOnValidationErrorsChange,
} from './hooks/fields'

import useDirty from './hooks/use-dirty'

import { useEffect } from 'react'

import { isFunction } from 'lodash-es'

import useAutoLoad from './hooks/use-auto-load'

import useAutoSave from './hooks/use-auto-save'

import useAutoClear from './hooks/use-auto-clear'

import useDirtyDetector from './hooks/use-dirty-detector'

import useValidationErrors from './hooks/use-validation-errors'

export default ({ children }) => {
    const [validationErrors] = useValidationErrors()

    const [onValidationErrorsChange] = useOnValidationErrorsChange()

    useEffect(() => {
        if (isFunction(onValidationErrorsChange))
            onValidationErrorsChange(validationErrors)
    }, [onValidationErrorsChange, validationErrors])

    const [onDataChange] = useOnDataChange()

    const [onDirtyChange] = useOnDirtyChange()

    const [dirty] = useDirty()

    const [data] = useData()

    useDirtyDetector()

    useEffect(() => {
        if (isFunction(onDataChange)) onDataChange(data)
    }, [onDataChange, data])

    useAutoClear()

    useAutoSave()

    useAutoLoad()

    useEffect(() => {
        if (isFunction(onDirtyChange)) onDirtyChange(dirty)
    }, [dirty, onDirtyChange])

    return children
}
