import React from 'react'

import { Route } from 'system/core/route'

import Page from './page'

import { MenuItem } from 'system/layout/sidebar'

import { IoMdHelpBuoy } from 'react-icons/io'

export default () => {
    return (
        <>
            <MenuItem
                to={'/getting-started'}
                icon={<IoMdHelpBuoy />}
                render={'Getting started'}
                sortOrder={20}
                permission={{
                    record: 'UserProfile',
                    method: 'READ',
                }}
            />
            <Route
                path={'/getting-started'}
                permission={{
                    record: 'UserProfile',
                    method: 'READ',
                }}
            >
                <Page />
            </Route>
        </>
    )
}
