/* global google */
import React, { useRef, useEffect, useState, useMemo } from 'react'

import styled from 'styled-components'

import { isEmpty, get, isFunction } from 'lodash-es'

import { empty } from 'crm-components/data-helpers'

import Loader from 'ui-components/loader'

const MAP_HEIGHT = '300px'

const Map = styled.div`
    height: ${MAP_HEIGHT};
    opacity: ${props => (props.queryLoading ? 0 : props.disabled ? 0.5 : 1)};
    position: ${props => (props.queryLoading ? 'absolute' : 'relative')};
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        pointer-events: ${props => (props.disabled ? 'initial' : 'none')};
    }
`

const LoaderContainer = styled(Map)`
    height: ${MAP_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
`

const Directions = styled.a.attrs(() => ({
    target: '_blank',
    rel: 'noopener noreferrer',
}))`
    color: ${props => props.theme.blue};
    display: block;
    overflow: hidden;
    padding: 1rem 0;
`

const GOOGLE_MAP_API_KEY = 'AIzaSyAV-bOJbhKMpW3K-_53HoWJh4aMXOHVgeQ'

const DEFAULT_CENTER = {
    lng: 55.274461,
    lat: 25.198235,
}

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function getLocationBias() {
    return new Promise(resolve => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                if (isApiLoaded()) {
                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy,
                    })
                    resolve(circle.getBounds())
                } else {
                    resolve(null)
                }
            })
        }
        resolve(null)
    })
}

function isApiLoaded() {
    return typeof google !== 'undefined' && typeof google.maps !== 'undefined'
}

function useLoadMapApi() {
    const [, setForce] = useState(false)

    useEffect(() => {
        if (isApiLoaded()) return
        let googleScript = document.getElementById('google-script')

        if (!googleScript) {
            googleScript = document.createElement('script')
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
            googleScript.async = true
            googleScript.defer = true
            googleScript.id = 'google-script'
            window.document.body.appendChild(googleScript)
            googleScript.onload = () => {
                setForce(force => !force)
            }
        }
    }, [])
}

function useGoogleMaps({ element, value, onChange }) {
    const googleMap = useRef(null)
    const marker = useRef(null)
    const listeners = useMemo(() => [], [])

    useEffect(() => {
        if (!isFunction(onChange)) return

        if (listeners.indexOf(onChange) < 0) {
            listeners.splice(0, listeners.length)
            listeners.push(onChange)
        }
    }, [onChange, listeners])

    useEffect(() => {
        if (isApiLoaded() && !empty(element.current)) {
            if (
                empty(googleMap.current) &&
                empty(marker.current) &&
                !empty(value)
            ) {
                googleMap.current = new google.maps.Map(element.current, {
                    zoom: 16,
                    center: value,
                })

                marker.current = new google.maps.Marker({
                    position: value,
                    map: googleMap.current,
                    draggable: true,
                })

                google.maps.event.addListener(
                    marker.current,
                    'dragend',
                    function (event) {
                        var lat = event.latLng.lat()
                        var lng = event.latLng.lng()
                        for (const listener of listeners) {
                            listener({ lat, lng })
                        }
                    }
                )
            }
        }

        if (
            !empty(googleMap.current) &&
            !empty(marker.current) &&
            !empty(value)
        ) {
            googleMap.current.setCenter(value)
            marker.current.setPosition(value)
        }
    })

    return { marker, googleMap }
}

function useLocationBias() {
    const bias = useRef(null)

    useEffect(() => {
        getLocationBias().then(locationBias => {
            if (locationBias) {
                bias.current = bias
            }
        })
    }, [])

    return bias.current
}

function useSearchQuery({ query, onChange, locationBias, googleMap }) {
    useEffect(() => {
        if (isEmpty(query)) return

        if (typeof google === 'undefined') return

        if (empty(googleMap.current)) return

        const service = new google.maps.places.PlacesService(googleMap.current)

        const request = {
            query,
            fields: ['name', 'geometry'],
            locationBias,
        }

        service.findPlaceFromQuery(request, function (results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                const place = results[0]

                const location = get(place, 'geometry.location')

                if (isEmpty(location)) return

                let center = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }

                onChange(center)
            }
        })
    }, [query, onChange, googleMap, locationBias])
}

export default ({
    value: componentValue,
    onChange,
    query,
    disabled,
    queryLoading,
}) => {
    const value = componentValue || DEFAULT_CENTER

    useLoadMapApi()

    const googleMapElement = useRef(null)

    const { googleMap } = useGoogleMaps({
        element: googleMapElement,
        value,
        onChange,
    })

    const locationBias = useLocationBias()

    useSearchQuery({ query, onChange, locationBias, googleMap })

    return (
        <>
            {queryLoading && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            <Map
                ref={googleMapElement}
                disabled={disabled}
                queryLoading={queryLoading}
            />
            <Directions
                href={
                    value &&
                    `https://www.google.com/maps/dir/?api=1&destination=${value.lat},${value.lng}`
                }
            >
                Get directions
            </Directions>
        </>
    )
}
