import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'
import { yesNo } from 'crm-components/data-helpers'

const GET_PROPERTY_OFFERINGS = /* GraphQL */ `
    query GetPropertyOfferings(
        $search: PropertyOfferingSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        propertyOfferings(
            search: $search
            pagination: $pagination
            sort: $sort
        ) {
            totalPages
            count
            nodes {
                ... on PropertyOffering {
                    _id
                    name
                    propertyFinderValue
                    bayutValue
                    createdAt
                    acceptsMultipleCheques
                }
            }
        }
    }
`

const DELETE_PROPERTY_OFFERINGS = /* GraphQL */ `
    mutation DeletePropertyOfferings($ids: [ID!]!) {
        deletePropertyOfferings(ids: $ids)
    }
`
const Cell = ({ col, row }) => {
    if (col.key === 'acceptsMultipleCheques') {
        return yesNo({ value: row[col.key] })
    }
}
export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Property finder',
                key: 'propertyFinderValue',
                sortKey: 'propertyFinderValue',
            },
            {
                name: 'Bayut value',
                key: 'bayutValue',
                sortKey: 'bayutValue',
            },
            {
                name: 'Multiple Cheques',
                key: 'acceptsMultipleCheques',
                sortKey: 'acceptsMultipleCheques',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_PROPERTY_OFFERINGS}
            deleteQuery={DELETE_PROPERTY_OFFERINGS}
            pageTitle={'Property offering'}
            entity="propertyOffering"
            cols={cols}
            record="PropertyOffering"
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
