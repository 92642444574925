import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'

import styled from 'styled-components'

import { range, isEmpty, get, isFunction, isEqual } from 'lodash-es'

import { IoIosImage } from 'react-icons/io'

import Loader from 'ui-components/loader'

import DefaultCheckbox from 'ui-components/checkbox'

import Section from 'ui-components/section'

import { useMutation, useQuery } from 'system/network/graphql'

import Modal from 'ui-components/modal'

import { getWaterMarkedUrl } from 'system/core/file'

import { useAffirmativeConfirm } from 'crm-components/modal'

import CollectionSelect from 'crm-components/collection-select'

import { IoMdEye } from 'react-icons/io'

import NotSavedAlert from './not-saved-alert'

import {
    getDataObject,
    empty,
    waitFor,
    sleep,
    isChildOf,
} from 'crm-components/data-helpers'

const FILE_FRAGMENT = /* GraphQL */ `
    fragment fileFields on File {
        _id
        parentCollection
        parentId
        url
        waterMarkedUrl
        documentType {
            _id
            name
        }
    }
`

const SAVE_FILES = /* GraphQL */ `
    mutation SaveFiles($uploads: [Upload], $files: [FileInput]) {
        saveFiles(uploads: $uploads, files: $files) {
            ...fileFields
        }
    }
    ${FILE_FRAGMENT}
`

const GET_FILES = /* GraphQL */ `
    query GetFiles($ids: [ID!]!) {
        files(ids: $ids) {
            ...fileFields
        }
    }
    ${FILE_FRAGMENT}
`

const DELETE_FILES = /* GraphQL */ `
    mutation DeleteFiles($ids: [ID!]!) {
        deleteFiles(ids: $ids)
    }
`

const Checkbox = styled(DefaultCheckbox)`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: ${props => props.theme.gray};
    z-index: 1;
`

const GALLERY_SIZE = 15

const IMAGE_RANGE = range(GALLERY_SIZE)

const Wrapper = styled.div`
    overflow: hidden;
`

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem -0.5rem 0.5rem -0.5rem;
`

const BoxWrapper = styled.div`
    flex: 1;
    flex: 100%;
    background-color: ${props => props.theme.gray2};
    margin: 0.5rem;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    @media ${props => props.theme.media.sm} {
        max-width: calc(50% - 1rem);
    }
    @media ${props => props.theme.media.md} {
        max-width: calc(25% - 1rem);
    }
    @media ${props => props.theme.media.lg} {
        max-width: calc(20% - 1rem);
    }
`

const Box = styled.div`
    padding-bottom: 65%;
    position: relative;
    width: 100%;
`
const BoxContent = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ImageIcon = styled(IoIosImage)`
    width: 100px;
    height: 100px;
    padding: 20px;
    color: ${props => props.theme.dark};
    opacity: 0.5;
`

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    background-color: white;
`

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    border: 2px solid ${props => props.theme.gray2};
    margin-bottom: 1rem;
    flex-wrap: wrap;
`

const ActionGroup = styled.div`
    display: flex;
    flex: 100%;
    @media ${props => props.theme.media.md} {
        flex: 1;
        justify-content: ${props => (props.end ? 'flex-end' : 'initial')};
    }
`

const Action = styled.div`
    padding: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
    text-decoration: underline;
    color: ${props => props.theme.dark};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    user-select: none;
`

const FileInput = styled.input.attrs(() => ({
    type: 'file',
    accept: 'image/*',
    multiple: 'multiple',
}))`
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
`

const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.gray2};
    font-size: 1.5rem;
`

const ModalDescription = styled.p`
    margin-bottom: 1rem;
    margin-top: 0;
`

const ImageCategory = styled.div`
    background-color: ${props => props.theme.gray};
    font-size: 0.5rem;
    color: ${props => (props.danger ? props.theme.danger : props.theme.dark)};
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0 0.25rem;
    z-index: 1;
`

const PreviewIcon = styled.a`
    display: flex;
    background-color: ${props => props.theme.gray};
    font-size: 0.8rem;
    color: ${props => props.theme.dark};
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    padding: 0 0.25rem;
    z-index: 1;
`

const Fix = styled.div`
    overflow: hidden;
`

const Preview = ({
    showLoader,
    file,
    setSelected,
    selected,
    category,
    disabled,
}) => {
    const url = getWaterMarkedUrl({ file })

    const onBoxWrapperClick = useCallback(
        e => {
            if (isChildOf({ node: e.target, parentSelector: 'a' })) {
                return
            }

            if (disabled) return

            setSelected(!selected)
        },
        [disabled, selected, setSelected]
    )

    return (
        <BoxWrapper disabled={isEmpty(url) ? 1 : 0} onClick={onBoxWrapperClick}>
            <Box>
                <BoxContent>
                    <Checkbox
                        disabled={disabled || isEmpty(url)}
                        value={selected}
                        onChange={checked => setSelected(checked)}
                    />
                    {url ? (
                        <>
                            <Image src={url} />
                            <ImageCategory danger={!category ? 1 : 0}>
                                {category || 'No category'}
                            </ImageCategory>
                            <PreviewIcon href={url} target="_blank">
                                <IoMdEye />
                            </PreviewIcon>
                        </>
                    ) : (
                        <ImageIcon />
                    )}

                    {showLoader && (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    )}
                </BoxContent>
            </Box>
        </BoxWrapper>
    )
}

const emptyArray = []

const emptyString = ''

// TODO: reset localUploads and input state after successfull upload.

export default ({
    value: componentValue,
    onChange,
    parentCollection,
    parentId,
    parentRecordName,
    disabled: componentDisabled,
}) => {
    const value = componentValue || emptyArray

    const confirm = useAffirmativeConfirm()

    const fileInput = useRef({})

    const {
        load: saveFiles,
        cacheValue: mutationCacheValue,
        loading: saveMutationLoading,
    } = useMutation({
        operation: {
            query: SAVE_FILES,
        },
    })

    const { loading: deleteMutationLoading, load: deleteFiles } = useMutation({
        operation: { query: DELETE_FILES },
    })

    const { loading: queryLoading, cacheValue: queryCacheValue } = useQuery({
        operation: {
            query: GET_FILES,
            variables: { ids: value },
            // loadOnMount: false
        },
    })

    const loading = queryLoading || saveMutationLoading || deleteMutationLoading

    const disabled = componentDisabled || loading

    const [categoryModalOpen, setCategoryModalOpen] = useState(false)

    const [localUploads, setLocalUploads] = useState([])

    const [selectedFileIds, setSelectedFileIds] = useState(emptyArray)

    const [documentType, setDocumentType] = useState(null)

    const documentTypeId = get(documentType, '_id') || emptyString

    const lastUploadedFiles = useRef(null)

    const remoteFiles = useMemo(() => {
        return getDataObject(queryCacheValue) || emptyArray
    }, [queryCacheValue])

    useEffect(() => {
        setSelectedFileIds(ids =>
            ids.filter(id => remoteFiles.find(file => file._id === id))
        )
    }, [remoteFiles])

    const setFileSelected = useCallback((file, selected) => {
        if (isEmpty(file)) return
        if (selected) {
            setSelectedFileIds(ids => [...ids, file._id])
        } else {
            setSelectedFileIds(ids => ids.filter(id => id !== file._id))
        }
    }, [])

    const isSelected = useCallback(
        file => {
            if (isEmpty(file)) return
            return !!selectedFileIds.find(id => id === file._id)
        },
        [selectedFileIds]
    )

    const onDeleteSelectedFiles = useCallback(async () => {
        if (empty(selectedFileIds)) return

        await confirm({
            message: `Are you sure you want to delete ${selectedFileIds.length} selected file(s)`,
            onAffirmative: async () => {
                deleteFiles({
                    ids: selectedFileIds,
                })
                await sleep(100)

                await waitFor(() => !loading)
            },
        })
    }, [deleteFiles, loading, selectedFileIds, confirm])

    const saveDocumentType = useCallback(
        () =>
            saveFiles({
                files: selectedFileIds.map(_id => ({
                    _id,
                    documentTypeId,
                })),
            }),
        [saveFiles, documentTypeId, selectedFileIds]
    )

    const onSetCategoryModalAffirmativeClose = useCallback(async () => {
        saveDocumentType()

        await sleep(100)

        await waitFor(() => !loading)

        setCategoryModalOpen(false)
    }, [saveDocumentType, loading])

    const selectAll = useCallback(() => {
        setSelectedFileIds(remoteFiles.map(file => file._id))
    }, [remoteFiles])

    const selectNone = useCallback(() => {
        setSelectedFileIds(emptyArray)
    }, [])

    useEffect(() => {
        if (
            !isEmpty(localUploads) &&
            lastUploadedFiles.current !== localUploads
        ) {
            saveFiles({
                uploads: localUploads,
                files: localUploads.map(() => ({ parentCollection, parentId })),
            })
            lastUploadedFiles.current = localUploads
        }
    }, [localUploads, parentCollection, parentId, saveFiles])

    useEffect(() => {
        if (!isFunction(onChange)) return

        const response = getDataObject(mutationCacheValue)

        if (empty(response)) return

        onChange(previousValue => {
            const value = previousValue || emptyArray
            const newValue = [...value, ...response.map(file => file._id)]

            if (!isEqual(newValue, value)) {
                return newValue
            }

            return value
        })
    }, [onChange, mutationCacheValue])

    const isFileLoading = useCallback(
        ({ i }) => {
            if (!loading) return

            if (selectedFileIds.length > 0) {
                return isSelected(remoteFiles[i])
            } else {
                return (
                    i >= remoteFiles.length &&
                    i < localUploads.length + remoteFiles.length
                )
            }
        },
        [loading, selectedFileIds, isSelected, remoteFiles, localUploads]
    )

    return (
        <>
            <Modal
                open={categoryModalOpen}
                onClose={() => setCategoryModalOpen(false)}
                title={`${selectedFileIds.length} image${
                    selectedFileIds.length > 1 ? 's' : ''
                } selected`}
                loading={loading}
                onAffirmativeClose={onSetCategoryModalAffirmativeClose}
            >
                <ModalDescription>
                    Set category of selected image
                    {selectedFileIds.length > 1 ? 's' : ''}
                </ModalDescription>

                <CollectionSelect
                    label="Category"
                    value={get(documentType, '_id')}
                    recordName="DocumentType"
                    queryName="documentTypes"
                    search={{ component: 'IMAGE_GALLERY' }}
                    onChange={category => setDocumentType(category)}
                />
            </Modal>
            <FileInput
                ref={fileInput}
                onChange={({ target: { validity, files } }) => {
                    if (!validity.valid || isEmpty(files)) return
                    setLocalUploads(Array.from(files))
                }}
            />
            <Section title="Images" icon={IoIosImage}>
                <Actions>
                    <ActionGroup>
                        <Action
                            onClick={() => {
                                fileInput.current.click()
                            }}
                            disabled={
                                disabled || remoteFiles.length >= GALLERY_SIZE
                                    ? 1
                                    : 0
                            }
                        >
                            Upload image(s)
                        </Action>
                        <Action
                            disabled={
                                disabled || (selectedFileIds.length > 0 ? 0 : 1)
                            }
                            onClick={onDeleteSelectedFiles}
                        >
                            Delete selected
                        </Action>
                        <Action
                            onClick={() => {
                                setCategoryModalOpen(true)
                            }}
                            disabled={
                                disabled || (selectedFileIds.length > 0 ? 0 : 1)
                            }
                        >
                            Set category
                        </Action>
                    </ActionGroup>
                    <ActionGroup end={1}>
                        <Action
                            disabled={
                                disabled ||
                                (selectedFileIds.length === remoteFiles.length
                                    ? 1
                                    : 0)
                            }
                            onClick={selectAll}
                        >
                            Select all
                        </Action>
                        <Action
                            onClick={selectNone}
                            disabled={
                                disabled || (selectedFileIds.length > 0 ? 0 : 1)
                            }
                        >
                            Select none
                        </Action>
                    </ActionGroup>
                </Actions>
                <Wrapper>
                    <Row>
                        {IMAGE_RANGE.map(i => (
                            <Preview
                                key={i}
                                showLoader={isFileLoading({ i })}
                                file={get(remoteFiles, i)}
                                selected={isSelected(
                                    get(remoteFiles, `[${i}]`)
                                )}
                                setSelected={selected => {
                                    setFileSelected(
                                        get(remoteFiles, `[${i}]`),
                                        selected
                                    )
                                }}
                                category={get(
                                    remoteFiles,
                                    `[${i}].documentType.name`
                                )}
                                disabled={disabled}
                            />
                        ))}
                    </Row>
                </Wrapper>

                <NotSavedAlert
                    parentId={parentId}
                    parentRecordName={parentRecordName}
                    loading={loading}
                    currentRecordName="Image"
                />
                <Fix />
            </Section>
        </>
    )
}
