import React from 'react'

import BalloonSelector from 'ui-components/balloon-selector'

import CollectionFetcher from 'crm-components/collection-fetcher'

export default props => {
    return (
        <CollectionFetcher
            {...props}
            render={props => <BalloonSelector {...props} />}
        />
    )
}
