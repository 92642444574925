import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import { useAccount } from 'system/account'

import { useCan } from 'system/account/acl'

import DocumentsTab from 'crm-components/documents-tab'

const USER_FRAGMENT = /* GraphQL */ `
    fragment userFields on User {
        _id
        email
        activityIds
        documentIds
        roleId
        companyId
    }
`

const SAVE_USER = /* GraphQL */ `
    mutation SaveUser($user: UserInput) {
        saveUser(user: $user) {
            ...userFields
        }
    }
    ${USER_FRAGMENT}
`

const GET_USER = /* GraphQL */ `
    query GetUser($id: ID!) {
        user(id: $id) {
            ...userFields
        }
    }
    ${USER_FRAGMENT}
`

export default () => {
    const { id } = useParams()

    const { account } = useAccount()

    const readCompaniesPermission = useMemo(
        () => ({
            record: 'Company',
            method: 'READ',
            accessModifier: 'ALL',
        }),
        []
    )

    const canReadAllCompanies = useCan(readCompaniesPermission)

    return (
        <CRUDForm
            query={GET_USER}
            mutation={SAVE_USER}
            mutationVariableName="user"
            record="User"
        >
            <Tab title="Details">
                <Section title="User details">
                    <FormFields>
                        <FormField name="email" type="email" label="Email" />
                        <FormField
                            name="password"
                            type="password"
                            label="Password"
                            disabled={account._id === id}
                            instructions={
                                account._id === id
                                    ? 'To change your password, logout and then go to Login > Forgot my password'
                                    : 'Leave empty to keep the password unchanged.'
                            }
                        />
                        <FormField
                            label="Role"
                            name="roleId"
                            type="collectionSelect"
                            queryName="roles"
                            recordName="Role"
                        />
                        <FormField
                            label="Company"
                            name="companyId"
                            type="collectionSelect"
                            queryName="companies"
                            recordName="Company"
                            hideIfCant={readCompaniesPermission}
                            previewBaseLink={canReadAllCompanies && '/company/'}
                        />
                    </FormFields>
                </Section>
            </Tab>
            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'users'}
                            parentId={id}
                            parentRecordName="User"
                        />
                    </FormFields>
                </Section>
            </Tab>

            <DocumentsTab parentResolveType="User" />
        </CRUDForm>
    )
}
