import React, { useCallback } from 'react'

import { Route } from 'system/core/route'

import { useRouteMatch, Switch } from 'react-router-dom'

const Router = ({
    list: List,
    form: Form,
    reOrder: ReOrder,
    record,
    accessModifier,
}) => {
    const { path } = useRouteMatch()

    if (!record) console.error('record was not provided')

    if (!accessModifier) console.error('accessModifier was not provided')

    // defer the actual render to happen inside the route,
    // so route params would be accessible by top level form
    const RouteForm = useCallback(() => <Form />, [])

    const RouteList = useCallback(() => <List />, [])

    return (
        <Switch>
            <Route
                path={path}
                exact
                permission={{
                    record,
                    method: 'READ',
                    accessModifier,
                }}
            >
                <RouteList />
            </Route>
            <Route
                path={`${path}/create`}
                exact
                permission={{
                    record,
                    method: 'READ|WRITE',
                    accessModifier,
                }}
            >
                <RouteForm />
            </Route>
            {ReOrder && (
                <Route
                    path={`${path}/re-order`}
                    exact
                    permission={{ record, method: 'WRITE', accessModifier }}
                >
                    <ReOrder title={record} />
                </Route>
            )}

            <Route
                path={`${path}/:id`}
                exact
                permission={{
                    record,
                    method: 'READ|WRITE',
                    accessModifier,
                }}
            >
                <RouteForm />
            </Route>
        </Switch>
    )
}

export default ({ path, list, form, record, reOrder, accessModifier }) => {
    return (
        <Route path={path}>
            <Router
                list={list}
                form={form}
                record={record}
                accessModifier={accessModifier}
                reOrder={reOrder}
            />
        </Route>
    )
}
