import React from 'react'

import GraphQLProvider from 'system/network/graphql'

import RouteProvider from './route'

import AccountProvider from 'system/account'

import UIProvider from 'system/ui'

import { TopBar } from 'system/layout/topbar'

import { SidebarContent } from 'system/layout/sidebar'

import LayoutProvider from 'system/layout'

import Modules from 'modules'

import AlertProvider from 'system/layout/alert'

const Providers = ({ children }) => {
    return (
        <GraphQLProvider>
            <AccountProvider>
                <RouteProvider>
                    <AlertProvider>
                        <LayoutProvider>
                            <UIProvider>{children}</UIProvider>
                        </LayoutProvider>
                    </AlertProvider>
                </RouteProvider>
            </AccountProvider>
        </GraphQLProvider>
    )
}

export default () => {
    return (
        <Providers>
            <TopBar />
            <SidebarContent>
                <Modules />
            </SidebarContent>
        </Providers>
    )
}
