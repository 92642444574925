import React from 'react'

import styled from 'styled-components'

import ExpandAll from 'ui-components/section/expand-all'

import { FaSave } from 'react-icons/fa'

import { MdDelete } from 'react-icons/md'

import { useDeleteRecords } from 'crm-components/delete-records'

import {
    useDeleteQuery,
    useFormId,
    useMutationLoading,
    useQueryLoading,
    useResolveType,
    useShowSaveStatus,
    useDirty,
    useFormData,
} from './hooks/fields'

import { camelCaseToSentenceCase, empty } from 'crm-components/data-helpers'

import { useOnSave } from './hooks/logic'

import SaveStatus from './save-status'

const Container = styled.div`
    background-color: ${props => props.theme.gray};
    margin: 0.5rem 0 1rem 0;
    display: flex;
    padding: 0 0.5rem 0 0.5rem;
    align-items: center;
`

const Icon = styled.div`
    display: flex;
    opacity: ${props => (props.disabled ? 0.1 : 0.5)};
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    padding: calc(0.5rem - 1px) 0.5rem;
    cursor: pointer;
`

const Push = styled.div`
    flex: 1;
`

export default () => {
    const [id] = useFormId()

    const [deleteQuery] = useDeleteQuery()

    const [resolveType] = useResolveType()

    const save = useOnSave()

    const [dirty] = useDirty()

    const [queryLoading] = useQueryLoading()

    const [mutationLoading] = useMutationLoading()

    const [data] = useFormData()

    const [showSaveStatus] = useShowSaveStatus()

    const onDelete = useDeleteRecords({
        deleteQuery,
        entity: camelCaseToSentenceCase(resolveType),
        selectedRows: [{ _id: id }],
        isSingular: true,
        redirectToListPageOnSuccess: true,
    })

    return (
        <Container>
            <Icon
                onClick={save}
                disabled={
                    empty(data) || !dirty || queryLoading || mutationLoading
                }
            >
                <FaSave />
            </Icon>
            {deleteQuery && (
                <Icon onClick={onDelete} disabled={!id}>
                    <MdDelete />
                </Icon>
            )}
            {showSaveStatus && <SaveStatus />}
            <Push></Push>
            <ExpandAll />
        </Container>
    )
}
