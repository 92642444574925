import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const COUNTRY_FRAGMENT = /* GraphQL */ `
    fragment countryFields on Country {
        _id
        name
        createdAt
    }
`

const SAVE_COUNTRY = /* GraphQL */ `
    mutation SaveCountry($country: CountryInput) {
        saveCountry(country: $country) {
            ...countryFields
        }
    }
    ${COUNTRY_FRAGMENT}
`

const GET_COUNTRY = /* GraphQL */ `
    query GetCountry($id: ID!) {
        country(id: $id) {
            ...countryFields
        }
    }
    ${COUNTRY_FRAGMENT}
`

const DELETE_COUNTRIES = /* GraphQL */ `
    mutation DeleteCountries($ids: [ID!]!) {
        deletecountries(ids: $ids)
    }
`

export default () => {
    return (
        <CRUDForm
            query={GET_COUNTRY}
            mutation={SAVE_COUNTRY}
            mutationVariableName="country"
            record="Country"
            deleteQuery={DELETE_COUNTRIES}
        >
            <Tab title="Details">
                <Section title="Country details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
