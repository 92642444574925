import React, { useCallback, useEffect, useState } from 'react'

import { Row, Col } from 'react-styled-flexboxgrid'

import CollectionSelect from 'crm-components/collection-select'

import {
    useDisabled,
    useFilteredFiles,
    useGetLoading,
    useRemoteFiles,
    useSaveLoading,
    useParentLoading,
} from './hooks'

import Input from 'ui-components/input'

import { empty, escapeRegexPattern } from 'crm-components/data-helpers'

import SearchActions from 'crm-components/search-actions'

import { useToggleSection } from 'ui-components/section/hooks'

export default () => {
    const [remoteFiles] = useRemoteFiles()

    const [, setFilteredFiles] = useFilteredFiles()

    const [disabled] = useDisabled()

    const [parentLoading] = useParentLoading()

    const [getLoading] = useGetLoading()

    const [saveLoading] = useSaveLoading()

    const [search, setSearch] = useState({})

    const toggle = useToggleSection()

    const setFileName = useCallback(value => {
        setSearch(search => ({ ...search, filename: value }))
    }, [])

    const setDocumentType = useCallback(value => {
        setSearch(search => ({ ...search, documentTypeId: value._id }))
    }, [])

    const clearSearch = useCallback(() => {
        setSearch({})
    }, [])

    useEffect(() => {
        setFilteredFiles(() => {
            if (empty(remoteFiles) || empty(search)) return remoteFiles

            const searchFileName = empty(search.filename) ? '' : search.filename

            const pattern = escapeRegexPattern(
                searchFileName.replace(/\s/gi, '')
            )

            return remoteFiles.filter(file => {
                let matched = true

                if (!empty(pattern))
                    matched =
                        matched &&
                        file.filename
                            .replace(/\s/gi, '')
                            .match(new RegExp(pattern, 'i'))

                if (!empty(search.documentTypeId))
                    matched =
                        matched && file.documentTypeId === search.documentTypeId

                return matched
            })
        })
    }, [search, remoteFiles, setFilteredFiles])

    return (
        <Row>
            <Col xs={12} md={9}>
                <Input
                    label="File name"
                    onChange={setFileName}
                    value={search.filename}
                    placeholder="Start typing..."
                />
            </Col>
            <Col xs={12} md={3}>
                <CollectionSelect
                    disabled={
                        disabled || getLoading || saveLoading || parentLoading
                    }
                    queryName="documentTypes"
                    recordName="DocumentType"
                    value={search.documentTypeId}
                    onChange={setDocumentType}
                    label="Document type"
                    search={{ component: 'DOCUMENTS_UPLOADER' }}
                />
            </Col>

            <Col xs={12}>
                <SearchActions onClear={clearSearch} onClose={toggle} />
            </Col>
        </Row>
    )
}
