import React, { useMemo } from 'react'

import AssignAction from 'crm-components/assign-action'

import DropDownMenu from 'ui-components/drop-down-menu'

import ArchiveAction, { ArchivePreLoader } from 'crm-components/archive-action'

const emptyArray = []

export default ({ selectedRows }) => {
    const ids = useMemo(
        () => (selectedRows || emptyArray).map(row => row._id),
        [selectedRows]
    )

    return (
        <>
            <ArchivePreLoader resolveType="Owner" />
            <DropDownMenu>
                <AssignAction ids={ids} resolveType="Owner" />
                <ArchiveAction ids={ids} resolveType="Owner" />
            </DropDownMenu>
        </>
    )
}
