import React, { useState, useCallback, useEffect } from 'react'

import styled, { createGlobalStyle } from 'styled-components'

import Modal from './modal'

import ReactCrop from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'

import DefaultButton from './button'

import { isEqual, isEmpty } from 'lodash-es'

const Button = styled(DefaultButton)`
    margin-left: 1rem;
`

const Styles = createGlobalStyle`
    .ReactCrop__image {
        max-height: 200px;

    }
`

const Crop = styled(ReactCrop)`
    margin: auto;
`

const Instructions = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    pointer-events: none;
`

const CropContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.gray};
    flex: 1;
`

export default ({
    open = true,
    title = 'Edit image',
    src,
    onClose,
    defaultCrop,
    circle,
    onChange,
}) => {
    const [crop, setCrop] = useState(defaultCrop)

    const saveDisabled = isEmpty(crop) || isEqual(crop)

    const onSave = useCallback(() => {
        onChange(crop)
        onClose()
    }, [onClose, crop, onChange])

    useEffect(() => {
        setCrop(defaultCrop)
    }, [open, defaultCrop])

    return (
        <>
            <Styles />
            <Modal
                open={open}
                title={title}
                onClose={onClose}
                closeOnClickOutside={false}
                renderAffirmativeButton={() => (
                    <Button onClick={onSave} disabled={saveDisabled}>
                        OK
                    </Button>
                )}
                renderCancelButton={({ onClose, loading }) => (
                    <Button onClick={onClose} neutral disabled={loading}>
                        Cancel
                    </Button>
                )}
            >
                <CropContainer>
                    <Crop
                        circularCrop={circle}
                        src={src}
                        crop={crop}
                        onChange={(_, percentCrop) =>
                            percentCrop && setCrop(percentCrop)
                        }
                    />
                    {!crop.width && !crop.height && (
                        <Instructions>Drag to crop</Instructions>
                    )}
                </CropContainer>
            </Modal>
        </>
    )
}
