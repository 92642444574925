import React from 'react'

import Section from 'ui-components/section'

import { FormField, FormFields } from 'crm-components/crud-form'

import { Col } from 'react-styled-flexboxgrid'

import styled from 'styled-components'

const Label = styled.label`
    color: ${props => props.theme.primary};
    user-select: none;
    margin-bottom: 1rem;
    display: block;
    font-weight: bold;
`

export default () => {
    // const [data, setData] = useFormData()

    return (
        <Section title="Commission">
            <FormFields>
                <Col xs={12} sm={6}>
                    <Label>Owner commission</Label>
                    <FormField
                        withoutContainer
                        label="Percentage"
                        name="ownerCommissionPercentage"
                    />
                    <FormField
                        label="Amount"
                        name="ownerCommissionAmount"
                        withoutContainer
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <Label>Lead commission</Label>
                    <FormField
                        withoutContainer
                        label="Percentage"
                        name="ownerCommissionPercentage"
                    />
                    <FormField
                        label="Amount"
                        name="ownerCommissionAmount"
                        withoutContainer
                    />
                </Col>
            </FormFields>
        </Section>
    )
}
