import React, { useCallback, useContext } from 'react'

import { get } from 'lodash-es'

import pluralize from 'pluralize'

import { GraphQLContext } from 'graphql-react'

import { operate } from 'system/network/graphql'

import { IoMdArchive } from 'react-icons/io'

import { useFetchStatuses } from './statuses-fetcher'

import { MenuLabel, MenuItem } from 'ui-components/drop-down-menu'

import { empty, getDataObject } from 'crm-components/data-helpers'

import { successToast, errorToast } from 'ui-components/toast'

import { useAffirmativeConfirm } from './modal'

export const ArchivePreLoader = ({ resolveType }) => {
    useFetchStatuses({
        resolveType,
        search: { name: 'archive' },
    })

    return null
}

export default ({ ids, resolveType }) => {
    const statuses = useFetchStatuses({
        resolveType,
        search: { name: 'archive' },
    })

    const archiveStatus = statuses[0]

    const graphql = useContext(GraphQLContext)

    const resolveTypeLower = resolveType.toLowerCase()

    const confirm = useAffirmativeConfirm()

    const archive = useCallback(async () => {
        await confirm({
            message: `Are you sure you want to archive ${
                ids.length
            } ${pluralize(resolveTypeLower, ids.length)}?`,
            onAffirmative: async function () {
                const { cacheValuePromise } = operate({
                    operation: {
                        query: /* GraphQL */ `
                            mutation ChangeStatus(
                                $ids: [ID]
                                $resolveType: String
                                $statusId: ID
                            ) {
                                changeStatus(
                                    ids: $ids
                                    resolveType: $resolveType
                                    statusId: $statusId
                                ) {
                                    affectedIds
                                    unAffectedIds
                                }
                            }
                        `,
                        variables: {
                            ids,
                            resolveType,
                            statusId: archiveStatus._id,
                        },
                    },
                })

                await cacheValuePromise

                const cacheValue = await cacheValuePromise

                const data = getDataObject(cacheValue)

                const affectedLength = get(data, 'affectedIds.length')

                if (!affectedLength) {
                    errorToast(
                        `Cannot archive ${pluralize(
                            resolveTypeLower,
                            ids.length
                        )}`
                    )
                } else if (affectedLength === ids.length) {
                    successToast(`Archived successfully`)
                } else {
                    successToast(
                        `Archived some of the ${resolveTypeLower} successfully`
                    )
                }

                if (affectedLength) {
                    graphql.reload()
                }
            },
        })
    }, [graphql, resolveType, confirm, archiveStatus, resolveTypeLower, ids])

    if (empty(statuses)) return null

    return (
        <>
            <MenuLabel>Archive</MenuLabel>
            <MenuItem
                icon={IoMdArchive}
                closeOnClick={false}
                disabled={empty(ids)}
                onClick={archive}
            >
                Archive
            </MenuItem>
        </>
    )
}
