import React, { useCallback, useContext, useRef, useState } from 'react'

import { CrudFormContext } from '../crud-form'

import { useParams } from 'react-router-dom'

import styled from 'styled-components'

import { empty } from '../data-helpers'

import { IoMdHelpCircleOutline } from 'react-icons/io'

import Modal from 'ui-components/modal'

import { camelCaseToSentenceCase } from 'crm-components/data-helpers'

import pluralize from 'pluralize'

import { isFunction } from 'lodash-es'
import { useDirty } from './hooks/fields'
import { useValidationErrors } from './hooks/fields'

const SaveStatus = styled.div`
    font-size: 0.8rem;
    margin-left: 0.5rem;
    color: ${props => props.theme[props.statusColor]};
    display: flex;
    align-items: center;
    user-select: none;
    cursor: ${props =>
        props.statusColor === 'danger' ? 'pointer' : 'initial'};
`

const Icon = styled(IoMdHelpCircleOutline)`
    margin-left: 0.5rem;
`

const List = styled.ul`
    margin: 0;
`

const Container = styled.div`
    color: ${props => props.theme.dark};
`

const Text = styled.p`
    margin: 0 0 0.5rem 0;
`

const FieldName = styled.span`
    margin-right: 0.5rem;
    display: inline-block;
    color: ${props => props.theme.primary};
    font-weight: bold;
`

function fieldNameTranslation(fieldName) {
    if (!fieldName) return ''

    if (!isFunction(fieldName.replace)) {
        return ''
    }

    return camelCaseToSentenceCase(fieldName.replace(/ids?/i, '')).toLowerCase()
}

export default () => {
    const [dirty] = useDirty()

    const [validationErrors] = useValidationErrors()

    const { id } = useParams()

    const [open, setOpen] = useState(false)

    const openModal = useCallback(
        event => {
            if (empty(validationErrors)) return

            let node = event.target

            while (node) {
                if (node === container.current) {
                    setOpen(true)
                }

                node = node.parentNode
            }
        },
        [validationErrors]
    )

    const onClose = useCallback(() => setOpen(false), [])

    const container = useRef()

    const saveStatus = [
        {
            condition: !empty(validationErrors),
            name: 'save error',
            color: 'danger',
        },
        {
            condition: !id && !dirty && empty(validationErrors),
            name: 'empty',
            color: 'gray2',
        },

        {
            condition: empty(validationErrors) && dirty,
            name: 'not saved',
            color: 'warning',
        },
        {
            condition: id && empty(validationErrors) && !dirty,
            name: 'saved',
            color: 'success',
        },
    ].find(state => state.condition)

    return (
        <SaveStatus
            statusColor={saveStatus.color}
            onClick={openModal}
            ref={container}
        >
            {saveStatus.name}

            {!empty(validationErrors) && <Icon />}

            <Modal
                open={open}
                title="Errors"
                onClose={onClose}
                affirmativeText="Acknowledge"
                renderCancelButton={() => null}
                onAffirmativeClose={onClose}
            >
                <Container>
                    <Text>
                        The following{' '}
                        {pluralize('error', validationErrors.length)} have
                        occurred, please check the form data and try again.
                    </Text>
                    <List>
                        {validationErrors.map(error => (
                            <li>
                                {error.path && (
                                    <FieldName>
                                        {fieldNameTranslation(error.path)}
                                    </FieldName>
                                )}
                                {error.message.toLowerCase()}
                            </li>
                        ))}
                    </List>
                </Container>
            </Modal>
        </SaveStatus>
    )
}
