import { get } from 'lodash-es'

import {
    WhatsAppShare,
    SMSShare,
    EmailShare,
    TelegramShare,
} from 'ui-components/social-share'

import React, { useMemo } from 'react'

import AssignAction from './assign-action'

import { FaTelegram } from 'react-icons/fa'

import { useFetchPropertyName } from './hooks'

import { MdEmail, MdSms } from 'react-icons/md'

import ActionsPreloader from './actions-preloader'

import { IoLogoWhatsapp, IoMdDocument } from 'react-icons/io'

import TransitionActions, { useTransitProperties } from './transition-actions'

import DropDownMenu, { MenuLabel, MenuItem } from 'ui-components/drop-down-menu'

function getBrochureUrl({ remoteData }) {
    return window.location.origin + '/property-brochure/' + remoteData._id
}

const SocialSharer = ({ platforms, text, url }) => {
    return platforms.map(platform => (
        <platform.component
            key={platform.label}
            text={text}
            url={url}
            render={({ href }) => (
                <MenuItem
                    href={href}
                    target={platform.newPage ? '_blank' : false}
                    icon={platform.icon}
                >
                    {platform.label}
                </MenuItem>
            )}
        />
    ))
}
const emptyArray = []

export default ({ remoteData }) => {
    const propertyIds = useMemo(
        () => (get(remoteData, '_id') ? [get(remoteData, '_id')] : emptyArray),
        [remoteData]
    )

    const transitProperties = useTransitProperties({
        redirectOnSave: '/property',
        showAffectedRecords: false,
        propertyIds,
    })

    const { name: propertyName } = useFetchPropertyName({
        property: remoteData,
    })

    const brochureUrl = getBrochureUrl({ remoteData })

    const shareText = propertyName

    const platforms = useMemo(
        () => [
            {
                label: 'WhatsApp',
                icon: IoLogoWhatsapp,
                component: WhatsAppShare,
                newPage: true,
            },
            {
                label: 'Telegram',
                icon: FaTelegram,
                component: TelegramShare,
                newPage: true,
            },
            {
                label: 'Email',
                icon: MdEmail,
                component: EmailShare,
            },
            {
                label: 'SMS',
                icon: MdSms,
                component: SMSShare,
            },
        ],
        []
    )

    return (
        <>
            <ActionsPreloader />
            <DropDownMenu>
                <TransitionActions
                    onTransitionClick={transitProperties}
                    propertyIds={propertyIds}
                />

                <AssignAction propertyIds={propertyIds} />

                <MenuLabel>Share</MenuLabel>

                <SocialSharer
                    text={shareText}
                    url={brochureUrl}
                    platforms={platforms}
                />

                <MenuLabel>View</MenuLabel>

                <MenuItem
                    href={brochureUrl}
                    target="_blank"
                    icon={IoMdDocument}
                >
                    Brochure
                </MenuItem>
            </DropDownMenu>
        </>
    )
}
