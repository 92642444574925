import { useContext, useEffect } from 'react'
import {
    useData,
    useDirtyData,
    useExcludeFromInput,
    useNoRemoteData,
} from './fields'
import { isDirty } from '../data-processors'
import { isObject } from 'lodash-es'

import useRemoteData from './use-remote-data'

export default () => {
    const [, setDirtyData] = useDirtyData()

    const [remoteData] = useRemoteData()

    const [excludeFromInput] = useExcludeFromInput()

    const [noRemoteData] = useNoRemoteData()

    const [data] = useData()

    useEffect(() => {
        if (!isObject(data)) return

        setDirtyData(
            Object.keys(data).reduce((dirtyData, key) => {
                dirtyData[key] = isDirty({
                    data,
                    excludeFromInput,
                    remoteData,
                    key,
                    noRemoteData,
                })
                return dirtyData
            }, {})
        )
    }, [data, excludeFromInput, remoteData, noRemoteData, setDirtyData])
}
