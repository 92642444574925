import React from 'react'

import SectionAlert from 'ui-components/section-alert'

import {
    camelCaseToSentenceCase,
    ucFirst,
    pluralize,
} from 'crm-components/data-helpers'

export default ({ parentId, parentRecordName, currentRecordName, loading }) => {
    const sentence = camelCaseToSentenceCase(parentRecordName)

    const firstCapital = ucFirst(sentence)

    const currentRecords = pluralize(currentRecordName, 3).toLowerCase()

    const message = `${firstCapital} is not saved, you need to save the ${sentence} before adding ${currentRecords}.`

    return parentId || loading ? null : <SectionAlert message={message} />
}
