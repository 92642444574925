import React from 'react'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import CRUDForm from 'crm-components/crud-form'

import ContactSection from 'modules/contact/contact-section'

import Section from 'ui-components/section'

import { useParams } from 'react-router-dom'

import MetaSection from 'crm-components/meta-section'

import PropertySection from './property-section'

import DocumentsTab from 'crm-components/documents-tab'

const TENANT_FRAGMENT = /* GraphQL */ `
    fragment tenantFields on Tenant {
        _id
        name
        email
        mobile
        phone
        officialDocumentNumber
        officialDocumentExpiryDate
        address
        countryId
        city
        tenantStatusId
        activityIds
        documentIds
        createdAt
        updatedAt
        createdById
        assignedToId
    }
`

const SAVE_TENANT = /* GraphQL */ `
    mutation SaveTenant($tenant: TenantInput) {
        saveTenant(tenant: $tenant) {
            ...tenantFields
        }
    }
    ${TENANT_FRAGMENT}
`

const GET_TENANT = /* GraphQL */ `
    query GetTenant($id: ID!) {
        tenant(id: $id) {
            ...tenantFields
        }
    }
    ${TENANT_FRAGMENT}
`

const DELETE_TENANTS = /* GraphQL */ `
    mutation DeleteTenants($ids: [ID!]!) {
        deleteTenants(ids: $ids)
    }
`

export default () => {
    const { id } = useParams()

    return (
        <CRUDForm
            query={GET_TENANT}
            mutation={SAVE_TENANT}
            mutationVariableName="tenant"
            record="Tenant"
            deleteQuery={DELETE_TENANTS}
        >
            <Tab title="Contact">
                <ContactSection />
            </Tab>

            <Tab title="Property">
                <PropertySection />
            </Tab>

            <Tab title="Status">
                <Section title="Status">
                    <FormFields>
                        <FormField
                            label="Tenant status"
                            name="tenantStatusId"
                            type="collectionSelect"
                            recordName="TenantStatus"
                            queryName="tenantStatuses"
                        />
                    </FormFields>
                </Section>
                <MetaSection />
            </Tab>

            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'tenants'}
                            parentId={id}
                            parentRecordName="Tenant"
                        />
                    </FormFields>
                </Section>
            </Tab>

            <DocumentsTab parentResolveType="Tenant" />
        </CRUDForm>
    )
}
