// import React, { useMemo } from 'react'

// import { contactTypeName } from 'modules/contact/helpers'

// import Select from 'ui-components/select'

export default ({ onChange, ...props }) => {
    throw new Error('Contact type select need to be reimplemented')

    // return null
    // const options = useMemo(
    //     () =>
    //         Object.keys(types).map(type => ({
    //             name: contactTypeName({ contactType: type, capitalize: true }),
    //             value: type,
    //         })),
    //     []
    // )

    // return <Select {...props} onValueChange={onChange} options={options} />
}
