import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PageTitle from 'ui-components/page-title'

import DragIcon from 'ui-components/drag-icon'

import Section from 'ui-components/section'

import styled from 'styled-components'

import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import arrayMove from 'array-move'

import { isEmpty, isEqual } from 'lodash-es'

import DefaultLoader from 'ui-components/loader'

import {
    camelCaseToSentenceCase,
    getDataNodes,
    getVariableNamePluralized,
    ucFirst,
} from './data-helpers'

import { useMutation, useQuery } from 'system/network/graphql'

const Loader = styled(DefaultLoader)`
    font-size: 1rem;
`

const Item = styled.div`
    background-color: white;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    user-select: none;
    z-index: 3;
    cursor: move;
`

const Icon = styled.div`
    display: flex;
    color: ${props => props.theme.gray2};
    margin-right: 0.5rem;
`

const DraggableItem = sortableElement(({ value: item }) => {
    return (
        <Item>
            <Icon>
                <DragIcon />
            </Icon>
            {item.name}
        </Item>
    )
})

const Container = sortableContainer(({ children }) => {
    return <div>{children}</div>
})

function makeQueryParams({ resolveType }) {
    const variableNamePluralized = getVariableNamePluralized({ resolveType })

    return {
        operation: {
            query: /* GraphQL */ `
                {
                    ${variableNamePluralized}(
                        pagination: { pageSize: 1000, pageNumber: 1 }
                        sort: { key: "sortOrder", order: 1 }
                    ) {
                        nodes {
                            ... on ${resolveType} {
                                _id
                                name
                                sortOrder
                            }
                        }
                    }
                }
            `,
        },
    }
}

function makeMutationParams({ resolveType }) {
    const variableNamePluralized = getVariableNamePluralized({ resolveType })

    const mutate = 'save' + ucFirst(variableNamePluralized)

    return {
        operation: {
            query: /* GraphQL */ `
                mutation Save($${variableNamePluralized}: [${resolveType}Input]) {
                    ${mutate}(${variableNamePluralized}: $${variableNamePluralized}) {
                        _id
                    }
                }
            `,
        },
    }
}

export default ({ resolveType }) => {
    const { cacheValue } = useQuery(makeQueryParams({ resolveType }))

    const { loading, load: saveItems } = useMutation(
        makeMutationParams({ resolveType })
    )

    const savedCountries = useRef()

    const items = useMemo(
        () =>
            (getDataNodes(cacheValue) || []).sort((a, b) => {
                if (a.sortOrder < b.sortOrder) return -1
                if (a.sortOrder > b.sortOrder) return 1
                return 0
            }),
        [cacheValue]
    )

    const [sortedItems, setSortedItems] = useState([])

    useEffect(() => {
        setSortedItems(items)
    }, [items])

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        setSortedItems(sortedItems =>
            arrayMove(sortedItems, oldIndex, newIndex)
        )
    }, [])

    useEffect(() => {
        if (savedCountries.current === sortedItems) return

        if (isEmpty(sortedItems)) return

        if (!isEqual(items, sortedItems)) {
            saveItems({
                countries: sortedItems.map((item, i) => ({
                    ...item,
                    sortOrder: i,
                })),
            })
            savedCountries.current = sortedItems
        }
    }, [sortedItems, saveItems, items])

    return (
        <>
            <PageTitle>
                Re-Order {camelCaseToSentenceCase(resolveType)}{' '}
                {loading && <Loader />}
            </PageTitle>

            <Section title="Drag and drop to sort">
                <Container onSortEnd={onSortEnd}>
                    {sortedItems.map((c, i) => {
                        return <DraggableItem value={c} key={c._id} index={i} />
                    })}
                </Container>
            </Section>
        </>
    )
}
