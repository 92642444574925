import { useCallback, useContext } from 'react'

import pluralize from 'pluralize'

import { isEmpty } from 'lodash-es'
import { useHistory } from 'react-router-dom'

import { GraphQLContext } from 'graphql-react'

import { operate } from 'system/network/graphql'

import { useConfirm } from 'crm-components/modal'

import { errorToast, successToast } from 'ui-components/toast'

export const useDeleteRecords = ({
    deleteQuery,
    selectedRows,
    entity,
    isSingular,
    redirectToListPageOnSuccess,
}) => {
    const confirm = useConfirm()

    const history = useHistory()

    const graphql = useContext(GraphQLContext)

    const onDelete = useCallback(
        async ({ row = null }) => {
            const rows = row ? [row] : selectedRows

            if (isEmpty(rows)) return

            const name = pluralize(entity, rows.length)

            const message = isSingular
                ? `Are you sure you want to delete?`
                : `Are you sure you want to delete ${rows.length} ${name}?`

            if (!(await confirm(message))) {
                return
            }

            const ids = rows.map(r => r._id)

            const { cacheValuePromise } = operate({
                operation: {
                    query: deleteQuery,
                    variables: { ids },
                },
            })

            try {
                await cacheValuePromise

                graphql.reload()

                successToast(
                    pluralize('Record', ids.length) + ' deleted successfully'
                )

                if (redirectToListPageOnSuccess) {
                    setTimeout(() => {
                        history.push('/' + pluralize(entity.toLowerCase(), 3))
                    }, 100)
                }
            } catch {
                errorToast('Cannot delete ' + pluralize('record', ids.length))
            }
        },
        [
            selectedRows,
            entity,
            graphql,
            confirm,
            deleteQuery,
            history,
            isSingular,
            redirectToListPageOnSuccess,
        ]
    )

    return onDelete
}
