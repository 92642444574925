import React from 'react'

import CompanyForm from './company-form'

import Route from './route'

import UpdateForm from './update-form'

import { MenuItem } from 'system/layout/sidebar'

import { AiOutlineProfile } from 'react-icons/ai'

const Page = () => {
    return <UpdateForm form={CompanyForm} />
}

export default () => {
    return (
        <>
            <MenuItem
                render={'Company details'}
                to="/company-details"
                icon={<AiOutlineProfile />}
                sortOrder={30}
                permission={{
                    code: 'Company',
                    method: 'READ',
                    accessModifier: 'OWN',
                }}
            />
            <Route
                path="/company-details"
                fullScreen
                backButton
                permission={{
                    code: 'Company',
                    method: 'READ',
                    accessModifier: 'OWN',
                }}
            >
                <Page />
            </Route>
        </>
    )
}
