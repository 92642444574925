import React from 'react'

import styled from 'styled-components'

import { FiMinusSquare, FiPlusSquare } from 'react-icons/fi'

import { useExpanded, useTitle, useToggleSection } from './hooks'

import { GiPokecog } from 'react-icons/gi'

import { useSubTitle, useIcon } from './hooks'

const Container = styled.header`
    align-items: center;
    display: flex;
    height: ${props => (props.expanded ? 'unset' : '30px')};
    padding: ${props => (props.expanded ? '1rem' : '0 1rem')};
    user-select: none;
    cursor: pointer;
`

const HeaderAction = styled.div`
    display: flex;
    margin-left: 1rem;
    opacity: 0.5;
`

const SectionTitle = styled.h2`
    font-weight: 500;
    margin: 0;
    font-size: ${props => (props.expanded ? '1rem' : '0.8rem')};
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    flex: 1;

    @media ${props => props.theme.media.sm} {
        flex: ${props =>
            isNaN(props.flex) && props.flex !== 'initial' ? 1 : props.flex};
    }
`

const SectionIcon = styled.div`
    display: flex;
    margin-right: 1rem;
    opacity: 0.5;
`

const SectionSubtitle = styled.div`
    margin-left: 1rem;
    color: ${props => props.theme.gray2};
    line-height: 110%;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;

    @media ${props => props.theme.media.sm} {
        display: block;
    }
`

export default () => {
    const [expanded] = useExpanded()

    const toggle = useToggleSection()

    const [title] = useTitle()

    const [subTitle] = useSubTitle()

    const [Icon] = useIcon()

    return (
        <Container onClick={toggle} expanded={expanded}>
            <SectionIcon>
                <Icon />
            </SectionIcon>
            <SectionTitle flex={subTitle ? 'initial' : 1} expanded={expanded}>
                {title}
            </SectionTitle>
            {subTitle && <SectionSubtitle>{subTitle}</SectionSubtitle>}

            <HeaderAction>
                {expanded ? <FiMinusSquare /> : <FiPlusSquare />}
            </HeaderAction>
        </Container>
    )
}
