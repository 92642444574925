import React from 'react'

import styled from 'styled-components'

import { FiArrowLeftCircle } from 'react-icons/fi'

import { useHistory } from 'react-router-dom'

const Button = styled(FiArrowLeftCircle)`
    font-size: 2rem;
    color: ${props => props.theme.gray2};
    cursor: pointer;
    position: absolute;
    outline: 0;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

export default () => {
    const history = useHistory()
    return <Button tabIndex={0} onClick={() => history.goBack()} />
}
