import React, { useCallback } from 'react'

import { getOptionValue } from 'crm-components/data-helpers'

import CheckBoxOptions from './checkbox-options'

import { isArray } from 'lodash-es'

import SaveStatus from 'ui-components/save-status'

const emptyArray = []

function getArray(value) {
    return !value || !isArray(value) ? emptyArray : value
}

export default ({
    onChange,
    label,
    value: componentValue,
    options: componentOptions,
    isDirty,
    showSaveStatus,
    onDiscard,
    disabled,
    loading,
    loadingRows = 3,
    isMulti = true,
}) => {
    const value = getArray(componentValue)

    const options = getArray(componentOptions)

    const isOptionSelected = useCallback(
        option => {
            return value.indexOf(getOptionValue({ option })) > -1
        },
        [value]
    )

    const setOptionSelected = useCallback(
        ({ option, selected }) => {
            onChange(previousValue => {
                let value = getArray(previousValue)

                if (selected) {
                    if (isMulti) value = [...value, option._id]
                    else value = [option._id]
                } else {
                    if (isMulti) value = value.filter(id => id !== option._id)
                    else value = []
                }

                return value
            })
        },
        [onChange, isMulti]
    )

    const renderSaveStatus = useCallback(
        () => (
            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />
        ),
        [isDirty, value, showSaveStatus, onDiscard]
    )

    return (
        <CheckBoxOptions
            loading={loading}
            label={label}
            renderSaveStatus={renderSaveStatus}
            loadingRows={loadingRows}
            onOptionChange={setOptionSelected}
            isOptionSelected={isOptionSelected}
            options={options}
            disabled={disabled}
        />
    )
}
