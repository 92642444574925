import React from 'react'

import ContentLoader from 'react-content-loader'

export default () => {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height={17}
            viewBox="0 0 800 50"
            preserveAspectRatio="none"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="3" ry="3" width="800" height="50" />
        </ContentLoader>
    )
}
