import React from 'react'

import Section from 'ui-components/section'

import { FormField, FormFields } from 'crm-components/crud-form'

import LocationSelect from 'crm-components/location-select'

export default () => {
    return (
        <>
            <Section title="Interest details">
                <FormFields>
                    <FormField
                        name="interestTypeId"
                        label="Interest type"
                        type="collectionSelect"
                        recordName="LeadInterestType"
                        queryName="leadInterestTypes"
                    />
                    <FormField
                        name="interestPropertyLocationId"
                        label="Location"
                        component={LocationSelect}
                        representScalarValue
                    />
                    <FormField
                        name="interestNumberOfBedroomsId"
                        label="Number of bedrooms"
                        type="collectionSelect"
                        recordName="Bedroom"
                        queryName="bedrooms"
                    />
                    <FormField
                        name="interestBudget"
                        label="Budget"
                        type="number"
                    />
                    <FormField name="interestNotes" label="Notes" />
                </FormFields>
            </Section>
            <Section title="Interest Amenities">
                <FormField
                    type="collectionBalloonSelector"
                    name="interestAmenityIds"
                    recordName="Amenity"
                    queryName="amenities"
                    fullWidth
                />
            </Section>
        </>
    )
}
