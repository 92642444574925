import React, { useMemo } from 'react'

import { get } from 'lodash-es'

import QuickView from './quick-view'

import List from 'crm-components/list'

import ListSearch from './list-search'

import ListActions from './list-actions'

import { COLUMN_PRIORITY_LOW } from 'ui-components/list/common'

import { getUserName } from 'crm-components/data-helpers'

import { useContactPredefindQueries } from 'crm-components/contact-predefined-queries'

const GET_LEADS = /* GraphQL */ `
    query GetLeads(
        $search: LeadSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        leads(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Lead {
                    _id
                    name
                    email
                    mobile
                    phone
                    officialDocumentNumber
                    officialDocumentExpiryDate
                    address
                    country {
                        name
                    }
                    city
                    interestType {
                        name
                    }
                    interestPropertyLocation {
                        city
                        community
                        subCommunity
                        property
                        country
                    }
                    interestNumberOfBedrooms {
                        name
                    }
                    interestBudget
                    interestNotes
                    interestAmenities {
                        name
                    }
                    assignedTo {
                        email
                        profile {
                            firstName
                            lastName
                        }
                    }
                    createdBy {
                        email
                        profile {
                            firstName
                            lastName
                        }
                    }
                    createdAt
                    leadStatus {
                        name
                    }
                }
            }
        }
    }
`

const DELETE_LEADS = /* GraphQL */ `
    mutation DeleteLeads($ids: [ID!]!) {
        deleteLeads(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    if (col.key === 'userName') {
        if (get(row, 'assignedTo')) {
            return getUserName(row.assignedTo)
        } else {
            return getUserName(row.createdBy)
        }
    }
}

export default () => {
    const predefinedQueries = useContactPredefindQueries({
        resolveType: 'Lead',
    })

    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Email',
                key: 'email',
                sortKey: 'email',
            },
            {
                name: 'Mobile',
                key: 'mobile',
                sortKey: 'mobile',
            },
            {
                name: 'Interest type',
                key: 'interestType',
            },
            {
                name: 'Status',
                key: 'leadStatus',
                priority: COLUMN_PRIORITY_LOW,
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_LEADS}
            deleteQuery={DELETE_LEADS}
            pageTitle={'Leads'}
            entity="lead"
            cols={cols}
            record="Lead"
            renderCell={Cell}
            renderSearchSection={ListSearch}
            expandable
            renderExpandedRow={QuickView}
            renderActions={ListActions}
            showQuickFiltersLoader
            predefinedQueries={predefinedQueries}
        />
    )
}
