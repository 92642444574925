import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_PROPERTY_LOCATIONS = /* GraphQL */ `
    query GetPropertyLocations(
        $search: PropertyLocationSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        propertyLocations(
            search: $search
            pagination: $pagination
            sort: $sort
        ) {
            totalPages
            count
            nodes {
                ... on PropertyLocation {
                    _id
                    city
                    community
                    subCommunity
                    property
                    country
                    createdAt
                }
            }
        }
    }
`

const DELETE_PROPERTY_LOCATIONS = /* GraphQL */ `
    mutation DeletePropertyLocations($ids: [ID!]!) {
        deletePropertyLocations(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'City',
                key: 'city',
                sortKey: 'city',
            },
            {
                name: 'Community',
                key: 'community',
                sortKey: 'community',
            },
            {
                name: 'Sub community',
                key: 'subCommunity',
                sortKey: 'subCommunity',
            },
            {
                name: 'Property',
                key: 'property',
                sortKey: 'property',
            },
            {
                name: 'Country',
                key: 'country',
                sortKey: 'country',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_PROPERTY_LOCATIONS}
            deleteQuery={DELETE_PROPERTY_LOCATIONS}
            pageTitle={'Property locations'}
            entity="propertyLocation"
            cols={cols}
            record="PropertyLocation"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="city" label="City" />
                        <SearchField name="community" label="Community" />
                        <SearchField
                            name="subCommunity"
                            label="Sub community"
                        />
                        <SearchField name="property" label="Property" />
                        <SearchField name="country" label="Country" />
                    </>
                )
            }}
        />
    )
}
