import React, { useState, useEffect, useCallback, useRef } from 'react'

import styled from 'styled-components'

import DefaultInput from 'ui-components/input'

import DefaultButton from 'ui-components/button'

import { useQuery, useMutation } from 'system/network/graphql'

import { get } from 'lodash-es'

import { empty, formatValue, getUserName } from 'crm-components/data-helpers'

import DefaultSaveStatus from 'ui-components/save-status'

import NotSavedAlert from './not-saved-alert'

import { FaPlus } from 'react-icons/fa'

const Button = styled(DefaultButton).attrs({
    buttonStyles: {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
    },
})`
    max-height: 58px;
`

const InputContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
`

const Input = styled(DefaultInput)`
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
`

const SaveStatus = styled(DefaultSaveStatus)`
    margin: 0.5rem 0 1rem 0;
`

const ActivityElement = styled.div`
    padding: 1rem;
    background-color: ${props => props.theme.light};
    margin-bottom: 1rem;
    border-radius: 5px;
    color: ${props => props.theme.dark};
`

const ActivityMeta = styled.div`
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    color: ${props => props.theme.primary};
    flex-wrap: wrap;
`

const ActivityMetaText = styled.span`
    margin-bottom: 0.5rem;
`

const ActivityText = styled.div`
    line-height: 180%;
`

const ACTIVITY_FIELDS = /* GraphQL */ `
    fragment activityFields on Activity {
        _id
        text
        createdAt
        updatedAt
        createdById
        parentCollection
        parentId
        deletedAt
        createdBy {
            email
            profile {
                firstName
                lastName
            }
        }
    }
`

const SAVE_ACTIVITY = /* GraphQL */ `
    mutation SaveActivity($activity: ActivityInput) {
        saveActivity(activity: $activity) {
            ...activityFields
        }
    }
    ${ACTIVITY_FIELDS}
`

const GET_ACTIVITYS = /* GraphQL */ `
    query GetActivitys($search: ActivitySearch, $pagination: PaginationInput) {
        activities(search: $search, pagination: $pagination) {
            nodes {
                ...activityFields
            }
        }
    }
    ${ACTIVITY_FIELDS}
`

const Activity = ({ activity }) => {
    let by = getUserName(get(activity, 'createdBy'))

    return (
        <ActivityElement>
            <ActivityMeta>
                <ActivityMetaText>Added by: {by}</ActivityMetaText>
                <ActivityMetaText>
                    {formatValue(activity.createdAt)}
                </ActivityMetaText>
            </ActivityMeta>
            <ActivityText
                dangerouslySetInnerHTML={{
                    __html: activity.text.trim().replace(/\n/g, '<br>'),
                }}
            ></ActivityText>
        </ActivityElement>
    )
}

const EMPTY_ACTIVITY = {
    text: '',
}

export default ({
    value,
    onChange,
    parentCollection,
    parentId,
    disabled,
    isDirty,
    showSaveStatus,
    onDiscard,
    parentRecordName,
}) => {
    const [activity, setActivity] = useState(EMPTY_ACTIVITY)

    const [activities, setActivities] = useState([])

    const activitySaved = useRef(true)

    const {
        load: saveActivity,
        cacheValue: savedActivity,
        loading,
    } = useMutation({
        operation: {
            query: SAVE_ACTIVITY,
        },
    })

    const { cacheValue: activitiesCacheValue = {} } = useQuery({
        operation: {
            query: GET_ACTIVITYS,
            variables: {
                search: { _id: value },
                pagination: { pageNumber: 1, pageSize: 200 },
            },
        },
        loadOnMount: !empty(value),
    })

    const onSaveActivity = useCallback(() => {
        setActivity(EMPTY_ACTIVITY)
        saveActivity({
            activity: {
                ...activity,
                parentCollection,
                parentId,
            },
        })
        activitySaved.current = false
    }, [saveActivity, activity, parentCollection, parentId])

    useEffect(() => {
        if (activitySaved.current) return

        const activity = get(savedActivity, 'data.saveActivity')

        if (empty(activity)) return

        const activities = empty(value) ? [] : value

        if (activities.indexOf(activity._id) < 0) {
            activitySaved.current = true
            onChange([...activities, activity._id])
        }
    }, [savedActivity, onChange, value])

    useEffect(() => {
        const remoteActivities = get(
            activitiesCacheValue,
            'data.activities.nodes'
        )

        if (!empty(remoteActivities)) {
            setActivities(remoteActivities)
        }
    }, [activitiesCacheValue])

    useEffect(() => {
        if (empty(value)) setActivities([])
        else {
            setActivities(activities =>
                activities.filter(activity => value.indexOf(activity._id) >= 0)
            )
        }
    }, [value])

    const shouldDisable = empty(parentId) || loading || disabled
    // test
    return (
        <>
            <InputContainer>
                <Input
                    disabled={shouldDisable}
                    label="New activity"
                    placeholder="Type activity details here"
                    value={activity.text}
                    type="textarea"
                    onChange={text => setActivity({ ...activity, text })}
                />
                <Button
                    disabled={empty(activity.text) || shouldDisable}
                    loading={loading}
                    type="button"
                    onClick={onSaveActivity}
                    icon={<FaPlus />}
                >
                    Add
                </Button>
            </InputContainer>

            {activities.map(activity => (
                <Activity key={activity._id} activity={activity} />
            ))}

            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />
            <NotSavedAlert
                parentId={parentId}
                parentRecordName={parentRecordName}
                loading={loading}
                currentRecordName="Activity"
            />
        </>
    )
}
