import React, { useCallback } from 'react'

import { MenuLabel, MenuItem } from 'ui-components/drop-down-menu'

import styled from 'styled-components'

import { usePropertyTransisions, useConductTransition } from './hooks'

import { isFunction, get } from 'lodash-es'

import { useAffirmativeConfirm } from 'crm-components/modal'

import pluralize from 'pluralize'

import { successToast, errorToast } from 'ui-components/toast'

import { sleep, empty } from 'crm-components/data-helpers'

import { useHistory } from 'react-router-dom'

const NameIcon = styled.span`
    font-weight: 800;
    letter-spacing: -1px;
    font-size: 0.8rem;
    display: inline-block;
`

const Bold = styled.span`
    font-weight: bold;
    text-transform: lowercase;
`
const Message = styled.div``

const TransitionIcon = ({ transition }) => {
    const initials = transition.name[0] + transition.name[1]

    return <NameIcon>{initials.toUpperCase()}</NameIcon>
}

export default ({ onTransitionClick, propertyIds }) => {
    const transitions = usePropertyTransisions()

    return (
        <>
            <MenuLabel>Action</MenuLabel>
            {transitions.map(transition => (
                <MenuItem
                    icon={() => <TransitionIcon transition={transition} />}
                    key={transition._id}
                    disabled={empty(propertyIds)}
                    onClick={() => {
                        if (isFunction(onTransitionClick)) {
                            onTransitionClick({ transition })
                        }
                    }}
                >
                    {transition.name}
                </MenuItem>
            ))}
        </>
    )
}

const ConfirmationMessage = ({
    propertyIds,
    transition,
    showAffectedRecords,
}) => {
    if (showAffectedRecords)
        return (
            <Message>
                Are you sure you want to <Bold>{transition.name}</Bold>? This
                will affect <Bold>{propertyIds.length}</Bold>{' '}
                {pluralize('property', propertyIds.length)}.
            </Message>
        )

    return (
        <Message>
            Are you sure you want to <Bold>{transition.name}</Bold>?
        </Message>
    )
}

export const useTransitProperties = ({
    propertyIds,
    showAffectedRecords,
    redirectOnSave,
}) => {
    const confirm = useAffirmativeConfirm()
    const conductTransition = useConductTransition()
    const history = useHistory()

    const transitProperities = useCallback(
        async ({ transition }) => {
            await confirm({
                message: (
                    <ConfirmationMessage
                        transition={transition}
                        propertyIds={propertyIds}
                        showAffectedRecords={showAffectedRecords}
                    />
                ),
                onAffirmative: async () => {
                    const result = await conductTransition({
                        id: transition._id,
                        propertyIds,
                    })

                    if (
                        get(
                            result,
                            'data.conductTransition.affectedIds.length'
                        ) > 0
                    ) {
                        successToast(`Saved successfully`)

                        if (redirectOnSave) {
                            await sleep(500)

                            history.push(redirectOnSave)
                        }
                    } else {
                        errorToast(`Cannot conduct transition on this property`)
                    }
                },
            })
        },
        [
            conductTransition,
            history,
            confirm,
            propertyIds,
            showAffectedRecords,
            redirectOnSave,
        ]
    )

    return transitProperities
}
