import React from 'react'

import styled from 'styled-components'

import Seo from 'ui-components/seo'

import Button from 'ui-components/button'

import { get } from 'lodash-es'

import PageTitle from 'ui-components/page-title'

import { useAccount } from 'system/account'

const Welcome = styled.h2`
    font-size: 1.5rem;
    color: ${props => props.theme.dark};
    margin: 0;
    font-weight: 500;
`

const HelpText = styled.p`
    font-size: 1rem;
    color: ${props => props.theme.dark};
    margin: 0;
    font-weight: 500;
    line-height: 140%;
    margin-top: 1rem;
`

const Header = styled.div`
    background-color: ${props => props.theme.gray};
    color: ${props => props.theme.dark};
    padding: 1rem;
    display: flex;
    flex-direction: column;
`

const Container = styled.div`
    max-width: 800px;
    margin: auto;
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid ${props => props.theme.gray};
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
`

const ItemText = styled.p`
    color: ${props => props.theme.dark};
`

export default () => {
    const { account } = useAccount()

    let name =
        (get(account, 'profile.firstName') || '') +
        ' ' +
        (get(account, 'profile.lastName') || '')

    name = name.length > 1 ? `${name}!` : name

    return (
        <>
            <Seo title="Getting started" />
            <Container>
                <PageTitle>Getting started</PageTitle>

                <Header>
                    <Welcome>Welcome, {name}</Welcome>
                    <HelpText>
                        To get up & running quickly, please follow steps
                        outlined below
                    </HelpText>
                </Header>
                <Item>
                    <ItemText>Invite your company members</ItemText>
                    <Button to="/invite-users">Start</Button>
                </Item>
                <Item>
                    <ItemText>Add your first property</ItemText>
                    <Button to="/property">Start</Button>
                </Item>
                <Item>
                    <ItemText>Add your first lead</ItemText>
                    <Button to="/lead">Start</Button>
                </Item>
            </Container>
        </>
    )
}
