import React, { createContext, useContext, useState, useCallback } from 'react'

import { useAccount } from './'

import { useHistory } from 'react-router-dom'

import { GraphQLContext } from 'graphql-react'

import { emptyValues } from 'crm-components/data-helpers'

const LOGIN_TOKEN_LOCAL_STORAGE_KEY = 'convertProCrm:token'

const DEFAULT_STATE = {
    accessModifiers: {
        ALL: 'ALL',
        TEAM: 'TEAM',
        COMPANY: 'COMPANY',
        OWN: 'OWN',
    },
    methods: {
        READ: 'READ',
        WRITE: 'WRITE',
        DELETE: 'DELETE',
    },
}

const ACLContext = createContext(DEFAULT_STATE)

export function useLogout() {
    const graphql = useContext(GraphQLContext)
    const history = useHistory()

    const logout = useCallback(
        ({ push, replace }) => {
            if (push && replace)
                throw new Error(
                    'Either push or replace should be provided, not both.'
                )

            graphql.reset()

            resetToken()

            setTimeout(() => {
                if (push) {
                    history.push(push)
                }
                if (replace) {
                    history.replace(replace)
                }
            }, 100)
        },
        [graphql, history]
    )

    return logout
}

export function useAcl() {
    const [state] = useContext(ACLContext)

    return state
}

export function loadToken() {
    const token = localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY]

    return token
}

export function storeToken(token) {
    if (token) localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY] = token
    else {
        resetToken()
    }
}

export function resetToken() {
    delete localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY]
}

function truthy(obj) {
    return Object.keys(obj).reduce((carry, key) => {
        if (obj[key]) carry[key] = obj[key]
        return carry
    }, {})
}

function matchAvailableProps(permission, other) {
    return Object.keys(truthy(permission)).reduce((can, key) => {
        const orValues = permission[key].split('|') // pipe is "or" operation
        return can && orValues.find(value => value === other[key])
    }, true)
}

// TODO: record parameter must be removed
export function useCan({
    record,
    code: defaultCode,
    accessModifier,
    method,
    parentId,
    parentCollection,
} = {}) {
    const { loading, account: user } = useAccount()

    if (loading) return undefined

    if (user.isSuperUser) return true

    const code = record || defaultCode || null

    const permission = {
        code,
        accessModifier,
        method,
        parentId,
        parentCollection,
    }

    const notProtected = emptyValues(permission)

    if (notProtected) return true

    const role = user.role

    if (!role) return false

    const found = role.permissions.find(_permission => {
        const matched = matchAvailableProps(permission, _permission)
        return matched
    })

    return !!found
}

export default ({ children }) => {
    const [state, setState] = useState(DEFAULT_STATE)

    return (
        <ACLContext.Provider value={[state, setState]}>
            {children}
        </ACLContext.Provider>
    )
}
