import { useMemo } from 'react'
import { getGraphqlErrors } from 'crm-components/data-helpers'
import { useMutationCacheValue } from './fields'

export default () => {
    const [mutationCacheValue] = useMutationCacheValue()

    const validationErrors = useMemo(
        () => getGraphqlErrors(mutationCacheValue),
        [mutationCacheValue]
    )

    return [validationErrors, () => {}] 
}
