import React from 'react'

import CRUDForm from 'crm-components/crud-form'

import { Tab } from 'crm-components/crud-form'

import BasicDetails from './basic-details'

import CommissionSection from './commission-section'

import TransactionBanner from './transaction-banner'

import Section from 'ui-components/section'

import { FormFields, FormField } from 'crm-components/crud-form'

import { useParams } from 'react-router-dom'

import DocumentsTab from 'crm-components/documents-tab'

const TRANSACTION_FRAGMENT = /* GraphQL */ `
    fragment transactionFields on Transaction {
        _id
        amount
        propertyId
        leadId
        ownerId
        tenantId
        transactionDate
        deposit
        amount
        ownerCommission
        leadCommission
        createdAt
    }
`

const SAVE_TRANSACTION = /* GraphQL */ `
    mutation SaveTransaction($transaction: TransactionInput) {
        saveTransaction(transaction: $transaction) {
            ...transactionFields
        }
    }
    ${TRANSACTION_FRAGMENT}
`

const GET_TRANSACTION = /* GraphQL */ `
    query GetTransaction($id: ID!) {
        transaction(id: $id) {
            ...transactionFields
        }
    }
    ${TRANSACTION_FRAGMENT}
`

export default () => {
    const { id } = useParams()

    return (
        <CRUDForm
            query={GET_TRANSACTION}
            mutation={SAVE_TRANSACTION}
            mutationVariableName="transaction"
            record="Transaction"
        >
            <Tab title="Details">
                <TransactionBanner />
                <BasicDetails />
            </Tab>
            <Tab title="Commission">
                <TransactionBanner />
                <CommissionSection />
            </Tab>
            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'transactions'}
                            parentId={id}
                            parentRecordName="Transaction"
                        />
                    </FormFields>
                </Section>
            </Tab>

            <DocumentsTab parentResolveType="Transaction" />
        </CRUDForm>
    )
}
