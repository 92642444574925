import React, { useEffect } from 'react'

import logoSrc from 'images/logo.svg'

import styled from 'styled-components'

import { Link as DefaultLink, useHistory } from 'react-router-dom'
import { useAccount } from 'system/account'
import { empty } from 'crm-components/data-helpers'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    padding: 1rem;
    margin: auto;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
`

const Logo = styled.img`
    max-width: 100%;
    width: 280px;
    display: block;
`

const Link = styled(DefaultLink)`
    font-size: 2rem;
    color: ${props => props.theme.primary};
    text-decoration: none;
`

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
`

const Separator = styled.div`
    margin: 0 1rem;
    width: 3px;
    height: 1.5rem;
    background-color: ${props => props.theme.secondary};
`

export default () => {
    const { account } = useAccount()
    const history = useHistory()

    useEffect(() => {
        const shouldRedirect = !empty(account)
        if (shouldRedirect) {
            history.push('/dashboard')
        }
    }, [account, history])

    return (
        <Container>
            <Logo src={logoSrc} alt="ConvertProCRM Logo" />
            <Row>
                <Link to="/login">Login</Link>
                <Separator />
                <Link to="/sign-up">Sign up</Link>
            </Row>
        </Container>
    )
}
