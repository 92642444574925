import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const LANGUAGE_FRAGMENT = /* GraphQL */ `
    fragment languageFields on Language {
        _id
        name
        localeCode
        createdAt
        isDefault
    }
`

const SAVE_LANGUAGE = /* GraphQL */ `
    mutation SaveLanguage($language: LanguageInput) {
        saveLanguage(language: $language) {
            ...languageFields
        }
    }
    ${LANGUAGE_FRAGMENT}
`

const GET_LANGUAGE = /* GraphQL */ `
    query GetLanguage($id: ID!) {
        language(id: $id) {
            ...languageFields
        }
    }
    ${LANGUAGE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_LANGUAGE}
            mutation={SAVE_LANGUAGE}
            mutationVariableName="language"
            record="Language"
        >
            <Tab title="Details">
                <Section title="Language details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField name="localeCode" label="Locale code" />
                        <FormField
                            name="isDefault"
                            label="Is this the default language?"
                            type="checkbox"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
