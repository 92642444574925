import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const PROPERTY_CATEGORY_FRAGMENT = /* GraphQL */ `
    fragment propertyCategoryFields on PropertyCategory {
        _id
        name
        propertyFinderValue
        bayutValue
        createdAt
    }
`

const SAVE_PROPERTY_CATEGORY = /* GraphQL */ `
    mutation SavePropertyCategory($propertyCategory: PropertyCategoryInput) {
        savePropertyCategory(propertyCategory: $propertyCategory) {
            ...propertyCategoryFields
        }
    }
    ${PROPERTY_CATEGORY_FRAGMENT}
`

const GET_PROPERTY_CATEGORY = /* GraphQL */ `
    query GetPropertyCategory($id: ID!) {
        propertyCategory(id: $id) {
            ...propertyCategoryFields
        }
    }
    ${PROPERTY_CATEGORY_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_PROPERTY_CATEGORY}
            mutation={SAVE_PROPERTY_CATEGORY}
            mutationVariableName="propertyCategory"
            record="PropertyCategory"
        >
            <Tab title="Details">
                <Section title="Property category details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Commercial "
                        />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                            placeholder="C"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
