import React from 'react'

import List from './list'

import Form from './form'

import CRUDRouter from 'crm-components/crud-router'

import { MenuItem } from 'system/layout/sidebar'

import { FaMoneyBill } from 'react-icons/fa'

export default () => {
    return (
        <>
            <MenuItem
                to="/transaction"
                render="Transactions"
                icon={<FaMoneyBill />}
                sortOrder={40}
                permission={{
                    record: 'Transaction',
                    method: 'WRITE',
                    accessModifier: 'ALL',
                }}
            />
            <CRUDRouter
                path="/transaction"
                list={List}
                form={Form}
                record="Transaction"
                accessModifier="ALL"
            />
        </>
    )
}
