import { cloneDeep, get, isEqual, isFunction } from 'lodash-es'
import { useContext, useCallback, useEffect } from 'react'
import { set as _set } from 'lodash-es'

export default (name, Context, { defaultValue = undefined } = {}) => {
    const [state, set] = useContext(Context)

    const setValue = useCallback(
        value => {
            const setter = isFunction(value) ? value : () => value

            set(state => {
                const newState = cloneDeep(state)

                const newValue = setter(get(state, name))

                _set(newState, name, newValue)

                if (!isEqual(newState, state)) {
                    return newState
                }

                return state
            })
        },
        [set, name]
    )

    const value = get(state, name)

    useEffect(() => {
        if (defaultValue !== undefined && !value && value !== defaultValue) {
            setValue(defaultValue)
        }
    }, [value, defaultValue, setValue])

    return [value || defaultValue, setValue]
}
