import React from 'react'

import { ThemeProvider, createGlobalStyle, keyframes } from 'styled-components'

import { Normalize } from 'styled-normalize'
import { useIsMobile } from 'system/ui/match-media'

export const GOLD = 1.61803398875

export const GOLD_I = 0.6180339887

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const GlobalStyles = createGlobalStyle`
  html {
    font-family: sans-serif;
  }
  body {
    background-color: ${props => props.theme.gray};
    --gray: ${props => props.theme.gray};
    --gray2: ${props => props.theme.gray2};
    --dark: ${props => props.theme.dark};
    --primary: ${props => props.theme.primary};
    --blue: ${props => props.theme.blue};
    --light: ${props => props.theme.light};
    color: ${props => props.theme.dark};
    animation: ${fadeIn} .5s .1s cubic-bezier(0.5, 0, 0.75, 0) both;
  }

  .mobile-sidebar-open {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
  }
  
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
  ::selection {
      background-color: ${props => props.theme.primary};
      color: white;
  }
`

const makeTheme = ({ isMobile }) => {
    return {
        primary: '#43318d',
        primaryFaded: '#e7eaff',
        secondary: '#39afa9',
        dark: '#040429',
        headerHeight: isMobile ? '50px' : '34px',
        sidebarWidth: '200px',
        gray: '#f2f3f7',
        gray2: '#a7a8c2',
        light: 'white',
        accent: '#80CBC4',
        danger: 'red',
        success: '#319d35',
        blue: '#3e3eff',
        warning: '#ff8316',
        flexboxgrid: {
            // Defaults
            gridSize: 12, // columns
            gutterWidth: 1, // rem
            outerMargin: 1, // rem
            mediaQuery: 'only screen',
            container: {
                sm: 46, // rem
                md: 61, // rem
                lg: 76, // rem
            },
            breakpoints: {
                xs: 0, // em
                sm: 48, // em
                md: 64, // em
                lg: 75, // em
            },
        },
        media: {
            xs: `(min-width: 0rem)`,
            sm: `(min-width: 48rem)`,
            md: `(min-width: 64rem)`,
            lg: `(min-width: 75rem)`,
            xl: `(min-width: 82rem)`,
        },
    }
}

export default ({ children }) => {
    const isMobile = useIsMobile(makeTheme({ isMobile: false }))

    const theme = makeTheme({ isMobile })

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Normalize />
            {children}
        </ThemeProvider>
    )
}
