import { useCallback } from 'react'

import {
    useDisabled,
    useLoading,
    useValidationSchema,
    useData,
    useSubmitHandler,
} from './fields'

import useValidationErrors from './use-validation-errors'

import useDirty from './use-dirty'

import { isEmpty } from 'lodash-es'

export default () => {
    const [loading] = useLoading()

    const [disabled] = useDisabled()

    const [dirty] = useDirty()

    const [, setValidationErrors] = useValidationErrors()

    const [validationSchema] = useValidationSchema()

    const [data] = useData()

    const [submit] = useSubmitHandler()

    const formSubmit = useCallback(async () => {
        if (loading || disabled || !dirty) return

        if (!isEmpty(validationSchema)) {
		if (setValidationErrors)
            		setValidationErrors([])
            try {
                await validationSchema.validate(data, {
                    abortEarly: false,
                })
                submit()
            } catch (ex) {
                if (isEmpty(ex.inner)) {
                    return setValidationErrors([ex])
                }
                setValidationErrors(ex.inner)
            }
        } else {
            submit()
        }
    }, [
        setValidationErrors,
        submit,
        data,
        dirty,
        validationSchema,
        loading,
        disabled,
    ])

    return formSubmit
}
