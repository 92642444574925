import React from 'react'

import styled from 'styled-components'

import Section from 'ui-components/section'

import { FormFields, FormField } from 'crm-components/crud-form'

import { ValueSelect } from 'crm-components/collection-select'

const Fix = styled.div`
    overflow: hidden;
`

export default () => {
    return (
        <Section title="Managment">
            <FormFields>
                <FormField
                    label="Owner"
                    name="ownerId"
                    component={ValueSelect}
                    recordName="Owner"
                    queryName="owners"
                />
                <FormField
                    label="Reference code"
                    name="referenceCode"
                    placeholder="Auto generated"
                    disabled
                />
                <FormField
                    label="Permit number"
                    name="permitNumber"
                    placeholder="RERA or DTCM"
                />
            </FormFields>
            <Fix />
        </Section>
    )
}
