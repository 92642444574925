import React, { useState, useCallback, useEffect, useContext } from 'react'

import pluralize from 'pluralize'

import { get } from 'lodash-es'

import Modal from 'ui-components/modal'

import { useCan } from 'system/account/acl'

import { GraphQLContext } from 'graphql-react'

import { operate } from 'system/network/graphql'

import UserSelect from 'crm-components/user-select'

import { IoIosGitPullRequest } from 'react-icons/io'

import { MenuLabel, MenuItem } from 'ui-components/drop-down-menu'

import { empty, getDataObject } from 'crm-components/data-helpers'

import { successToast, errorToast } from 'ui-components/toast'

const ASSIGN_QUERY = /* GraphQL */ `
    mutation Assign($ids: [ID], $userId: ID, $resolveType: String!) {
        assign(ids: $ids, userId: $userId, resolveType: $resolveType) {
            affectedIds
            unAffectedIds
        }
    }
`

const Action = ({ ids, resolveType }) => {
    const [userId, setUserId] = useState()

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [menuIsOpen, setMenuIsOpen] = useState(true)

    const close = useCallback(() => {
        setModalIsOpen(false)
        setMenuIsOpen(false)
    }, [])

    const openModal = useCallback(() => setModalIsOpen(true), [])

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState()

    const graphql = useContext(GraphQLContext)

    useEffect(() => {
        if (!empty(userId)) setError(null)
    }, [userId])

    const label = pluralize(resolveType.toLowerCase(), ids.length)

    const modalTitle =
        ids.length > 1 ? `Assign ${ids.length} ${label}` : `Assignment`

    const onAffirmativeClose = useCallback(async () => {
        if (empty(userId)) {
            return setError({
                message: `You must select a user`,
            })
        }

        setLoading(true)

        const { cacheValuePromise } = operate({
            operation: {
                query: ASSIGN_QUERY,
                variables: {
                    userId,
                    ids,
                    resolveType,
                },
            },
        })

        const cacheValue = await cacheValuePromise

        const data = getDataObject(cacheValue)

        const affectedLength = get(data, 'affectedIds.length')

        if (!affectedLength) {
            errorToast(`Cannot assign ${label}`)
        } else if (affectedLength === ids.length) {
            successToast(`Assigned successfully`)
        } else {
            successToast(`Assigned some of the ${label} successfully`)
        }

        if (affectedLength) {
            graphql.reload()
        }

        setLoading(false)

        close()
    }, [close, userId, graphql, ids, resolveType, label])

    return (
        <>
            <MenuLabel>Assignment</MenuLabel>
            <MenuItem
                icon={IoIosGitPullRequest}
                onClick={openModal}
                closeOnClick={false}
                open={menuIsOpen}
                disabled={empty(ids)}
            >
                Assign
            </MenuItem>
            <Modal
                title={modalTitle}
                open={modalIsOpen}
                onClose={close}
                loading={loading}
                disabled={loading}
                affirmativeText="Assign"
                onAffirmativeClose={onAffirmativeClose}
            >
                <UserSelect
                    label="User"
                    value={userId}
                    onValueChange={setUserId}
                    error={error}
                />
            </Modal>
        </>
    )
}

export default ({ ids, resolveType }) => {
    const can = useCan({
        code: `Assign${resolveType}`,
    })

    if (!can) return null

    return <Action ids={ids} resolveType={resolveType} />
}
