import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'

import { useQuery } from 'system/network/graphql'

import { get } from 'lodash-es'

import { useParams } from 'react-router-dom'

import AccountPage from 'modules/account/account-page'

import AccountForm from 'modules/account/account-form'

import FormField from 'crm-components/form/field'

import { useLogin } from 'modules/account/hooks'

import Route from './route'

import { useAccount } from 'system/account'

const GET_INVITE = /* GraphQL */ `
    query GetInvite($code: String) {
        invite(code: $code) {
            _id
            email
            createdBy {
                company {
                    name
                }
            }
        }
    }
`

const CREATE_INVITED_USER = /* GraphQL */ `
    mutation CreateInvitedUser(
        $code: String
        $password: String
        $passwordConfirmation: String
    ) {
        createInvitedUser(
            code: $code
            password: $password
            passwordConfirmation: $passwordConfirmation
        ) {
            _id
        }
    }
`
const InvitedForm = () => {
    const [data, setData] = useState({})

    const loginLoading = useRef(false)

    const params = useParams()

    const { login } = useLogin()

    const { loading: accountLoading } = useAccount({ autoSync: true })

    const [user, setUser] = useState()

    const { loading: getInviteLoading, cacheValue: getInviteValue } = useQuery({
        operation: {
            query: GET_INVITE,
            variables: { code: params.code },
        },
    })

    const invite = useMemo(() => get(getInviteValue, 'data.invite') || {}, [
        getInviteValue,
    ])

    const isLoading = useCallback(
        ({ saveLoading }) =>
            saveLoading || loginLoading.current || accountLoading,
        [accountLoading]
    )

    const companyName = get(invite, 'createdBy.company.name')

    const invitedText = `Your are invited ${
        companyName
            ? `by ${companyName}`
            : `to join ${process.env.REACT_APP_APP_NAME}`
    }`

    const isDisabled = useCallback(
        ({ saveLoading }) => saveLoading || getInviteLoading,
        [getInviteLoading]
    )

    useEffect(() => {
        const id = get(user, '_id')

        if (id && !loginLoading.current) {
            login({
                email: invite.email,
                password: data.password,
            })
            loginLoading.current = true
        }
    }, [user, data, login, invite])

    return (
        <AccountPage title="Create your password" subtitle={invitedText}>
            <AccountForm
                buttonTitle="Continue"
                mutation={CREATE_INVITED_USER}
                noRemoteData
                onDataChange={setData}
                isLoading={isLoading}
                isDisabled={isDisabled}
                appendSaveData={{ code: params.code }}
                onMutationDataChange={setUser}
            >
                <FormField
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="A few words easy to remember"
                    instructions="At least 8 characters, includes 1 capital letter, 1 small letter, and 1 number."
                />
                <FormField
                    name="passwordConfirmation"
                    type="password"
                    label="Confirm"
                    placeholder="Confirm your password"
                />
            </AccountForm>
        </AccountPage>
    )
}

export default () => {
    return (
        <Route path="/invited/:code" exact fullScreen>
            <InvitedForm />
        </Route>
    )
}
