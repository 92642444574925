import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const AMENITY_FRAGMENT = /* GraphQL */ `
    fragment amenityFields on Amenity {
        _id
        name
        propertyFinderValue
        bayutValue
        categoryId
        createdAt
    }
`

const SAVE_AMENITY = /* GraphQL */ `
    mutation SaveAmenity($amenity: AmenityInput) {
        saveAmenity(amenity: $amenity) {
            ...amenityFields
        }
    }
    ${AMENITY_FRAGMENT}
`

const GET_AMENITY = /* GraphQL */ `
    query GetAmenity($id: ID!) {
        amenity(id: $id) {
            ...amenityFields
        }
    }
    ${AMENITY_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_AMENITY}
            mutation={SAVE_AMENITY}
            mutationVariableName="amenity"
            record="Amenity"
        >
            <Tab title="Details">
                <Section title="Amenity details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                        <FormField
                            name="categoryId"
                            label="Category"
                            type="collectionSelect"
                            recordName="PropertyCategory"
                            queryName="propertyCategories"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
