import React, { createContext, useState } from 'react'

const defaultState = {
    remoteFiles: [],
    filteredFiles: undefined,
}

export const DocumentsUploaderContext = createContext()

export default ({ children }) => (
    <DocumentsUploaderContext.Provider value={useState(defaultState)}>
        {children}
    </DocumentsUploaderContext.Provider>
)
