import { empty, equal } from 'crm-components/data-helpers'
import { get, isArray } from 'lodash-es'

/**
 * These fields are set by the backend, should not be included in dirty data calculations
 */
const metaFields = [
    'updatedAt',
    'createdAt',
    'createdById',
    'createdBy',
    'assignedTo',
    'assignedToId',
]

export function isExcluded({ excludeFromInput, key }) {
    let excludes = isArray(excludeFromInput) ? excludeFromInput : []

    excludes = excludes.concat(metaFields)

    return excludes.indexOf(key) >= 0
}

export function excludeFields({ data: dataParam, excludeFromInput }) {
    const data = empty(dataParam) ? {} : dataParam

    const result = Object.keys(data).reduce((newData, key) => {
        if (isExcluded({ excludeFromInput, key })) return newData

        newData[key] = data[key]

        return newData
    }, {})

    return result
}

export function isDirty({
    data,
    excludeFromInput,
    remoteData,
    key,
    noRemoteData,
}) {
    if (noRemoteData) {
        return !empty(data[key])
    }

    const areEmpty = empty(get(remoteData, key)) && empty(get(data, key))

    return (
        !areEmpty &&
        !equal(get(remoteData, key), data[key]) &&
        !isExcluded({ excludeFromInput, key })
    )
}
