import React from 'react'

import styled from 'styled-components'

import { Link as DefaultLink } from 'react-router-dom'

import AccountPage from './account-page'

import Route from './route'

const SuccessMessage = styled.div`
    line-height: 140%;
    color: ${props => props.theme.gray2};
`

const Link = styled(DefaultLink)`
    color: ${props => props.theme.blue};
    display: block;
    margin: 1rem 0;
`

const Page = () => {
    return (
        <AccountPage
            title="Reset password"
            subtitle={`Enter the email address associated with your account and
        we'll send you a link to reset your password.`}
        >
            <SuccessMessage>
                If we found an account associated with that email, we've sent
                password reset instructions to the primary email address on the
                account.
            </SuccessMessage>
            <Link to="/sign-up">Don't have an account?</Link>
        </AccountPage>
    )
}

export default () => {
    return (
        <Route path="/forgot-password/success" fullScreen>
            <Page />
        </Route>
    )
}
