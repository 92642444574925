import styled, { css } from 'styled-components'

import { Link as DefaultLink } from 'react-router-dom'

export const COLUMN_PRIORITY_LOW = 'COLUMN_PRIORITY_LOW'

export const LowerPriorityStyles = css`
    @media ${props => props.theme.media.sm} and (max-width: 940px),
        (min-width: 1020px) and (max-width: 1090px) {
        display: ${props =>
            props.priority === COLUMN_PRIORITY_LOW ? 'none' : 'table-cell'};
    }
`

export const Fix = styled.div`
    overflow: hidden;
`

const ListLinkStyles = css`
    font-size: 0.8rem;
    color: ${props => (props.disabled ? props.theme.gray2 : props.theme.dark)};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    margin: 0.5rem;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    outline: 0;
    &:focus {
        box-shadow: 0px 0px 0px 3px ${props => props.theme.secondary};
    }
`

export const ListAction = styled.div`
    ${ListLinkStyles}
`
export const ListLink = styled(DefaultLink).attrs({ tabIndex: 0 })`
    ${ListLinkStyles}
`
