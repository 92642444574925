import React, { useCallback } from 'react'

import { isEqual, isFunction } from 'lodash-es'

import styled from 'styled-components'

import { empty } from './data-helpers'

import DefaultSaveStatus from 'ui-components/save-status'

import { Row, Col } from 'react-styled-flexboxgrid'

import CollectionFetcher from 'crm-components/collection-fetcher'

import MarketingInput from 'crm-components/marketing-input'

const SaveStatus = styled(DefaultSaveStatus)`
    margin: 0;
`

const AddNewTemplateLink = styled.a`
    color: ${props => props.theme.gray2};
    letter-spacing: 1px;
    font-size: 0.5rem;
    margin: 0;
    align-items: center;
`

const ActionsRow = styled.div`
    display: flex;
    flex: 100%;
    justify-content: space-between;
    padding: 0 0.5rem 1rem;
`

const emptyObject = {}

const emptyArray = []

function getValue(value) {
    return value || emptyArray
}

function getLanguageMarketingValue({ value, language }) {
    return (
        getValue(value).find(
            marketing => marketing.languageId === language._id
        ) || emptyObject
    )
}

export default ({
    value = emptyArray,
    onChange,
    isDirty,
    showSaveStatus,
    onDiscard,
}) => {
    const getInputValue = useCallback(
        ({ language }) => {
            return getLanguageMarketingValue({ value, language })
        },
        [value]
    )

    const onInputChange = useCallback(
        ({ value: inputValueChange, language }) => {
            onChange(value => {
                const inputValue = isFunction(inputValueChange)
                    ? inputValueChange(
                          getLanguageMarketingValue({ value, language })
                      )
                    : inputValueChange

                let newValue

                if (!empty(getLanguageMarketingValue({ value, language }))) {
                    newValue = value.map(marketingValue => {
                        return marketingValue.languageId ===
                            inputValue.languageId
                            ? inputValue
                            : marketingValue
                    })
                } else {
                    newValue = [...getValue(value), inputValue]
                }

                return isEqual(value, newValue) ? value : newValue
            })
        },
        [onChange]
    )

    return (
        <Row>
            <CollectionFetcher
                queryName="languages"
                recordName="Language"
                render={({ options = [] }) =>
                    options.map(language => (
                        <Col xs={12} md={6} key={language._id}>
                            <MarketingInput
                                language={language}
                                value={getInputValue({ language })}
                                onChange={value =>
                                    onInputChange({ value, language })
                                }
                                onInputChange={onInputChange}
                            />
                        </Col>
                    ))
                }
            />
            <ActionsRow>
                <SaveStatus
                    isDirty={isDirty}
                    value={value}
                    showSaveStatus={showSaveStatus}
                    onDiscard={onDiscard}
                />
                <AddNewTemplateLink
                    href="/marketing-template/create"
                    target="_blank"
                >
                    add a new template
                </AddNewTemplateLink>
            </ActionsRow>
        </Row>
    )
}
