import React, { useContext, useMemo } from 'react'

import { isObject, get, isFunction } from 'lodash-es'

import { formatValue } from 'crm-components/data-helpers'

import { ListContext } from './provider'

import {
    useAdditionalCols,
    useListSelection,
    useToggleRowExpanded,
} from './hooks'

import styled from 'styled-components'

import { LowerPriorityStyles } from './common'

import Checkbox from 'ui-components/checkbox'

import { DesktopExpand, MobileExpand } from './expand-cell'

import Column from './column'

const Cell = styled.td`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${LowerPriorityStyles}
`

const SelectCell = styled.td`
    @media ${props => props.theme.media.md} {
        width: 30px;
    }
`

function getCellValue({ row, col }) {
    let value = get(row, col.key)

    while (isObject(value)) {
        const key = Object.keys(value)[0]

        value = value[key]
    }

    if (!value) {
        return '---'
    }

    return formatValue(value)
}

export default ({ row }) => {
    const [
        { renderActions, renderCell, cols, renderExpandedRow: ExpandedRow },
    ] = useContext(ListContext)

    const { toggleRowSelection, isRowSelected } = useListSelection()

    const [additionalCols] = useAdditionalCols()

    const { toggleExpandRow, expanded } = useToggleRowExpanded({ row })

    const Actions = useMemo(
        () => (isFunction(renderActions) ? renderActions : () => null),
        [renderActions]
    )

    return (
        <>
            <tr
                key={row._id + '-main'}
                onClick={() => {
                    toggleRowSelection(row)
                }}
            >
                <SelectCell>
                    <Checkbox value={isRowSelected(row)} noMargin />
                </SelectCell>

                <DesktopExpand row={row} />

                {cols.map(col => {
                    return (
                        <Column
                            cell={Cell}
                            data-title={col.name}
                            key={col.key}
                            priority={col.priority}
                        >
                            {isFunction(renderCell) && renderCell({ row, col })
                                ? renderCell({ row, col })
                                : getCellValue({ row, col })}
                        </Column>
                    )
                })}
                <td>
                    <Actions
                        row={row}
                        expanded={expanded}
                        toggleExpandRow={toggleExpandRow}
                    />
                    <MobileExpand row={row} />
                </td>
            </tr>

            {expanded && isFunction(ExpandedRow) && (
                <tr key={row._id + '-child'} className="no-hover">
                    <td colSpan={cols.length + additionalCols}>
                        <ExpandedRow row={row} close={toggleExpandRow} />
                    </td>
                </tr>
            )}
        </>
    )
}
