// import React, { useCallback } from 'react'

// import Select from 'ui-components/select'

// import CollectionFetcher from 'crm-components/collection-fetcher'

// import { useCan } from 'system/account/acl'

// import { contactTypeLink } from 'modules/contact/helpers'

export default () => {
    console.error('contact select should not be used')
    return null
}
