import React, { useCallback } from 'react'

import { string, object } from 'yup'

import { get } from 'lodash-es'

import AccountPage from './account-page'

import FormField from 'crm-components/form/field'

import Route from './route'

import AccountForm from './account-form'

import { useHistory } from 'react-router-dom'

const SEND_PASSWORD_INSTRUCTIONS_VALUE = /* GraphQL */ `
    mutation SendPasswordResetInstructions($email: String!) {
        sendPasswordResetInstructions(email: $email) {
            done
        }
    }
`

const validationSchema = object().shape({
    email: string()
        .required('Email is required')
        .email('Must be a valid email'),
})

function isSuccess({ mutationData }) {
    return get(mutationData, 'done')
}

const Page = () => {
    const history = useHistory()
    const onSuccess = useCallback(
        () => history.push('/forgot-password/success'),
        [history]
    )
    return (
        <AccountPage
            title="Reset password"
            subtitle={`Enter the email address associated with your account and
        we'll send you a link to reset your password.`}
        >
            <AccountForm
                mutation={SEND_PASSWORD_INSTRUCTIONS_VALUE}
                onSuccess={onSuccess}
                validationSchema={validationSchema}
                buttonTitle="Continue"
                isSuccess={isSuccess}
                noRemoteData
            >
                <FormField
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                    immediate
                />
            </AccountForm>
        </AccountPage>
    )
}

export default () => {
    return (
        <Route path="/forgot-password" exact fullScreen>
            <Page />
        </Route>
    )
}
