import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const PROPERTY_TYPE_FRAGMENT = /* GraphQL */ `
    fragment propertyTypeFields on PropertyType {
        _id
        name
        propertyFinderValue
        bayutValue
        categoryId
        createdAt
    }
`

const SAVE_PROPERTY_TYPE = /* GraphQL */ `
    mutation SavePropertyType($propertyType: PropertyTypeInput) {
        savePropertyType(propertyType: $propertyType) {
            ...propertyTypeFields
        }
    }
    ${PROPERTY_TYPE_FRAGMENT}
`

const GET_PROPERTY_TYPE = /* GraphQL */ `
    query GetPropertyType($id: ID!) {
        propertyType(id: $id) {
            ...propertyTypeFields
        }
    }
    ${PROPERTY_TYPE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_PROPERTY_TYPE}
            mutation={SAVE_PROPERTY_TYPE}
            mutationVariableName="propertyType"
            record="PropertyType"
        >
            <Tab title="Details">
                <Section title="Property type details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Apartment"
                        />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                            placeholder="AP"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                        <FormField
                            label="Category"
                            type="collectionSelect"
                            name="categoryId"
                            recordName="PropertyCategory"
                            queryName="propertyCategories"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
