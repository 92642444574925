import { useCallback, useContext } from 'react'

import { ListContext } from './provider'

import { useDeleteRecords } from 'crm-components/delete-records'

export const useOnDelete = () => {
    const [{ deleteQuery, selectedRows, entity }] = useContext(ListContext)

    return useDeleteRecords({ deleteQuery, selectedRows, entity })
}

export const useSelectedRows = () => {
    const [{ selectedRows }, set] = useContext(ListContext)

    const setSelectedRows = useCallback(
        selectedRows => {
            set(state => ({ ...state, selectedRows }))
        },
        [set]
    )

    return [selectedRows, setSelectedRows]
}
