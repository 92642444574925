import React, { createContext, useState, useMemo } from 'react'

import styled from 'styled-components'

import { Link as RouterLink } from 'react-router-dom'

import { IoMdMenu } from 'react-icons/io'

import logoWhite from '../../images/logo-white.svg'

import { useSidebarOpen } from './sidebar'

import { useFullScreen } from '../core/route'

import { MenuItem, Menu } from './menu-item'

import { Alert } from './alert'

import { noop } from 'lodash-es'

const Nav = styled.nav`
    background-color: ${props => props.theme.primary};
    height: ${props => props.theme.headerHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
`

const Link = styled(RouterLink)`
    font-size: 0;
    display: block;
    font-weight: 800;
    color: #fff2db;
    text-decoration: none;
    user-select: none;
    outline: 0;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const MenuButton = styled(IoMdMenu)`
    position: relative;
    z-index: 100000;
    margin-right: 0.5rem;
    color: white;
    opacity: ${props => (props.open ? 1 : 0.5)};
    transition: opacity 0.2s ease;
    cursor: pointer;
    @media ${props => props.theme.media.md} {
        display: none;
    }
`
const Logo = styled.img`
    width: 120px;
`

const StyledTopBarLink = styled(RouterLink)`
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
    cursor: pointer;
    font-weight: 800;
    user-select: none;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    &:not(:last-child) {
        margin-right: 1rem;
    }
`

const Icon = styled(({ isProfile, ...props }) => <RouterLink {...props} />)`
    display: flex;
    margin: 0 0.5rem;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    width: 16px;
    height: 16px;
    position: relative;
    align-items: center;
    justify-content: center;
    outline: 0;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const Actions = styled.div`
    display: flex;
    align-items: center;
`

const Icons = styled.div`
    display: flex;
    margin-right: 1rem;
`

const DEFAULT_STATE = {
    links: [],
    icons: [],
}

const TopBarContext = createContext()

const TopBarMenuItem = ({
    menuName,
    onClick = noop,
    to,
    sortOrder,
    render,
}) => {
    const item = useMemo(() => {
        return { onClick, to, sortOrder, render }
    }, [onClick, to, sortOrder, render])

    return <MenuItem menuName={menuName} context={TopBarContext} item={item} />
}

export const TopBarLink = props => {
    return <TopBarMenuItem menuName="links" {...props} />
}

export const TopBarIcon = props => {
    return <TopBarMenuItem menuName="icons" {...props} />
}

export const TopBar = () => {
    const [open, setOpen] = useSidebarOpen()

    const [fullScreen] = useFullScreen()

    return !fullScreen ? (
        <>
            <Alert />
            <Nav>
                <Actions>
                    <MenuButton open={open} onClick={() => setOpen(!open)} />
                    <Link to="/">
                        <Logo src={logoWhite} />
                    </Link>
                </Actions>
                <Actions>
                    <Icons>
                        <Menu
                            menuName="icons"
                            context={TopBarContext}
                            component={Icon}
                        />
                    </Icons>
                    <Menu
                        menuName="links"
                        context={TopBarContext}
                        component={StyledTopBarLink}
                    />
                </Actions>
            </Nav>
        </>
    ) : null
}

export default ({ children }) => {
    return (
        <TopBarContext.Provider value={useState(DEFAULT_STATE)}>
            {children}
        </TopBarContext.Provider>
    )
}
