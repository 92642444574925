import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_FINANCIAL_STATUSES = /* GraphQL */ `
    query GetFinancialStatuses(
        $search: FinancialStatusSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        financialStatuses(
            search: $search
            pagination: $pagination
            sort: $sort
        ) {
            totalPages
            count
            nodes {
                ... on FinancialStatus {
                    _id
                    name
                    propertyFinderValue
                    bayutValue
                    createdAt
                }
            }
        }
    }
`

const DELETE_FINANCIAL_STATUSES = /* GraphQL */ `
    mutation DeleteFinancialStatuses($ids: [ID!]!) {
        deleteFinancialStatuses(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Property finder',
                key: 'propertyFinderValue',
                sortKey: 'propertyFinderValue',
            },
            {
                name: 'Bayut value',
                key: 'bayutValue',
                sortKey: 'bayutValue',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_FINANCIAL_STATUSES}
            deleteQuery={DELETE_FINANCIAL_STATUSES}
            pageTitle={'Financial statuses'}
            entity="financialStatus"
            cols={cols}
            record="FinancialStatus"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
