import { useEffect } from 'react'

import { GRAPHQL_ERROR } from 'system/network/graphql'

import { errorToast } from 'ui-components/toast'

export default () => {
    useEffect(() => {
        const handleError = () => {
            errorToast(
                'We could not handle your request, please try again later'
            )
        }

        document.addEventListener(GRAPHQL_ERROR, handleError)

        return () => document.removeEventListener(GRAPHQL_ERROR, handleError)
    }, [])

    return null
}
