import React from 'react'

import { Tab as DefaultTab } from 'ui-components/tabs'

import Toolbar from './toolbar'

export default ({ children, ...props }) => {
    return (
        <DefaultTab {...props}>
            <Toolbar />
            {children}
        </DefaultTab>
    )
}
