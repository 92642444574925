import React, { useState, useCallback } from 'react'

import Section from 'ui-components/section'

import { FaMapPin } from 'react-icons/fa'

import Map from 'ui-components/map'

import DefaultLocationSelect from 'crm-components/location-select'

import { FormField, FormFields } from 'crm-components/crud-form'

import { get } from 'lodash-es'

const LocationSelect = ({ onLabelChange, onChange, value, ...props }) => {
    const callOnLabelChange = useCallback(
        option => {
            onLabelChange(get(option, 'label'))
        },
        [onLabelChange]
    )

    return (
        <DefaultLocationSelect
            onValueChange={onChange}
            value={value}
            onChange={callOnLabelChange}
            {...props}
        />
    )
}

export default () => {
    const [query, setQuery] = useState('')

    return (
        <Section title="Property location" icon={FaMapPin}>
            <FormFields>
                <FormField
                    name="locationId"
                    component={props => (
                        <LocationSelect {...props} onLabelChange={setQuery} />
                    )}
                />
                <FormField
                    component={Map}
                    query={query}
                    label="Search"
                    name="geoLocation"
                    placeholder="Search places..."
                />
            </FormFields>
        </Section>
    )
}
