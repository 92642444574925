import React, { useState } from 'react'

import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import CalendarStyles from './calendar-styles'

import {
    InputContainer,
    Label,
    InnerInputContainer,
    Error,
    Instructions,
} from 'ui-components/input'

import styled, { createGlobalStyle } from 'styled-components'

import SaveStatus from 'ui-components/save-status'

import { isDate, empty, isChildOf } from 'crm-components/data-helpers'

const DateRangePickerStyles = createGlobalStyle`
    .react-daterange-picker {
        display: flex;
        margin-top: .5rem;
    }

    .react-daterange-picker__wrapper {
        border: 0;
    }

    .react-daterange-picker button {
        outline: 0;
    }

    .react-daterange-picker__button {
        opacity: .3;
        padding: 0;
    }

    .react-daterange-picker__button:hover {
        opacity: 1;
    }

    .react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon, .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
        stroke: ${props => props.theme.primary};
    }
    
    
    .react-daterange-picker__clear-button {
        margin-left: auto;
        margin-right: .5rem;
    }
    
    .react-daterange-picker__button svg {
        width: 13px;
        height: 13px;
    }

    .react-daterange-picker__clear-button {
        margin-right: .5rem;
    }

    .react-daterange-picker__inputGroup {
        flex-grow: 0;
        height: 20px;
        min-width: unset;
        font-size: .8rem;
    }

    .react-daterange-picker__range-divider {
        margin: 0 .25rem;
    }

    .react-daterange-picker__inputGroup input::placeholder,
    .react-date-picker__inputGroup__divider,
    .react-daterange-picker__range-divider {
        color: ${props => (props.empty ? props.theme.gray2 : props.theme.dark)};
    }
`

const DateRangePickerContainer = styled(InputContainer)`
    color: ${props => props.theme.dark};
`

export default ({
    error,
    onChange,
    value,
    isDirty,
    showSaveStatus,
    onDiscard,
    disabled,
    label,
    instructions,
}) => {
    const [open, setOpen] = useState(false)

    let safeValue = null

    if (!empty(value)) {
        let transformed = value
            .map(v => {
                if (isDate(v)) {
                    return new Date(v)
                }
                return null
            })
            .filter(v => v)

        if (!empty(transformed)) {
            safeValue = transformed
        }
    }

    return (
        <>
            <DateRangePickerStyles empty={empty(safeValue)} />
            <CalendarStyles />
            <DateRangePickerContainer
                focused={open ? 1 : 0}
                error={error}
                disabled={disabled ? 1 : 0}
                empty={empty(safeValue) ? 1 : 0}
                onClick={e => {
                    if (
                        isChildOf({
                            node: e.target,
                            parentNodeSelector: 'button',
                        })
                    )
                        return
                    setOpen(true)
                }}
                marginBottom={instructions ? 0 : 1}
            >
                <InnerInputContainer>
                    <Label>{label}</Label>
                    {error && <Error>{error.message}</Error>}
                    <DateRangePicker
                        isOpen={open}
                        value={safeValue}
                        onChange={onChange}
                        format="dd/MM/y"
                        onCalendarOpen={() => setOpen(true)}
                        onCalendarClose={() => setOpen(false)}
                    />
                    <SaveStatus
                        isDirty={isDirty}
                        value={value}
                        showSaveStatus={showSaveStatus}
                        onDiscard={onDiscard}
                    />
                </InnerInputContainer>
            </DateRangePickerContainer>
            {instructions ? <Instructions>{instructions}</Instructions> : null}
        </>
    )
}
