import React from 'react'

import Section from 'ui-components/section'

import { IoMdHelpCircleOutline } from 'react-icons/io'

import styled from 'styled-components'

const Text = styled.div`
    margin: 0 0 1rem 0;
    line-height: 1.618;
    color: ${props => props.theme.gray2};
`

export default ({
    children,
    title = 'Help',
    icon = IoMdHelpCircleOutline,
    ...props
}) => {
    return (
        <Section title={title} icon={icon} {...props}>
            <Text>{children}</Text>
        </Section>
    )
}
