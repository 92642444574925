import React from 'react'

import ContactSearch from 'modules/contact/contact-search'

export default () => {
    return (
        <>
            <ContactSearch />
        </>
    )
}
