import { useContext, useCallback } from 'react'

export default (name, Context) => {
    const [, set] = useContext(Context)

    const callback = useCallback(
        value => {
            set(state => ({ ...state, [name]: value }))
        },
        [set, name]
    )

    return callback
}
