import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

import { yesNo } from 'crm-components/data-helpers'

const GET_TENANT_STATUSES = /* GraphQL */ `
    query GetTenantStatuses(
        $search: TenantStatusSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        tenantStatuses(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on TenantStatus {
                    _id
                    name
                    isHidden
                    showPredefinedQuery
                    createdAt
                }
            }
        }
    }
`

const DELETE_TENANT_STATUSES = /* GraphQL */ `
    mutation DeleteTenantStatuses($ids: [ID!]!) {
        deletetenantStatuses(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    switch (col.key) {
        case 'isHidden':
        case 'showPredefinedQuery':
            return yesNo({ value: row[col.key] })
        default:
            return
    }
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Hidden',
                key: 'isHidden',
                sortKey: 'isHidden',
            },
            {
                name: 'Predefined Query',
                key: 'showPredefinedQuery',
                sortKey: 'showPredefinedQuery',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_TENANT_STATUSES}
            deleteQuery={DELETE_TENANT_STATUSES}
            pageTitle={'Tenant statuses'}
            entity="tenantStatus"
            cols={cols}
            record="TenantStatus"
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
