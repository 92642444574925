import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const TENURE_TYPE_FRAGMENT = /* GraphQL */ `
    fragment tenureTypeFields on TenureType {
        _id
        name
        propertyFinderValue
        bayutValue
        createdAt
    }
`

const SAVE_TENURE_TYPE = /* GraphQL */ `
    mutation SaveTenureType($tenureType: TenureTypeInput) {
        saveTenureType(tenureType: $tenureType) {
            ...tenureTypeFields
        }
    }
    ${TENURE_TYPE_FRAGMENT}
`

const GET_TENURE_TYPE = /* GraphQL */ `
    query GetTenureType($id: ID!) {
        tenureType(id: $id) {
            ...tenureTypeFields
        }
    }
    ${TENURE_TYPE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_TENURE_TYPE}
            mutation={SAVE_TENURE_TYPE}
            mutationVariableName="tenureType"
            record="TenureType"
        >
            <Tab title="Details">
                <Section title="Tenure type details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
