import React, { useEffect, useMemo } from 'react'

import { get } from 'lodash-es'

import Section from 'ui-components/section'

import PropertySelect from 'crm-components/property-select'

import { FormField, FormFields } from 'crm-components/crud-form'

import { ValueSelect } from 'crm-components/collection-select'

import { useQuery } from 'system/network/graphql'

import { useFormData } from 'crm-components/crud-form/hooks/fields'

import { getDataObject } from 'crm-components/data-helpers'

const OwnerSelect = ({ onChange, ...props }) => {
    const [data] = useFormData()

    const propertyId = get(data, 'propertyId')

    const { cacheValue } = useQuery({
        operation: {
            query: /* GraphQL */ `
                query Get($id: ID) {
                    property(id: $id) {
                        owner {
                            _id
                        }
                    }
                }
            `,
            variables: {
                id: propertyId,
            },
        },
    })

    const ownerId = useMemo(() => get(getDataObject(cacheValue), 'ownerId'), [
        cacheValue,
    ])

    useEffect(() => {
        if (ownerId) onChange(ownerId)
    }, [ownerId, onChange])

    return <ValueSelect {...props} onChange={onChange} />
}

export default () => {
    return (
        <Section title="Basic details">
            <FormFields>
                <FormField name="amount" type="number" label="Net amount" />

                <FormField
                    name="propertyId"
                    label="Property"
                    component={PropertySelect}
                    showAddLink
                />

                <FormField
                    name="leadId"
                    label="Lead"
                    component={ValueSelect}
                    queryName="leads"
                    recordName="Lead"
                    previewBaseLink="/leads/"
                    showAddLink
                />

                <FormField
                    name="ownerId"
                    label="Owner"
                    component={OwnerSelect}
                    queryName="owners"
                    recordName="Owner"
                    previewBaseLink="/owners/"
                    showAddLink
                />
            </FormFields>
        </Section>
    )
}
