import React from 'react'

import Body from './body'

import Header from './header'

import SectionContextProvider from './provider'

import PropContextBinder from './prop-context-binder'

import Layout from './layout'

export default ({
    title,
    subTitle,
    icon,
    paddingBottom,
    hiddenOverflow,
    children,
    expanded,
}) => {
    return (
        <SectionContextProvider>
            <PropContextBinder
                title={title}
                subTitle={subTitle}
                icon={icon}
                paddingBottom={paddingBottom}
                hiddenOverflow={hiddenOverflow}
                children={children}
                expanded={expanded}
            />
            <Layout>
                <Header />
                <Body>{children}</Body>
            </Layout>
        </SectionContextProvider>
    )
}
