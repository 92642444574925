import { useEffect, useContext, useCallback, useMemo } from 'react'

import { isFunction, isArray, isEmpty, get } from 'lodash-es'

import { ListContext } from './provider'

const emptyArray = []

export const useAdditionalCols = () => {
    const [state, set] = useContext(ListContext)

    const setAddtionalCols = useCallback(
        value => {
            const setter = isFunction(value) ? value : () => value
            set(state => ({
                ...state,
                additionalCols: setter(state.additionalCols),
            }))
        },
        [set]
    )

    return [state.additionalCols, setAddtionalCols]
}

const useExpandedRows = () => {
    const [state, set] = useContext(ListContext)

    const setExpandedRows = useCallback(
        value => {
            set(state => {
                const setter = isFunction(value) ? value : () => value

                const expandedRows = setter(state.expandedRows)

                return { ...state, expandedRows }
            })
        },
        [set]
    )

    useEffect(() => {
        setExpandedRows(emptyArray)
    }, [state.rows, setExpandedRows])

    return [state.expandedRows, setExpandedRows]
}

export const useToggleExpandAll = () => {
    const [{ rows }] = useContext(ListContext)

    const [expandedRows, setExpandedRows] = useExpandedRows()

    const toggleExpandAll = useCallback(
        () =>
            setExpandedRows(expandedRows => {
                // console.log({ expandedRows, rows })
                expandedRows =
                    expandedRows.length === rows.length ? emptyArray : rows

                return expandedRows
            }),
        [rows, setExpandedRows]
    )

    const areAllRowsExpanded = useMemo(() => {
        if (isEmpty(rows)) return false

        return expandedRows.length === rows.length
    }, [expandedRows, rows])

    return { toggleExpandAll, areAllRowsExpanded }
}

export const useToggleRowExpanded = ({ row }) => {
    const [expandedRows, setExpandedRows] = useExpandedRows()

    const rowId = get(row, '_id')

    const toggleExpandRow = useCallback(() => {
        setExpandedRows(expandedRows => {
            if (!rowId) return expandedRows

            const found = expandedRows.find(_row => rowId === _row._id)

            if (found) {
                expandedRows = expandedRows.filter(_row => rowId !== _row._id)
            } else {
                expandedRows = [...expandedRows, row]
            }

            return expandedRows
        })
    }, [setExpandedRows, rowId, row])

    const expanded = useMemo(() => {
        if (!rowId) return undefined

        return !!expandedRows.find(row => row._id === rowId)
    }, [rowId, expandedRows])

    return { expanded, toggleExpandRow }
}

export const useListSelection = () => {
    const [{ selectedRows, rows, onChangeSelected }, set] = useContext(
        ListContext
    )

    const setSelectedRows = useCallback(
        value => {
            const setter = isFunction(value) ? value : () => value
            set(state => ({
                ...state,
                selectedRows: setter(state.selectedRows),
            }))
        },
        [set]
    )

    const isRowSelected = useCallback(
        row => {
            return (
                isArray(selectedRows) &&
                !!selectedRows.find(r => r._id === row._id)
            )
        },
        [selectedRows]
    )

    const toggleRowSelection = useCallback(
        row => {
            const selected = isRowSelected(row)

            setSelectedRows(selectedRows => {
                if (selected) {
                    return selectedRows.filter(r => r._id !== row._id)
                } else {
                    return [...selectedRows, row]
                }
            })
        },
        [isRowSelected, setSelectedRows]
    )

    const toggleSelectAll = useCallback(() => {
        setSelectedRows(selectedRows => {
            if (selectedRows.length === rows.length) {
                return emptyArray
            } else {
                return rows
            }
        })
    }, [rows, setSelectedRows])

    useEffect(() => {
        if (isFunction(onChangeSelected)) onChangeSelected(selectedRows)
    }, [selectedRows, onChangeSelected])

    useEffect(() => {
        setSelectedRows(selected => {
            if (isEmpty(selected)) return selected
            return emptyArray
        })
    }, [rows, setSelectedRows])

    return { toggleRowSelection, isRowSelected, toggleSelectAll, selectedRows }
}

export const useSort = () => {
    const [{ sort, onSortChange }, set] = useContext(ListContext)

    const setSort = useCallback(
        value => {
            set(state => {
                return {
                    ...state,
                    sort: value,
                }
            })
        },
        [set]
    )

    useEffect(() => {
        if (isFunction(onSortChange)) onSortChange(sort)
    }, [sort, onSortChange])

    return [sort, setSort]
}
