import React from 'react'

import styled from 'styled-components'

import AccountPage from './account-page'

import Route from './route'

import Button from 'ui-components/button'

const SuccessMessage = styled.div`
    color: ${props => props.theme.success};
    line-height: 140%;
    text-align: center;
`

const ButtonWrapper = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: center;
`

const Page = () => {
    return (
        <AccountPage title="Reset password" subtitle={`Change your password`}>
            <SuccessMessage>
                Your password has been changed successfully!
            </SuccessMessage>
            <ButtonWrapper>
                <Button to="/login">Login now</Button>
            </ButtonWrapper>
        </AccountPage>
    )
}

export default () => {
    return (
        <Route path="/password-changed" fullScreen>
            <Page />
        </Route>
    )
}
