import React from 'react'

import DropDownMenu, {
    MenuLabel,
    MenuItem as DefaultMenuItem,
} from 'ui-components/drop-down-menu'

import { isFunction } from 'lodash-es'

import styled from 'styled-components'

import Spinner from 'ui-components/loader'

import { empty } from 'crm-components/data-helpers'

import QueryButton, { Badge } from './query-button'

import { BsFilter as MenuIcon } from 'react-icons/bs'

import { useIsMobile } from 'system/ui/match-media'

const MenuItem = styled(DefaultMenuItem)`
    background-color: ${props =>
        props.isActive() ? props.theme.gray2 : 'transparent'};

    color: ${props => (props.isActive() ? props.theme.light : 'initial')};

    &:hover {
        color: ${props => (props.isActive() ? props.theme.dark : 'initial')};
    }
`

const Container = styled.div`
    display: flex;
    min-height: 30px;
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0.75, 0);
    flex-wrap: wrap;
`

const Loader = () => {
    return (
        <Container>
            <QueryButton name="loading">
                Loading...
                <Badge>
                    <Spinner />
                </Badge>
            </QueryButton>
        </Container>
    )
}

const Queries = ({ queries, component: Component, isActive, onQueryClick }) => {
    if (empty(queries)) return null

    return queries.map(query => {
        return (
            <Component
                key={query.name + query.count}
                onClick={() => {
                    if (!isFunction(onQueryClick)) return

                    onQueryClick(query)
                }}
                isActive={() => {
                    if (!isFunction(isActive)) return

                    return isActive(query)
                }}
            >
                {query.name}
                <Badge>{query.count}</Badge>
            </Component>
        )
    })
}

export default ({ queries, loading, onQueryClick, isActive }) => {
    const isMobile = useIsMobile()

    if (isMobile)
        return (
            <DropDownMenu position="left" icon={MenuIcon}>
                <MenuLabel>Queries</MenuLabel>
                <Queries
                    queries={queries}
                    isActive={isActive}
                    onQueryClick={onQueryClick}
                    component={MenuItem}
                />
            </DropDownMenu>
        )

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <Container>
                    <Queries
                        queries={queries}
                        isActive={isActive}
                        onQueryClick={onQueryClick}
                        component={QueryButton}
                    />
                </Container>
            )}
        </>
    )
}
