import React, { useState, useRef, useEffect, useCallback } from 'react'

import styled, { keyframes, css } from 'styled-components'

import TextareaAutosize from 'react-textarea-autosize'

import { isFunction, isEqual, get } from 'lodash-es'

import SaveStatus from 'ui-components/save-status'

import DebouncedInput from 'ui-components/debounced-input'

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

export const InputStyles = css`
    width: 100%;
    display: block;
    box-shadow: none;
    outline: 0;
    margin-top: 0.5rem;
    border: 0;
    color: ${props => props.theme.dark};

    ::placeholder {
        color: ${props => props.theme.gray2};
        font-style: italic;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px white inset;

        background-clip: content-box !important;
    }
    &[disabled] {
        background-color: transparent;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }
`

const Input = styled.input`
    ${InputStyles}
`

const TextArea = styled(TextareaAutosize)`
    ${InputStyles}
    line-height: ${props => (props.value.match(/\n/g) ? '180%' : '100%')};
    height: 20px;
`

export const InputContainer = styled.div`
    background-color: white;
    padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 0 1px 2px
        ${props =>
            props.error
                ? props.theme.danger
                : props.focused
                ? props.theme.gray2
                : props.theme.gray};
    align-items: center;
    margin-bottom: ${props => (props.marginBottom ? '1rem' : 0)};
    transition: 0.2s ease;
    position: relative;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

export const Label = styled.label`
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.theme.primary};
    user-select: none;
`

export const Error = styled.div`
    color: ${props => props.theme.danger};
    position: absolute;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 400;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    animation: ${fadeIn} 0.5s ease;
`

export const InnerInputContainer = styled.div`
    flex: 1;
    padding: 0 0.5rem;
`

export const Instructions = styled.p`
    font-size: 0.8rem;
    color: ${props => props.theme.gray2};
    font-weight: 200;
    margin: 0.5rem 0 1rem;
    line-height: 140%;
`

export function makeRandomName(length = 10) {
    var result = ''
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }
    return result
}

const defaultInputRef = { current: null }

export default ({
    label,
    placeholder,
    icon,
    error,
    inputRef = defaultInputRef,
    onChange,
    instructions,
    wait = 500,
    immediate, // pretend to call onChange immediately?
    type = 'text',
    value,
    disabled,
    isDirty,
    showSaveStatus,
    onDiscard,
    min,
    max,
    readOnly,
    className,
}) => {
    const [inputName] = useState(makeRandomName())

    const [focused, setFocused] = useState(false)

    const input = useRef({})

    const lastError = useRef()

    useEffect(() => {
        inputRef.current = input.current
    }, [inputRef])

    useEffect(() => {
        if (!isEqual(error, lastError.current)) {
            if (isFunction(get(input, 'current.focus'))) {
                input.current.focus()
            }
        }
        lastError.current = error
    }, [error])

    const onInputChange = useCallback(
        value => {
            if (type === 'number') {
                if (isFunction(onChange)) onChange(+value)
            } else {
                if (isFunction(onChange)) onChange(value)
            }
        },
        [onChange, type]
    )

    return (
        <>
            <InputContainer
                focused={focused ? 1 : 0}
                onClick={() => input.current.focus()}
                error={error}
                disabled={disabled ? 1 : 0}
                marginBottom={instructions ? 0 : 1}
                className={className}
            >
                {icon}
                <InnerInputContainer>
                    <Label htmlFor={inputName}>{label}</Label>
                    {error && <Error>{error.message}</Error>}
                    {type === 'textarea' ? (
                        <DebouncedInput
                            wait={wait}
                            immediate={immediate}
                            component={TextArea}
                            disabled={disabled ? 1 : 0}
                            inputRef={input}
                            name={inputName}
                            id={inputName}
                            placeholder={placeholder || label}
                            onFocus={() => {
                                setFocused(true)
                            }}
                            onBlur={() => setFocused(false)}
                            value={value}
                            onChange={onInputChange}
                            readOnly={readOnly}
                        />
                    ) : (
                        <DebouncedInput
                            component={Input}
                            wait={wait}
                            immediate={immediate}
                            disabled={disabled ? 1 : 0}
                            type={type}
                            ref={input}
                            name={inputName}
                            id={inputName}
                            placeholder={placeholder || label}
                            onFocus={() => {
                                setFocused(true)
                            }}
                            onBlur={() => setFocused(false)}
                            value={value}
                            onChange={onInputChange}
                            min={min}
                            max={max}
                            readOnly={readOnly}
                        />
                    )}
                    <SaveStatus
                        isDirty={isDirty}
                        value={value}
                        showSaveStatus={showSaveStatus}
                        onDiscard={onDiscard}
                    />
                </InnerInputContainer>
            </InputContainer>
            {instructions ? <Instructions>{instructions}</Instructions> : null}
        </>
    )
}
