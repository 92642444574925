import React, { useContext } from 'react'

import { useOnDelete } from './hooks'

import styled from 'styled-components'

import { ListContext } from './provider'

import { useCan } from 'system/account/acl'

import { isArray, isFunction } from 'lodash-es'

import { useRouteMatch } from 'react-router-dom'

import { ListLink, ListAction } from 'ui-components/list/common'

const Container = styled.div`
    display: flex;
    border: 2px solid ${props => props.theme.gray2};
    margin-bottom: 1rem;
`

const ActionGroup = styled.div`
    display: flex;
    flex: 100%;
    @media ${props => props.theme.media.md} {
        flex: 1;
        justify-content: ${props => (props.end ? 'flex-end' : 'initial')};
    }
    ${ListLink}, ${ListAction} {
        opacity: 1;
    }
`

export default () => {
    const [{ record, actions, selectedRows }] = useContext(ListContext)

    const accessModifier = undefined

    const { url } = useRouteMatch()

    const canDelete = useCan({
        record,
        method: 'DELETE',
        accessModifier,
    })

    const canWrite = useCan({
        record,
        method: 'WRITE',
        accessModifier,
    })

    const onDelete = useOnDelete()

    return (
        <Container>
            <ActionGroup>
                <ListLink to={`${url}/create`} disabled={!canWrite}>
                    Create new
                </ListLink>
                <ListAction
                    onClick={onDelete}
                    disabled={!selectedRows.length || !canDelete}
                >
                    Delete selected
                </ListAction>
                {isArray(actions) &&
                    actions.map(action => (
                        <ListAction
                            key={action.name}
                            onClick={() =>
                                isFunction(action.onClick) &&
                                action.onClick({ selectedRows: selectedRows })
                            }
                            disabled={
                                isFunction(action.isDisabled) &&
                                action.isDisabled({
                                    selectedRows: selectedRows,
                                })
                            }
                        >
                            {action.name}
                        </ListAction>
                    ))}
            </ActionGroup>
        </Container>
    )
}
