import { isObject } from 'lodash-es'
import { useMemo } from 'react'
import { useDirtyData } from './fields'

export default () => {
    const [dirtyData] = useDirtyData()

    const dirty = useMemo(() => {
        if (!isObject(dirtyData)) return false

        return Object.keys(dirtyData).reduce((isDirty, key) => {
            return isDirty || dirtyData[key]
        }, false)
    }, [dirtyData])

    return [dirty]
}
