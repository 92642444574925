import React from 'react'

import { FormFields, FormField } from 'crm-components/crud-form'

import DocumentsUploaderContextProvider from 'crm-components/documents-uploader/provider'

import Section from 'ui-components/section'

import { useParams } from 'react-router-dom'

import { ImFilesEmpty } from 'react-icons/im'

import { AiOutlineFileSearch } from 'react-icons/ai'

import Search from 'crm-components/documents-uploader/search'

import { Tab } from 'crm-components/crud-form'

import { getVariableNamePluralized } from 'crm-components/data-helpers'

import DocumentUploader from './documents-uploader/uploader'

export default ({ parentResolveType }) => {
    const { id } = useParams()

    return (
        <Tab title="Documents">
            <DocumentsUploaderContextProvider>
                <Section
                    title="Search"
                    icon={AiOutlineFileSearch}
                    expanded={false}
                >
                    <Search />
                </Section>
                <Section title="Documents" icon={ImFilesEmpty}>
                    <FormFields>
                        <FormField
                            fullWidth
                            name="documentIds"
                            component={DocumentUploader}
                            parentCollection={getVariableNamePluralized(
                                parentResolveType
                            )}
                            parentId={id}
                            parentRecordName={parentResolveType}
                        />
                    </FormFields>
                </Section>
            </DocumentsUploaderContextProvider>
        </Tab>
    )
}
