import React from 'react'

import FormProvider, { FormContext } from './provider'

import useBindPropContextValue from 'helpers/use-bind-prop-context-value'

import Form from './form'

import Field from './field'

import Button from './button'

import { excludeFields as _excludeFields } from './data-processors'

export const FormField = Field

export const FormButton = Button

export const excludeFields = _excludeFields

const ContextPropBinder = props => {
    useBindPropContextValue(props, FormContext)

    return null
}

export default ({
    loading,
    disabled,
    mutationCacheValue,
    submit,
    children,
    onDataChange,
    validationSchema,
    saveChangesToLocalStorage,
    queryCacheValue,
    saveOnEnter,
    onDirtyChange,
    noRemoteData,
    excludeFromInput,
    isKeyDirty,
    onValidationErrorsChange,
    formName,
}) => {
    return (
        <FormProvider>
            <ContextPropBinder
                loading={loading}
                disabled={disabled}
                mutationCacheValue={mutationCacheValue}
                queryCacheValue={queryCacheValue}
                submit={submit}
                onDataChange={onDataChange}
                validationSchema={validationSchema}
                saveChangesToLocalStorage={saveChangesToLocalStorage}
                saveOnEnter={saveOnEnter}
                onDirtyChange={onDirtyChange}
                noRemoteData={noRemoteData}
                excludeFromInput={excludeFromInput}
                isKeyDirty={isKeyDirty}
                onValidationErrorsChange={onValidationErrorsChange}
                formName={formName}
            />
            <Form>{children}</Form>
        </FormProvider>
    )
}
