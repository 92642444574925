import { get } from 'lodash-es'

import React, { useMemo } from 'react'

import { Route } from 'system/core/route'

import Slider from './property-image-slider'

import { useParams } from 'react-router-dom'

import styled, { css } from 'styled-components'

import DefaultLoader from 'ui-components/loader'

import { getResizedUrl } from 'system/core/file'

import { useQuery } from 'system/network/graphql'

import { Grid, Row, Col } from 'react-styled-flexboxgrid'

import { useRowFields } from 'crm-components/label-value-maker'

import { getDataObject, getUserName } from 'crm-components/data-helpers'

import {
    usePropertyImages,
    useFetchPropertyName,
    useFetchPropertyMarketingDescription,
    getPropertyIgnoredKeyNamesForFieldLabelValueMaker,
} from './hooks'

const Loader = styled(DefaultLoader)`
    font-size: 3rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
`

const USER_FRAGMENT = /* GraphQL */ `
    fragment userFields on User {
        profile {
            firstName
            lastName
            companyRole
            mobile
            brn
            createdAt
            updatedAt
            photo {
                resizedUrl
            }
        }
        company {
            name
            logo {
                resizedUrl
            }
        }
    }
`

const PROPERTY_FRAGMENT = /* GraphQL */ `
    fragment propertyFields on Property {
        _id
        category {
            name
        }
        propertyOffering {
            name
        }
        propertyType {
            name
        }
        size
        numberOfBedrooms {
            name
        }
        numberOfBathrooms {
            name
        }
        numberOfParkingSpaces
        furnitureType {
            name
        }
        propertyDeveloper
        tenureType {
            name
        }
        buildYear
        plotSize
        plotNumber
        builtUpArea
        buildingFloors
        occupancy {
            name
        }
        projectStatus {
            name
        }
        renovationType {
            name
        }
        layoutType
        dewaNumber
        views
        price
        serviceCharges
        financialStatus {
            name
        }
        numberOfCheques {
            name
        }
        referenceCode
        permitNumber
        images {
            url
            waterMarkedUrl
            resizedUrl
        }
        location {
            city
            community
            subCommunity
            property
            country
        }
        projectName
        streetAddress
        streetNumber
        unitNumber
        listingPlatforms {
            name
        }
        amenities {
            name
        }
        marketingRecords {
            name
            content
            language {
                isDefault
            }
        }
        assignedTo {
            ...userFields
        }
        createdBy {
            ...userFields
        }
    }
`

const GET_PROPERTY = /* GraphQL */ `
    query GetProperty($id: ID!) {
        property(id: $id) {
            ...propertyFields
        }
    }
    ${PROPERTY_FRAGMENT}
    ${USER_FRAGMENT}
`

const Container = styled(Grid)`
    line-height: 170%;
`

const Title = styled.h1`
    line-height: 140%;
    font-weight: normal;
    color: ${props => props.theme.primary};
    margin: 0 0 1rem 0;
    text-transform: capitalize;
`

const FieldName = styled.div`
    color: ${props => props.theme.secondary};
    margin-right: 0.5rem;
`

const FieldValue = styled.div`
    color: ${props => props.theme.dark};
`

const KeyValuePair = styled.div`
    display: flex;
`

const Description = styled.div`
    display: block;
`

const TitlesContainer = styled.div`
    @media ${props => props.theme.media.sm} {
        margin-left: 2rem;
    }
`

const Header = styled.div`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.light};
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
`

const DetailsSection = styled.div`
    margin: 1rem 0;
`

const SubHeader = styled.h2`
    text-transform: capitalize;
    line-height: 140%;
    font-weight: normal;
    color: ${props => props.theme.primary};
    border-top: 1px solid ${props => props.theme.gray2};
    padding-top: 2rem;
`

const ImageStyles = css`
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
`

const PersonalPhoto = styled.img`
    ${ImageStyles}
    border-radius: 50%;
`

const CompanyLogo = styled.img`
    ${ImageStyles}
`

const AgentDetails = styled.div`
    color: ${props => props.theme.primary};
`

const Footer = styled.div`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.light};
    text-align: center;
    padding: 0.5rem 0;
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 0.8rem;
`

const BrochureLoader = () => {
    return <Loader />
}

const BrochureDetails = ({ property, name, description }) => {
    const images = usePropertyImages({ property })
    const fields = useRowFields({
        row: property,
        ignoredKeyNames: getPropertyIgnoredKeyNamesForFieldLabelValueMaker(),
    })

    const user = get(property, 'assignedTo') || get(property, 'createdBy')
    const personalPhoto = getResizedUrl({ file: get(user, 'profile.photo') })
    const companyLogo = getResizedUrl({ file: get(user, 'company.logo') })
    const agentRole = get(user, 'profile.companyRole')
    const companyName = get(user, 'company.name')
    const mobile = get(user, 'profile.mobile')
    const brn = get(user, 'profile.brn')

    if (!property) return null

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <Header>Property Brochure</Header>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <Slider images={images} />
                </Col>
                <Col xs={12} sm={6} md={8}>
                    <TitlesContainer>
                        <Title>{name}</Title>
                        <Description>{description}</Description>
                    </TitlesContainer>
                </Col>
            </Row>
            <DetailsSection>
                <Row>
                    <Col xs={12}>
                        <SubHeader>Property details</SubHeader>
                    </Col>
                    {fields.map(keyValuePair => (
                        <Col xs={12} sm={6} key={keyValuePair.key}>
                            <KeyValuePair>
                                <FieldName>{keyValuePair.name}: </FieldName>
                                <FieldValue>{keyValuePair.value}</FieldValue>
                            </KeyValuePair>
                        </Col>
                    ))}
                </Row>
            </DetailsSection>
            <DetailsSection>
                <Row>
                    <Col xs={12}>
                        <SubHeader>Listed by</SubHeader>
                        {personalPhoto && <PersonalPhoto src={personalPhoto} />}
                        <AgentDetails>{getUserName(user)}</AgentDetails>
                        <AgentDetails>{agentRole}</AgentDetails>
                        <KeyValuePair>
                            <FieldName>Mobile: </FieldName>
                            <FieldValue>{mobile}</FieldValue>
                        </KeyValuePair>
                        <KeyValuePair>
                            <FieldName>BRN: </FieldName>
                            <FieldValue>{brn}</FieldValue>
                        </KeyValuePair>
                        <AgentDetails>{companyName}</AgentDetails>
                        {companyLogo && <CompanyLogo src={companyLogo} />}
                    </Col>
                </Row>
            </DetailsSection>
            <Row>
                <Col xs={12}>
                    <Footer>
                        Convert Pro CRM &copy; 2020 - all rights reserved
                    </Footer>
                </Col>
            </Row>
        </Container>
    )
}

const BrochurePage = () => {
    const { id } = useParams()

    const fetchParam = { property: { _id: id } }

    const { loading: nameLoading, name } = useFetchPropertyName(fetchParam)
    const {
        loading: descriptionLoading,
        description,
    } = useFetchPropertyMarketingDescription(fetchParam)

    const { cacheValue, loading } = useQuery({
        operation: {
            query: GET_PROPERTY,
            variables: { id },
        },
    })

    const property = useMemo(() => getDataObject(cacheValue), [cacheValue])

    const showLoader = loading || nameLoading || descriptionLoading || !property

    return showLoader ? (
        <BrochureLoader />
    ) : (
        <BrochureDetails
            property={property}
            name={name}
            description={description}
        />
    )
}

export default () => {
    return (
        <Route path={'/property-brochure/:id'} fullScreen>
            <BrochurePage />
        </Route>
    )
}
