import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from 'react'

import styled from 'styled-components'

import Loader from './loader'

import { useQuery, useMutation } from 'system/network/graphql'

import { IoIosImage, IoIosCheckbox, IoIosRemoveCircle } from 'react-icons/io'

import SaveStatus from 'ui-components/save-status'

import { Error } from './input'

import { get, isEmpty } from 'lodash-es'

import CropModal from './crop-modal'

import DefaultButton from './button'

import { getFileUrl, getResizedUrl } from 'system/core/file'

import { GraphQLContext } from 'graphql-react'

import { errorToast } from './toast'

import { empty } from 'crm-components/data-helpers'

const FILE_FIELDS = /* GraphQL */ `
    fragment fileFields on File {
        _id
        url
        filename
        crop {
            x
            y
            width
            height
            unit
            aspect
        }
        resizedUrl
    }
`

const SAVE_FILE = /* GraphQL */ `
    mutation SaveFile($upload: Upload, $file: FileInput) {
        saveFile(upload: $upload, file: $file) {
            ...fileFields
        }
    }
    ${FILE_FIELDS}
`

const GET_FILE = /* GraphQL */ `
    query GetFile($id: ID!) {
        file(id: $id) {
            ...fileFields
        }
    }
    ${FILE_FIELDS}
`

const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    font-size: 2rem;
    color: ${props => props.theme.dark};
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props => (props.circle ? '50%' : 0)};
`

const Container = styled.div`
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 0 1px 2px
        ${props =>
            props.error
                ? props.theme.danger
                : props.focused
                ? props.theme.gray2
                : props.theme.gray};
    border-radius: 5px;
    position: relative;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const FileName = styled.div`
    color: ${props => props.theme.gray2};
    margin-bottom: 0.5rem;
    line-height: 140%;
`

const Image = styled(IoIosImage)`
    font-size: 2rem;
    color: ${props => props.theme.gray2};
`

const UploadedCheck = styled(IoIosCheckbox)`
    margin-right: 0.5rem;
`

const StatusContainer = styled.div`
    position: absolute;
    top: 50%;
    font-size: 0.8rem;
    left: calc(120px + 1.5rem);
    transform: translateY(-50%);
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
`

const Uploaded = styled.div`
    color: ${props => props.theme.success};
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
`

const ImageContainer = styled.div`
    padding: 0.5rem;
    background-color: ${props => props.theme.gray};
    margin: 0.5rem 0 0 0;
    width: 120px;
    height: 120px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props => (props.circle ? '50%' : 0)};
`

const Input = styled.input`
    display: none;
`

const InputLabel = styled.span`
    color: ${props => props.theme.primary};
    font-weight: bold;
    display: block;
    font-size: 0.8rem;
`
const ImagePreview = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: ${props => props.theme.gray};
    border-radius: ${props => {
        return props.circle ? '50%' : 0
    }};
`

const Row = styled.div`
    position: relative;
`

const ActionButtons = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justif-content: space-between;
    width: 120px;
`

const Button = styled(DefaultButton)`
    margin-right: 0.5rem;
    flex: 1;
    &:last-child {
        margin-right: 0;
    }
`

const RemoveIcon = styled(IoIosRemoveCircle)`
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    color: ${props => props.theme.danger};
    background-color: ${props => props.theme.light};
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 1;
    outline: 0;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const TopOverlay = styled.div`
    position: absolute;
    z-index: 0;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    border-radius: ${props => (props.circle ? '50%' : 0)};

    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.1) 30%,
        rgba(0, 0, 0, 0) 100%
    );
`

const noop = null

export default ({
    label,
    circle,
    isDirty,
    parentId,
    className,
    value = noop,
    parentCollection,
    error: componentError,
    disabled: componentDisabled,
    onChange = () => {},
    showSaveStatus,
    onDiscard,
}) => {
    const graphql = useContext(GraphQLContext)

    const fileRef = useRef({})

    const fileRemoved = useRef(false)

    // safe rename to file, setFile
    const [crop, setCrop] = useState(noop)

    const [cropModalOpen, setCropModalOpen] = useState(false)

    const [previewUrl, setPreviewUrl] = useState(null)

    /** @type {File} */
    // safe rename to upload
    const [uploadState, setUpload] = useState(null)

    const {
        load: saveFile,
        cacheValue: saveFileValue,
        loading: saveLoading,
    } = useMutation({
        operation: { query: SAVE_FILE },
    })

    const {
        loading: getLoading,
        cacheValue: getFileValue = {},
        load: loadGetFileQuery,
    } = useQuery({
        operation: {
            query: GET_FILE,
            variables: { id: value },
        },
        loadOnMount: false,
    })

    const loading = getLoading || saveLoading

    const disabled = componentDisabled || getLoading

    const [remoteFileData, setRemoteFileData] = useState(noop)

    const originalUrl = value && getFileUrl({ file: remoteFileData })

    const shouldUploadFile = useRef(false)

    const shouldSaveCrop = useRef(false)

    const uploadError = useMemo(() => {
        const uploadError = get(saveFileValue, 'graphQLErrors.0')

        if (uploadError) {
            return { message: 'Upload error, refresh the page and try again' }
        }

        return null
    }, [saveFileValue])

    const error = useMemo(() => {
        return componentError || uploadError
    }, [componentError, uploadError])

    const defaultCrop = useMemo(() => {
        const defaultCrop = {
            // aspect: 1,
            unit: '%',
            x: 0,
            y: 0,
            width: 100,
            height: 100,
        }
        if (circle) {
            return { ...defaultCrop, aspect: 1 }
        }
        return defaultCrop
    }, [circle])

    const uploaded =
        get(saveFileValue, 'data.saveFile._id') && uploadState && value

    const openFileDialogue = () => fileRef.current.click()

    const loadFile = useCallback(loadGetFileQuery, [value])

    const removeFile = useCallback(() => {
        onChange(noop)
        setRemoteFileData(noop)
        fileRef.current.value = null
        fileRef.current.type = 'text'
        fileRef.current.type = 'file'
        fileRemoved.current = true
        graphql.reload()
    }, [onChange, graphql])

    const onCropModalClose = useCallback(() => {
        setCropModalOpen(false)
    }, [setCropModalOpen])

    const onCropChange = useCallback(crop => {
        shouldSaveCrop.current = true
        setCrop(crop)
    }, [])

    useEffect(() => {
        setCrop(get(remoteFileData, 'crop') || defaultCrop)
    }, [remoteFileData, crop, defaultCrop])

    useEffect(() => {
        if (!isEmpty(remoteFileData) && value) {
            setPreviewUrl(getResizedUrl({ file: remoteFileData }))
        } else {
            setPreviewUrl(null)
        }
    }, [remoteFileData, value])

    useEffect(() => {
        const id = get(remoteFileData, '_id')

        if (value && value !== id) loadFile()
    }, [loadFile, value, remoteFileData])

    useEffect(() => {
        const remoteFile =
            get(saveFileValue, 'data.saveFile') ||
            get(getFileValue, 'data.file') ||
            noop

        setRemoteFileData(remoteFile)
    }, [saveFileValue, getFileValue])

    useEffect(() => {
        const id = get(remoteFileData, '_id') || noop
        if (!fileRemoved.current) onChange(id)
    }, [remoteFileData, onChange])

    useEffect(() => {
        if (!empty(get(uploadState, 'name'))) {
            fileRemoved.current = false
        }
    }, [uploadState])

    useEffect(() => {
        if (uploadError || loading) return

        let variables = null

        if (shouldUploadFile.current) {
            variables = {
                file: {
                    _id: value,
                    parentCollection,
                    parentId,
                    crop: defaultCrop,
                },
                upload: uploadState,
            }
            shouldUploadFile.current = false
        } else if (shouldSaveCrop.current) {
            variables = {
                file: {
                    _id: value,
                    parentCollection,
                    parentId,
                    crop,
                },
            }
            shouldSaveCrop.current = false
        }

        if (variables) {
            if (empty(variables.file._id)) delete variables.file._id
            saveFile(variables)
        }
    }, [
        uploadError,
        loading,
        crop,
        defaultCrop,
        parentCollection,
        parentId,
        saveFile,
        uploadState,
        value,
    ])

    return (
        <Container error={error} disabled={disabled} className={className}>
            <InputLabel>{label}</InputLabel>
            {error && <Error>{error.message}</Error>}
            <Row>
                <ImageContainer circle={circle ? 1 : 0}>
                    {loading && (
                        <LoaderContainer circle={circle ? 1 : 0}>
                            <Loader />
                        </LoaderContainer>
                    )}

                    {previewUrl ? (
                        <ImagePreview
                            circle={circle ? 1 : 0}
                            src={previewUrl}
                        />
                    ) : (
                        <Image circle={circle ? 1 : 0} />
                    )}
                    {value && !loading && (
                        <>
                            <TopOverlay circle={circle ? 1 : 0} />
                            <RemoveIcon tabIndex={0} onClick={removeFile} />
                        </>
                    )}
                </ImageContainer>
                <StatusContainer>
                    {get(remoteFileData, 'filename') && value && (
                        <FileName>{remoteFileData.filename}</FileName>
                    )}

                    {uploaded && (
                        <Uploaded>
                            <UploadedCheck /> Uploaded
                        </Uploaded>
                    )}
                </StatusContainer>
            </Row>
            <ActionButtons>
                <Button
                    type="button"
                    mini
                    neutral
                    onClick={openFileDialogue}
                    disabled={disabled}
                >
                    {value ? 'Change' : 'Select'}
                </Button>
                <Button
                    disabled={!value || disabled}
                    type="button"
                    mini
                    neutral
                    onClick={() => setCropModalOpen(true)}
                >
                    Edit
                </Button>
            </ActionButtons>

            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />

            <Input
                ref={fileRef}
                type="file"
                accept="image/*"
                onChange={({
                    target: {
                        validity,
                        files: [upload],
                    },
                }) => {
                    if (!validity.valid || !upload) return

                    if (!upload.type.match(/png|jpe?g/)) {
                        return errorToast('Unsupported file type')
                    }

                    shouldUploadFile.current = true
                    setUpload(upload)
                }}
            />
            <CropModal
                circle={circle}
                open={cropModalOpen}
                src={originalUrl}
                onClose={onCropModalClose}
                loading={saveLoading}
                disabled={saveLoading}
                onChange={onCropChange}
                defaultCrop={crop || defaultCrop}
            />
        </Container>
    )
}
