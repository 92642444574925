import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const LEAD_INTEREST_TYPE_FRAGMENT = /* GraphQL */ `
    fragment leadInterestTypeFields on LeadInterestType {
        _id
        name
        createdAt
    }
`

const SAVE_LEAD_INTEREST_TYPE = /* GraphQL */ `
    mutation SaveLeadInterestType($leadInterestType: LeadInterestTypeInput) {
        saveLeadInterestType(leadInterestType: $leadInterestType) {
            ...leadInterestTypeFields
        }
    }
    ${LEAD_INTEREST_TYPE_FRAGMENT}
`

const GET_LEAD_INTEREST_TYPE = /* GraphQL */ `
    query GetLeadInterestType($id: ID!) {
        leadInterestType(id: $id) {
            ...leadInterestTypeFields
        }
    }
    ${LEAD_INTEREST_TYPE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_LEAD_INTEREST_TYPE}
            mutation={SAVE_LEAD_INTEREST_TYPE}
            mutationVariableName="leadInterestType"
            record="LeadInterestType"
        >
            <Tab title="Details">
                <Section title="Lead interest type details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
