import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const PROPERTY_TRANSITION_FRAGMENT = /* GraphQL */ `
    fragment propertyTransitionFields on PropertyTransition {
        _id
        name
        sourceStatusIds
        destinationStatusId
        sortOrder
    }
`

const SAVE_PROPERTY_TRANSITION = /* GraphQL */ `
    mutation SavePropertyTransition(
        $propertyTransition: PropertyTransitionInput
    ) {
        savePropertyTransition(propertyTransition: $propertyTransition) {
            ...propertyTransitionFields
        }
    }
    ${PROPERTY_TRANSITION_FRAGMENT}
`

const GET_PROPERTY_TRANSITION = /* GraphQL */ `
    query GetPropertyTransition($id: ID!) {
        propertyTransition(id: $id) {
            ...propertyTransitionFields
        }
    }
    ${PROPERTY_TRANSITION_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_PROPERTY_TRANSITION}
            mutation={SAVE_PROPERTY_TRANSITION}
            mutationVariableName="propertyTransition"
            record="PropertyTransition"
        >
            <Tab title="Details">
                <Section title="Property transition details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="e.g. approve, reject, move to archive"
                        />
                        <FormField
                            name="sourceStatusIds"
                            label="From status"
                            type="collectionSelect"
                            queryName="propertyStatuses"
                            recordName="PropertyStatus"
                            isMulti
                        />
                        <FormField
                            name="destinationStatusId"
                            label="To status"
                            type="collectionSelect"
                            queryName="propertyStatuses"
                            recordName="PropertyStatus"
                        />
                        <FormField
                            name="sortOrder"
                            type="number"
                            label="Sort order"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
