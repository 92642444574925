import React, { useState, useEffect, memo, useMemo } from 'react'

import Select from 'react-select'

import AsyncSelect from 'react-select/async'

import styled, { keyframes, useTheme } from 'styled-components'

import { MdKeyboardArrowDown } from 'react-icons/md'

import { isArray, isObject, isFunction } from 'lodash-es'

import { empty } from 'crm-components/data-helpers'

import { IoMdEye } from 'react-icons/io'

import { Link } from 'react-router-dom'

import SaveStatus from 'ui-components/save-status'

import InputComment from 'ui-components/input-comment'

import { Label as DefaultLabel } from 'ui-components/input'

const AddNewLink = styled.a`
    color: ${props => props.theme.gray2};
    letter-spacing: 1px;
    font-size: 0.5rem;
    margin: 0.5rem 0 0 0;
    align-items: center;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const Container = styled.div`
    background-color: white;
    padding: 0.5rem 0;
    border-radius: 5px;
    box-shadow: 0 0 1px 2px
        ${props =>
            props.error
                ? props.theme.danger
                : props.focus
                ? props.theme.gray2
                : props.theme.gray};
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.2s ease;
    position: relative;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const Icon = styled.div`
    display: flex;
    transition: transform 0.2s ease;
    transform: ${props => (props.focus ? 'rotate(180deg)' : 'rotate(0)')};
    opacity: ${props => (props.readOnly ? 0.2 : 1)};
`

const IconRow = styled.div`
    position: absolute;
    top: 0;
    right: 1rem;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`

const Label = styled(DefaultLabel)`
    padding: 0 1rem;
`

const Error = styled.div`
    color: ${props => props.theme.danger};
    position: absolute;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 400;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    text-transform: lowercase;
    animation: ${fadeIn} 0.5s ease;
`

const PreviewLink = styled(Link)`
    animation: ${fadeIn} 0.5s ease;
    color: ${props => props.theme.primary};
    z-index: 99;
    text-decoration: none;
    display: flex;
    margin-right: 0.5rem;
`

const SaveStatusContainer = styled.div`
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    position: relative;
    z-index: 1;
`

function makeInputName(name) {
    return btoa(name)
}

function makeCustomStyles({
    showSaveStatus,
    comment,
    readOnly,
    disabled,
    theme,
}) {
    const customStyles = {
        indicatorsContainer: () => {
            return { display: 'none' }
        },
        control: provided => {
            const styles = {
                ...provided,
                borderWidth: 0,
                boxShadow: 'none',
                padding: 0,
                minHeight: 'initial',
                cursor: 'text',
            }

            if (readOnly || disabled) {
                styles.backgroundColor = 'transparent'
                styles.borderColor = 'transparent'
            }

            return styles
        },
        valueContainer: provided => {
            return {
                ...provided,
                padding: '0 1rem',
                marginTop: '0.5rem',
                height: '20px',
            }
        },
        singleValue: provided => {
            const styles = {
                ...provided,
                maxWidth: 'calc(100% - 3rem)',
            }

            if (readOnly || disabled) {
                styles.color = theme.dark
            }
            return styles
        },
        menu: provided => {
            const styles = {
                ...provided,
                zIndex: 2,
                borderRadius: '5px',
                boxShadow: '0 0 1px 2px var(--gray2)',
                border: 0,
                top:
                    showSaveStatus || comment
                        ? 'calc(100% + 1.5rem)'
                        : 'calc(100% + .5rem)',
            }

            return styles
        },
        placeholder: provided => {
            return {
                ...provided,
                margin: 0,
                color: 'var(--gray2)',
                fontStyle: 'italic',
            }
        },
        option: (provided, state) => {
            const { isSelected, isFocused } = state

            return {
                ...provided,
                backgroundColor: isSelected
                    ? 'var(--primary)'
                    : isFocused
                    ? 'var(--gray)'
                    : 'transparent',
                color: isSelected ? 'white' : 'var(--dark)',
            }
        },
        multiValue: provided => {
            return { ...provided, margin: '0 2px 0 0' }
        },
    }

    return customStyles
}

function getSingleOptionValue(option) {
    if (isObject(option)) return option.value || option._id
    return option
}

function callOnValueChange(option, onValueChange) {
    if (!isFunction(onValueChange)) return

    if (empty(option)) return onValueChange(option)

    if (isArray(option)) {
        onValueChange(option.map(getSingleOptionValue))
    } else onValueChange(getSingleOptionValue(option))
}

function isOptionSelected({ option, value }) {
    if (empty(value) || empty(option)) return false

    if (isArray(value)) {
        return value.find(value => {
            return getSingleOptionValue(option) === getSingleOptionValue(value)
        })
    }

    return getSingleOptionValue(option) === getSingleOptionValue(value)
}

const emptyArray = []

const noop = () => {}

export default memo(
    ({
        async,
        label,
        previewBaseLink,
        error: componentError,
        placeholder = null,
        name,
        disabled,
        options: theOptions = emptyArray,
        value,
        onChange = noop,
        onValueChange = noop,
        isMulti,
        loadOptions,
        isDirty,
        showSaveStatus = null,
        onDiscard,
        className,
        comment = null,
        showAddLink,
        readOnly,
        representScalarValue,
        ...props
    }) => {
        const [focus, setFocus] = useState(false)

        const inputName = makeInputName(name)

        const error = componentError

        const SelectComponent = async ? AsyncSelect : Select

        const [selectedOption, setSelectedOption] = useState(value)

        const options = theOptions || emptyArray

        const theme = useTheme()

        const customStyles = useMemo(
            () =>
                makeCustomStyles({
                    showSaveStatus,
                    comment,
                    readOnly,
                    theme,
                    disabled,
                }),
            [showSaveStatus, comment, readOnly, disabled, theme]
        )

        useEffect(() => {
            const findOption = options => {
                if (empty(value)) return null

                if (!isMulti)
                    return (
                        options.find(o => getSingleOptionValue(o) === value) ||
                        null
                    )
                else {
                    return options.filter(
                        o => value.indexOf(getSingleOptionValue(o)) > -1
                    )
                }
            }

            if (async) {
                loadOptions().then(options => {
                    const selected = findOption(options)

                    setSelectedOption(selected)
                })
            } else {
                setSelectedOption(findOption(options))
            }
        }, [value, async, options, loadOptions, isMulti])

        return (
            <Container
                disabled={disabled}
                focus={focus}
                error={error ? 1 : 0}
                className={className}
            >
                <Label htmlFor={inputName}>{label}</Label>

                {error && <Error>{error.message}</Error>}

                <SelectComponent
                    key="name"
                    search
                    styles={customStyles}
                    options={options}
                    value={selectedOption}
                    isDisabled={disabled || readOnly}
                    placeholder={
                        placeholder
                            ? isFunction(placeholder)
                                ? placeholder({ options })
                                : placeholder
                            : 'Please select'
                    }
                    isMulti={isMulti}
                    makeItLarge="true"
                    onMenuOpen={() => {
                        setFocus(true)
                    }}
                    onMenuClose={() => {
                        setFocus(false)
                    }}
                    onChange={option => {
                        if (representScalarValue) {
                            callOnValueChange(option, onChange)
                        } else {
                            callOnValueChange(option, onValueChange)
                            onChange(option)
                        }
                    }}
                    getOptionValue={option => {
                        return getSingleOptionValue(option)
                    }}
                    getOptionLabel={option => {
                        return option.label || option.name
                    }}
                    isOptionSelected={option =>
                        isOptionSelected({
                            option,
                            value,
                        })
                    }
                    loadOptions={loadOptions}
                    {...props}
                />
                <IconRow>
                    {previewBaseLink && !empty(value) && (
                        <PreviewLink
                            to={previewBaseLink + value}
                            target="_blank"
                        >
                            <IoMdEye />
                        </PreviewLink>
                    )}
                    <Icon focus={focus} readOnly={readOnly}>
                        <MdKeyboardArrowDown />
                    </Icon>
                </IconRow>

                <SaveStatusContainer>
                    {comment && (
                        <InputComment>
                            {isFunction(comment)
                                ? comment({ options })
                                : comment}
                        </InputComment>
                    )}
                    <SaveStatus
                        isDirty={isDirty}
                        value={value}
                        showSaveStatus={showSaveStatus}
                        onDiscard={onDiscard}
                    />
                    {showAddLink && (
                        <AddNewLink
                            href={previewBaseLink + 'create'}
                            target="_blank"
                        >
                            Add new record
                        </AddNewLink>
                    )}
                </SaveStatusContainer>
            </Container>
        )
    }
)
