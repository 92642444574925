import { empty } from 'crm-components/data-helpers'
import { useCallback, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { excludeFields } from '../data-processors'
import {
    useData,
    useExcludeFromInput,
    useFormName,
    useStaticFormFields,
} from './fields'

const LOCAL_STORAGE_DATA_KEY = 'convertProCrm:formData'

function useFormID() {
    const { url } = useRouteMatch()

    const [name] = useFormName()

    const hash = !empty(name) ? `#${name}` : ''

    return url + hash
}

function loadLocalStorageState() {
    const loadedData = localStorage[LOCAL_STORAGE_DATA_KEY] || '{}'

    const savedState = JSON.parse(loadedData)

    return savedState
}

export function useLocalStorage() {
    const localStorageState = loadLocalStorageState()

    const id = useFormID()

    const [data] = useData()

    const [excludeFromInput] = useExcludeFromInput()

    const [staticFormFields] = useStaticFormFields()

    const excludedFields = useMemo(
        () => [...excludeFromInput, ...staticFormFields],
        [excludeFromInput, staticFormFields]
    )

    const save = useCallback(() => {
        const currentData = loadLocalStorageState()

        currentData[id] = excludeFields({
            data,
            excludeFromInput: excludedFields,
        })

        localStorage[LOCAL_STORAGE_DATA_KEY] = JSON.stringify(currentData)

        console.log('saving', { formId: id })
    }, [data, excludedFields, id])

    return [localStorageState[id], save]
}

export function useClearLocalStorage() {
    const id = useFormID()

    const clear = useCallback(() => {
        const state = loadLocalStorageState()

        delete state[id]

        localStorage[LOCAL_STORAGE_DATA_KEY] = JSON.stringify(state)

        console.log('clearing', { formId: id })
    }, [id])

    return clear
}
