import React from 'react'

import List from './list'

import Form from './form'

import CRUDRouter from 'crm-components/crud-router'

import { MenuItem } from 'system/layout/sidebar'

import { GiOrganigram } from 'react-icons/gi'

export default () => {
    return (
        <>
            <MenuItem
                to="/company"
                render="Companies"
                icon={<GiOrganigram />}
                sortOrder={50}
                permission={{
                    record: 'Company',
                    method: 'READ',
                    accessModifier: 'ALL',
                }}
            />
            <CRUDRouter
                path="/company"
                list={List}
                form={Form}
                accessModifier="ALL"
                record="Company"
            />
        </>
    )
}
