import { useQuery } from 'system/network/graphql'

import { getDataNodes } from 'crm-components/data-helpers'

const emptyArray = []

export const useFetchStatuses = ({ resolveType, search }) => {
    const resolveTypeLower = resolveType.toLowerCase()

    const { cacheValue } = useQuery({
        operation: {
            query: /* GraphQL */ `
                query Get${resolveType}Statuses(
                    $search: ${resolveType}StatusSearch
                    $pagination: PaginationInput
                ) {
                    ${resolveTypeLower}Statuses(search: $search, pagination: $pagination) {
                        nodes {
                            ... on ${resolveType}Status {
                                _id
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                search,
                pagination: {
                    pageNumber: 1,
                },
            },
        },
    })

    const nodes = getDataNodes(cacheValue) || emptyArray

    return nodes
}
