import { DocumentsUploaderContext } from './provider'

import useContextField from 'helpers/use-context-field'

import useDefaultBindPropContextValue from 'helpers/use-bind-prop-context-value'

export const useBindPropContextValue = data => {
    useDefaultBindPropContextValue(data, DocumentsUploaderContext)
}

export const useRemoteFiles = () => {
    return useContextField('remoteFiles', DocumentsUploaderContext)
}

export const useFilteredFiles = () => {
    return useContextField('filteredFiles', DocumentsUploaderContext)
}

export const useGetLoading = () => {
    return useContextField('getLoading', DocumentsUploaderContext)
}

export const useSaveLoading = () => {
    return useContextField('saveLoading', DocumentsUploaderContext)
}

export const useDisabled = () => {
    return useContextField('disabled', DocumentsUploaderContext)
}

export const useParentLoading = () => {
    return useContextField('parentLoading', DocumentsUploaderContext)
}
