import React from 'react'

import styled, { keyframes } from 'styled-components'

import { FiLoader } from 'react-icons/fi'

const spin = (transform = '') => keyframes`
    from {
        transform: rotate(0) ${transform};
    }

    to {
        transform: rotate(360deg) ${transform};
    }
`

export default styled(({ slow, transform, ...props }) => (
    <FiLoader {...props} />
))`
    animation: ${props => spin(props.transform)} linear
        ${props => (props.slow ? '2s' : '1s')} infinite both;
`
