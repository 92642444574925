import useContextField from 'helpers/use-context-field'

import { FormContext } from '../provider'

export const useMutationLoading = () =>
    useContextField('mutationLoading', FormContext)

export const useQueryLoading = () =>
    useContextField('queryLoading', FormContext)

export const useGraphQLQueryString = () =>
    useContextField('graphQLQueryString', FormContext)

export const useGraphQLMutationString = () =>
    useContextField('graphQLMutationString', FormContext)

export const usePageTitle = () => useContextField('pageTitle', FormContext)

export const useCloseAfterSave = () =>
    useContextField('closeAfterSave', FormContext)

export const useAutoRedirectToUpdateAfterCreate = () =>
    useContextField('autoRedirectToUpdateAfterCreate', FormContext)

export const useMutationCacheValue = () =>
    useContextField('mutationCacheValue', FormContext)

export const useQueryCacheValue = () =>
    useContextField('queryCacheValue', FormContext)

export const useMutationLoad = () =>
    useContextField('mutationLoad', FormContext)

export const useFormId = () => useContextField('formId', FormContext)

export const useIsSuccess = () => useContextField('isSuccess', FormContext)

export const useMutationVariableName = () =>
    useContextField('mutationVariableName', FormContext)

export const useExcludeFromInput = () =>
    useContextField('excludeFromInput', FormContext)

export const useFormData = () => useContextField('data', FormContext)

export const useRedirectTo = () => useContextField('redirectTo', FormContext)

export const useNoQueryVariables = () =>
    useContextField('useNoQueryVariables', FormContext)

export const useDirty = () => useContextField('dirty', FormContext)

export const useRenderMenu = () => useContextField('renderMenu', FormContext)

export const useDeleteQuery = () => useContextField('deleteQuery', FormContext)

export const useResolveType = () => useContextField('resolveType', FormContext)

export const useShowSaveStatus = () =>
    useContextField('showSaveStatus', FormContext)

export const useValidationErrors = () =>
    useContextField('validationErrors', FormContext)

export const useOnFetchListeners = () =>
    useContextField('onFetchListeners', FormContext, { defaultValue: [] })

export const useMutationCacheKey = () =>
    useContextField('mutationCacheKey', FormContext)
