import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_LISTING_PLATFORMS = /* GraphQL */ `
    query GetListingPlatforms(
        $search: ListingPlatformSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        listingPlatforms(
            search: $search
            pagination: $pagination
            sort: $sort
        ) {
            totalPages
            count
            nodes {
                ... on ListingPlatform {
                    _id
                    name
                    slug
                    createdAt
                }
            }
        }
    }
`

const DELETE_LISTING_PLATFORMS = /* GraphQL */ `
    mutation DeleteListingPlatforms($ids: [ID!]!) {
        deleteListingPlatforms(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Slug',
                key: 'slug',
                sortKey: 'slug',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_LISTING_PLATFORMS}
            deleteQuery={DELETE_LISTING_PLATFORMS}
            pageTitle={'Listing platforms'}
            entity="listingPlatform"
            cols={cols}
            record="ListingPlatform"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
