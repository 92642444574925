import useBindPropContextValue from 'helpers/use-bind-prop-context-value'
import { SectionContext } from './provider'

export default ({
    title,
    subTitle,
    icon,
    paddingBottom,
    hiddenOverflow,
    expanded,
}) => {
    useBindPropContextValue(
        {
            title,
            subTitle,
            paddingBottom,
            hiddenOverflow,
            expanded,
            icon,
        },
        SectionContext
    )
    return null
}
