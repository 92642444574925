import React, { useCallback, useState } from 'react'

import { useParams } from 'react-router-dom'

import Form from 'crm-components/form'

import { useCan } from 'system/account/acl'

import Tabs from 'ui-components/tabs'

import useBindPropContextValue from 'helpers/use-bind-prop-context-value'

import { FormContext } from './provider'

import useBindContextCallback from 'helpers/use-bind-context-callback'

import Meta from './meta'

import Footer from './footer'

import Header from './header'

import { FORM_PAGE_MODE_MODAL, FORM_PAGE_MODE_PAGE } from './constants'
import {
    useBindOnSuccess,
    useFormMutation,
    useFormQuery,
    useOnSave,
} from './hooks/logic'
import { useMutationLoading, useQueryLoading } from './hooks/fields'

const noopObject = {}

const noopArray = []

const noopFunction = () => null

export default ({
    deleteQuery,
    query: graphQLQueryString,
    mutation: graphQLMutationString,
    mutationVariableName,
    children,
    record,
    redirectTo = null,
    pageTitle,
    noQueryVariables,
    isSuccess = noopObject,
    autoRedirectToUpdateAfterCreate = true,
    excludeFromInput = noopArray,
    isKeyDirty,
    renderMenu = noopFunction,
    showSaveStatus = true,
    mode = FORM_PAGE_MODE_PAGE,
    id: propId,
}) => {
    const { id: routeId } = useParams()

    const formId =
        mode === FORM_PAGE_MODE_PAGE
            ? routeId
            : mode === FORM_PAGE_MODE_MODAL
            ? propId
            : routeId

    const [activeTabIndex, setActiveTabIndex] = useState()

    const canWriteRecord = useCan({ record, method: 'WRITE' })

    const save = useOnSave()

    useBindPropContextValue(
        {
            mode,
            formId,
            propId,
            deleteQuery,
            record,
            showSaveStatus,
            isSuccess,
            noQueryVariables,
            redirectTo,
            graphQLQueryString,
            graphQLMutationString,
            renderMenu,
            pageTitle,
            mutationVariableName,
            autoRedirectToUpdateAfterCreate,
            excludeFromInput,
            resolveType: record,
        },
        FormContext
    )

    const onValidationErrorsChange = useBindContextCallback(
        'validationErrors',
        FormContext
    )

    useBindOnSuccess()

    const onDataChange = useBindContextCallback('data', FormContext)

    const onDirtyChange = useBindContextCallback('dirty', FormContext)

    const [mutationLoading] = useMutationLoading()

    const [queryLoading] = useQueryLoading()

    const isLoading = mutationLoading

    const isDisabled = queryLoading || mutationLoading || !canWriteRecord

    const onActiveTabIndexChange = useCallback(index => {
        setActiveTabIndex(index)
    }, [])

    const { cacheValue: mutationCacheValue } = useFormMutation()

    const { cacheValue: queryCacheValue } = useFormQuery()

    return (
        <>
            <Meta />
            <Header />
            <Form
                submit={save}
                loading={isLoading}
                disabled={isDisabled}
                mutationCacheValue={mutationCacheValue}
                queryCacheValue={queryCacheValue}
                onDataChange={onDataChange}
                saveChangesToLocalStorage
                onDirtyChange={onDirtyChange}
                saveOnEnter={activeTabIndex === 0}
                excludeFromInput={excludeFromInput}
                noRemoteData={!formId && autoRedirectToUpdateAfterCreate}
                isKeyDirty={isKeyDirty}
                onValidationErrorsChange={onValidationErrorsChange}
            >
                <Tabs onActiveTabIndexChange={onActiveTabIndexChange}>
                    {children}
                </Tabs>
                <Footer />
            </Form>
        </>
    )
}
