import React from 'react'

import Section from 'ui-components/section'

import { FormField, FormFields } from 'crm-components/crud-form'

import CountrySelect from 'crm-components/country-select'

export default () => {
    return (
        <Section title="Contact details">
            <FormFields>
                <FormField name="name" label="Name" />
                <FormField name="email" label="Email" />
                <FormField name="mobile" label="Mobile" />
                <FormField name="phone" label="Phone" />
                <FormField name="officialDocumentNumber" label="ID number" />
                <FormField
                    name="officialDocumentExpiryDate"
                    label="ID expiry date"
                    type="datepicker"
                />
                <FormField name="address" label="Address" />
                <FormField
                    name="countryId"
                    label="Country"
                    component={CountrySelect}
                />
                <FormField name="city" label="City" />
            </FormFields>
        </Section>
    )
}
