import React, { useCallback } from 'react'

import { useQuery } from 'system/network/graphql'

import Select from 'ui-components/select'

import { get, isEmpty } from 'lodash-es'

import styled from 'styled-components'

import { getDataObject, sleep, empty, escapeRegexPattern } from './data-helpers'

const MAXIMUM_RENDERED_OPTIONS = 10

const LOCATION_KEYS = ['property', 'subCommunity', 'community', 'city']

const Value = styled.span`
    display: inline;
    color: ${props => (props.isLast ? 'inherite' : props.theme.gray2)};
    margin-right: 0.5rem;
    font-size: ${props => (props.isLast ? 1 : 0.8)}rem;

    &:nth-child(1) {
        display: none;
    }

    @media ${props => props.theme.media.sm} {
        &:nth-child(1) {
            display: inline;
        }
    }
`

const GET_LOCATIONS = /* GraphQL */ `
    {
        propertyLocations(pagination: { pageSize: 5000, pageNumber: 1 }) {
            nodes {
                ... on PropertyLocation {
                    _id
                    city
                    community
                    subCommunity
                    property
                }
            }
        }
    }
`

function getHigherNotEmptyValue({ locationOption }) {
    const lastNotEmptyKeyValue = LOCATION_KEYS.reduce(
        (notEmptyKeyValue, key) =>
            !empty(locationOption[key]) && empty(notEmptyKeyValue)
                ? { key, value: locationOption[key] }
                : notEmptyKeyValue,
        null
    )

    let higherNotEmptyKey = LOCATION_KEYS.indexOf(lastNotEmptyKeyValue.key) + 1

    higherNotEmptyKey =
        higherNotEmptyKey >= LOCATION_KEYS.length
            ? LOCATION_KEYS.length - 1
            : higherNotEmptyKey

    const higherValue = locationOption[LOCATION_KEYS[higherNotEmptyKey]]

    return higherValue
}

function getFilteredOptions({ allLocations, search: defaultSearch, value }) {
    let locations = allLocations

    const keys = LOCATION_KEYS

    const currentOption = value
        ? allLocations.find(location => location._id === value)
        : null

    const search = defaultSearch
        ? defaultSearch
        : currentOption
        ? getHigherNotEmptyValue({ locationOption: currentOption })
        : ''

    locations = locations.map(l => {
        let label = ''

        for (const key of keys) {
            if (!isEmpty(l[key])) {
                label = l[key]
                break
            }
        }

        return {
            ...l,
            label: label,
        }
    })

    locations = locations
        .filter(location => {
            let match = false

            for (const key of keys) {
                if (
                    location[key].match(
                        new RegExp(escapeRegexPattern(search), 'i')
                    )
                ) {
                    match = true
                }
            }
            return match
        })
        .slice(0, MAXIMUM_RENDERED_OPTIONS)

    if (
        currentOption &&
        !locations.find(location => location._id === currentOption._id)
    ) {
        locations.push(currentOption)
    }

    return locations
}

export default ({ disabled, value, ...props }) => {
    const { cacheValue, loading } = useQuery({
        operation: {
            query: GET_LOCATIONS,
        },
        loadOnReload: false,
    })

    const allLocations = get(getDataObject(cacheValue), 'nodes') || []

    const filterOptions = async search => {
        await sleep(300)

        const locations = getFilteredOptions({ search, allLocations, value })

        return locations
    }

    const formatOptionLabel = useCallback(option => {
        const keys = ['city', 'community', 'subCommunity', 'property']

        const availableKeys = keys.filter(key => option[key])

        return (
            <>
                {availableKeys.map((key, i) => {
                    return (
                        <Value
                            key={key}
                            isLast={i === availableKeys.length - 1}
                        >
                            {option[key]}
                        </Value>
                    )
                })}
            </>
        )
    }, [])

    return (
        <Select
            {...props}
            value={value}
            async={allLocations.length}
            label="Location"
            placeholder="Search locations..."
            loadOptions={filterOptions}
            cacheOptions
            defaultOptions
            noOptionsMessage={({ inputValue: search }) => {
                if (search.length) {
                    return 'No locations could be found'
                }

                return 'Start typing...'
            }}
            disabled={loading || disabled}
            formatOptionLabel={formatOptionLabel}
        />
    )
}
