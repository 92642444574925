import React, { useMemo } from 'react'

import CRUDForm from 'crm-components/crud-form'

import { Tab } from 'crm-components/crud-form'

import OfferingSection from './offering-section'

import SpecificationsSection from './specifications-section'

import PricingSection from './pricing-section'

import DescriptionSection from './description-section'

import AmenitiesSection from './amenities-section'

import ManagmentSection from './management-section'

import ImageGallery from './image-gallery'

import LocationSection from './location-section'

import AddressSection from './address-section'

import PublishingSection from './publishing-section'

import ActivitiesSection from './activities-section'

import DocumentsTab from 'crm-components/documents-tab'

import { useParams } from 'react-router-dom'

import FormActions from './form-actions'

import MetaSection from 'crm-components/meta-section'

const PROPERTY_FRAGMENT = /* GraphQL */ `
    fragment propertyFields on Property {
        _id
        categoryId
        propertyOfferingId
        propertyTypeId
        size
        numberOfBedroomsId
        numberOfBathroomsId
        numberOfParkingSpaces
        furnitureTypeId
        propertyDeveloper
        tenureTypeId
        buildYear
        plotSize
        plotNumber
        builtUpArea
        buildingFloors
        occupancyId
        projectStatusId
        renovationTypeId
        layoutType
        dewaNumber
        views
        price
        serviceCharges
        financialStatusId
        numberOfChequesId
        amenityIds
        ownerId
        referenceCode
        permitNumber
        imageIds
        geoLocation {
            lat
            lng
        }
        locationId
        projectName
        streetAddress
        streetNumber
        unitNumber
        notes
        publishFrom
        publishTo
        listingPlatformIds
        activityIds
        documentIds
        statusId
        marketingRecords {
            languageId
            name
            content
            marketingTemplateId
        }
        createdAt
        updatedAt
        createdBy {
            _id
            profile {
                firstName
                lastName
            }
        }
        assignedTo {
            _id
            profile {
                firstName
                lastName
            }
        }
    }
`

const SAVE_PROPERTY = /* GraphQL */ `
    mutation SaveProperty($property: PropertyInput) {
        saveProperty(property: $property) {
            ...propertyFields
        }
    }
    ${PROPERTY_FRAGMENT}
`

const GET_PROPERTY = /* GraphQL */ `
    query GetProperty($id: ID!) {
        property(id: $id) {
            ...propertyFields
        }
    }
    ${PROPERTY_FRAGMENT}
`

export default () => {
    const { id } = useParams()

    const excludeFromInput = useMemo(() => ['referenceCode'], [])

    return (
        <CRUDForm
            query={GET_PROPERTY}
            mutation={SAVE_PROPERTY}
            mutationVariableName="property"
            record="Property"
            excludeFromInput={excludeFromInput}
            renderMenu={FormActions}
        >
            <Tab title="Details">
                <OfferingSection />
                <SpecificationsSection />
                <PricingSection />
                <DescriptionSection />
                <AmenitiesSection />
                <ManagmentSection />
                <MetaSection />
            </Tab>
            <Tab title="Media">
                <ImageGallery />
            </Tab>
            <Tab title="Location">
                <LocationSection />
                <AddressSection />
            </Tab>
            <Tab title="Publishing">
                <PublishingSection />
            </Tab>
            <Tab title="Activity">
                <ActivitiesSection id={id} />
            </Tab>

            <DocumentsTab parentResolveType="Property" />
        </CRUDForm>
    )
}
