import React from 'react'

import ModalProvider from 'system/ui/modal'

import SectionsProvider from 'system/ui/sections'

export default ({ children }) => {
    return (
        <SectionsProvider>
            <ModalProvider>{children}</ModalProvider>
        </SectionsProvider>
    )
}
