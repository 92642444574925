import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

    .react-calendar__month-view__days__day--weekend {
        color: ${props => props.theme.danger};
    }

    .react-calendar__tile--now {
        color: ${props => props.theme.light};
        background-color: ${props => props.theme.secondary};
    }

    .react-calendar__tile--now:enabled:focus,
    .react-calendar__tile--now:enabled:hover {
        color: ${props => props.theme.light};
        background-color: ${props => props.theme.primary};
    }

    .react-calendar__tile--active {
        color: ${props => props.theme.light};
        background-color: ${props => props.theme.primary};
    }

    .react-calendar__tile--active:enabled:hover, 
    .react-calendar__tile--active:enabled:focus {
        color: ${props => props.theme.light};
        background-color: ${props => props.theme.secondary};
    }

    .react-date-picker__calendar {
        z-index: 30;
    }
`
