import React, { useEffect, useMemo } from 'react'

import Slider from './property-image-slider'

import { Col } from 'react-styled-flexboxgrid'

import QuickView from 'ui-components/quick-view'

import { useQuery } from 'system/network/graphql'

import {
    usePropertyImages,
    getPropertyIgnoredKeyNamesForFieldLabelValueMaker,
} from './hooks'

import { getDataObject, empty } from 'crm-components/data-helpers'

import { useRowFields } from 'crm-components/label-value-maker'

const PROPERTY_FRAGMENT = /* GraphQL */ `
    fragment propertyFields on Property {
        category {
            name
        }
        propertyOffering {
            name
        }
        propertyType {
            name
        }
        size
        numberOfBedrooms {
            name
        }
        numberOfBathrooms {
            name
        }
        numberOfParkingSpaces
        furnitureType {
            name
        }
        propertyDeveloper
        tenureType {
            name
        }
        buildYear
        plotSize
        plotNumber
        builtUpArea
        buildingFloors
        occupancy {
            name
        }
        projectStatus {
            name
        }
        renovationType {
            name
        }
        layoutType
        dewaNumber
        views
        price
        serviceCharges
        financialStatus {
            name
        }
        numberOfCheques {
            name
        }
        owner {
            name
        }
        referenceCode
        permitNumber
        images {
            url
            waterMarkedUrl
            resizedUrl
        }
        location {
            city
            community
            subCommunity
            property
            country
        }
        projectName
        streetAddress
        streetNumber
        unitNumber
        publishFrom
        publishTo
        listingPlatforms {
            name
        }
        amenities {
            name
        }
    }
`

const GET_PROPERTY = /* GraphQL */ `
    query GetProperty($id: ID!) {
        property(id: $id) {
            ...propertyFields
        }
    }
    ${PROPERTY_FRAGMENT}
`

const PropertySlider = ({ images }) => {
    return (
        <Col xs={12} sm={6} md={4} lg={3}>
            <Slider images={images} />
        </Col>
    )
}

const FieldsWrapper = ({ children }) => {
    return (
        <Col xs={12} sm={6} md={8} lg={9}>
            {children}
        </Col>
    )
}

export default ({ row: property, close }) => {
    const { load, cacheValue, loading } = useQuery({
        loadOnMount: false,
        operation: {
            query: GET_PROPERTY,
            variables: {
                id: property._id,
            },
        },
    })

    const propertyDetails = useMemo(() => getDataObject(cacheValue), [
        cacheValue,
    ])

    useEffect(() => {
        if (empty(propertyDetails)) load()
    }, [property._id, load, propertyDetails])

    const images = usePropertyImages({ property: propertyDetails })

    const fields = useRowFields({
        row: propertyDetails,
        ignoredKeyNames: getPropertyIgnoredKeyNamesForFieldLabelValueMaker(),
    })

    const beforeFieldsProps = useMemo(() => ({ images }), [images])

    return (
        <QuickView
            fields={fields}
            beforeFieldsRender={PropertySlider}
            beforeFieldsProps={beforeFieldsProps}
            loading={loading}
            loaderRows={37}
            close={close}
            fieldsWrapper={FieldsWrapper}
        />
    )
}
