import { getDataObject } from 'crm-components/data-helpers'
import { useMemo } from 'react'
import { useMutationCacheValue, useQueryCacheValue } from './fields'

export default () => {
    const [mutationCacheValue] = useMutationCacheValue()

    const [queryCacheValue] = useQueryCacheValue()

    const remoteData = useMemo(() => {
        const mutationData = getDataObject(mutationCacheValue)

        const queryData = getDataObject(queryCacheValue)

        return mutationData || queryData
    }, [mutationCacheValue, queryCacheValue])

    return [remoteData]
}
