import React from 'react'

import styled from 'styled-components'

import { Row, Col } from 'react-styled-flexboxgrid'

import ModalForm from 'crm-components/modal-form'

const Box = styled.div`
    background-color: ${props => props.theme.gray};
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;
`

const Label = styled.h3`
    color: ${props => props.theme.gray2};
    font-size: 1rem;
    display: block;
    margin: 0;
    margin-bottom: 0.5rem;
`

const Value = styled.div`
    color: ${props => props.theme.primary};
    font-size: 2rem;
`

export default () => {
    return (
        <Row>
            <ModalForm open={true} />
            <Col xs={12} sm={6} md={4}>
                <Box>
                    <Label>Amount</Label>
                    <Value>10000000</Value>
                </Box>
            </Col>
            <Col xs={12} sm={6} md={4}>
                <Box>
                    <Label>Type</Label>
                    <Value>Sell</Value>
                </Box>
            </Col>
            <Col xs={12} sm={6} md={4}>
                <Box>
                    <Label>Status</Label>
                    <Value>Open</Value>
                </Box>
            </Col>
        </Row>
    )
}
