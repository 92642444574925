import React from 'react'

import styled from 'styled-components'

import { MdDelete, MdClose } from 'react-icons/md'

const Icon = styled.div`
    display: flex;
    margin-right: 0.5rem;
`

const FiltersActionsRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const FilterAction = styled.div`
    text-decoration: underline;
    display: flex;
    color: ${props => props.theme.gray2};
    user-select: none;
    cursor: pointer;
    margin-bottom: 1rem;
    font-size: 0.8rem;
`

export const ClearSearch = ({ onClick }) => {
    return (
        <FilterAction onClick={onClick}>
            <Icon>
                <MdDelete />
            </Icon>
            Clear search
        </FilterAction>
    )
}

export default ({ onClear, onClose }) => {
    return (
        <FiltersActionsRow>
            <ClearSearch onClick={onClear} />
            <FilterAction onClick={onClose}>
                <Icon>
                    <MdClose />
                </Icon>
                Close search
            </FilterAction>
        </FiltersActionsRow>
    )
}
