import React, { useCallback } from 'react'

import Form, { FormField, FormFields, Tab } from 'crm-components/crud-form'

import Section from 'ui-components/section'

import { get } from 'lodash-es'

import { empty, getDataObject } from 'crm-components/data-helpers'

const SETTINGS_FRAGMENT = /* GraphQL */ `
    fragment settingsFragment on Settings {
        _id
        ROLES_AVAILABLE_TO_COMPANY_ADMIN
        NEW_USER_ROLE_ID
        COMPANY_DEMO_DAYS
        INITIAL_PROPERTY_STATUS_ID
        PROPERTY_STATUSES_AVAILABLE_TO_LISTING
        NEW_LEAD_STATUS_ID
        NEW_TENANT_STATUS_ID
        NEW_OWNER_STATUS_ID
    }
`
const GET_SETTINGS = /* GraphQL */ `
    {
        settings {
            ...settingsFragment
        }
    }
    ${SETTINGS_FRAGMENT}
`

const SAVE_SETTINGS = /* GraphQL */ `
    mutation SaveSettings($settings: SettingsInput) {
        saveSettings(settings: $settings) {
            ...settingsFragment
        }
    }
    ${SETTINGS_FRAGMENT}
`

export default () => {
    const isSuccess = useCallback(
        mutationCacheValue =>
            !empty(get(getDataObject(mutationCacheValue), 'NEW_USER_ROLE_ID')),
        []
    )
    return (
        <Form
            redirectTo="/dashboard"
            query={GET_SETTINGS}
            mutation={SAVE_SETTINGS}
            mutationVariableName="settings"
            pageTitle="Settings"
            noQueryVariables
            isSuccess={isSuccess}
            autoRedirectToUpdateAfterCreate={false}
            record="Settings"
            showSaveStatus={false}
        >
            <Tab title="System settings">
                <Section title="Roles">
                    <FormFields>
                        <FormField
                            isMulti
                            name="ROLES_AVAILABLE_TO_COMPANY_ADMIN"
                            type="collectionSelect"
                            queryName="roles"
                            recordName="Role"
                            label="Roles available to company admin"
                        />
                        <FormField
                            name="NEW_USER_ROLE_ID"
                            type="collectionSelect"
                            queryName="roles"
                            recordName="Role"
                            label="New user role"
                        />
                    </FormFields>
                </Section>
                <Section title="Licensing">
                    <FormFields>
                        <FormField
                            name="COMPANY_DEMO_DAYS"
                            type="number"
                            label="Demo period"
                        />
                    </FormFields>
                </Section>
                <Section title="Property">
                    <FormFields>
                        <FormField
                            name="INITIAL_PROPERTY_STATUS_ID"
                            label="Initial Property Status"
                            type="collectionSelect"
                            recordName="PropertyStatus"
                            queryName="propertyStatuses"
                        />
                        <FormField
                            isMulti
                            name="PROPERTY_STATUSES_AVAILABLE_TO_LISTING"
                            label="Property statuses available to listing"
                            type="collectionSelect"
                            recordName="PropertyStatus"
                            queryName="propertyStatuses"
                        />
                    </FormFields>
                </Section>

                <Section title="Lead">
                    <FormFields>
                        <FormField
                            name="NEW_LEAD_STATUS_ID"
                            label="New lead status"
                            type="collectionSelect"
                            recordName="LeadStatus"
                            queryName="leadStatuses"
                        />
                    </FormFields>
                </Section>
                <Section title="Tenant">
                    <FormFields>
                        <FormField
                            name="NEW_TENANT_STATUS_ID"
                            label="New tenant status"
                            type="collectionSelect"
                            recordName="TenantStatus"
                            queryName="tenantStatuses"
                        />
                    </FormFields>
                </Section>
                <Section title="Owner">
                    <FormFields>
                        <FormField
                            name="NEW_OWNER_STATUS_ID"
                            label="New owner status"
                            type="collectionSelect"
                            recordName="OwnerStatus"
                            queryName="ownerStatuses"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </Form>
    )
}
