import React, { createContext, useState, useContext, useCallback } from 'react'

import styled from 'styled-components'

const defaultState = {
    message: '',
    visible: false,
}

const Context = createContext(defaultState)

const Container = styled.div`
    height: ${props => props.theme.headerHeight};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    position: relative;
    z-index: 100;
    background-color: ${props => props.theme.warning};
    color: white;
    user-select: none;
`

export const useAlert = () => {
    const [state, set] = useContext(Context)

    const show = useCallback(
        ({ message }) => {
            set({ message, visible: true })
        },
        [set]
    )

    const hide = useCallback(() => {
        set(state => {
            return { ...state, visible: false }
        })
    }, [set])

    return { alert: state, show, hide }
}

export const Alert = () => {
    const { alert } = useAlert()

    return alert.visible && <Container>{alert.message}</Container>
}

export default ({ children }) => {
    return (
        <Context.Provider value={useState(defaultState)}>
            {children}
        </Context.Provider>
    )
}
