import React from 'react'

import { Row, Col } from 'react-styled-flexboxgrid'

import styled from 'styled-components'

import logo from 'images/logo.svg'

import BackButton from 'ui-components/back-button'

import { useAccountModuleContext } from './'

import SEO from 'ui-components/seo'

const MainWrapper = styled.div`
    background-color: white;
    min-height: 100vh;
    overflow: hidden;
`

const LogoImage = styled.img`
    height: 15px;
    display: block;
    margin: auto;
`

const Title = styled.h1`
    font-size: 2rem;
    color: ${props => props.theme.dark};
    font-weight: 200;
    margin: 2rem auto;
    text-align: center;
`

const Subtitle = styled.p`
    color: ${props => props.theme.gray2};
    text-align: center;
    font-size: 1.2rem;
    margin: 2rem 0;
    font-weight: 200;
    line-height: 140%;
`

const AccountDetailsWrapper = styled.div`
    position: relative;
    margin: auto;
    padding: 1rem;
    padding-top: ${props => (props.editMode ? '2rem' : '50px')};
    margin-bottom: 2rem;
    max-width: 500px;
    @media ${props => props.theme.media.lg} {
        padding: 0;
        padding-top: ${props => (props.editMode ? '2rem' : '50px')};
    }
`

const LogoRow = styled.div`
    display: flex;
    align-items: center;
`

const FormContainer = ({ children, title, subtitle }) => {
    const [state] = useAccountModuleContext()

    return (
        <AccountDetailsWrapper>
            <LogoRow>
                {state.showBackButton && <BackButton />}
                <LogoImage src={logo} alt="Convert PRO CRM Logo" />
            </LogoRow>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            {children}
        </AccountDetailsWrapper>
    )
}

export default ({ title, subtitle, children, sidebar }) => {
    return (
        <>
            <SEO title={title} />
            <MainWrapper>
                <Row middle={sidebar ? 'md' : null}>
                    {sidebar ? (
                        <>
                            <Col xs={12} md={6} lg={7}>
                                <FormContainer
                                    title={title}
                                    subtitle={subtitle}
                                >
                                    {children}
                                </FormContainer>
                            </Col>
                            <Col xs={12} md={6} lg={5}>
                                {sidebar}
                            </Col>
                        </>
                    ) : (
                        <Col xs={12} sm={8} smOffset={2}>
                            <FormContainer title={title} subtitle={subtitle}>
                                {children}
                            </FormContainer>
                        </Col>
                    )}
                </Row>
            </MainWrapper>
        </>
    )
}
