import { useEffect } from 'react'

import { useClearLocalStorage, useLocalStorage } from './local-storage'

import { useData, useMutationCacheValue } from './fields'
import { get, isEmpty, isEqual } from 'lodash-es'
import { empty, getDataObject } from 'crm-components/data-helpers'
import useRemoteData from './use-remote-data'
import useShouldClear from './use-should-clear'

export default () => {
    const [localStorageState] = useLocalStorage()

    const clearLocalStorage = useClearLocalStorage()

    const [remoteData] = useRemoteData()

    const [data, setData] = useData()

    const localStorageId = get(localStorageState, '_id')

    const remoteId = get(remoteData, '_id')

    const [mutationCacheValue] = useMutationCacheValue()

    const [shouldClear] = useShouldClear()

    useEffect(() => {
        if (localStorageId && !empty(remoteId) && localStorageId !== remoteId) {
            // same url, different ids. Reset local cache. e.g. /user-profile, /company-details
            console.log('clearing local storage')

            clearLocalStorage()

            setData({})
        }
    }, [localStorageId, remoteId, setData, clearLocalStorage])

    useEffect(() => {
        if (shouldClear) {
            console.log('clearing local storage')

            clearLocalStorage()
        }
    }, [shouldClear, clearLocalStorage])
}
