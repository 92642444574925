import React from 'react'

import Section from 'ui-components/section'

import { useParams } from 'react-router-dom'

import CRUDForm from 'crm-components/crud-form'

import PropertySection from './property-section'

import MetaSection from 'crm-components/meta-section'

import ContactSection from 'modules/contact/contact-section'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import DocumentsTab from 'crm-components/documents-tab'

const OWNER_FRAGMENT = /* GraphQL */ `
    fragment ownerFields on Owner {
        _id
        name
        email
        mobile
        phone
        officialDocumentNumber
        officialDocumentExpiryDate
        address
        countryId
        city
        ownerStatusId
        activityIds
        documentIds
        createdAt
        updatedAt
        createdById
        assignedToId
    }
`

const SAVE_OWNER = /* GraphQL */ `
    mutation SaveOwner($owner: OwnerInput) {
        saveOwner(owner: $owner) {
            ...ownerFields
        }
    }
    ${OWNER_FRAGMENT}
`

const GET_OWNER = /* GraphQL */ `
    query GetOwner($id: ID!) {
        owner(id: $id) {
            ...ownerFields
        }
    }
    ${OWNER_FRAGMENT}
`

const DELETE_OWNERS = /* GraphQL */ `
    mutation DeleteOwners($ids: [ID!]!) {
        deleteowners(ids: $ids)
    }
`

export default () => {
    const { id } = useParams()

    return (
        <CRUDForm
            query={GET_OWNER}
            mutation={SAVE_OWNER}
            mutationVariableName="owner"
            record="Owner"
            deleteQuery={DELETE_OWNERS}
        >
            <Tab title="Contact">
                <ContactSection />
            </Tab>

            <Tab title="Property">
                <PropertySection />
            </Tab>

            <Tab title="Status">
                <Section title="Status">
                    <FormFields>
                        <FormField
                            label="Owner status"
                            name="ownerStatusId"
                            type="collectionSelect"
                            recordName="OwnerStatus"
                            queryName="ownerStatuses"
                        />
                    </FormFields>
                </Section>
                <MetaSection />
            </Tab>

            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'owners'}
                            parentId={id}
                            parentRecordName="Owner"
                        />
                    </FormFields>
                </Section>
            </Tab>

            <DocumentsTab parentResolveType="Owner" />
        </CRUDForm>
    )
}
