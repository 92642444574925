import React, { useMemo } from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import CheckBoxOptions from 'crm-components/checkbox-options'

const LEAD_STATUS_FRAGMENT = /* GraphQL */ `
    fragment leadStatusFields on LeadStatus {
        _id
        name
        isHidden
        showPredefinedQuery
        createdAt
    }
`

const SAVE_LEAD_STATUS = /* GraphQL */ `
    mutation SaveLeadStatus($leadStatus: LeadStatusInput) {
        saveLeadStatus(leadStatus: $leadStatus) {
            ...leadStatusFields
        }
    }
    ${LEAD_STATUS_FRAGMENT}
`

const GET_LEAD_STATUS = /* GraphQL */ `
    query GetLeadStatus($id: ID!) {
        leadStatus(id: $id) {
            ...leadStatusFields
        }
    }
    ${LEAD_STATUS_FRAGMENT}
`

export default () => {
    const fields = useMemo(
        () => [
            {
                path: 'isHidden',
                name: 'Is hidden',
            },
            {
                path: 'showPredefinedQuery',
                name: 'Predefined query',
            },
        ],
        []
    )

    return (
        <CRUDForm
            query={GET_LEAD_STATUS}
            mutation={SAVE_LEAD_STATUS}
            mutationVariableName="leadStatus"
            record="LeadStatus"
        >
            <Tab title="Details">
                <Section title="Lead status details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            label="Options"
                            component={CheckBoxOptions}
                            fields={fields}
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
