import byteSize from 'byte-size'

import { isEmpty, get } from 'lodash-es'

function getUrlOfFile({ file, fallBackUrl, variationName }) {
    const base = process.env.REACT_APP_API_HOST

    if (!isEmpty(get(file, variationName))) {
        return base + file[variationName]
    }

    const priorities = ['waterMarkedUrl', 'resizedUrl', 'url']

    const url = priorities.reduce((url, prioritizedVariationName) => {
        if (!isEmpty(url)) {
            return url
        }

        if (!isEmpty(get(file, prioritizedVariationName))) {
            return base + file[prioritizedVariationName]
        }

        return null
    }, null)

    return url
}

export function getFileUrl({ file = null }) {
    return getUrlOfFile({ file, variationName: 'url' })
}

export function getResizedUrl({ file = null }) {
    return getUrlOfFile({ file, variationName: 'resizedUrl' })
}

export function getWaterMarkedUrl({ file = null }) {
    return getUrlOfFile({ file, variationName: 'waterMarkedUrl' })
}

export function formatFileSize({ file }) {
    const size = byteSize(file.size)

    return size.value + ' ' + size.unit
}

export const isFile = input => 'File' in window && input instanceof File
