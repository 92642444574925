/**
 * CRM component that utilizes multi-select-checkbox and FormField, where we have multiple fields with
 * different names, let's say: isInitial and isFinal, the field name is different and the value type
 * is not an array
 */
import React, { Fragment, useMemo, useCallback } from 'react'

import CheckBoxOptions from 'ui-components/checkbox-options'

import useRemoteData from 'crm-components/form/hooks/use-remote-data'

import { isDirty } from 'crm-components/form/data-processors'

import FormField from 'crm-components/form/field'

import SaveStatus from 'ui-components/save-status'

import { useFormData } from './crud-form/hooks/fields'

import { get } from 'lodash-es'

import styled from 'styled-components'

import { Label as DefaultLabel } from 'ui-components/input'

const Label = styled(DefaultLabel)`
    margin-bottom: 0.6rem;
`

const CheckBox = styled(FormField).attrs({ type: 'checkbox' })`
    margin-bottom: 0.5rem;
`

/**
 * @param fields [{ path, name }]
 */

export default ({ fields, label, container: ComponentContainer }) => {
    const Container = ComponentContainer || Fragment

    const [data] = useFormData()

    const remoteData = useRemoteData()

    const dirty = useMemo(
        () =>
            fields.reduce(
                (dirty, field) =>
                    dirty || isDirty({ data, remoteData, key: field.path }),
                false
            ),
        [fields, data, remoteData]
    )

    const value = useMemo(
        () => fields.reduce((value, field) => get(data, field.path) + value, 0),
        [fields, data]
    )

    const renderSaveStatus = useCallback(
        () => <SaveStatus isDirty={dirty} value={value} showSaveStatus />,
        [value, dirty]
    )

    const labelRenderer = useCallback(() => <Label>{label}</Label>, [label])

    return (
        <Container>
            <CheckBoxOptions
                renderLabel={labelRenderer}
                options={fields}
                renderOption={({ option }) => (
                    <CheckBox name={option.path} label={option.name} />
                )}
                renderSaveStatus={renderSaveStatus}
            />
        </Container>
    )
}
