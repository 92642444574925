import React from 'react'

import styled from 'styled-components'

import { empty } from 'crm-components/data-helpers'

import { MdClose } from 'react-icons/md'

import Comment from 'ui-components/input-comment'
import { isFunction } from 'lodash-es'

const Discard = styled(MdClose)`
    margin: 0 0.5rem;
    cursor: pointer;
`

export default ({ isDirty, value, showSaveStatus, onDiscard, className }) => {
    const isUnchanged = empty(value) && !isDirty
    return (
        !!showSaveStatus && (
            <Comment
                className={className}
                icon={
                    isDirty &&
                    !isUnchanged &&
                    isFunction(onDiscard) && <Discard onClick={onDiscard} />
                }
            >
                {isUnchanged ? 'Empty' : isDirty ? 'Not saved' : 'Saved'}
            </Comment>
        )
    )
}
