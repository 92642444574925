import { Link as DefaultLink } from 'react-router-dom'

import styled from 'styled-components'

const Link = styled(DefaultLink)`
    color: ${props => props.theme.blue};
    outline: 0;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

export default Link
