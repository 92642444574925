import React, { createContext, useState } from 'react'

const defaultContext = {
    recordStatus: '',
    queryLoading: false,
    mutationLoading: false,
    record: '',
    deleteQuery: '',
    data: {},
    save: null,
    validationErrors: [],
    showSaveStatus: true,
}

export const FormContext = createContext(defaultContext)

export default ({ children }) => {
    const [state, set] = useState(defaultContext)

    return (
        <FormContext.Provider value={[state, set]}>
            {children}
        </FormContext.Provider>
    )
}
