import React, { useMemo } from 'react'

import List from 'crm-components/list'

import { usePredefinedQueries, usePropertyName } from './hooks'

import ListActions from './list-actions'

import ListSearch from './list-search'

import QuickView from './quick-view'

import { getUserName } from 'crm-components/data-helpers'

import { COLUMN_PRIORITY_LOW } from 'ui-components/list/common'

const GET_PROPERTYS = /* GraphQL */ `
    query GetProperties(
        $search: PropertySearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        properties(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Property {
                    _id
                    referenceCode
                    layoutType
                    propertyOffering {
                        name
                    }
                    marketingRecords {
                        languageId
                        name
                        language {
                            isDefault
                        }
                    }
                    status {
                        name
                    }
                    createdAt
                    assignedTo {
                        profile {
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`

const DELETE_PROPERTYS = /* GraphQL */ `
    mutation DeleteProperties($ids: [ID!]!) {
        deleteProperties(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    const name = usePropertyName({ property: row })

    switch (col.key) {
        case 'title':
            return name

        case 'assignedTo':
            return getUserName(row[col.key])

        default:
            return undefined
    }
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Reference',
                key: 'referenceCode',
                sortKey: 'referenceCode',
            },
            {
                name: 'Title',
                key: 'title',
            },
            {
                name: 'Layout',
                key: 'layoutType',
                sortKey: 'layoutType',
            },
            {
                name: 'Status',
                key: 'status',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
                priority: COLUMN_PRIORITY_LOW,
            },
            {
                name: 'Assigned',
                key: 'assignedTo',
            },
        ],
        []
    )

    const predefinedQueries = usePredefinedQueries()

    return (
        <List
            getQuery={GET_PROPERTYS}
            deleteQuery={DELETE_PROPERTYS}
            pageTitle={'Properties'}
            entity="property"
            cols={cols}
            showQuickFiltersLoader
            record="Property"
            predefinedQueries={predefinedQueries}
            renderCell={Cell}
            renderSearchSection={ListSearch}
            renderActions={ListActions}
            renderExpandedRow={QuickView}
            expandable
        />
    )
}
