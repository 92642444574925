import React from 'react'
import { Route } from 'system/core/route'

import HomePage from './home-page'

export default () => {
    return (
        <Route path="/" exact fullScreen>
            <HomePage />
        </Route>
    )
}
