import React from 'react'
import { get } from 'lodash-es'
import { GoHome } from 'react-icons/go'
import { FaRegBuilding } from 'react-icons/fa'
import CollectionIconBoxSelector from 'crm-components/collection-icon-box-selector'

export default props => {
    return (
        <CollectionIconBoxSelector
            loaderCount={2}
            queryName="propertyCategories"
            recordName="PropertyCategory"
            renderIcon={option => {
                const name = (get(option, 'name') || '').toLowerCase()
                if (name === 'commercial') {
                    return <FaRegBuilding />
                } else if (name === 'residential') {
                    return <GoHome />
                }
            }}
            {...props}
        />
    )
}
