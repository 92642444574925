import { useEffect, useState, useCallback } from 'react'

import { get, isEqual, isEmpty } from 'lodash-es'

import { storeToken } from 'system/account/acl'

import { useMutation } from 'system/network/graphql'

import { pick } from 'lodash-es'

const LOGIN = /* GraphQL */ `
    mutation Login($user: UserInput!) {
        login(user: $user) {
            token
        }
    }
`

export function useLogin() {
    const { load: mutateLogin, cacheValue, loading } = useMutation({
        operation: {
            query: LOGIN,
        },
        loadOnReset: true,
    })

    const [userData, setUserData] = useState(null)

    const [token, setToken] = useState(null)

    useEffect(() => {
        if (isEmpty(userData)) return

        mutateLogin(userData)
    }, [userData, mutateLogin])

    useEffect(() => {
        const token = get(cacheValue, 'data.login.token')

        if (token) {
            setToken(token)
        }
    }, [cacheValue])

    useEffect(() => {
        if (token) storeToken(token)
    }, [token])

    const login = useCallback(
        credentials => {
            if (isEmpty(credentials)) return

            setUserData(old => {
                const userData = {
                    user: pick(credentials, ['email', 'password']),
                }

                if (isEqual(old, userData)) {
                    return old
                }

                return userData
            })
        },
        [setUserData]
    )

    return { token, cacheValue, loading, login }
}
