import React from 'react'

import Route from './route'

import SignUpForm from './sign-up-form'

import { Switch, useRouteMatch, useLocation, Redirect } from 'react-router-dom'

import UserProfileForm from './user-profile-form'

import CompanyForm from './company-form'

import { useAccount } from 'system/account'

import { isEmpty } from 'lodash-es'

function getAfterSignUpUrl(account) {
    if (account && account.emailConfirmedAt) {
        return `/dashboard`
    }
    return `/getting-started`
}

const signUpSteps = [
    {
        path: rootPath => rootPath,
        render: props => <SignUpForm {...props} />,
        isNext: account => isEmpty(account),
    },
    {
        path: rootPath => `${rootPath}/about-you`,
        render: props => <UserProfileForm mode="register" {...props} />,
        isNext: account => !isEmpty(account) && isEmpty(account.profile),
    },
    {
        path: rootPath => `${rootPath}/about-your-company`,
        render: props => <CompanyForm mode="register" {...props} />,
        isNext: account =>
            !isEmpty(account) &&
            !isEmpty(account.profile) &&
            isEmpty(account.company),
    },
]

const SignUpStepSelector = () => {
    const { account, loading } = useAccount()

    let { path: rootPath } = useRouteMatch()

    let { pathname: currentPath } = useLocation()

    if (loading) return null

    for (const step of signUpSteps) {
        if (step.isNext(account) && currentPath !== step.path(rootPath)) {
            return <Redirect to={step.path(rootPath)} />
        }
    }

    if (
        !isEmpty(account) &&
        !isEmpty(account.profile) &&
        !isEmpty(account.company)
    )
        return <Redirect to={getAfterSignUpUrl(account)} />

    return null
}

const Router = () => {
    let { path: rootPath } = useRouteMatch()

    return (
        <>
            <SignUpStepSelector />
            <Switch>
                {signUpSteps.map((step, index) => {
                    const path = step.path(rootPath)
                    const Step = step.render

                    return (
                        <Route path={path} key={path} exact={index === 0}>
                            <Step />
                        </Route>
                    )
                })}
            </Switch>
        </>
    )
}

export default () => {
    return (
        <Route path="/sign-up" fullScreen>
            <Router />
        </Route>
    )
}
