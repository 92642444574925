import React from 'react'

import styled from 'styled-components'

import { useExpanded, useHiddenOverflow, usePaddingBottom } from './hooks'

const Body = styled.div`
    overflow: ${props => (props.hiddenOverflow ? 'hidden' : 'visible')};
    padding: 0 1rem 1rem 1rem;
    padding-bottom: ${props => (props.paddingBottom ? '1rem' : 0)};
`

const HideOverflow = styled.div``

export default ({ children }) => {
    const [expanded] = useExpanded()

    const [paddingBottom] = usePaddingBottom()

    const [hiddenOverflow] = useHiddenOverflow()

    return expanded ? (
        <Body paddingBottom={paddingBottom} hiddenOverflow={hiddenOverflow}>
            {children}
            <HideOverflow />
        </Body>
    ) : null
}
