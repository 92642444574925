import React from 'react'

import ToastModule from 'modules/toast'

import GraphQLModule from 'modules/graphql'

import ModalModule from 'modules/modal'

import GettingStartedModule from 'modules/getting-started'

import PropertyModule from 'modules/property'

import DashboardModule from 'modules/dashboard'

import CompanyModule from 'modules/company'

import RoleModule from 'modules/role'

import SettingsModule from 'modules/settings'

import TeamsModule from 'modules/team'

import DocumentTypeModule from 'modules/document-type'

import PropertyTypeModule from 'modules/property-type'

import PropertyStatusModule from 'modules/property-status'

import UserModule from 'modules/user'

import AccountModule from 'modules/account'

import PropertyOfferingModule from 'modules/property-offering'

import BedroomModule from 'modules/bedroom'

import BathroomModule from 'modules/bathroom'

import FurnitureTypeModule from 'modules/furniture-type'

import TenureTypeModule from 'modules/tenure-type'

import PropertyOccupancyModule from 'modules/property-occupancy'

import ProjectStatusModule from 'modules/project-status'

import RenovationTypeModule from 'modules/renovation-type'

import FinancialStatusModule from 'modules/financial-status'

import ChequeModule from 'modules/cheque'

import AmenityModule from 'modules/amenity'

import InviteModule from 'modules/invite'

import ContactStatusModule from 'modules/contact-status'

import PropertyLocationModule from 'modules/property-location'

import PropertyCategoryModule from 'modules/property-category'

import MarketingTemplateModule from 'modules/marketing-template'

import LanguageModule from 'modules/language'

import ListingPlatformModule from 'modules/listing-platform'

import PropertyTransitionModule from 'modules/property-transition'

import ConvertProWebsite from 'modules/convertpro-website'

import TransactionModule from 'modules/transaction'

import LeadModule from 'modules/lead'

import LeadInterestTypeModule from 'modules/lead-interest-type'

import LeadStatusModule from 'modules/lead-status'

import TenantModule from 'modules/tenant'

import TenantStatusModule from 'modules/tenant-status'

import CountryModule from "modules/country"

import OwnerModule from "modules/owner"

import OwnerStatusModule from "modules/owner-status"

// import injection

export default () => {
    return (
        <>
            <ModalModule />
            <AccountModule />
            <UserModule />
            <ToastModule />
            <GraphQLModule />
            <GettingStartedModule />
            <PropertyModule />
            <DashboardModule />
            <CompanyModule />
            <RoleModule />
            <SettingsModule />
            <TeamsModule />
            <DocumentTypeModule />
            <PropertyTypeModule />
            <PropertyStatusModule />
            <PropertyOfferingModule />
            <BedroomModule />
            <BathroomModule />
            <FurnitureTypeModule />
            <TenureTypeModule />
            <PropertyOccupancyModule />
            <ProjectStatusModule />
            <RenovationTypeModule />
            <FinancialStatusModule />
            <ChequeModule />
            <AmenityModule />
            <InviteModule />
            <ContactStatusModule />
            <PropertyLocationModule />
            <PropertyCategoryModule />
            <MarketingTemplateModule />
            <LanguageModule />
            <ListingPlatformModule />
            <PropertyTransitionModule />
            <ConvertProWebsite />
            <TransactionModule />
            <LeadModule />
            <LeadInterestTypeModule />
            <LeadStatusModule />
            <TenantModule />
            <TenantStatusModule />

            <CountryModule />

            <OwnerModule />

            <OwnerStatusModule />

            {/* jsx injection */}
        </>
    )
}
