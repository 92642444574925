import React from 'react'

import styled from 'styled-components'

import { FiMinusSquare, FiPlusSquare, FiSquare } from 'react-icons/fi'

import { useExpandSections } from 'system/ui/sections'

const ExpandAllContainer = styled.div`
    display: flex;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;
    padding: calc(0.5rem - 1px);
`

export default () => {
    const [expandSections, setExpandSections] = useExpandSections()

    return (
        <ExpandAllContainer onClick={() => setExpandSections(!expandSections)}>
            {typeof expandSections === 'undefined' ? (
                <FiSquare />
            ) : expandSections ? (
                <FiMinusSquare />
            ) : (
                <FiPlusSquare />
            )}
        </ExpandAllContainer>
    )
}
