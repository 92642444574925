import React from 'react'

import { Route } from 'system/core/route'

import InviteForm from './invite-form'

export default () => {
    return (
        <>
            <Route path="/invite-users">
                <InviteForm />
            </Route>
        </>
    )
}
