import { isFunction } from 'lodash-es'
import { useState, useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components'

export const useMatchMedia = query => {
    const [matches, setMatches] = useState()

    const match = useMemo(() => {
        if (!query) {
            return null
        }

        return window.matchMedia(query)
    }, [query])

    useEffect(() => {
        const matchListener = ({ matches }) => {
            setMatches(matches)
        }

        if (isFunction(match.addEventListener)) {
            match.addEventListener('change', matchListener)
        } else {
            match.addListener(matchListener)
        }

        setMatches(matches => {
            if (typeof matches === 'undefined') {
                return match.matches
            }

            return matches
        })

        return () => {
            if (isFunction(match.removeEventListener)) {
                match.removeEventListener('change', matchListener)
            } else {
                match.removeListener(matchListener)
            }
        }
    }, [match])

    return matches
}

export const useIsMobile = defaultTheme => {
    const contextualTheme = useTheme()

    const theme = defaultTheme || contextualTheme

    const query = `(max-width: ${theme.flexboxgrid.breakpoints.sm}rem)`

    return useMatchMedia(query)
}
