import React from 'react'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import CRUDForm from 'crm-components/crud-form'

import InterestSection from './interest-section'

import ContactSection from 'modules/contact/contact-section'

import Section from 'ui-components/section'

import { useParams } from 'react-router-dom'

import MetaSection from 'crm-components/meta-section'

import DocumentsTab from 'crm-components/documents-tab'

const LEAD_FRAGMENT = /* GraphQL */ `
    fragment leadFields on Lead {
        _id
        name
        email
        mobile
        phone
        officialDocumentNumber
        officialDocumentExpiryDate
        address
        countryId
        city
        interestPropertyLocationId
        interestNumberOfBedroomsId
        interestBudget
        interestTypeId
        interestAmenityIds
        interestNotes
        leadStatusId
        activityIds
        documentIds
        createdAt
        updatedAt
        createdById
        assignedToId
    }
`

const SAVE_LEAD = /* GraphQL */ `
    mutation SaveLead($lead: LeadInput) {
        saveLead(lead: $lead) {
            ...leadFields
        }
    }
    ${LEAD_FRAGMENT}
`

const GET_LEAD = /* GraphQL */ `
    query GetLead($id: ID!) {
        lead(id: $id) {
            ...leadFields
        }
    }
    ${LEAD_FRAGMENT}
`

const DELETE_LEADS = /* GraphQL */ `
    mutation DeleteLeads($ids: [ID!]!) {
        deleteLeads(ids: $ids)
    }
`

export default () => {
    const { id } = useParams()

    return (
        <CRUDForm
            query={GET_LEAD}
            mutation={SAVE_LEAD}
            mutationVariableName="lead"
            record="Lead"
            deleteQuery={DELETE_LEADS}
        >
            <Tab title="Contact">
                <ContactSection />
            </Tab>
            <Tab title="Interest">
                <InterestSection />
            </Tab>
            <Tab title="Status">
                <Section title="Status">
                    <FormFields>
                        <FormField
                            label="Lead status"
                            name="leadStatusId"
                            type="collectionSelect"
                            recordName="LeadStatus"
                            queryName="leadStatuses"
                        />
                    </FormFields>
                </Section>
                <MetaSection />
            </Tab>
            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'leads'}
                            parentId={id}
                            parentRecordName="Lead"
                        />
                    </FormFields>
                </Section>
            </Tab>

            <DocumentsTab parentResolveType="Lead" />
        </CRUDForm>
    )
}
