import React from 'react'

import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'

import styled from 'styled-components'

import { fadeIn } from './input'
import { isFunction } from 'lodash-es'

const Container = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    color: ${props => props.theme.dark};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? '.5' : '1')};
    margin-bottom: ${props => (props.noMargin ? 0 : `1rem`)};
`

const Label = styled.span`
    display: inline-block;
    margin-left: 0.5rem;
    font-size: 0.8rem;
`

const Icon = styled.div`
    display: flex;
    color: ${props => props.theme.primary};
`

export const Error = styled.div`
    color: ${props => props.theme.danger};
    position: absolute;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 400;
    position: absolute;
    bottom: -1rem;
    left: 1.5rem;
    animation: ${fadeIn} 0.5s ease;
`

export default ({
    label,
    value = false,
    onChange,
    className,
    disabled,
    error,
    noMargin,
}) => {
    const onClick = () => {
        if (isFunction(onChange)) {
            onChange(!value)
        }
    }

    return (
        <Container
            className={className}
            disabled={disabled ? 1 : 0}
            noMargin={noMargin}
            onClick={onClick}
        >
            <Icon checked={value ? 1 : 0}>
                {value ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
            </Icon>
            {label ? <Label>{label}</Label> : null}
            {error ? <Error>{error.message}</Error> : null}
        </Container>
    )
}
