import React, { useContext, useEffect, useCallback, useState } from 'react'

import Section from 'ui-components/section'

import { AiOutlineUnorderedList } from 'react-icons/ai'

import { Fix } from 'ui-components/list/common'

import { ListContext } from './provider'

import { useQuery } from 'system/network/graphql'

import { empty, getDataNodes } from 'crm-components/data-helpers'

import { isEqual, get } from 'lodash-es'

import { getDataKey } from 'crm-components/data-helpers'

import ToolbarActions from './toolbar-actions'

import List from 'ui-components/list'

import { loadDefaultPageSize } from 'ui-components/pagination'

import RowActions from './row-actions'

import Pagination from 'ui-components/pagination'

import { useSelectedRows } from './hooks'

function getTotalPages(getQueryCacheValue) {
    const key = getDataKey(getQueryCacheValue)

    const totalPages = get(getQueryCacheValue, `data.${key}.totalPages`) || 0

    return totalPages
}

function getCount(getQueryCacheValue) {
    const key = getDataKey(getQueryCacheValue)

    const count = get(getQueryCacheValue, `data.${key}.count`)

    return count
}

export default () => {
    const [
        {
            getQuery,
            search,
            rows,
            cols,
            renderCell,
            renderExpandedRow,
            expandable,
            entity,
        },
        set,
    ] = useContext(ListContext)

    const [sort, setSort] = useState(null)

    const [selectedRows, setSelectedRows] = useSelectedRows()

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: loadDefaultPageSize(),
    })

    const { loading, cacheValue: getQueryCacheValue } = useQuery({
        operation: {
            query: getQuery,
            variables: {
                search: search,
                sort: sort,
                pagination,
            },
        },
        loadOnReload: true,
        loadOnMount: !empty(getQuery),
    })

    useEffect(() => {
        set(state => {
            const rows = getDataNodes(getQueryCacheValue)

            if (!isEqual(rows, state.rows)) {
                return {
                    ...state,
                    rows,
                }
            }

            return state
        })
    }, [getQueryCacheValue, set])

    const totalPages = getTotalPages(getQueryCacheValue)

    const count = getCount(getQueryCacheValue)

    const onChangeSelected = useCallback(
        selected => setSelectedRows(selected),
        [setSelectedRows]
    )

    return (
        <Section expanded={true} icon={AiOutlineUnorderedList} title={'Data'}>
            <ToolbarActions />
            <List
                onSortChange={setSort}
                loading={loading}
                onChangeSelected={onChangeSelected}
                rows={rows}
                cols={cols}
                renderActions={RowActions}
                renderCell={renderCell}
                renderExpandedRow={renderExpandedRow}
                expandable={expandable}
            />
            <Pagination
                loading={loading}
                {...pagination}
                totalPages={totalPages}
                count={count}
                baseLink={''}
                entity={entity}
                onChange={pagination => setPagination(pagination)}
                selectedRowsCount={selectedRows.length}
            />
            <Fix />
        </Section>
    )
}
