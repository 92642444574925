import React, { createContext, useState } from 'react'

export const FormContext = createContext()

const DEFAULT_STATE = {
    // the actual form data
    data: {},
    // the server data
    remoteData: {},
    // the differences between remote and data
    dirtyData: {},
    isDirty: false,
    getLoading: false,
    saveLoading: false,
    loading: false,
    disabled: false,
    validationErrors: [],
    excludeFromInput: [],
    staticFormFields: [],
    record: '',
    submit: () => {},
}

export default ({ children }) => {
    return (
        <FormContext.Provider value={useState(DEFAULT_STATE)}>
            {children}
        </FormContext.Provider>
    )
}
