import React from 'react'

import Section from 'ui-components/section'

import { FaCalendarAlt } from 'react-icons/fa'

import { FormFields, FormField } from 'crm-components/crud-form'

import ListPlatformsSelect from 'crm-components/listing-platforms-multi-select-checkbox'

export default () => {
    return (
        <Section title="Publishing" icon={FaCalendarAlt}>
            <FormFields>
                <FormField
                    name="publishFrom"
                    type="datepicker"
                    label="Publish from"
                />
                <FormField
                    label="Publish to"
                    name="publishTo"
                    type="datepicker"
                />
                <FormField
                    label="Listing platforms"
                    name="listingPlatformIds"
                    component={ListPlatformsSelect}
                />
            </FormFields>
        </Section>
    )
}
