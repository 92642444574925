import React, { useState, useContext, useMemo, forwardRef } from 'react'

import styled, { css, ThemeContext } from 'styled-components'

import { Link as DefaultLink } from 'react-router-dom'

import DefaultLoader from './loader'

import { GOLD_I } from 'system/layout/theme'

const Loader = styled(({ loadingColor, ...props }) => (
    <DefaultLoader {...props} />
)).attrs({
    transform: 'translateY(-50%)',
})`
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform-origin: top;
    color: ${props => props.loadingColor};
`

const ButtonStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
    -webkit-appearance: none;
    border: 0;
    border-radius: 5px;
    color: ${props => props.color};
    padding: ${props => {
        return props.mini ? '.5rem' : `${GOLD_I}rem 2rem`
    }};
    box-shadow: ${props =>
        props.hover ? '2px 2px 2px 2px #8080804f' : 'none'};
    transform: ${props => (props.down ? 'scale(0.98)' : 'scale(1)')};
    user-select: none;
    outline: 0;
    text-transform: uppercase;
    font-size: ${props => (props.mini ? '0.5rem' : '0.8rem')};
    transition: all ease 0.2s;
    cursor: ${props => (props.disabled ? 'context' : 'pointer')};
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    text-decoration: none;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const Button = styled.button`
    ${ButtonStyles}
`

const Link = styled(({ backgroundColor, loadingColor, ...props }) => (
    <DefaultLink {...props} />
))`
    ${ButtonStyles}
`

const ButtonContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const Icon = styled.div`
    opacity: 0.5;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    padding-left: 0.5rem;
    z-index: 2;
    color: white;
`
export default forwardRef(
    (
        {
            to,
            children,
            loading,
            disabled,
            className,
            secondary,
            icon = null,
            success,
            danger,
            neutral,
            warning,
            primary,
            onKeyUp = () => {},
            buttonStyles,
            ...props
        },
        ref
    ) => {
        const [hover, setHover] = useState(false)
        const [down, setDown] = useState(false)

        let Elem = null

        const theme = useContext(ThemeContext)

        const variations = useMemo(
            () => ({
                success: {
                    backgroundColor: theme.success,
                    color: theme.light,
                    loadingColor: theme.light,
                },
                primary: {
                    backgroundColor: theme.primary,
                    color: theme.light,
                    loadingColor: theme.light,
                },
                neutral: {
                    backgroundColor: theme.gray,
                    color: theme.blue,
                    loadingColor: theme.blue,
                },
                danger: {
                    backgroundColor: theme.danger,
                    color: theme.light,
                    loadingColor: theme.light,
                },
                warning: {
                    backgroundColor: theme.warning,
                    color: theme.light,
                    loadingColor: theme.light,
                },
            }),
            [theme]
        )

        const variation = useMemo(() => {
            for (const variation of Object.keys(variations)) {
                // eslint-disable-next-line
                if (eval(variation) === true) {
                    return variations[variation]
                }
            }
            // Default variation is primary
            return variations.primary
        }, [variations])

        if (to) {
            Elem = Link
        } else {
            Elem = Button
        }

        return (
            <ButtonContainer className={className} disabled={disabled}>
                {icon && <Icon>{icon}</Icon>}
                <Elem
                    {...props}
                    {...variation}
                    to={to}
                    onKeyUp={onKeyUp}
                    onTouchStart={() => setHover(true)}
                    onTouchEnd={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => {
                        setHover(false)
                        setDown(false)
                    }}
                    onMouseDown={() => setDown(true)}
                    onMouseUp={() => setDown(false)}
                    onBlur={() => {
                        setDown(false)
                        setHover(false)
                    }}
                    disabled={loading || disabled ? 1 : 0}
                    hover={hover ? 1 : 0}
                    down={down ? 1 : 0}
                    ref={ref}
                    style={buttonStyles}
                >
                    {children}
                </Elem>
                {loading && <Loader color={variation.loadingColor} />}
            </ButtonContainer>
        )
    }
)
