import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const MARKETING_TEMPLATE_FRAGMENT = /* GraphQL */ `
    fragment marketingTemplateFields on MarketingTemplate {
        _id
        name
        content
        languageId
    }
`

const SAVE_MARKETING_TEMPLATE = /* GraphQL */ `
    mutation SaveMarketingTemplate($marketingTemplate: MarketingTemplateInput) {
        saveMarketingTemplate(marketingTemplate: $marketingTemplate) {
            ...marketingTemplateFields
        }
    }
    ${MARKETING_TEMPLATE_FRAGMENT}
`

const GET_MARKETING_TEMPLATE = /* GraphQL */ `
    query GetMarketingTemplate($id: ID!) {
        marketingTemplate(id: $id) {
            ...marketingTemplateFields
        }
    }
    ${MARKETING_TEMPLATE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_MARKETING_TEMPLATE}
            mutation={SAVE_MARKETING_TEMPLATE}
            mutationVariableName="marketingTemplate"
            record="MarketingTemplate"
        >
            <Tab title="Details">
                <Section title="Marketing template details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="2 bedrooms in Jumeirah"
                        />
                        <FormField
                            name="content"
                            label="Content"
                            placeholder="Template content"
                            type="textarea"
                        />
                        <FormField
                            name="languageId"
                            label="Language"
                            type="collectionSelect"
                            recordName="Language"
                            queryName="languages"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
