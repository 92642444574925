import cogoToast from 'cogo-toast'

import { createGlobalStyle } from 'styled-components'

export const ToastStyles = createGlobalStyle`
    .ct-toast {
        box-shadow: 0 5px 5px ${props => props.theme.gray}!important;
        color: ${props => props.theme.dark};
        user-select: none;
    }
    .ct-toast-success {
        border-left: 3px solid ${props => props.theme.success}!important;
    }
    .ct-toast-success svg path {
        fill: ${props => props.theme.success}!important;
    }

    .ct-toast-error {
        border-left: 3px solid ${props => props.theme.danger}!important;
    }
    .ct-toast-error svg path {
        fill: ${props => props.theme.danger}!important;
    }
`

let canShowToast = true

function toast(message, type) {
    if (!canShowToast) return

    canShowToast = false

    cogoToast[type](message, {
        position: 'bottom-center',
    })

    setTimeout(() => {
        canShowToast = true
    }, 3 * 1000)
}

export function successToast(message) {
    toast(message, 'success')
}

export function errorToast(message) {
    toast(message, 'error')
}
