import { equal } from 'crm-components/data-helpers'
import { useEffect, useRef } from 'react'
import { useData, useSaveChangesToLocalStorage } from './fields'
import { useLocalStorage } from './local-storage'
import useShouldClear from './use-should-clear'

export default () => {
    const [saveChangesToLocalStorage] = useSaveChangesToLocalStorage()

    const [, save] = useLocalStorage()

    const [data] = useData()

    const prevDataRef = useRef(null)

    const [shouldClear] = useShouldClear()

    useEffect(() => {
        if (equal(prevDataRef.current, data)) return

        if (!saveChangesToLocalStorage) return

        if (shouldClear) return

        save()

        prevDataRef.current = data
    }, [data, saveChangesToLocalStorage, save, shouldClear])
}
