import React, { useContext } from 'react'

import styled from 'styled-components'

import Checkbox from 'ui-components/checkbox'

import { useToggleExpandAll, useListSelection } from './hooks'

import { ListContext } from './provider'

import { FiChevronRight } from 'react-icons/fi'

import { get, isArray } from 'lodash-es'

import HeaderCell from './header-cell'

const ExpandCellContainer = styled.div`
    color: ${props => props.theme.primary};
    transition: transform 0.2s ease;
    transform: ${props => (props.expanded ? `rotate(90deg)` : `rotate(0deg)`)};
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
`

const SelectAllHeaderCell = styled.th`
    @media ${props => props.theme.media.sm} {
        width: 30px;
    }
`

const ExpandHeaderCell = styled.th`
    width: 60px;
`

export default () => {
    const [{ expandable, cols, rows }] = useContext(ListContext)

    const { toggleSelectAll, selectedRows } = useListSelection()

    const { toggleExpandAll, areAllRowsExpanded } = useToggleExpandAll()

    const selectedLength = get(selectedRows, 'length') || 0

    const rowsLength = get(rows, 'length') || 0

    return (
        <thead>
            <tr>
                <SelectAllHeaderCell>
                    <Checkbox
                        noMargin
                        value={selectedLength === rowsLength && rowsLength > 0}
                        onChange={() => toggleSelectAll()}
                    />
                </SelectAllHeaderCell>

                {expandable && (
                    <ExpandHeaderCell>
                        <ExpandCellContainer
                            onClick={toggleExpandAll}
                            expanded={areAllRowsExpanded}
                        >
                            <FiChevronRight />
                        </ExpandCellContainer>
                    </ExpandHeaderCell>
                )}

                {isArray(cols) &&
                    cols.map((col, i) => <HeaderCell key={i} col={col} />)}
                <th>Actions</th>
            </tr>
        </thead>
    )
}
