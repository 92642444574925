import { get } from 'lodash-es'
import React from 'react'

import { useQuery } from 'system/network/graphql'

const emptyArray = []

export default ({ recordId, resolveType, visible = false }) => {
    const { cacheValue } = useQuery({
        operation: {
            query: /* GraphQL */ `
                query GetHistory($recordId: ID, $resolveType: String) {
                    history(recordId: $recordId, resolveType: $resolveType) {
                        changes
                        userId
                        date
                    }
                }
            `,
            variables: {
                resolveType,
                recordId,
            },
        },
        loadOnMount: visible,
    })

    const records = get(cacheValue, 'data.history') || emptyArray

    if (!visible) {
        return null
    }

    return records.map(record => (
        <div>
            <p>By: {record.userId}</p>
            <p>Date: {record.date}</p>
            <p>Changes: {record.changes}</p>
        </div>
    ))
}
