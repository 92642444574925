import React from 'react'

import SearchField from 'crm-components/list/search-field'

export default () => {
    return (
        <>
            <SearchField name="name" label="Name" />
            <SearchField name="email" label="Email" />
            <SearchField name="mobile" label="Email" />
            <SearchField name="phone" label="Phone" />
            <SearchField
                name="officialDocumentNumber"
                label="Official document numnber"
            />
            <SearchField
                name="officialDocumentExpiryDate"
                label="Official document expiry date"
                type="dateRangePicker"
            />
            <SearchField
                name="createdAt"
                label="Date created"
                type="dateRangePicker"
            />
        </>
    )
}
