import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_USERS = /* GraphQL */ `
    query GetUsers(
        $search: UserSearch
        $sort: Sort
        $pagination: PaginationInput
    ) {
        users(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on User {
                    _id
                    email
                    roleId
                    isSuperUser
                    role {
                        name
                    }
                    company {
                        name
                    }
                    createdAt
                }
            }
        }
    }
`

const DELETE_USERS = /* GraphQL */ `
    mutation DeleteUsers($ids: [ID!]!) {
        deleteUsers(ids: $ids) {
            affectedIds
            unAffectedIds
            message
        }
    }
`

const Cell = ({ row, col }) => {
    if (col.key === 'role') {
        if (row.isSuperUser) return 'Super User'
    }

    return undefined
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Email',
                key: 'email',
                sortKey: 'email',
            },
            {
                name: 'Company',
                key: 'company',
            },
            {
                name: 'Role',
                key: 'role',
            },
            {
                name: 'Register date',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_USERS}
            deleteQuery={DELETE_USERS}
            pageTitle={'Users'}
            entity="user"
            cols={cols}
            record="User"
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="email" label="Email" />
                        <SearchField
                            name="companyId"
                            type="collectionSelect"
                            label="Company"
                            queryName="companies"
                            recordName="Company"
                        />
                        <SearchField
                            name="roleId"
                            type="collectionSelect"
                            label="Role"
                            queryName="roles"
                            recordName="Role"
                        />
                        <SearchField
                            name="createdAt"
                            type="dateRangePicker"
                            label="Registration date"
                        />
                    </>
                )
            }}
        />
    )
}
