import React from 'react'

import CollectionFetcher from 'crm-components/collection-fetcher'

import MultiSelectCheckBox from 'ui-components/multi-select-checkbox'

export default props => {
    return (
        <CollectionFetcher
            {...props}
            queryName="listingPlatforms"
            recordName="ListingPlatform"
            render={props => <MultiSelectCheckBox {...props} />}
        />
    )
}
