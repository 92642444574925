import React from 'react'

import Section from 'ui-components/section'

import { FormField, FormFields } from 'crm-components/crud-form'

import { useFormData } from 'crm-components/crud-form/hooks/fields'

export default () => {
    const [data] = useFormData()

    return (
        <Section title="Specs">
            <FormFields>
                <FormField
                    name="propertyTypeId"
                    label="Property type"
                    type="collectionSelect"
                    queryName="propertyTypes"
                    recordName="PropertyType"
                    search={{ categoryId: data.categoryId }}
                />
                <FormField
                    name="size"
                    label="Size"
                    placeholder="Size (sqft)"
                    type="number"
                />

                <FormField
                    name="numberOfBedroomsId"
                    type="collectionSelect"
                    queryName="bedrooms"
                    recordName="Bedroom"
                    label="Number of bedrooms"
                />

                <FormField
                    name="numberOfBathroomsId"
                    type="collectionSelect"
                    queryName="bathrooms"
                    recordName="Bathroom"
                    label="Number of bathrooms"
                />
                <FormField
                    name="numberOfParkingSpaces"
                    type="number"
                    queryName="bathrooms"
                    recordName="Bathrooms"
                    label="Number of parking spaces"
                    step={1}
                    min={0}
                />
                <FormField
                    name="furnitureTypeId"
                    type="collectionSelect"
                    queryName="furnitureTypes"
                    recordName="FurnitureType"
                    label="Furniture type"
                />

                <FormField
                    name="propertyDeveloper"
                    label="Property developer"
                />

                <FormField
                    name="tenureTypeId"
                    label="Tenure type"
                    type="collectionSelect"
                    recordName="TenureType"
                    queryName="tenureTypes"
                />

                <FormField name="buildYear" label="Build year" />

                <FormField name="plotSize" label="Plot size" type="number" />

                <FormField name="plotNumber" label="Plot number" />

                <FormField
                    name="builtUpArea"
                    label="Built-up area (sqft)"
                    type="number"
                />

                <FormField
                    name="buildingFloors"
                    type="number"
                    step={1}
                    label="Building floors"
                />

                <FormField
                    name="occupancyId"
                    label="Occupancy"
                    type="collectionSelect"
                    recordName="PropertyOccupancy"
                    queryName="propertyOccupancies"
                />

                <FormField
                    name="projectStatusId"
                    label="Project status"
                    type="collectionSelect"
                    recordName="ProjectStatus"
                    queryName="projectStatuses"
                />

                <FormField
                    name="renovationTypeId"
                    label="Renovation type"
                    type="collectionSelect"
                    recordName="RenovationType"
                    queryName="renovationTypes"
                />
                <FormField
                    name="layoutType"
                    label="Layout type"
                    placeholder="e.g. Type A"
                />

                <FormField name="dewaNumber" label="Dewa number" />

                <FormField name="views" label="Views" />

                <FormField name="notes" label="Notes" type="textarea" />
            </FormFields>
        </Section>
    )
}
