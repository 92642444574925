import React from 'react'

import styled from 'styled-components'

const Comment = styled.div`
    letter-spacing: 1px;
`

const Container = styled.div`
    display: flex;
    color: ${props => props.theme.gray2};
    font-size: 0.5rem;
    margin: 0.5rem 0 0 0;
    align-items: center;
    user-select: none;
`

export default ({ className, children, icon }) => (
    <Container className={className}>
        <Comment>{children}</Comment>
        {icon}
    </Container>
)
