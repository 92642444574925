import React from 'react'

import { Route } from 'system/core/route'

export default () => {
    return (
        <Route
            path="/dashboard"
            permission={{
                record: 'Dashboard',
                method: 'READ',
                accessModifier: 'OWN',
            }}
        >
            Dashbaord screen
        </Route>
    )
}
