import { useMemo } from 'react'

import { empty } from 'crm-components/data-helpers'

import { useFetchStatuses } from 'crm-components/statuses-fetcher'

const useStatusesPredefinedQueries = ({ resolveType }) => {
    const hiddenStatuses = useFetchStatuses({
        search: { showPredefinedQuery: true },
        resolveType,
    })

    const queries = useMemo(() => {
        if (empty(hiddenStatuses)) return undefined

        const hiddenStatusSearch = hiddenStatuses.map(status => ({
            name: status.name,
            filter: {
                [resolveType.toLowerCase() + 'StatusId']: status._id,
            },
        }))

        return hiddenStatusSearch
    }, [hiddenStatuses, resolveType])

    return queries
}

export const useContactPredefindQueries = ({ resolveType }) => {
    const statuses = useStatusesPredefinedQueries({
        resolveType,
    })

    const queries = useMemo(() => {
        if (empty(statuses)) return undefined

        let now = new Date()

        let nextMonth = new Date()
        nextMonth.setMonth(nextMonth.getMonth() + 1)

        let tenYearsAgo = new Date()
        tenYearsAgo.setMonth(tenYearsAgo.getMonth() - 10 * 12)

        return [
            {
                name: 'All',
                filter: {},
            },
            {
                name: 'Document expired',
                filter: {
                    officialDocumentExpiryDate: [tenYearsAgo, new Date()],
                },
            },
            {
                name: 'Expiring soon',
                filter: {
                    officialDocumentExpiryDate: [now, nextMonth],
                },
            },
            ...statuses,
        ]
    }, [statuses])

    return queries
}
