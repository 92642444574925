import React, { useEffect, useState } from 'react'

import Route from './route'

import { get } from 'lodash-es'

import styled from 'styled-components'

import AccountPage from './account-page'

import { useParams } from 'react-router-dom'

import DefaultLoader from 'ui-components/loader'

import { useMutation } from 'system/network/graphql'

import { empty, getGraphqlErrors } from 'crm-components/data-helpers'

const Loader = styled(DefaultLoader)`
    font-size: 2rem;
    margin: auto;
    display: block;
`

const Error = styled.p`
    margin: 0;
    color: ${props => props.theme.danger};
    text-align: center;
    line-height: 1.7;
`

const Success = styled(Error)`
    color: ${props => props.theme.success};
`

const Message = styled.p`
    margin: 1rem 0;
    font-size: 1rem;
    color: ${props => props.theme.dark};
    text-align: center;
`

const ACTIVATE_USER = /* GraphQL */ `
    mutation ActivateUser($activationCode: String) {
        activateUser(activationCode: $activationCode) {
            _id
        }
    }
`

const SuccessScreen = () => {
    return (
        <>
            <Success>Your account is active</Success>
            <Message>Feel free to close this window</Message>
        </>
    )
}

const Page = () => {
    const { activationCode } = useParams()

    const [error, setError] = useState(null)

    const [success, setSuccess] = useState(false)

    const {
        load: activate,
        cacheValue: activateUserValue,
        loading,
    } = useMutation({
        operation: {
            query: ACTIVATE_USER,
        },
    })

    useEffect(() => {
        activate({ activationCode })
    }, [activate, activationCode])

    useEffect(() => {
        const id = get(activateUserValue, 'data.activateUser._id')

        if (!empty(id)) setSuccess(true)
        else if (!loading) {
            return setError({
                message:
                    'We were unable to activate your account, please try again later',
            })
        }

        const error = get(getGraphqlErrors(activateUserValue), '0')

        setError(error)
    }, [loading, activateUserValue])

    return (
        <AccountPage
            title="Activating your account"
            subtitle={`Please bear with us`}
        >
            {loading && <Loader />}
            {error && <Error>{error.message}</Error>}
            {success && <SuccessScreen />}
        </AccountPage>
    )
}

export default () => {
    return (
        <Route path="/activate/:activationCode" fullScreen>
            <Page />
        </Route>
    )
}
