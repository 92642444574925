import React from 'react'

import { getUserName } from './data-helpers'

import { get } from 'lodash-es'

import styled from 'styled-components'

const StyledUser = styled.div`
    display: flex;
`

const Name = styled.div`
    margin-right: 1rem;
`

const Role = styled.div`
    color: ${props => props.theme.gray2};
`

export default ({ user }) => {
    return (
        <StyledUser>
            <Name>{getUserName(user)}</Name>
            <Role>{get(user, 'role.name')}</Role>
        </StyledUser>
    )
}
