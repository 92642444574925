import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useContext,
} from 'react'

import {
    GraphQL,
    GraphQLContext,
    GraphQLProvider,
    useGraphQL,
} from 'graphql-react'

import { loadToken } from '../account/acl'

import { isEmpty, get, isEqual, isFunction } from 'lodash-es'

import { empty, emptyValues, sleep } from 'crm-components/data-helpers'

const graphql = new GraphQL()

export const GRAPHQL_ERROR = 'system.graphql.error'

const noop = {}

function fetchOptionsOverride(options) {
    options.url = process.env.REACT_APP_API_URL

    const token = loadToken()

    if (token) {
        options.headers.Authorization = `Bearer ${token}`
    }
}

export const useQuery = options => {
    const { cacheKey, cacheValue = noop, loading, load } = useGraphQL({
        loadOnMount: true,
        loadOnReload: true,
        reloadOnLoad: false,
        ...options,
        fetchOptionsOverride,
    })
    return { cacheKey, cacheValue, loading, load }
}

export const useCachedQuery = options => {
    const variables = get(options, 'operation.variables')

    const prevVariables = useRef()

    const { load, ...rest } = useQuery({
        ...options,
        loadOnMount: false,
        loadOnReload: false,
    })

    useEffect(() => {
        if (emptyValues(variables)) {
            return
        }

        if (isEqual(prevVariables.current, variables)) {
            return
        }

        if (!isEqual(prevVariables.current, variables)) {
            prevVariables.current = variables
        }

        load()
    }, [load, variables])

    return rest
}

export function operate(options = {}) {
    return graphql.operate({
        ...options,
        fetchOptionsOverride,
    })
}

export const useMutation = ({ onFetch, ...params }) => {
    const [options, setOptions] = useState(params)

    const shouldLoad = useRef(false)

    const prevParams = useRef()

    const graphql = useContext(GraphQLContext)

    const { cacheKey, cacheValue, loading, load } = useGraphQL({
        reloadOnLoad: true,
        loadOnMount: false,
        ...options,
        fetchOptionsOverride,
    })

    const cacheKeyRef = useRef(cacheKey)

    useEffect(() => {
        setOptions(options => {
            if (params && isEqual(prevParams.current, params)) return options

            prevParams.current = params

            if (!isEqual(options, params)) {
                return params
            }

            return options
        })
    }, [params])

    const mutate = useCallback(mutationVariables => {
        setOptions(options => {
            const variables = empty(mutationVariables)
                ? empty(get(options, 'operation.variables'))
                    ? noop
                    : options.operation.variables
                : mutationVariables

            if (isEqual(variables, get(options, 'operation.variables'))) {
                return options
            }

            const newOptions = {
                ...options,

                operation: {
                    ...options.operation,
                    variables,
                },
            }

            shouldLoad.current = true

            return newOptions
        })
    }, [])

    useEffect(() => {
        if (!empty(options) && shouldLoad.current) {
            shouldLoad.current = false
            load()
        }
    }, [options, load, shouldLoad])

    useEffect(() => {
        cacheKeyRef.current = cacheKey

        const handler = async ({ cacheKey: fetchKey, cacheValuePromise }) => {
            if (!isFunction(onFetch)) return

            await sleep(100)

            if (fetchKey !== cacheKeyRef.current) return

            const cacheValue = await cacheValuePromise

            onFetch({ cacheValue })
        }

        graphql.on('fetch', handler)

        return () => {
            setTimeout(() => graphql.off('fetch', handler), 200)
        }
    }, [onFetch, cacheKey, graphql, options])

    return { cacheKey, cacheValue, loading, load: mutate }
}

export const useMutationDeprecated = params => {
    const [defaultOptions] = useState(params)
    const [options, setOptions] = useState(defaultOptions)
    const [mutateOptions, setMutateOptions] = useState(noop)

    const { cacheKey, cacheValue = noop, loading, load } = useGraphQL({
        reloadOnLoad: true,
        fetchOptionsOverride,
        loadOnMount: false,
        ...options,
    })

    useEffect(() => {
        setOptions(options => {
            if (!isEqual(options, params)) {
                return params
            }

            return options
        })
    }, [params])

    const mutate = useCallback(
        mutationVariables => {
            const defaultVariables =
                get(defaultOptions, 'operation.variables') || {}

            const variables = isEmpty(mutationVariables)
                ? defaultVariables
                : { ...defaultVariables, ...mutationVariables }

            const mutateOptions = {
                ...defaultOptions,
                operation: {
                    ...defaultOptions.operation,
                    variables,
                },
            }

            if (isEqual(mutateOptions.operation, options.operation)) return

            setMutateOptions(mutateOptions)

            setOptions(mutateOptions)
        },
        [defaultOptions, options]
    )

    useEffect(() => {
        if (!isEmpty(mutateOptions) && isEqual(mutateOptions, options)) {
            load()
            setMutateOptions(noop)
        }
    }, [mutateOptions, load, options])

    return { cacheKey, cacheValue, loading, load: mutate }
}

export default ({ children }) => {
    return <GraphQLProvider graphql={graphql}>{children}</GraphQLProvider>
}

const GET_TYPES = /* GraphQL */ `
    {
        __schema {
            types {
                name
                kind
                enumValues {
                    name
                    description
                }
            }
        }
    }
`

const noopArray = []

export const useTypes = ({ kind = '' }) => {
    const { loading, cacheValue } = useQuery({
        operation: {
            query: GET_TYPES,
        },
    })

    const types = useMemo(() => {
        const types = get(cacheValue, 'data.__schema.types') || noopArray

        return types.filter(t => t.kind === kind)
    }, [cacheValue, kind])

    return { loading, types }
}

export const useEnums = () => {
    const { loading, types } = useTypes({ kind: 'ENUM' })
    return { loading, enums: types }
}
