import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import ContactTypeSelect from 'crm-components/contact-type-select'

const PROPERTY_OFFERING_FRAGMENT = /* GraphQL */ `
    fragment propertyOfferingFields on PropertyOffering {
        _id
        name
        propertyFinderValue
        bayutValue
        acceptsMultipleCheques
        transactionSourceContactTypes
    }
`

const SAVE_PROPERTY_OFFERING = /* GraphQL */ `
    mutation SavePropertyOffering($propertyOffering: PropertyOfferingInput) {
        savePropertyOffering(propertyOffering: $propertyOffering) {
            ...propertyOfferingFields
        }
    }
    ${PROPERTY_OFFERING_FRAGMENT}
`

const GET_PROPERTY_OFFERING = /* GraphQL */ `
    query GetPropertyOffering($id: ID!) {
        propertyOffering(id: $id) {
            ...propertyOfferingFields
        }
    }
    ${PROPERTY_OFFERING_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_PROPERTY_OFFERING}
            mutation={SAVE_PROPERTY_OFFERING}
            mutationVariableName="propertyOffering"
            record="PropertyOffering"
        >
            <Tab title="Details">
                <Section title="Property offering details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Rent"
                        />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                            placeholder="R"
                        />
                        <FormField
                            name="bayutValue"
                            label="Bayut value"
                            placeholder="Rent"
                        />
                        <FormField
                            name="transactionSourceContactTypes"
                            label="Transaction source contact types"
                            placeholder="Select types"
                            isMulti
                            component={ContactTypeSelect}
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
