import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'
import { yesNo } from 'crm-components/data-helpers'

const GET_LANGUAGES = /* GraphQL */ `
    query GetLanguages(
        $search: LanguageSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        languages(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Language {
                    _id
                    name
                    localeCode
                    createdAt
                    isDefault
                }
            }
        }
    }
`

const DELETE_LANGUAGES = /* GraphQL */ `
    mutation DeleteLanguages($ids: [ID!]!) {
        deleteLanguages(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    if (col.key === 'isDefault') {
        return yesNo({ value: row[col.key] })
    }
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Locale code',
                key: 'localeCode',
                sortKey: 'localeCode',
            },
            {
                name: 'Default?',
                key: 'isDefault',
                sortKey: 'isDefault',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_LANGUAGES}
            deleteQuery={DELETE_LANGUAGES}
            pageTitle={'Languages'}
            entity="language"
            cols={cols}
            record="Language"
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
