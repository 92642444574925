import React, { useContext, useEffect, useRef } from 'react'

import styled from 'styled-components'

import ContentLoader from 'react-content-loader'

import { useAdditionalCols } from './hooks'
import { ListContext } from './provider'
import { get } from 'lodash-es'

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const DEFAULT_ROWS = 7

export default () => {
    const [{ rows, loading, cols }] = useContext(ListContext)

    const [additionalCols] = useAdditionalCols()

    const lastRowsLength = useRef(get(rows, 'length'))

    const numberOfRows = lastRowsLength.current
        ? lastRowsLength.current
        : DEFAULT_ROWS

    useEffect(() => {
        if (get(rows, 'length')) {
            lastRowsLength.current = rows.length
        }
    }, [rows])

    if (!loading) return null

    return Array.from({ length: numberOfRows }).map((_, i) => (
        <tr key={i}>
            <td colSpan={cols.length + additionalCols}>
                <LoadingContainer>
                    <ContentLoader
                        speed={2}
                        width={'100%'}
                        height={17}
                        viewBox="0 0 800 50"
                        preserveAspectRatio="none"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="800"
                            height="50"
                        />
                    </ContentLoader>
                </LoadingContainer>
            </td>
        </tr>
    ))
}
