import React from 'react'

import RadioGroup from 'ui-components/radio-group'

import CollectionFetcher from 'crm-components/collection-fetcher'

export default props => {
    return (
        <CollectionFetcher
            {...props}
            render={props => <RadioGroup {...props} />}
        />
    )
}
