import React, { useContext } from 'react'

import styled from 'styled-components'

import Loader from './loader'

import { get, isEmpty } from 'lodash-es'

import { ListContext } from './provider'

import Row from './row'

import { useAdditionalCols } from './hooks'

const NoResultsContainer = styled.div`
    text-align: left;
`

export default () => {
    const [{ loading, rows, cols }] = useContext(ListContext)

    const [additionalCols] = useAdditionalCols()

    return (
        <tbody>
            <Loader />

            {!loading &&
                (rows || []).map(row => <Row key={row._id} row={row} />)}
            {isEmpty(rows) && !loading && (
                <tr>
                    <td colSpan={get(cols, 'length') + additionalCols}>
                        <NoResultsContainer>
                            No results could be found
                        </NoResultsContainer>
                    </td>
                </tr>
            )}
        </tbody>
    )
}
