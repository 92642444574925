import React, { useCallback } from 'react'

import styled from 'styled-components'
import SaveStatus from './save-status'

const Option = styled.div`
    flex: 1;
    padding: 0.25rem 0.5rem;
    border: 1px solid
        ${props => (props.checked ? props.theme.primary : props.theme.dark)};
    margin: 0.25rem;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.8rem;
    background-color: ${props =>
        props.checked ? props.theme.primary : 'transperant'};
    user-select: none;
    color: ${props => (props.checked ? 'white' : props.theme.dark)};
    white-space: nowrap;
    text-align: center;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const Container = styled.div`
    margin: -0.25rem;
    display: flex;
    flex-wrap: wrap;
`

const Wrapper = styled.div`
    overflow: hidden;
`

const Actions = styled.div`
    display: flex;
    border: 2px solid ${props => props.theme.gray2};
    margin-bottom: 1rem;
`

const Action = styled.div`
    font-size: 0.8rem;
    color: ${props => (props.disabled ? props.theme.gray2 : props.theme.dark)};
    margin: 0.5rem;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`

const OuterWrapper = styled.div`
    margin-bottom: 1rem;
`

const Balloon = ({ option, checked, onClick, disabled }) => {
    return (
        <Option
            checked={checked}
            onClick={() => onClick(option)}
            disabled={disabled}
        >
            {option.name}
        </Option>
    )
}

const noopArray = []

export default ({
    options = noopArray,
    value: componentValue = noopArray,
    onChange,
    disabled,
    isDirty,
    showSaveStatus,
    onDiscard,
}) => {
    const value = componentValue || noopArray

    const isSelected = option => value.indexOf(option._id) > -1

    const toggleOption = useCallback(
        option => {
            onChange(previousValue => {
                const value = previousValue ? previousValue : noopArray

                const index = value.indexOf(option._id)

                if (index < 0) {
                    return [option._id, ...value]
                } else {
                    return value.filter(id => id !== option._id)
                }
            })
        },
        [onChange]
    )

    return (
        <OuterWrapper>
            <Actions>
                <Action
                    onClick={() => onChange(options.map(option => option._id))}
                    disabled={value.length === options.length || disabled}
                >
                    Select all
                </Action>
                <Action
                    disabled={value.length === 0 || disabled}
                    onClick={() => onChange([])}
                >
                    Select none
                </Action>
            </Actions>
            <Wrapper>
                <Container>
                    {options.map(option => {
                        return (
                            <Balloon
                                disabled={disabled}
                                key={option._id}
                                option={option}
                                checked={isSelected(option)}
                                onClick={toggleOption}
                            />
                        )
                    })}
                </Container>
            </Wrapper>
            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />
        </OuterWrapper>
    )
}
