import React, {
    createContext,
    useState,
    useMemo,
    useEffect,
    useContext,
    useCallback,
} from 'react'

import { Row, Col } from 'react-styled-flexboxgrid'

import DefaultCheckbox from 'ui-components/checkbox'

import { isEqual } from 'lodash-es'

import styled from 'styled-components'

import DefaultSaveStatus from 'ui-components/save-status'

import { useAcl } from 'system/account/acl'

import Section from 'ui-components/section'

import Input from 'ui-components/input'

import {
    pluralize,
    camelCaseToSentenceCase,
    empty,
    getDataObject,
    emptyValues,
    escapeRegexPattern,
} from 'crm-components/data-helpers'

import ContentLoader from 'react-content-loader'

import { useQuery } from 'system/network/graphql'

import EnumValuesSelect from 'crm-components/enum-values-select'

import { ClearSearch } from 'crm-components/search-actions'

const GET_DYNAMIC_PERMISSIONS = /* GraphQL */ `
    {
        permissions {
            code
            name
            description
            parentId
            parentCollection
            family
        }
    }
`

const Checkbox = styled(DefaultCheckbox)`
    margin: 0.5rem;
`

const PermissionTitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media ${props => props.theme.media.sm} {
        flex-direction: row;
    }
`

const PermissionTitle = styled.h2`
    text-transform: capitalize;
`

const PermissionSubTitle = styled.p``

const ContentLoaderContainer = styled.div`
    padding-bottom: 1rem;
`

const Method = styled.div`
    padding-left: 0;
`
const MethodRow = styled.div`
    padding: 1rem 0;
`

const Methods = styled.div`
    margin-bottom: 1rem;
`

const PermissionValue = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const SaveStatus = styled(DefaultSaveStatus)`
    margin: 1rem 0;
`

const PermissionDescription = styled.div`
    margin-bottom: 1rem;
`
const AccessModifiers = ({ permission, method, disabled }) => {
    const acl = useAcl()

    const label = getPermissionLabel({ permission })

    const togglePermission = useTogglePermission()

    const isPermissionSelected = useIsPermissionSelected()

    return (
        <>
            {Object.keys(acl.accessModifiers).map(key => {
                const accessModifier = acl.accessModifiers[key]
                const singlePermission = {
                    ...permission,
                    accessModifier,
                    method,
                }

                return (
                    <Checkbox
                        key={`${label}-${method}-${accessModifier}`}
                        label={accessModifier}
                        onChange={() => {
                            togglePermission(singlePermission)
                        }}
                        value={isPermissionSelected(singlePermission)}
                        disabled={disabled}
                    />
                )
            })}
        </>
    )
}

const SectionLoader = () => (
    <Section>
        <ContentLoaderContainer>
            <ContentLoader
                speed={2}
                width={'100%'}
                height={192}
                viewBox="0 0 800 170"
                preserveAspectRatio="none"
                backgroundColor="#eee"
                foregroundColor="#fff"
            >
                <rect x="0" y="20" rx="3" ry="3" width="200" height="20" />
                <rect x="0" y="60" rx="3" ry="3" width="200" height="20" />
                <rect x="0" y="100" rx="3" ry="3" width="200" height="20" />
                <rect x="450" y="20" rx="3" ry="3" width="800" height="20" />
                <rect x="450" y="60" rx="3" ry="3" width="800" height="20" />
                <rect x="450" y="100" rx="3" ry="3" width="800" height="20" />
            </ContentLoader>
        </ContentLoaderContainer>
    </Section>
)

const Permission = ({ permission, disabled }) => {
    const acl = useAcl()

    const label = getPermissionLabel({ permission })

    const count = useCountOfSectionPermissions({
        label,
    })

    const countablePermissions = pluralize('permission', count)

    const subTitle = `${count} ${countablePermissions}`

    const title = permission.name
        ? permission.name
        : permission.record
        ? camelCaseToSentenceCase(permission.record)
        : ''

    return (
        <>
            <PermissionTitleRow>
                <PermissionTitle>{title}</PermissionTitle>
                <PermissionSubTitle>{subTitle}</PermissionSubTitle>
            </PermissionTitleRow>

            {permission.description && (
                <PermissionDescription>
                    {permission.description}
                </PermissionDescription>
            )}
            <Methods>
                {Object.keys(acl.methods).map(methodKey => {
                    const method = acl.methods[methodKey]

                    return (
                        <MethodRow key={method}>
                            <Row middle="xs">
                                <Col xs={6}>
                                    <Method>{method}</Method>
                                </Col>
                                <Col xs={6}>
                                    <PermissionValue>
                                        <AccessModifiers
                                            key={method + '-accessModifiers'}
                                            permission={permission}
                                            method={method}
                                            disabled={disabled}
                                        />
                                    </PermissionValue>
                                </Col>
                            </Row>
                        </MethodRow>
                    )
                })}
            </Methods>
        </>
    )
}

function truthy(data) {
    return Object.keys(data).reduce((carry, key) => {
        if (data[key]) carry[key] = data[key]

        return carry
    }, {})
}

function equals(obj1, obj2) {
    return isEqual(truthy(obj1), truthy(obj2))
}

const PermissionFilter = () => {
    const [filter, setFilter] = useFilter()

    return (
        <Row>
            <Col xs={12} sm={6}>
                <Input
                    label="Search"
                    placeholder="Try approve..."
                    value={filter.name}
                    onChange={name => setFilter({ ...filter, name })}
                />
            </Col>
            <Col xs={12} sm={6}>
                <EnumValuesSelect
                    enumName="PermissionFamily"
                    label="Family"
                    onValueChange={family => setFilter({ ...filter, family })}
                    value={filter.family}
                />
            </Col>
            <Col xs={12} sm={6}>
                <ClearSearch
                    onClick={() => setFilter({ name: '', family: '' })}
                />
            </Col>
        </Row>
    )
}
const noopArray = []

const Permissions = ({ isDirty, showSaveStatus, onDiscard, disabled }) => {
    const [{ value, filteredPermissionList, permissionList }] = useContext(
        PermissionsContext
    )

    return empty(permissionList) ? (
        <SectionLoader />
    ) : (
        <Section title="Permissions">
            <PermissionFilter />
            {filteredPermissionList.map(permission => (
                <Permission
                    disabled={disabled}
                    key={getPermissionLabel({ permission })}
                    permission={permission}
                />
            ))}
            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />
        </Section>
    )
}

const defaultState = {
    filter: {
        name: '',
        family: '',
    },
    filteredPermissionList: [],
    permissionList: [],
    onChange: () => {},
    value: [],
}

const PermissionsContext = createContext(defaultState)

function getPermissionLabel({ permission }) {
    return permission.name || permission.record
}

function useFilter() {
    const [
        { filter, permissionList, filteredPermissionList },
        set,
    ] = useContext(PermissionsContext)

    useEffect(() => {
        if (!emptyValues(filter)) {
            set(state => ({
                ...state,
                filteredPermissionList: state.permissionList.filter(
                    permission => {
                        const permissionIsMatched = Object.keys(filter).reduce(
                            (match, filterKey) => {
                                if (empty(filter[filterKey])) return match

                                return (
                                    match &&
                                    permission[filterKey] &&
                                    permission[filterKey].match(
                                        new RegExp(
                                            escapeRegexPattern(
                                                filter[filterKey]
                                            ),
                                            'gi'
                                        )
                                    )
                                )
                            },
                            true
                        )

                        return permissionIsMatched
                    }
                ),
            }))
        } else {
            set(state => ({
                ...state,
                filteredPermissionList: state.permissionList,
            }))
        }
    }, [filter, set])

    useEffect(() => {
        if (
            emptyValues(filter) &&
            !empty(permissionList) &&
            empty(filteredPermissionList)
        ) {
            set(state => ({ ...state, filteredPermissionList: permissionList }))
        }
    }, [filter, set, permissionList, filteredPermissionList])

    const setFilter = useCallback(
        filter => set(state => ({ ...state, filter })),
        [set]
    )

    return [filter, setFilter]
}

function useTogglePermission() {
    const [state] = useContext(PermissionsContext)

    const { onChange } = state

    const isPermissionSelected = useCallback(({ permission, permissions }) => {
        return !!permissions.find(p => equals(p, permission))
    }, [])

    const togglePermission = useCallback(
        permission => {
            onChange(previousPermissions => {
                const permissions = previousPermissions || noopArray

                if (isPermissionSelected({ permission, permissions }))
                    return permissions.filter(p => !equals(permission, p))
                else {
                    return [...permissions, permission]
                }
            })
        },
        [onChange, isPermissionSelected]
    )

    return togglePermission
}

function useIsPermissionSelected() {
    const [state] = useContext(PermissionsContext)

    const { value } = state

    const permissions = value || noopArray

    const isPermissionSelected = useCallback(
        permission => {
            return !!permissions.find(p => equals(p, permission))
        },
        [permissions]
    )

    return isPermissionSelected
}

function useCountOfSectionPermissions({ label = '' }) {
    const [state] = useContext(PermissionsContext)

    const { value } = state

    const count = useMemo(() => {
        return (value || noopArray).filter(
            permission => getPermissionLabel({ permission }) === label
        ).length
    }, [value, label])

    return count
}
export default ({ onChange, value, ...rest }) => {
    const [state, set] = useState(defaultState)

    const { cacheValue } = useQuery({
        operation: {
            query: GET_DYNAMIC_PERMISSIONS,
        },
    })

    const permissionList = useMemo(
        () => getDataObject(cacheValue) || noopArray,
        [cacheValue]
    )

    useEffect(() => {
        set(state => ({
            ...state,
            permissionList,
        }))
    }, [set, permissionList])

    useEffect(() => {
        set(state => ({ ...state, value }))
    }, [value])

    useEffect(() => {
        set(state => ({ ...state, onChange }))
    }, [onChange])

    return (
        <PermissionsContext.Provider value={[state, set]}>
            <Permissions {...rest} />
        </PermissionsContext.Provider>
    )
}
