import React, { useState, useCallback, useEffect, useContext } from 'react'

import { useCan } from 'system/account/acl'
import { IoIosGitPullRequest } from 'react-icons/io'
import { MenuLabel, MenuItem } from 'ui-components/drop-down-menu'
import { operate } from 'system/network/graphql'

import UserSelect from 'crm-components/user-select'

import Modal from 'ui-components/modal'

import { empty, getDataObject } from 'crm-components/data-helpers'
import { successToast, errorToast } from 'ui-components/toast'
import { get } from 'lodash-es'
import pluralize from 'pluralize'
import { GraphQLContext } from 'graphql-react'

const ASSIGN_PROPERTY_PERMISSION = 'ASSIGN_PROPERTY'

const ASSIGN_PROPERTY_QUERY = /* GraphQL */ `
    mutation AssignProperties($propertyIds: [ID], $userId: ID) {
        assignProperties(propertyIds: $propertyIds, userId: $userId) {
            affectedIds
            unAffectedIds
        }
    }
`

const Action = ({ propertyIds }) => {
    const [userId, setUserId] = useState()

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [menuIsOpen, setMenuIsOpen] = useState(true)

    const close = useCallback(() => {
        setModalIsOpen(false)
        setMenuIsOpen(false)
    }, [])

    const openModal = useCallback(() => setModalIsOpen(true), [])

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState()

    const graphql = useContext(GraphQLContext)

    useEffect(() => {
        if (!empty(userId)) setError(null)
    }, [userId])

    const propertyLabel = pluralize(`property`, propertyIds.length)

    const modalTitle =
        propertyIds.length > 1
            ? `Assign ${propertyIds.length} ${propertyLabel}`
            : `Assignment`

    const onAffirmativeClose = useCallback(async () => {
        if (empty(userId)) {
            return setError({
                message: `You must select a user`,
            })
        }

        setLoading(true)

        const { cacheValuePromise } = operate({
            operation: {
                query: ASSIGN_PROPERTY_QUERY,
                variables: {
                    userId,
                    propertyIds,
                },
            },
        })

        const cacheValue = await cacheValuePromise

        const data = getDataObject(cacheValue)

        const affectedLength = get(data, 'affectedIds.length')

        if (!affectedLength) {
            errorToast(`Cannot assign ${propertyLabel}`)
        } else if (affectedLength === propertyIds.length) {
            successToast(`Assigned successfully`)
        } else {
            successToast(`Assigned some of the ${propertyLabel} successfully`)
        }

        if (affectedLength) {
            graphql.reload()
        }

        setLoading(false)

        close()
    }, [close, userId, graphql, propertyIds, propertyLabel])

    return (
        <>
            <MenuLabel>Assignment</MenuLabel>
            <MenuItem
                icon={IoIosGitPullRequest}
                onClick={openModal}
                closeOnClick={false}
                open={menuIsOpen}
                disabled={empty(propertyIds)}
            >
                Assign
            </MenuItem>
            <Modal
                title={modalTitle}
                open={modalIsOpen}
                onClose={close}
                loading={loading}
                disabled={loading}
                affirmativeText="Assign"
                onAffirmativeClose={onAffirmativeClose}
            >
                <UserSelect
                    label="User"
                    value={userId}
                    onValueChange={setUserId}
                    error={error}
                />
            </Modal>
        </>
    )
}

export default ({ propertyIds }) => {
    const can = useCan({
        code: ASSIGN_PROPERTY_PERMISSION,
    })

    if (!can) return null

    return <Action propertyIds={propertyIds} />
}
