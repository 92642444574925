import React from 'react'
import { get } from 'lodash-es'
import { GiHouseKeys } from 'react-icons/gi'
import { FiFileText } from 'react-icons/fi'

import CollectionIconBoxSelector from 'crm-components/collection-icon-box-selector'

export default props => {
    return (
        <CollectionIconBoxSelector
            loaderCount={2}
            queryName="propertyOfferings"
            recordName="PropertyOffering"
            renderIcon={option => {
                const name = (get(option, 'name') || '').toLowerCase()
                if (name === 'sale') {
                    return <GiHouseKeys />
                } else if (name === 'rent') {
                    return <FiFileText />
                }
            }}
            {...props}
        />
    )
}
