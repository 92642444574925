import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    useRef,
    useLayoutEffect,
    useCallback,
} from 'react'

import styled, { css } from 'styled-components'

import { Link, useRouteMatch } from 'react-router-dom'

import { isEmpty, get, isFunction } from 'lodash-es'

import { useQueryParam, StringParam } from 'use-query-params'

import { slugify } from 'crm-components/data-helpers'

import { useIsMobile } from 'system/ui/match-media'

const TabsContext = createContext()

const DEFAULT_STATE = {
    items: [],
    activeTabSlug: null,
}

const StyledTabs = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    position: relative;
    user-select: none;
    overflow-x: scroll;
    min-height: 38px;
    &::-webkit-scrollbar {
        /*Chrome, Safari, Edge*/
        display: none;
    }
`

const StyledTab = styled.li`
    flex: 1;
    z-index: 1;
    position: relative;
    max-width: 100px;

    @media ${props => props.theme.media.sm} {
        max-width: 200px;
    }
`

const TabStyles = css`
    display: block;
    white-space: nowrap;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
    background-color: ${props =>
        props.active ? props.theme.primary : props.theme.gray};
    color: ${props => (props.active ? props.theme.light : props.theme.gray2)};
    text-decoration: none;
    outline: 0;
    width: 100px;

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: ${props => props.theme.light};
    }

    @media ${props => props.theme.media.sm} {
        width: unset;
    }

    @media (hover) {
        &:hover {
            background-color: ${props =>
                props.active ? props.theme.primary : props.theme.primaryFaded};
        }
    }
`

const TabLink = styled(Link)`
    ${TabStyles}
`

const TabDiv = styled.div`
    ${TabStyles}
`

const TabsLine = styled.div`
    background-color: ${props => props.theme.primary};
    height: 2px;
    margin-bottom: 0.5rem;
`

function scrollTo(el) {
    const elLeft = el.offsetLeft + el.offsetWidth
    const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth

    // check if element not in view
    if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
        // el.parentNode.scrollLeft = elLeft - elParentLeft
        el.parentNode.scrollLeft = elLeft
    } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
        el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft
    }
}

const Tabs = ({ onActiveTabIndexChange, shouldUseQueryParam = false }) => {
    const [state, set] = useContext(TabsContext)

    const { url } = useRouteMatch()

    const [activeTabSlugQueryParam, setActiveTabSlugQueryParam] = useQueryParam(
        'tab',
        StringParam
    )

    const [activeTabSlugState, setActiveTabSlugState] = useState('')

    const activeTabSlug = shouldUseQueryParam
        ? activeTabSlugQueryParam
        : activeTabSlugState

    const setActiveTabSlug = useCallback(
        value => {
            shouldUseQueryParam
                ? setActiveTabSlugQueryParam(value)
                : setActiveTabSlugState(value)
        },
        [shouldUseQueryParam, setActiveTabSlugQueryParam]
    )

    const tabsContainer = useRef()

    const isMobile = useIsMobile()

    useEffect(() => {
        set(state => {
            const itemFound = state.items.find(
                title => slugify(title) === activeTabSlug
            )

            let slug = itemFound ? activeTabSlug : slugify(state.items[0])

            return { ...state, activeTabSlug: slug }
        })
    }, [activeTabSlug, set, state.items])

    useEffect(() => {
        const title = get(state, 'items.0')

        if (isEmpty(activeTabSlug) && !isEmpty(title))
            setActiveTabSlug(slugify(title))
    }, [activeTabSlug, setActiveTabSlug, state])

    useEffect(() => {
        if (isFunction(onActiveTabIndexChange)) {
            onActiveTabIndexChange(
                state.items.indexOf(
                    state.items.find(item => slugify(item) === activeTabSlug)
                )
            )
        }
    }, [activeTabSlug, state.items, onActiveTabIndexChange])

    useLayoutEffect(() => {
        if (!isMobile) return

        const parent = tabsContainer.current

        const activeTab = parent.querySelector('[active="1"]')

        if (!activeTab) {
            return
        }

        scrollTo(activeTab.parentNode)
    }, [state.activeTabSlug, isMobile])

    const TabComponent = shouldUseQueryParam ? TabLink : TabDiv

    return (
        <>
            <StyledTabs ref={tabsContainer}>
                {state.items.map(tab => (
                    <StyledTab key={tab}>
                        <TabComponent
                            to={`${url}?tab=` + slugify(tab)}
                            onClick={() =>
                                !shouldUseQueryParam &&
                                setActiveTabSlug(slugify(tab))
                            }
                            active={
                                slugify(tab) === state.activeTabSlug ? 1 : 0
                            }
                        >
                            {tab}
                        </TabComponent>
                    </StyledTab>
                ))}
            </StyledTabs>
            <TabsLine />
        </>
    )
}

export default ({ children, ...props }) => {
    return (
        <TabsContext.Provider value={useState(DEFAULT_STATE)}>
            <Tabs {...props} />
            {children}
        </TabsContext.Provider>
    )
}

export const Tab = ({ title, children }) => {
    const [state, set] = useContext(TabsContext)

    useEffect(() => {
        set(state => ({ ...state, items: [...state.items, title] }))
        return () => {
            set(state => {
                const items = state.items.filter(item => item.title !== title)
                return { ...state, items }
            })
        }
    }, [title, set])

    return state.activeTabSlug === slugify(title) && children
}
