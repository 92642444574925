import React, { useCallback, useMemo } from 'react'

import { useDropzone } from 'react-dropzone'

import styled from 'styled-components'

import Button from 'ui-components/button'

import { isFunction } from 'lodash-es'

import DefaultLoader from 'ui-components/loader'

const Container = styled.div`
    border: 2px dashed ${props => props.theme.gray2};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    outline: 0;
    margin-bottom: 1rem;
    background-color: ${props =>
        props.dragging ? props.theme.gray2 : 'transparent'};
    position: relative;
`

const Text = styled.p`
    margin: 0 0 1rem 0;
    color: ${props => (props.dragging ? props.theme.light : props.theme.gray2)};
`

const LoadingContainer = styled.div`
    background-color: ${props => props.theme.gray};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Loader = styled(DefaultLoader).attrs({ slow: true })`
    font-size: 2rem;
    color: ${props => props.theme.gray2};
`

export default ({ onChange, disabled, loading }) => {
    const onDrop = useCallback(
        files => {
            if (isFunction(onChange)) onChange(files)
        },
        [onChange]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const rootProps = useMemo(() => (disabled ? {} : getRootProps()), [
        getRootProps,
        disabled,
    ])

    const dragging = useMemo(() => !disabled && isDragActive, [
        disabled,
        isDragActive,
    ])

    return (
        <Container dragging={dragging} {...rootProps}>
            <input {...getInputProps()} disabled={disabled} />

            {loading ? (
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            ) : null}

            <Text dragging={dragging}>
                {dragging ? 'Drop here...' : 'Drag & drop to upload...'}
            </Text>

            <Button disabled={disabled}>Upload</Button>
        </Container>
    )
}
