import React from 'react'

import Provider from './provider'

import Page from './page'

export default props => {
    return (
        <Provider {...props}>
            <Page />
        </Provider>
    )
}
