import { isEmpty, isEqual } from 'lodash-es'

import { useData, useMutationCacheValue } from './fields'

import { getDataObject } from 'crm-components/data-helpers'

export default () => {
    const [data] = useData()

    const [mutationCacheValue] = useMutationCacheValue()

    const mutationData = getDataObject(mutationCacheValue)

    return [!isEmpty(mutationData) && isEqual(mutationData, data)]
}
