import {
    empty,
    getDataObject,
    getGraphqlErrors,
    prepareFormData,
} from 'crm-components/data-helpers'
import { GraphQLContext } from 'graphql-react'
import useBindPropContextValue from 'helpers/use-bind-prop-context-value'

import { get, isFunction } from 'lodash-es'
import { useMemo, useContext, useCallback, useRef, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useMutation, useQuery } from 'system/network/graphql'
import { successToast } from 'ui-components/toast'
import { FormContext } from '../provider'

import { excludeFields } from 'crm-components/form'

import {
    useAutoRedirectToUpdateAfterCreate,
    useCloseAfterSave,
    useExcludeFromInput,
    useFormId,
    useGraphQLMutationString,
    useGraphQLQueryString,
    useIsSuccess,
    useMutationCacheValue,
    useMutationLoad,
    useMutationVariableName,
    useNoQueryVariables,
    useQueryCacheValue,
    useRedirectTo,
    useFormData,
    useOnFetchListeners,
} from './fields'

const noopObject = {}

const useOnSuccess = () => {
    const [closeAfterSave] = useCloseAfterSave()

    const graphql = useContext(GraphQLContext)

    const listUrl = useListUrl()

    const [
        autoRedirectToUpdateAfterCreate,
    ] = useAutoRedirectToUpdateAfterCreate()

    const [id] = useFormId()

    const history = useHistory()

    const onSuccess = useCallback(
        ({ cacheValue }) => {
            successToast('Saved successfully')

            graphql.reload()

            let redirect

            if (closeAfterSave) {
                redirect = listUrl
            } else if (autoRedirectToUpdateAfterCreate) {
                const _id = get(getDataObject(cacheValue), '_id')

                if (empty(id)) {
                    redirect = listUrl + '/' + _id + window.location.search
                }
            }

            if (redirect) {
                setTimeout(() => history.push(redirect), 500) // allow react to update child components before redirect.
            }
        },
        [
            closeAfterSave,
            autoRedirectToUpdateAfterCreate,
            history,
            id,
            graphql,
            listUrl,
        ]
    )

    return onSuccess
}

export const useBindOnSuccess = () => {
    const onSuccessCalled = useRef(false)

    const onSuccess = useOnSuccess()

    const [isSuccess] = useIsSuccess()

    const shouldCallOnSuccess = useCallback(
        ({ cacheValue }) => {
            if (isFunction(isSuccess)) return isSuccess(cacheValue)

            const _id = get(getDataObject(cacheValue), '_id')

            const errors = getGraphqlErrors(cacheValue)

            return !empty(_id) && empty(errors)
        },
        [isSuccess]
    )

    const onFetch = useCallback(
        ({ cacheValue }) => {
            if (shouldCallOnSuccess({ cacheValue })) {
                console.log('calling on success')
                onSuccess({ cacheValue })
            }
        },
        [shouldCallOnSuccess, onSuccess]
    )

    useRegisterOnFetchListener(onFetch)
}

export const useOnSave = () => {
    const [mutationVariableName] = useMutationVariableName()

    const [load] = useMutationLoad()

    const [data] = useFormData()

    const [excludeFromInput] = useExcludeFromInput()

    const save = useCallback(() => {
        load({
            [mutationVariableName]: prepareFormData(
                excludeFields({
                    data,
                    excludeFromInput,
                })
            ),
        })
    }, [data, load, mutationVariableName, excludeFromInput])

    return save
}

export const useRemoteData = () => {
    const [mutationCacheValue] = useMutationCacheValue()

    const [queryCacheValue] = useQueryCacheValue()

    const remoteData = useMemo(() => {
        return (
            getDataObject(mutationCacheValue) ||
            getDataObject(queryCacheValue) ||
            noopObject
        )
    }, [mutationCacheValue, queryCacheValue])

    return remoteData
}

export const useListUrl = () => {
    const [redirectTo] = useRedirectTo()

    const { url } = useRouteMatch()

    const [id] = useFormId()

    const listUrl = useMemo(() => {
        return redirectTo
            ? redirectTo
            : url.replace('/' + id, '').replace('/create', '')
    }, [url, id, redirectTo])

    return listUrl
}

export const useFormQuery = () => {
    const [query] = useGraphQLQueryString()

    const [noQueryVariables] = useNoQueryVariables()

    const [id] = useFormId()

    const { loading, cacheValue } = useQuery({
        operation: {
            query,
            variables: noQueryVariables ? undefined : { id },
        },
        loadOnMount: noQueryVariables ? true : !empty(id),
    })

    useBindPropContextValue(
        { queryLoading: loading, queryCacheValue: cacheValue },
        FormContext
    )

    return { loading, cacheValue }
}

export const useFormMutation = () => {
    const [mutation] = useGraphQLMutationString()

    const [listeners] = useOnFetchListeners()

    const onFetch = useCallback(
        ({ cacheValue }) => {
            for (const cp of listeners) {
                cp({ cacheValue })
            }
        },
        [listeners]
    )

    const { loading, cacheValue, cacheKey, load } = useMutation({
        operation: {
            query: mutation,
        },
        onFetch,
    })

    useBindPropContextValue(
        {
            mutationLoading: loading,
            mutationCacheValue: cacheValue,
            mutationCacheKey: cacheKey,
            mutationLoad: load,
        },
        FormContext
    )

    return { loading, cacheValue, load }
}

export const useRegisterOnFetchListener = cp => {
    const [listeners] = useOnFetchListeners()

    useEffect(() => {
        if (!listeners.find(cp)) {
            listeners.push(cp)
        }

        return () => {
            listeners.splice(listeners.indexOf(cp), 1)
        }
    }, [cp, listeners])
}
