import React, { useContext, useCallback } from 'react'

import styled from 'styled-components'

import { ListContext } from './provider'

import { useToggleRowExpanded } from './hooks'

import { FiChevronRight } from 'react-icons/fi'

import { useIsMobile } from 'system/ui/match-media'

import { FiChevronDown } from 'react-icons/fi'

const ArrowDown = styled(FiChevronDown)`
    transform: rotate(${({ expanded }) => (expanded ? '180deg' : '0')});
`

const ExpandContainer = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: center;
    padding: 0.5rem;
    background-color: ${props => props.theme.gray};
    margin-top: 1rem;
    color: ${props => props.theme.dark};
`

const ExpandCellContainer = styled.div`
    color: ${props => props.theme.primary};
    transition: transform 0.2s ease;
    transform: ${props => (props.expanded ? `rotate(90deg)` : `rotate(0deg)`)};
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
`

const TableCell = styled.td`
    overflow: hidden;
`

export const MobileExpand = ({ row }) => {
    const isMobile = useIsMobile()

    const toggle = useToggleExpanded({ row })

    const { expanded } = useToggleRowExpanded({ row })

    const [{ expandable }] = useContext(ListContext)

    if (!isMobile || !expandable) return null

    return (
        <ExpandContainer onClick={toggle}>
            <ArrowDown expanded={expanded} />
        </ExpandContainer>
    )
}

export const DesktopExpand = ({ row }) => {
    const isMobile = useIsMobile()

    const toggle = useToggleExpanded({ row })

    const { expanded } = useToggleRowExpanded({ row })

    const [{ expandable }] = useContext(ListContext)

    if (!expandable || isMobile) return null

    return (
        <TableCell onClick={toggle} key={row._id + 'expand-cell'}>
            <ExpandCellContainer expanded={expanded}>
                <FiChevronRight />
            </ExpandCellContainer>
        </TableCell>
    )
}

// Helper hook, no need to add this to ./hooks
const useToggleExpanded = ({ row }) => {
    const { toggleExpandRow } = useToggleRowExpanded({ row })

    const toggleExpanded = useCallback(
        e => {
            e.stopPropagation()
            e.preventDefault()

            toggleExpandRow()
        },
        [toggleExpandRow]
    )

    return toggleExpanded
}
