import React, { useContext } from 'react'

import SEO from 'ui-components/seo'

import PageTitle from 'ui-components/page-title'

import Header from './header'

import SearchSection from './search-section'

import DataSection from './data-section'

import { ListContext } from './provider'

export default () => {
    const [{ pageTitle, record }] = useContext(ListContext)

    if (!record) console.error('record was not provided')

    // Will have to double check this and test this out accross all records
    // The backend should give the final word if a record can be written or no.
    // const accessModifier = 'ALL|COMPANY|TEAM'

    return (
        <>
            <SEO title={pageTitle} />

            <PageTitle>{pageTitle}</PageTitle>

            <Header />

            <SearchSection />

            <DataSection />
        </>
    )
}
