import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const FURNITURE_TYPE_FRAGMENT = /* GraphQL */ `
    fragment furnitureTypeFields on FurnitureType {
        _id
        name
        propertyFinderValue
        bayutValue
        createdAt
    }
`

const SAVE_FURNITURE_TYPE = /* GraphQL */ `
    mutation SaveFurnitureType($furnitureType: FurnitureTypeInput) {
        saveFurnitureType(furnitureType: $furnitureType) {
            ...furnitureTypeFields
        }
    }
    ${FURNITURE_TYPE_FRAGMENT}
`

const GET_FURNITURE_TYPE = /* GraphQL */ `
    query GetFurnitureType($id: ID!) {
        furnitureType(id: $id) {
            ...furnitureTypeFields
        }
    }
    ${FURNITURE_TYPE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_FURNITURE_TYPE}
            mutation={SAVE_FURNITURE_TYPE}
            mutationVariableName="furnitureType"
            record="FurnitureType"
        >
            <Tab title="Details">
                <Section title="Furniture type details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
