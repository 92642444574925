import React, { useCallback } from 'react'

import { get } from 'lodash-es'

import { LowerPriorityStyles } from './common'

import styled from 'styled-components'

import { FaCaretUp, FaCaretDown } from 'react-icons/fa'

import { useSort } from './hooks'

import Column from './column'

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &[disabled] {
        pointer-events: none;
    }
`

const HeaderCell = styled.th`
    ${LowerPriorityStyles}
`

const CaretIcon = styled.div`
    display: flex;
    overflow: hidden;
    opacity: ${props => (props.active ? 0.5 : 0.2)};
    color: ${props => props.theme.dark};
    margin-left: 0.5rem;
    flex-direction: column;
`

const SORT_ASC = 1

const SORT_DESC = -1

export default ({ col }) => {
    const [sort, setSort] = useSort()

    const sortKey = get(sort, 'key')

    const active = sortKey === col.sortKey

    const sortOrder = active ? get(sort, 'order') || SORT_ASC : SORT_ASC

    const onClick = useCallback(() => {
        if (active) {
            setSort({
                key: col.sortKey,
                order: sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
            })
        } else {
            setSort({
                key: col.sortKey,
                order: SORT_ASC,
            })
        }
    }, [setSort, active, sortOrder, col])

    return (
        <Column cell={HeaderCell} priority={col.priority}>
            <Container disabled={!col.sortKey} onClick={onClick}>
                <span>{col.name}</span>
                {col.sortKey && (
                    <CaretIcon active={active ? 1 : 0}>
                        {sortOrder === SORT_ASC && <FaCaretUp />}
                        {sortOrder === SORT_DESC && <FaCaretDown />}
                    </CaretIcon>
                )}
            </Container>
        </Column>
    )
}
