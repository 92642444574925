import React from 'react'

import { get } from 'lodash-es'

import CollectionSelect from 'crm-components/collection-select'

function mapProperty(property) {
    const defaultTitle = (property.marketingRecords || []).reduce(
        (defaultTitle, currentRecord) => {
            if (get(currentRecord, 'language.isDefault')) {
                return currentRecord.name
            }
            return defaultTitle
        },
        ''
    )

    const referenceCode = property.referenceCode || ''

    const separator = referenceCode && defaultTitle ? ' | ' : ''

    return {
        _id: property._id,
        name: `${referenceCode}${separator}${defaultTitle}`,
    }
}

export default ({ onChange, ...props }) => {
    return (
        <CollectionSelect
            {...props}
            onValueChange={onChange}
            label="Select property"
            placeholder="Reference code or marketing title"
            queryName="properties"
            recordName="Property"
            previewBaseLink="/property/"
            optionMapper={mapProperty}
            fragmentFields={
                /* GraphQL */ `_id, marketingRecords { name, content, language { isDefault }}, referenceCode`
            }
        />
    )
}
