import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'

import CollectionSelect from 'crm-components/collection-select'

import { empty, ucFirst, emptyValues } from 'crm-components/data-helpers'

import { get, isFunction, isEqual } from 'lodash-es'

import Input from 'ui-components/input'

function getString(obj, path) {
    return get(obj, path) || ''
}

export default ({ language, value, onChange }) => {
    const [template, setTemplate] = useState()

    const lastTemplate = useRef()

    const languageName = get(language, 'name')

    const templateId = useMemo(
        () => get(template, '_id') || value.marketingTemplateId,
        [value, template]
    )

    const updateValue = useCallback(
        data => {
            if (!isFunction(onChange)) return

            onChange(value => {
                return { ...value, ...data, languageId: language._id }
            })
        },
        [onChange, language._id]
    )

    const setName = useCallback(
        name => {
            updateValue({ name })
        },
        [updateValue]
    )

    const setContent = useCallback(
        content => {
            updateValue({ content })
        },
        [updateValue]
    )

    const setInitialLastTemplate = useCallback(
        options => {
            lastTemplate.current = options.find(
                option => option._id === templateId
            )
        },
        [templateId]
    )

    const content = useMemo(() => getString(value, 'content'), [value])

    const name = useMemo(() => getString(value, 'name'), [value])

    useEffect(() => {
        if (empty(template)) return

        const templateId = get(template, '_id')

        if (templateId === get(lastTemplate, 'current._id')) return

        onChange(value => {
            let newValue = { ...value, languageId: language._id }

            const name = getString(newValue, 'name')

            const content = getString(newValue, 'content')

            if (
                empty(name) ||
                name === getString(lastTemplate, 'current.name')
            ) {
                newValue.name = getString(template, 'name')
            }
            if (
                empty(content) ||
                content === getString(lastTemplate, 'current.content')
            ) {
                newValue.content = getString(template, 'content')
            }

            if (templateId) {
                newValue.marketingTemplateId = templateId
            }

            if (!emptyValues(newValue) && !isEqual(newValue, value)) {
                return newValue
            }

            return value
        })
    }, [template, onChange, language._id])

    useEffect(() => {
        lastTemplate.current = template
    }, [template])

    return (
        <>
            <CollectionSelect
                recordName="MarketingTemplate"
                queryName="marketingTemplates"
                label={`Marketing template ${language.name}`}
                placeholder={({ options }) =>
                    empty(options) ? 'No templates available.' : 'Please select'
                }
                search={{ languageId: language._id }}
                onChange={setTemplate}
                value={templateId}
                previewBaseLink="/marketing-template/"
                comment={({ options }) =>
                    empty(options)
                        ? `Please click on add new template`
                        : `${ucFirst(language.name)} templates are available`
                }
                fragmentFields={`
                    _id
                    name
                    content
                `}
                onLoadOptions={setInitialLastTemplate}
            />
            <Input
                type="text"
                value={name}
                onChange={setName}
                label={`Marketing name ${languageName}`}
                placeholder="Marketing name"
            />

            <Input
                type="textarea"
                value={content}
                onChange={setContent}
                label={`Marketing content ${languageName}`}
                placeholder="Marketing content"
            />
        </>
    )
}
