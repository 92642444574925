import React, { useMemo, useCallback } from 'react'

import { useEnums } from 'system/network/graphql'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'
import { get } from 'lodash-es'

const GET_DOCUMENT_TYPES = /* GraphQL */ `
    query GetDocumentTypes(
        $search: DocumentTypeSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        documentTypes(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on DocumentType {
                    _id
                    name
                    component
                    createdAt
                }
            }
        }
    }
`

const DELETE_DOCUMENT_TYPES = /* GraphQL */ `
    mutation DeleteDocumentTypes($ids: [ID!]!) {
        deleteDocumentTypes(ids: $ids)
    }
`

function getComponentDescription({ enums, name }) {
    const enumObj = enums.find(enumObj => enumObj.name === 'FileUploader')

    const enumValue = enumObj.enumValues.find(value => value.name === name)

    const description = get(enumValue, 'description') || ''

    return description
}

export default () => {
    const { enums } = useEnums()

    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Component',
                key: 'component',
                sortKey: 'component',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    const renderCell = useCallback(
        ({ row, col }) => {
            if (col.key === 'component') {
                return getComponentDescription({
                    enums,
                    name: row[col.key],
                })
            }
        },
        [enums]
    )

    return (
        <List
            getQuery={GET_DOCUMENT_TYPES}
            deleteQuery={DELETE_DOCUMENT_TYPES}
            pageTitle={'Document types'}
            entity="documentType"
            cols={cols}
            record="DocumentType"
            renderCell={renderCell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
