import React, { useCallback } from 'react'

import { TopBarLink, TopBarIcon } from 'system/layout/topbar'

import { FaUserCircle, FaBell } from 'react-icons/fa'

import { useAccount } from 'system/account'

import { get } from 'lodash-es'

import styled from 'styled-components'

import { getResizedUrl } from 'system/core/file'

import { useLogout } from 'system/account/acl'

const ProfileImage = styled.img`
    border-radius: 50%;
    max-width: 100%;
    transform: scale(1.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 16px;
    height: 16px;
    object-fit: cover;
    object-position: center;
`

export default () => {
    const { account } = useAccount()

    const profilePhoto = get(account, 'profile.photo')

    const profilePhotoSrc = getResizedUrl({ file: profilePhoto })

    const logout = useLogout()

    const doLogout = useCallback(() => logout({ push: '/login' }), [logout])

    return (
        <>
            <TopBarIcon
                render={() => <FaBell />}
                to="/notifications"
                sortOrder={1}
            />

            <TopBarIcon
                render={() =>
                    profilePhotoSrc ? (
                        <ProfileImage src={profilePhotoSrc} />
                    ) : (
                        <FaUserCircle />
                    )
                }
                to="/user-profile"
                sortOrder={2}
            />

            <TopBarLink
                render="Logout"
                onClick={doLogout}
                sortOrder={2}
                to="/login"
            />
        </>
    )
}
