import React, { useContext, useEffect, useRef, useState } from 'react'

import { ListContext } from './provider'

export default ({ cell: Cell, ...props }) => {
    const [{ loading, rows, cols }] = useContext(ListContext)

    const ref = useRef()

    const [boundingRect, setBoundingRect] = useState()

    useEffect(() => {
        const node = ref.current

        if (!node) return

        if (!loading && rows.length && cols.length) {
            setBoundingRect(ref.current.getBoundingClientRect())
        }
    }, [cols.length, rows.length, loading])

    useEffect(() => {
        if (!boundingRect) return

        const node = ref.current

        if (!node) return

        node.style.width = boundingRect.width + 'px'

        function resetWidth() {
            node.style.width = 'initial'
        }

        window.addEventListener('resize', resetWidth)

        return () => {
            window.removeEventListener('resize', resetWidth)
        }
    }, [boundingRect])
    return <Cell ref={ref} {...props} />
}
