import React, { useRef, useEffect, useCallback } from 'react'

import { isFunction } from 'lodash-es'

import styled from 'styled-components'

const QueryButton = styled.div`
    font-size: 0.8rem;
    padding: 0.5rem;
    margin-right: 1rem;
    background-color: ${props =>
        props.active ? props.theme.gray2 : props.theme.gray};
    display: flex;
    cursor: pointer;
    user-select: none;
    outline: 0;
    color: ${props => (props.active ? props.theme.light : 'inherit')};
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

export const Badge = styled.div`
    margin-left: 0.5rem;
    font-weight: 800;
    display: flex;
`

export default ({ onClick, isActive, children }) => {
    const filterRef = useRef()

    const clickHandler = useCallback(() => {
        if (isFunction(onClick)) onClick()
    }, [onClick])

    useEffect(() => {
        const elem = filterRef.current

        if (!elem) return

        const keyUpHandler = e => {
            if (e.key === 'Enter') clickHandler()
        }

        elem.addEventListener('keyup', keyUpHandler)

        return () => {
            elem.removeEventListener('keyup', keyUpHandler)
        }
    }, [clickHandler])

    return (
        <QueryButton
            ref={filterRef}
            tabIndex={0}
            onClick={clickHandler}
            active={isFunction(isActive) && isActive() ? 1 : 0}
        >
            {children}
        </QueryButton>
    )
}
