import React from 'react'

import List from './list'

import Form from './form'

import CRUDRouter from 'crm-components/crud-router'

import { MenuItem } from 'system/layout/sidebar'

import { FaUserCheck } from 'react-icons/fa'

export default () => {
    return (
        <>
            <MenuItem
                to="/role"
                render="Roles"
                icon={<FaUserCheck />}
                sortOrder={50}
                permission={{
                    record: 'Role',
                    method: 'WRITE',
                    accessModifier: 'ALL',
                }}
            />
            <CRUDRouter
                path="/role"
                list={List}
                form={Form}
                record="Role"
                accessModifier="ALL"
            />
        </>
    )
}
