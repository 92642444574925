import React, { createContext, useState } from 'react'

const initialState = {
    expanded: true,
}

export const SectionContext = createContext([initialState])

export default ({ children }) => {
    return (
        <SectionContext.Provider value={useState(initialState)}>
            {children}
        </SectionContext.Provider>
    )
}
