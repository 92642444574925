import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'
import { useHistory } from 'react-router-dom'

const GET_COUNTRIES = /* GraphQL */ `
    query GetCountries(
        $search: CountrySearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        countries(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Country {
                    _id
                    name
                    isoCode
                    dialCode
                    sortOrder
                    createdAt
                }
            }
        }
    }
`

const DELETE_COUNTRIES = /* GraphQL */ `
    mutation DeleteCountries($ids: [ID!]!) {
        deletecountries(ids: $ids)
    }
`

const Cell = ({ row, col }) => {
    if (col.key === 'sortOrder') return row[col.key] + ''
    return
}

export default () => {
    const history = useHistory()

    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'ISO Code',
                key: 'isoCode',
                sortKey: 'isoCode',
            },
            {
                name: 'Dial Code',
                key: 'dialCode',
                sortKey: 'dialCode',
            },
            {
                name: 'Sort order',
                key: 'sortOrder',
                sortKey: 'sortOrder',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    const actions = useMemo(
        () => [
            {
                name: 'Re-Order',
                onClick: () => {
                    history.push('/countries/re-order')
                },
            },
        ],
        [history]
    )

    return (
        <List
            getQuery={GET_COUNTRIES}
            deleteQuery={DELETE_COUNTRIES}
            pageTitle={'Countries'}
            entity="country"
            cols={cols}
            record="Country"
            renderCell={Cell}
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
            actions={actions}
        />
    )
}
