import React from 'react'

import List from './list'

import Form from './form'

import CRUDRouter from 'crm-components/crud-router'

import { MenuItem } from 'system/layout/sidebar'

import { GoFileSubmodule } from 'react-icons/go'

export default () => {
    return (
        <>
            <MenuItem
                to="/language"
                render="Language"
                icon={<GoFileSubmodule />}
                sortOrder={90}
                permission={{
                    record: 'Language',
                    method: 'WRITE',
                    accessModifier: 'ALL',
                }}
            />
            <CRUDRouter
                path="/language"
                list={List}
                form={Form}
                record="Language"
                accessModifier="ALL"
            />
        </>
    )
}
