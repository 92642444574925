import React from 'react'

import styled from 'styled-components'

import { isFunction } from 'lodash-es'

import { MdClose } from 'react-icons/md'

import ContentLoader from 'react-content-loader'

import { IoMdCloseCircleOutline } from 'react-icons/io'

import { Row, Col, Grid } from 'react-styled-flexboxgrid'

const Header = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
`

const CloseIcon = styled.div`
    display: flex;
    margin-left: auto;
    color: ${props => props.theme.gray2};
    font-size: 1.5rem;
    cursor: pointer;
`

const KeyValuePair = styled.div`
    margin-bottom: 0.5rem;
    line-height: 170%;
    text-align: left;
`

const DetailsKey = styled.span`
    color: ${props => props.theme.primary};
`

const ContentLoaderRow = styled.div`
    margin-bottom: 0.5rem;
`

const DetailsValue = styled.span``

const CloseQuickView = styled.div`
    color: ${props => props.theme.gray2};
    text-align: left;
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const LoaderContainer = styled.div`
    margin-top: 2.5rem;
`
const Icon = styled.div`
    display: flex;
    margin-right: 0.5rem;
`

const Footer = styled.div``

const QuickViewLoader = ({ loaderRows = 37 } = {}) => {
    const range = Array.from({ length: loaderRows }).map((_, i) => i)

    return (
        <LoaderContainer>
            <Row>
                {range.map(i => (
                    <Col xs={12} sm={4} lg={3} key={i}>
                        <ContentLoaderRow>
                            <ContentLoader
                                speed={2}
                                width={'100%'}
                                height={17}
                                viewBox="0 0 800 50"
                                preserveAspectRatio="none"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    rx="3"
                                    ry="3"
                                    width="800"
                                    height="50"
                                />
                            </ContentLoader>
                        </ContentLoaderRow>
                    </Col>
                ))}
            </Row>
        </LoaderContainer>
    )
}

const DefaultFieldsWrapper = ({ children }) => {
    return <Col xs={12}>{children}</Col>
}

const RowDetails = ({
    fields,
    close,
    beforeFieldsRender: BeforeFieldsRender,
    beforeFieldsProps = {},
    fieldsWrapper: FieldsWrapper = DefaultFieldsWrapper,
}) => {
    return (
        <>
            <Header>
                <CloseIcon onClick={close}>
                    <IoMdCloseCircleOutline />
                </CloseIcon>
            </Header>
            <Grid fluid>
                <Row>
                    {isFunction(BeforeFieldsRender) ? (
                        <BeforeFieldsRender
                            fields={fields}
                            {...beforeFieldsProps}
                        />
                    ) : null}
                    <FieldsWrapper>
                        <Row>
                            {fields.map(keyValuePair => (
                                <Col
                                    xs={12}
                                    sm={4}
                                    lg={3}
                                    key={keyValuePair.key}
                                >
                                    <KeyValuePair>
                                        <DetailsKey>
                                            {keyValuePair.name}:{' '}
                                        </DetailsKey>
                                        <DetailsValue>
                                            {keyValuePair.value}
                                        </DetailsValue>
                                    </KeyValuePair>
                                </Col>
                            ))}
                        </Row>
                    </FieldsWrapper>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Footer>
                            <CloseQuickView onClick={close}>
                                <Icon>
                                    <MdClose />
                                </Icon>
                                Close quick view
                            </CloseQuickView>
                        </Footer>
                    </Col>
                </Row>
            </Grid>
        </>
    )
}

export default ({
    fields,
    close,
    loading,
    beforeFieldsRender,
    loaderRows,
    beforeFieldsProps,
    fieldsWrapper,
}) => {
    const showLoader = loading || !fields

    return showLoader ? (
        <QuickViewLoader />
    ) : (
        <RowDetails
            fields={fields}
            close={close}
            beforeFieldsRender={beforeFieldsRender}
            loaderRows={loaderRows}
            beforeFieldsProps={beforeFieldsProps}
            fieldsWrapper={fieldsWrapper}
        />
    )
}
