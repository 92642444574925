import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const BATHROOM_FRAGMENT = /* GraphQL */ `
    fragment bathroomFields on Bathroom {
        _id
        name
        propertyFinderValue
        bayutValue
        createdAt
    }
`

const SAVE_BATHROOM = /* GraphQL */ `
    mutation SaveBathroom($bathroom: BathroomInput) {
        saveBathroom(bathroom: $bathroom) {
            ...bathroomFields
        }
    }
    ${BATHROOM_FRAGMENT}
`

const GET_BATHROOM = /* GraphQL */ `
    query GetBathroom($id: ID!) {
        bathroom(id: $id) {
            ...bathroomFields
        }
    }
    ${BATHROOM_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_BATHROOM}
            mutation={SAVE_BATHROOM}
            mutationVariableName="bathroom"
            record="Bathroom"
        >
            <Tab title="Details">
                <Section title="Bathroom details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
