import React, { useState, createContext } from 'react'

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    body.is-modal-open {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
  }
`

const DEFAULT_STATE = {
    confirmModal: {
        open: false,
        title: 'Confirmation',
        message: '',
        loading: false,
        closesImmediately: true,
    },
}

export const ModalContext = createContext(DEFAULT_STATE)

export default ({ children }) => {
    return (
        <>
            <GlobalStyle />
            <ModalContext.Provider value={useState(DEFAULT_STATE)}>
                {children}
            </ModalContext.Provider>
        </>
    )
}
