import React from 'react'

import SearchField from 'crm-components/list/search-field'

import ContactSelect from 'crm-components/contact-select'

// import { CONTACT_TYPE_OWNER } from 'modules/contact/delete/constants'

import DefaultLocationSelect from 'crm-components/location-select'

const LocationSelect = ({ onChange, ...props }) => {
    return <DefaultLocationSelect onValueChange={onChange} {...props} />
}

export default () => {
    return (
        <>
            <SearchField name="referenceCode" label="Reference Code" />
            <SearchField name="title" label="Title" />
            <SearchField
                name="locationId"
                label="Location"
                component={LocationSelect}
            />
            <SearchField
                name="categoryId"
                type="collectionSelect"
                queryName="propertyCategories"
                recordName="PropertyCategory"
                label="Category"
            />
            <SearchField
                name="propertyOfferingId"
                type="collectionSelect"
                queryName="propertyOfferings"
                recordName="PropertyOffering"
                label="Offering"
            />
            <SearchField
                name="propertyTypeId"
                type="collectionSelect"
                queryName="propertyTypes"
                recordName="PropertyType"
                label="Type"
            />
            <SearchField name="size" type="numberRange" label="Size (sqft)" />
            <SearchField
                name="numberOfBedroomsId"
                type="collectionSelect"
                label="Bedroom"
                queryName="bedrooms"
                recordName="Bedroom"
            />
            <SearchField
                name="numberOfBathroomsId"
                type="collectionSelect"
                label="Bathroom"
                queryName="bathrooms"
                recordName="Bathroom"
            />
            <SearchField
                name="projectStatusId"
                type="collectionSelect"
                label="Project Status"
                queryName="projectStatuses"
                recordName="ProjectStatus"
            />

            <SearchField name="price" type="numberRange" label="Price (AED)" />

            <SearchField
                name="ownerId"
                component={ContactSelect}
                label="Owner"
                showAddLink={false}
            />

            <SearchField
                name="createdAt"
                label="Date created"
                type="dateRangePicker"
            />
        </>
    )
}
