import React, {
    useState,
    useContext,
    createContext,
    useEffect,
    useMemo,
} from 'react'

import styled, { css } from 'styled-components'

import { FiMoreHorizontal as MenuIcon } from 'react-icons/fi'

import { Link } from 'react-router-dom'

import { isFunction } from 'lodash-es'

const Wrapper = styled.div`
    position: relative;
    display: flex;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    outline: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: ${props =>
        props.open ? props.theme.gray2 : props.theme.gray};
    border: 0;
    cursor: pointer;
    height: 30px;
    width: 30px;
    padding: 0;
    font-size: 1.5rem;
    z-index: 3;
    color: ${props => (props.open ? props.theme.gray : props.theme.gray2)};
    transition: all 0.2s ease;
    &:focus {
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const MenuContainer = styled.div`
    position: absolute;
    top: ${props => props.position.top};
    right: ${props => props.position.right};
    left: ${props => props.position.left};
    border: 1px solid ${props => props.theme.gray2};
    background-color: white;
    z-index: 3;
    display: flex;
    flex-direction: column;
    width: max-content;
    padding: 0.3rem 0;
    max-width: 250px;
    overflow: hidden;
`

const StyledMenuLabel = styled.div`
    font-size: 0.7rem;
    padding: 0.3rem 0.5rem;
    text-transform: uppercase;
    color: ${props => props.theme.gray2};
    user-select: none;
    width: max-content;
`

const StyledMenuItem = styled.div`
    position: relative;
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
    user-select: none;
    cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
    opacity: ${props => (props.disabled ? 0.2 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    min-width: max-content;
    display: flex;
    padding-left: ${props =>
        props.emptyIcon ? `calc(1rem + 12.8px)` : `0.5rem`};
    &:hover {
        background-color: ${props => props.theme.gray};
    }
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
`

const LinkStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
`

const RouterLink = styled(Link)`
    ${LinkStyles}
`

const NormalLink = styled.a`
    ${LinkStyles}
`

const IconContainer = styled.div`
    color: ${props => props.theme.gray2};
    display: flex;
    margin-right: 0.5rem;
    width: 20px;
    align-items: center;
    overflow: hidden;
    font-size: 1rem;
`

const DEFAULT_STATE = {
    open: false,
}

function useOpen() {
    const [state, setState] = useContext(DropdownMenuContext)
    return [state.open, open => setState(state => ({ ...state, open }))]
}

const Menu = ({ children, position }) => {
    const [open] = useOpen()

    return open && <MenuContainer position={position}>{children}</MenuContainer>
}

const ActionButton = ({ icon: Icon }) => {
    const [open, setOpen] = useOpen()

    return (
        <Button open={open ? 1 : 0} onClick={() => setOpen(!open)}>
            <Icon />
        </Button>
    )
}

const DropdownMenuContext = createContext()

export default ({ children, position, icon = MenuIcon }) => {
    const [state, setState] = useState(DEFAULT_STATE)

    useEffect(() => {
        const handleKeyUp = e => {
            if (e.key === 'Escape')
                setState(state => ({ ...state, open: false }))
        }

        document.addEventListener('keyup', handleKeyUp)

        return () => document.removeEventListener('keyup', handleKeyUp)
    }, [])

    const positionVariation = useMemo(() => {
        const top = { top: `calc(100% + 0.5rem)` }

        const right = { right: 0, left: 'initial' }

        const left = { left: 0, right: 'initial' }

        if (!position) {
            return {
                ...top,
                ...right,
            }
        }

        switch (position) {
            case 'left':
                return { ...top, ...left }
            case 'right':
                return { ...top, right }
            default:
                return { ...top, right }
        }
    }, [position])

    return (
        <DropdownMenuContext.Provider value={[state, setState]}>
            <Wrapper>
                <ActionButton icon={icon} />
                <Menu position={positionVariation}>{children}</Menu>
            </Wrapper>
            {state.open && (
                <Overlay
                    onClick={() =>
                        setState(state => ({ ...state, open: false }))
                    }
                />
            )}
        </DropdownMenuContext.Provider>
    )
}

export const MenuItem = ({
    children,
    onClick,
    hidden = false,
    disabled,
    state,
    to,
    target,
    href,
    emptyIcon,
    icon: Icon,
    closeOnClick = true,
    open: defaultOpen = undefined,
    className,
}) => {
    const [open, setOpen] = useOpen()

    const MenuItemLink = useMemo(() => {
        if (to) {
            return () => <RouterLink to={to} target={target} />
        }

        if (href) {
            return () => <NormalLink href={href} target={target} />
        }

        return () => null
    }, [to, href, target])

    useEffect(() => {
        if (typeof defaultOpen !== 'undefined' && defaultOpen !== open)
            setOpen(defaultOpen)
    }, [defaultOpen, open, setOpen])

    if (hidden) {
        return null
    }

    return (
        <StyledMenuItem
            className={className}
            emptyIcon={emptyIcon}
            disabled={disabled}
            onClick={() => {
                if (closeOnClick) setOpen(false)

                if (isFunction(onClick)) {
                    onClick(state)
                }
            }}
        >
            {Icon && (
                <IconContainer>
                    <Icon />
                </IconContainer>
            )}
            {children}
            <MenuItemLink />
        </StyledMenuItem>
    )
}

export const MenuLabel = ({ children }) => {
    return <StyledMenuLabel>{children}</StyledMenuLabel>
}
