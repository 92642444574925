import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import HelpSection from 'ui-components/help-section'

import Users from './users'

import { get } from 'lodash-es'

import { pluralize } from 'crm-components/data-helpers'

const TEAM_FRAGMENT = /* GraphQL */ `
    fragment teamFields on Team {
        _id
        name
        users {
            _id
            email
            profile {
                firstName
                lastName
            }
            role {
                name
            }
        }
        userIds
        activityIds
        documentIds
    }
`

const SAVE_TEAM = /* GraphQL */ `
    mutation SaveTeam($team: TeamInput) {
        saveTeam(team: $team) {
            ...teamFields
        }
    }
    ${TEAM_FRAGMENT}
`

const GET_TEAM = /* GraphQL */ `
    query GetTeam($id: ID!) {
        team(id: $id) {
            ...teamFields
        }
    }
    ${TEAM_FRAGMENT}
`

const excludeFromInput = ['users']

export default () => {
    const { id } = useParams()

    const [users, setUsers] = useState([])

    const count = get(users, 'length') || 0

    return (
        <CRUDForm
            query={GET_TEAM}
            mutation={SAVE_TEAM}
            mutationVariableName="team"
            excludeFromInput={excludeFromInput}
            record="Team"
        >
            <Tab title="Details">
                <HelpSection>
                    Teams help you manage the access rights of your company
                    users. For example, a manager can view all properties
                    submitted by any user of their team. Each team may have a
                    single Manager.
                </HelpSection>
                <Section title="Team details">
                    <FormFields>
                        <FormField name="name" type="text" label="Name" />
                    </FormFields>
                </Section>
                <Section
                    title="Members"
                    subTitle={`${count} ${pluralize('user', count)}`}
                >
                    <FormFields>
                        <FormField
                            name="userIds"
                            component={Users}
                            fullWidth
                            onChange={setUsers}
                        />
                    </FormFields>
                </Section>
            </Tab>
            <Tab title="Activity">
                <Section title="Activities">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="activityIds"
                            type="activities"
                            parentCollection={'teams'}
                            parentId={id}
                            parentRecordName="Team"
                        />
                    </FormFields>
                </Section>
            </Tab>
            <Tab title="Documents">
                <Section title="Documents">
                    <FormFields>
                        <FormField
                            fullWidth
                            name="documentIds"
                            type="documents"
                            parentCollection={'teams'}
                            parentId={id}
                            parentRecordName="Team"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
