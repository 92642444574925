import React from 'react'

import Section from 'ui-components/section'

import CRUDForm, { FormField, FormFields, Tab } from 'crm-components/crud-form'

import Permissions from './permissions'

export const PERMISSION_FRAGMENT = /* GraphQL */ `
    fragment permissionFragment on Permission {
        code
        name
        description
        method
        accessModifier
        parentId
        parentCollection
        family
    }
`

const SAVE_ROLE = /* GraphQL */ `
    mutation SaveRole($role: RoleInput) {
        saveRole(role: $role) {
            _id
            name
            permissions {
                ...permissionFragment
            }
        }
    }
    ${PERMISSION_FRAGMENT}
`

const GET_ROLE = /* GraphQL */ `
    query GetRole($id: ID!) {
        role(id: $id) {
            _id
            name
            permissions {
                ...permissionFragment
            }
        }
    }
    ${PERMISSION_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_ROLE}
            mutation={SAVE_ROLE}
            mutationVariableName="role"
            record="Role"
        >
            <Tab title="Details">
                <Section title="Details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Role name"
                        />
                    </FormFields>
                </Section>
            </Tab>
            <Tab title="Permissions">
                <FormFields>
                    <FormField
                        component={Permissions}
                        name="permissions"
                        fullWidth
                    />
                </FormFields>
            </Tab>
        </CRUDForm>
    )
}
