import React, { useState, useEffect, createContext } from 'react'

const defaultListState = {
    loading: false,
    rows: [],
    cols: [],
    renderActions: undefined,
    renderCell: undefined,
    onChangeSelected: undefined,
    onSortChange: undefined,
    expandable: false,
    selectedRows: [],
    additionalCols: 2,
    renderExpandedRow: undefined,
    expandedRows: [],
}

const emptyArray = []

export const ListContext = createContext(defaultListState)

export default ({
    children,
    loading,
    rows = emptyArray,
    cols = emptyArray,
    renderActions = null,
    renderCell = null,
    onChangeSelected = null,
    sort = {},
    onSortChange = null,
    expandable = false,
    renderExpandedRow = null,
}) => {
    const providerState = useState(defaultListState)

    const [, set] = providerState

    useEffect(() => {
        set(state => ({
            ...state,
            loading,
            rows,
            cols,
            renderCell,
            renderActions,
            onChangeSelected,
            onSortChange,
            expandable,
            renderExpandedRow,
        }))
    }, [
        loading,
        rows,
        cols,
        renderCell,
        renderActions,
        onChangeSelected,
        onSortChange,
        expandable,
        set,
        renderExpandedRow,
    ])

    return (
        <ListContext.Provider value={providerState}>
            {children}
        </ListContext.Provider>
    )
}
