import React from 'react'

import QuickView from 'ui-components/quick-view'

import { useRowFields } from 'crm-components/label-value-maker'

import { formatValue } from 'crm-components/data-helpers'

export default ({ row, close }) => {
    const fields = useRowFields({
        row,
        ignoredKeyNames: ['_id', 'assignedTo', 'createdBy'],
        makeValue: ({ row, key }) => {
            if (key === 'officialDocumentExpiryDate') {
                const value = formatValue(row[key], { noTime: true })
                return value
            }
        },
    })

    return <QuickView close={close} fields={fields} />
}
