import styled from 'styled-components'

export default styled.h1`
    margin: 0;
    font-weight: 500;
    color: ${props => props.theme.dark};
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 100%;
`
