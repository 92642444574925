import React, { useContext, useEffect, useState } from 'react'

import DefaultButton from './button'

import { pluralize } from 'crm-components/data-helpers'

import styled from 'styled-components'

import { ThemeContext } from 'styled-components'

import { FiChevronsRight, FiChevronsLeft, FiChevronDown } from 'react-icons/fi'

import ContentLoader from 'react-content-loader'

export const PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY = 'convertProCrm:pagination'

const Wrapper = styled.div``

const List = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    user-select: none;
    margin: 0 -0.25rem;
`

const ListItem = styled.li`
    display: flex;
    &:nth-child(2) {
        display: ${props => (props.totalPages < 2 ? 'flex' : 'none')};
    }
    &:nth-child(6) {
        display: none;
    }
    @media ${props => props.theme.media.sm} {
        &:nth-child(2) {
            display: flex;
        }
        &:nth-child(6) {
            display: flex;
        }
    }
`

const Button = styled(DefaultButton).attrs({ borderRadius: 0 })`
    min-width: 35px;
    margin: 0;
`

const PageNumber = styled(Button)`
    text-align: center;
    text-decoration: ${props => (props.active ? 'underline' : 'none')};
`

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    user-select: none;
    min-height: 26px;
`

const PaginationText = styled.p`
    font-size: 0.8rem;
    color: ${props => props.theme.gray2};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    @media ${props => props.theme.media.sm} {
        margin: 0;
    }
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const PageSizeSelectContainer = styled.div`
    display: flex;
    position: relative;
`

const PageSizeSelect = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    --webkit-appearance: none;
    opacity: 0;
    z-index: 1;
    width: 100%;
`

const PageSizeText = styled.div`
    display: flex;
    align-items: center;
`

const PageSizeNumber = styled.div`
    padding: 0.25rem;
    display: flex;
    border: 1px solid ${props => props.theme.gray2};
    border-radius: 2px;
    margin: 0 0.5rem;
`

const PageSizeAndInfo = styled.div`
    display: flex;
    flex: 100%;
    align-items: center;
    flex-wrap: wrap;
    @media ${props => props.theme.media.sm} {
        flex: 1;
    }
`

const PageSizeSelectorWrapper = styled.div`
    display: flex;
    user-select: none;
    font-size: 0.8rem;
    color: ${props => props.theme.gray2};
    margin-right: 0.5rem;
    flex: 100%;
    @media ${props => props.theme.media.sm} {
        flex: initial;
    }
`

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const NUMBER_OF_BUTTONS = 5

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]

export const loadDefaultPageSize = () => {
    return isNaN(+localStorage[PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY])
        ? PAGE_SIZE_OPTIONS[0]
        : +localStorage[PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY]
}

const Loader = () => {
    return (
        <LoadingContainer>
            <ContentLoader
                speed={2}
                width={'100%'}
                height={17}
                viewBox="0 0 800 50"
                preserveAspectRatio="none"
                backgroundColor="var(--gray)"
                foregroundColor="var(--light)"
            >
                <rect x="0" y="0" rx="3" ry="3" width="800" height="50" />
            </ContentLoader>
        </LoadingContainer>
    )
}

const PageSizeSelector = ({
    disabled,
    pageSize: componentPageSize,
    onChange = () => {},
}) => {
    const [pageSize, _setPageSize] = useState(componentPageSize)

    useEffect(() => {
        localStorage[PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY] = pageSize
    }, [pageSize])

    useEffect(() => {
        if (componentPageSize) setPageSize(componentPageSize)
        // eslint-disable-next-line
    }, [componentPageSize])

    const setPageSize = pageSize => {
        const size = +pageSize
        _setPageSize(size)
        onChange(size)
    }

    return (
        <PageSizeSelectorWrapper>
            <PageSizeText>Page size: </PageSizeText>
            <PageSizeSelectContainer>
                <PageSizeNumber>
                    {pageSize}
                    <FiChevronDown />
                </PageSizeNumber>
                <PageSizeSelect
                    disabled={disabled}
                    onChange={({ target: { value: pageSize } }) =>
                        setPageSize(pageSize)
                    }
                    value={pageSize}
                >
                    {PAGE_SIZE_OPTIONS.map(size => (
                        <option key={size}>{size}</option>
                    ))}
                </PageSizeSelect>
            </PageSizeSelectContainer>
        </PageSizeSelectorWrapper>
    )
}

const pageNumberStyles = { borderRadius: 0 }

const PaginationElement = ({
    loading,
    totalPages,
    pageNumber, // current page number
    baseLink,
    onPageNumberChange = () => {},
}) => {
    const themeContext = useContext(ThemeContext)

    const updateCurrentPage = value => {
        onPageNumberChange(value)
    }

    if (totalPages < 1) return null

    let sliceStart = Math.max(pageNumber - Math.ceil(NUMBER_OF_BUTTONS / 2), 0)

    let sliceEnd = sliceStart + NUMBER_OF_BUTTONS

    return (
        <Wrapper>
            {loading && <Loader />}
            {!loading && (
                <List>
                    <ListItem>
                        <Button
                            link={baseLink}
                            disabled={pageNumber === 1 ? 1 : 0}
                            backgroundColor={themeContext.gray2}
                            onClick={() => {
                                !baseLink && updateCurrentPage(1)
                            }}
                            mini={1}
                            buttonStyles={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        >
                            <Icon>
                                <FiChevronsLeft />
                            </Icon>
                        </Button>
                    </ListItem>
                    {Array.from({ length: totalPages })
                        .map((_, i) => i)
                        .slice(sliceStart, sliceEnd)
                        .map(i => {
                            return (
                                <ListItem key={i} totalPages={totalPages}>
                                    <PageNumber
                                        buttonStyles={pageNumberStyles}
                                        to={
                                            baseLink
                                                ? i === 0
                                                    ? baseLink
                                                    : `${baseLink}/${i + 1}`
                                                : undefined
                                        }
                                        active={i + 1 === pageNumber ? 1 : 0}
                                        disabled={i + 1 === pageNumber ? 1 : 0}
                                        onClick={() => updateCurrentPage(i + 1)}
                                        backgroundColor={themeContext.gray2}
                                        mini={1}
                                    >
                                        {i + 1}
                                    </PageNumber>
                                </ListItem>
                            )
                        })}
                    <ListItem>
                        <Button
                            {...{
                                link: baseLink
                                    ? `${baseLink}/${totalPages}`
                                    : undefined,
                            }}
                            onClick={() => updateCurrentPage(totalPages)}
                            disabled={pageNumber === totalPages ? 1 : 0}
                            backgroundColor={themeContext.gray2}
                            mini={1}
                            buttonStyles={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            }}
                        >
                            <Icon>
                                <FiChevronsRight />
                            </Icon>
                        </Button>
                    </ListItem>
                </List>
            )}
        </Wrapper>
    )
}

export default ({
    pageSize,
    count,
    entity,
    loading,
    totalPages,
    pageNumber,
    baseLink,
    onChange = () => {},
    selectedRowsCount,
}) => {
    return (
        <PaginationContainer>
            <PageSizeAndInfo>
                <PageSizeSelector
                    pageSize={pageSize}
                    onChange={pageSize => {
                        onChange({
                            pageSize,
                            pageNumber,
                        })
                    }}
                />
                {!loading && (
                    <PaginationText>
                        {paginateText({
                            pageSize,
                            pageNumber,
                            totalPages,
                            entity,
                            count,
                        })}
                        .{' '}
                        {selectedRowsText({
                            selectedRowsCount,
                            entity,
                        })}
                    </PaginationText>
                )}
            </PageSizeAndInfo>
            <PaginationElement
                loading={loading}
                totalPages={totalPages}
                pageNumber={pageNumber}
                baseLink={baseLink}
                onPageNumberChange={pageNumber =>
                    onChange({ pageSize, pageNumber })
                }
            />
        </PaginationContainer>
    )
}

export const paginateText = ({
    pageSize,
    pageNumber,
    totalPages,
    count,
    entity,
}) => {
    if (+count === 0) {
        return ''
    }
    if (totalPages === 1) {
        return `Showing ${count} ${pluralize(entity, count)}`
    }

    if (isNaN(pageNumber) || isNaN(pageSize) || isNaN(count)) {
        return 'Showing from 0 to 0 out of 0 ' + pluralize(entity)
    }

    return `Showing from ${(pageNumber - 1) * pageSize + 1} to ${Math.min(
        pageNumber * pageSize,
        count
    )} out of ${count} ${pluralize(entity)}`
}

function selectedRowsText({ entity, selectedRowsCount }) {
    if (+selectedRowsCount === 0) return ''

    const selection = pluralize(entity, selectedRowsCount)

    return `Selected ${selectedRowsCount} ${selection}.`
}
