import useContextField from 'helpers/use-context-field'
import { useCallback } from 'react'

import { SectionContext } from './provider'

const nullFunction = () => null

export const useExpanded = () => {
    return useContextField('expanded', SectionContext)
}

export const useTitle = () => useContextField('title', SectionContext)

export const useToggleSection = () => {
    const [, set] = useExpanded()

    const toggle = useCallback(() => {
        set(oldValue => {
            return !oldValue
        })
    }, [set])

    return toggle
}

export const usePaddingBottom = () =>
    useContextField('paddingBottom', SectionContext)

export const useHiddenOverflow = () =>
    useContextField('hiddenOverFlow', SectionContext)

export const useSubTitle = () => useContextField('subTitle', SectionContext)

export const useIcon = () =>
    useContextField('icon', SectionContext, { defaultValue: nullFunction })
