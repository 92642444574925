import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const CHEQUE_FRAGMENT = /* GraphQL */ `
    fragment chequeFields on Cheque {
        _id
        name
        propertyFinderValue
        bayutValue
        createdAt
    }
`

const SAVE_CHEQUE = /* GraphQL */ `
    mutation SaveCheque($cheque: ChequeInput) {
        saveCheque(cheque: $cheque) {
            ...chequeFields
        }
    }
    ${CHEQUE_FRAGMENT}
`

const GET_CHEQUE = /* GraphQL */ `
    query GetCheque($id: ID!) {
        cheque(id: $id) {
            ...chequeFields
        }
    }
    ${CHEQUE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_CHEQUE}
            mutation={SAVE_CHEQUE}
            mutationVariableName="cheque"
            record="Cheque"
        >
            <Tab title="Details">
                <Section title="Cheque details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
