import React, { useCallback } from 'react'

import { useConfirm } from 'crm-components/modal'

import { useMutation } from 'system/network/graphql'

import { SAVE_FILE } from './queries'

import { Row, Col } from 'react-styled-flexboxgrid'

import styled from 'styled-components'

import { MdDelete } from 'react-icons/md'

import { IoMdDownload } from 'react-icons/io'

import { getFileUrl, formatFileSize } from 'system/core/file'

import CollectionSelect from 'crm-components/collection-select'

import {
    FaRegFileImage,
    FaRegFilePdf,
    FaRegFile,
    FaRegFileWord,
} from 'react-icons/fa'

const FileContainer = styled.div`
    padding: 1rem;
    min-height: 58px;
    background-color: white;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex: 1;
`

const FileIcon = styled.div`
    display: flex;
    margin-right: 0.5rem;
    opacity: 0.5;
`

const DeleteIcon = styled.div`
    display: flex;
    opacity: ${props => (props.disabled ? 0.3 : 0.5)};
    cursor: pointer;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`
const DownloadIcon = styled.a`
    display: flex;
    margin-left: auto;
    opacity: 0.5;
    margin-right: 0.5rem;
    color: ${props => props.theme.dark};
    text-decoration: none;
`

const FileSize = styled.div`
    margin: 0 0.5rem;
    font-size: 0.8rem;
    color: ${props => props.theme.gray2};
`

const FileName = styled.div`
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: context;
    @media ${props => props.theme.media.sm} {
        max-width: 200px;
    }
`

function isImage(mimetype) {
    return mimetype.match('image')
}

function isWord(mimetype) {
    return mimetype.match('word')
}

function isPdf(mimetype) {
    return mimetype.match('pdf')
}

function isOtherFileType(mimetype) {
    return !isImage(mimetype) && !isWord(mimetype) && !isPdf(mimetype)
}

export default ({ file, onDelete, disabled }) => {
    const { loading, load: saveFile } = useMutation({
        operation: {
            query: SAVE_FILE,
        },
    })

    const confirm = useConfirm()

    const updateRemoteFile = useCallback(
        updates =>
            saveFile({
                file: {
                    _id: file._id,
                    documentTypeId: file.documentTypeId,
                    filename: file.filename,
                    ...updates,
                },
            }),
        [saveFile, file]
    )

    return (
        <Row>
            <Col xs={12} md={9}>
                <FileContainer>
                    <FileIcon>
                        {isImage(file.mimetype) ? <FaRegFileImage /> : null}
                        {isWord(file.mimetype) ? <FaRegFileWord /> : null}
                        {isPdf(file.mimetype) ? <FaRegFilePdf /> : null}
                        {isOtherFileType(file.mimetype) ? <FaRegFile /> : null}
                    </FileIcon>
                    <FileName title={file.filename}>{file.filename}</FileName>
                    <FileSize>{formatFileSize({ file })}</FileSize>
                    <DownloadIcon
                        href={getFileUrl({ file })}
                        download={file.filename}
                        target="_blank"
                    >
                        <IoMdDownload />
                    </DownloadIcon>
                    <DeleteIcon
                        disabled={disabled}
                        onClick={async () => {
                            if (
                                await confirm(
                                    'Are you sure you want to delete ' +
                                        file.filename +
                                        ' ?'
                                )
                            )
                                onDelete(file)
                        }}
                    >
                        <MdDelete />
                    </DeleteIcon>
                </FileContainer>
            </Col>
            <Col xs={12} md={3}>
                <CollectionSelect
                    disabled={disabled || loading}
                    queryName="documentTypes"
                    recordName="DocumentType"
                    value={file.documentTypeId || ''}
                    onChange={selected => {
                        updateRemoteFile({ documentTypeId: selected._id })
                    }}
                    label="Document type"
                    search={{ component: 'DOCUMENTS_UPLOADER' }}
                />
            </Col>
        </Row>
    )
}
