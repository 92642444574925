import React, { createContext, useState, useContext } from 'react'

import Login from './login'

import TopBar from './topbar'

import ForgotPassword from './forgot-password'

import ForgotPasswordSuccess from './forgot-password-success'

import SignUp from './sign-up-router'

import UserProfile from './user-profile'

import ResetPassword from './reset-password'

import PasswordChanged from './password-changed'

import CompanyDetails from './company-details'

import ActivateAccount from './activate-account'

import Invited from './invited'

import SignUpCompletionValidator from './sign-up-completion-validator'

import EmailVerificationValidator from './email-verification-validator'

const DEFAULT_STATE = {
    showBackButton: false,
}

const AccountModuleContext = createContext(DEFAULT_STATE)

export function useAccountModuleContext() {
    return useContext(AccountModuleContext)
}

export default () => {
    return (
        <AccountModuleContext.Provider value={useState(DEFAULT_STATE)}>
            <TopBar />
            <Login />
            <ForgotPassword />
            <ForgotPasswordSuccess />
            <SignUp />
            <UserProfile />
            <ResetPassword />
            <PasswordChanged />
            <CompanyDetails />
            <ActivateAccount />
            <Invited />
            <SignUpCompletionValidator />
            <EmailVerificationValidator />
        </AccountModuleContext.Provider>
    )
}
