import React, { useContext } from 'react'

import { useRouteMatch } from 'react-router-dom'

import styled from 'styled-components'

import { ListLink, ListAction } from 'ui-components/list/common'

import { ListContext } from './provider'

import { useCan } from 'system/account/acl'

import { useOnDelete } from './hooks'

const Container = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;

    @media ${props => props.theme.media.sm} {
        justify-content: flex-start;
    }
    ${ListLink}, ${ListAction} {
        margin: 0;
        color: ${props => props.theme.blue};

        @media ${props => props.theme.media.sm} {
            margin-right: 0.5rem;
        }
    }
`

export default ({ row, expanded, toggleExpandRow }) => {
    const { url } = useRouteMatch()

    const [{ record }] = useContext(ListContext)

    const accessModifier = undefined

    const canDelete = useCan({
        record,
        method: 'DELETE',
        accessModifier,
    })

    const onDelete = useOnDelete()

    return (
        <>
            <Container>
                <ListLink to={`${url}/${row._id}`}>View</ListLink>
                <ListAction
                    onClick={() => onDelete({ row })}
                    disabled={!canDelete}
                >
                    Delete
                </ListAction>
            </Container>
        </>
    )
}
