import React from 'react'

import { AiOutlineEdit } from 'react-icons/ai'

import Section from 'ui-components/section'

import { FormFields, FormField } from 'crm-components/crud-form'

import styled from 'styled-components'

import MarketingInputsForSupportedLanguages from 'crm-components/marketing-inputs-for-supported-languages'

const Fix = styled.div`
    overflow: hidden;
`

export default () => {
    return (
        <Section icon={AiOutlineEdit} title="Description">
            <FormFields>
                <FormField
                    name="marketingRecords"
                    component={MarketingInputsForSupportedLanguages}
                    fullWidth
                />
            </FormFields>
            <Fix />
        </Section>
    )
}
