import React, { useMemo } from 'react'

import { useEnums } from 'system/network/graphql'

import Select from 'ui-components/select'

function useFileUploaders() {
    const { enums } = useEnums()

    const uploaders = useMemo(() => {
        const uploaders = enums.find(enumObj => enumObj.name === 'FileUploader')

        if (uploaders) {
            return uploaders.enumValues.map(v => ({
                name: v.description,
                value: v.name,
            }))
        }

        return []
    }, [enums])

    return uploaders
}

export default ({ onChange, ...props }) => {
    const uploaders = useFileUploaders()

    return (
        <Select
            options={uploaders}
            label="Component"
            onValueChange={onChange}
            {...props}
        />
    )
}
