import { useEffect } from 'react'
import { useAccount } from 'system/account'
import { empty } from 'crm-components/data-helpers'
import { get } from 'lodash-es'
import { useLocation, useHistory } from 'react-router-dom'

export default () => {
    const { loading, account } = useAccount()

    const location = useLocation()

    const history = useHistory()

    useEffect(() => {
        if (loading || empty(account)) return

        const completed =
            !empty(get(account, 'company')) && !empty(get(account, 'profile'))

        const shouldRedirectToSignup =
            !completed && !location.pathname.match(/sign-up/)

        if (shouldRedirectToSignup) {
            history.replace('/sign-up')
        }
    }, [account, loading, history, location])

    return null
}
