import React from 'react'

import { AiOutlineEdit } from 'react-icons/ai'

import Section from 'ui-components/section'

import { FormFields, FormField } from 'crm-components/crud-form'

import styled from 'styled-components'

const Fix = styled.div`
    overflow: hidden;
`

export default () => {
    return (
        <Section icon={AiOutlineEdit} title="Amenities">
            <FormFields>
                <FormField
                    type="collectionBalloonSelector"
                    name="amenityIds"
                    recordName="Amenity"
                    queryName="amenities"
                    fullWidth
                />
            </FormFields>
            <Fix />
        </Section>
    )
}
