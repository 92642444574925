const FILE_FIELDS = /* GraphQL */ `
    fragment fileFields on File {
        _id
        filename
        mimetype
        url
        size
        documentTypeId
        parentId
        parentCollection
        documentType {
            name
        }
    }
`

export const GET_FILES = /* GraphQL */ `
    query GetFiles($ids: [ID!]!) {
        files(ids: $ids) {
            ...fileFields
        }
    }
    ${FILE_FIELDS}
`

export const SAVE_FILE = /* GraphQL */ `
    mutation SaveFile($upload: Upload, $file: FileInput) {
        saveFile(upload: $upload, file: $file) {
            ...fileFields
        }
    }
    ${FILE_FIELDS}
`

export const SAVE_FILES = /* GraphQL */ `
    mutation SaveFiles($uploads: [Upload], $files: [FileInput]) {
        saveFiles(uploads: $uploads, files: $files) {
            ...fileFields
        }
    }
    ${FILE_FIELDS}
`
