import React, { useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'

import { string, object, ref, bool } from 'yup'

import { Link as DefaultLink } from 'react-router-dom'

import { get } from 'lodash-es'

import AccountForm from './account-form'

import { useLogin } from './hooks'

import AccountPage from './account-page'

import { useAccount } from 'system/account'

import { isEmpty } from 'lodash-es'

import FormField from 'crm-components/form/field'

const REGISTER_USER = /* GraphQL */ `
    mutation RegisterUser($user: UserInput) {
        registerUser(user: $user) {
            _id
        }
    }
`

const Link = styled(DefaultLink)`
    color: ${props => props.theme.blue};
`

const Sidebar = styled.div`
    background-color: white;
    background-color: ${props => props.theme.gray};
    min-height: 100vh;
    display: none;
    @media ${props => props.theme.media.md} {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

const SidebarInner = styled.div`
    @media ${props => props.theme.media.md} {
        margin: 0 50px;
    }
    @media ${props => props.theme.media.lg} {
        margin: 0 50px;
    }
    @media ${props => props.theme.media.xl} {
        margin: 0 150px;
    }
`

const YourProducts = styled.h2`
    color: ${props => props.theme.gray2};
    magin: 0;
    font-size: 1.5rem;
    font-weight: bold;
`
const Product = styled.div`
    background-color: white;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    border-radius: 5px;
    color: ${props => props.theme.gray2};
`

const ProductNameAndDescription = styled.div``

const ProductName = styled.div`
    font-weight: bold;
    margin-bottom: 0.5rem;
`

const ProductDescription = styled.div`
    font-weight: 200;
    font-size: 1rem;
`

const ProductPrice = styled.div`
    font-weight: bold;
`

const Total = styled.div`
    background-color: ${props => props.theme.dark};
    padding: 1rem;
    justify-content: space-between;
    display: flex;
    color: ${props => props.theme.gray};
    border-radius: 5px;
`

const TotalNameAndDescription = styled.div``

const TotalName = styled.div`
    font-weight: bold;
    margin-bottom: 0.5rem;
`

const TotalDescription = styled.div`
    font-weight: 200;
    font-size: 1rem;
`
const TotalPrice = styled.div`
    font-weight: bold;
    font-size: 1.5rem;
`

const userSchema = object().shape({
    email: string()
        .required('Email is required')
        .email('Must be a valid email'),
    password: string()
        .required('Password is required')
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i,
            'Password is not strong enough'
        ),
    passwordConfirmation: string()
        .required('Password confirmation is required')
        .oneOf([ref('password'), null], 'Passwords must match'),
    consent: bool()
        .test(
            'consent',
            'You have to accept our terms and conditions',
            value => value === true
        )
        .required('You have to accept our terms and conditions'),
})

const SignUpSidebar = () => {
    return (
        <Sidebar>
            <SidebarInner>
                <YourProducts>Your products</YourProducts>
                <Product>
                    <ProductNameAndDescription>
                        <ProductName>Full featured demo</ProductName>
                        <ProductDescription>
                            Free for one month
                        </ProductDescription>
                    </ProductNameAndDescription>
                    <ProductPrice>$00.00</ProductPrice>
                </Product>
                <Product>
                    <ProductNameAndDescription>
                        <ProductName>Unlimited number of users</ProductName>
                        <ProductDescription>
                            Free for one month
                        </ProductDescription>
                    </ProductNameAndDescription>
                    <ProductPrice>$00.00</ProductPrice>
                </Product>
                <Total>
                    <TotalNameAndDescription>
                        <TotalName>Total price</TotalName>
                        <TotalDescription>Sign up for free</TotalDescription>
                    </TotalNameAndDescription>
                    <TotalPrice>$00.00</TotalPrice>
                </Total>
            </SidebarInner>
        </Sidebar>
    )
}

export default ({ onSuccess }) => {
    const { account, loading: accountLoading } = useAccount({
        autoSync: true,
    })

    const { login, loading: loginLoading } = useLogin()

    const [data, setData] = useState({})

    const [signUpResult, setSignUpResult] = useState({})

    const onMutationDataChange = useCallback(
        mutationData => {
            setSignUpResult(mutationData)
        },
        [setSignUpResult]
    )

    const onDataChange = useCallback(data => setData(data), [setData])

    useEffect(() => {
        const id = get(signUpResult, '_id')

        if (id && !accountLoading && isEmpty(account)) {
            login(data)
        }
    }, [signUpResult, data, accountLoading, account, login])

    const isSuccess = useCallback(() => {
        return get(signUpResult, '_id') && !isEmpty(account)
    }, [account, signUpResult])

    const isDisabled = useCallback(
        ({ saveLoading }) => saveLoading || accountLoading || loginLoading,
        [accountLoading, loginLoading]
    )

    return (
        <AccountPage
            sidebar={<SignUpSidebar />}
            title="Let's get started"
            subtitle="Sign up for free. No credit card required."
        >
            <AccountForm
                mutation={REGISTER_USER}
                buttonTitle="Create account"
                validationSchema={userSchema}
                mutationVariableName="user"
                onDataChange={onDataChange}
                isDisabled={isDisabled}
                onSuccess={onSuccess}
                isSuccess={isSuccess}
                shouldSyncMutationDataWithFormData={false}
                onMutationDataChange={onMutationDataChange}
                noRemoteData
            >
                <FormField
                    name="email"
                    label="Email"
                    instructions="You’ll need access to this email address to verify your account."
                    placeholder="jane@acmelabs.com"
                />
                <FormField
                    name="password"
                    label="Password"
                    instructions="At least 8 characters, includes 1 capital letter, 1 small letter, and 1 number."
                    placeholder="Secure password"
                    type="password"
                />
                <FormField
                    type="password"
                    name="passwordConfirmation"
                    label="Confirm password"
                />
                <FormField
                    name="consent"
                    label={
                        <span>
                            I accept and I have read the{' '}
                            <Link to="/terms">terms & conditions</Link>
                        </span>
                    }
                    type="checkbox"
                />
            </AccountForm>
        </AccountPage>
    )
}
