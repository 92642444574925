import { useQuery, operate } from 'system/network/graphql'

import { useMemo, useCallback, useContext, useEffect, useRef } from 'react'

import { getDataNodes, empty, getDataObject } from 'crm-components/data-helpers'

import { GraphQLContext } from 'graphql-react'

import { isEmpty, get } from 'lodash-es'

const emptyArray = []

export const useConductTransition = () => {
    const graphql = useContext(GraphQLContext)

    const conductTransition = useCallback(
        async ({ id, propertyIds }) => {
            const { cacheValuePromise } = operate({
                operation: {
                    query: /* GraphQL */ `
                        mutation ConductTransition(
                            $id: ID
                            $propertyIds: [ID]
                        ) {
                            conductTransition(
                                id: $id
                                propertyIds: $propertyIds
                            ) {
                                affectedIds
                                unAffectedIds
                                message
                            }
                        }
                    `,
                    variables: {
                        id,
                        propertyIds,
                    },
                },
            })

            const cacheValue = await cacheValuePromise

            graphql.reload()

            return cacheValue
        },
        [graphql]
    )

    return conductTransition
}

export const usePropertyStatuses = () => {
    const { cacheValue } = useQuery({
        operation: {
            loadOnReload: false,
            query: /* GraphQL */ `
                {
                    propertyStatuses(
                        pagination: { pageNumber: 1, pageSize: 5000 }
                        sort: { key: "name", order: 1 }
                    ) {
                        nodes {
                            ... on PropertyStatus {
                                _id
                                name
                            }
                        }
                    }
                }
            `,
        },
    })

    const statuses = useMemo(() => {
        const statuses = getDataNodes(cacheValue) || emptyArray
        return statuses
    }, [cacheValue])

    return statuses
}

const usePropertyOfferings = () => {
    const { cacheValue } = useQuery({
        loadOnReload: false,
        operation: {
            query: /* GraphQL */ `
                {
                    propertyOfferings(
                        pagination: { pageNumber: 1, pageSize: 1000 }
                    ) {
                        nodes {
                            ... on PropertyOffering {
                                _id
                                name
                            }
                        }
                    }
                }
            `,
        },
    })

    const offerings = useMemo(() => {
        const offerings = getDataNodes(cacheValue) || emptyArray
        return offerings
    }, [cacheValue])

    return offerings
}

export const usePredefinedQueries = () => {
    const statuses = usePropertyStatuses()

    const offerings = usePropertyOfferings()

    const predefinedQueries = useMemo(() => {
        const statusQueries = statuses.map(status => ({
            name: status.name,
            filter: {
                statusId: status._id,
            },
        }))
        const offeringQueries = offerings.map(offering => ({
            name: offering.name,
            filter: {
                propertyOfferingId: offering._id,
            },
        }))

        const queries = [...offeringQueries, ...statusQueries]

        return isEmpty(queries) ? emptyArray : queries
    }, [statuses, offerings])

    return predefinedQueries
}

export const usePropertyTransisions = () => {
    const { cacheValue } = useQuery({
        operation: {
            query: /* GraphQL */ `
                {
                    propertyTransitions(
                        pagination: { pageNumber: 1, pageSize: 5000 }
                        sort: { key: "sortOrder", order: 1 }
                    ) {
                        nodes {
                            ... on PropertyTransition {
                                _id
                                sourceStatuses {
                                    _id
                                    name
                                }
                                destinationStatus {
                                    _id
                                    name
                                }
                                name
                            }
                        }
                    }
                }
            `,
        },
    })

    const transitions = useMemo(() => getDataNodes(cacheValue) || emptyArray, [
        cacheValue,
    ])

    return transitions
}

const useFetchDefaultMarketingRecord = ({ property: defaultProperty }) => {
    const lastId = useRef()

    const { loading, load, cacheValue } = useQuery({
        loadOnReload: true,
        loadOnMount: false,
        operation: {
            query: /* GraphQL */ `{
                property(id: "${defaultProperty._id}") {
                    marketingRecords {
                        languageId
                        name
                        content
                        marketingTemplateId 
                        language {
                            isDefault
                        }
                    }
                }
            }`,
        },
    })

    const property = useMemo(() => {
        return getDataObject(cacheValue)
    }, [cacheValue])

    const defaultMarketingRecord = useGetDefaultMarketingRecord({ property })

    useEffect(() => {
        if (lastId.current !== defaultProperty._id) {
            load()
        }

        lastId.current = defaultProperty._id
    }, [defaultProperty, load])

    return { loading, defaultMarketingRecord }
}

const useGetDefaultMarketingRecord = ({ property }) => {
    const defaultMarketingRecord = useMemo(() => {
        if (!property) return undefined

        return (property.marketingRecords || []).find(
            record => record.language.isDefault
        )
    }, [property])

    return defaultMarketingRecord
}

export const usePropertyName = ({ property }) => {
    const defaultMarketingRecord = useGetDefaultMarketingRecord({ property })

    const name = get(defaultMarketingRecord, 'name') || null

    return name
}

export const usePropertyMarketingDescription = ({ property }) => {
    const defaultMarketingRecord = useGetDefaultMarketingRecord({ property })
    const content = get(defaultMarketingRecord, 'content') || null
    return content
}

export const useFetchPropertyName = ({ property }) => {
    const { loading, defaultMarketingRecord } = useFetchDefaultMarketingRecord({
        property,
    })

    return { name: get(defaultMarketingRecord, 'name'), loading }
}

export const useFetchPropertyMarketingDescription = ({ property }) => {
    const { loading, defaultMarketingRecord } = useFetchDefaultMarketingRecord({
        property,
    })
    return { loading, description: get(defaultMarketingRecord, 'content') }
}

export const usePropertyImages = ({ property }) => {
    const images = useMemo(() => {
        if (empty(get(property, 'images'))) return emptyArray

        return property.images
    }, [property])

    return images
}

export const getPropertyIgnoredKeyNamesForFieldLabelValueMaker = () => {
    return [
        'language',
        'marketingRecords',
        'createdBy',
        '_id',
        'assignedTo',
        'listingPlatforms',
        'images',
    ]
}
