import React, { useMemo } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

const GET_RENOVATION_TYPES = /* GraphQL */ `
    query GetRenovationTypes(
        $search: RenovationTypeSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        renovationTypes(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on RenovationType {
                    _id
                    name
                    propertyFinderValue
                    bayutValue
                    createdAt
                }
            }
        }
    }
`

const DELETE_RENOVATION_TYPES = /* GraphQL */ `
    mutation DeleteRenovationTypes($ids: [ID!]!) {
        deleteRenovationTypes(ids: $ids)
    }
`

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Property finder',
                key: 'propertyFinderValue',
                sortKey: 'propertyFinderValue',
            },
            {
                name: 'Bayut value',
                key: 'bayutValue',
                sortKey: 'bayutValue',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    return (
        <List
            getQuery={GET_RENOVATION_TYPES}
            deleteQuery={DELETE_RENOVATION_TYPES}
            pageTitle={'Renovation types'}
            entity="renovationType"
            cols={cols}
            record="RenovationType"
            renderSearchSection={() => {
                return (
                    <>
                        <SearchField name="name" label="Name" />
                        <SearchField
                            name="createdAt"
                            label="Date created"
                            type="dateRangePicker"
                        />
                    </>
                )
            }}
        />
    )
}
