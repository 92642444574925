import React from 'react'

import SEO from 'ui-components/seo'

import { usePageTitle } from './hooks/fields'

export default () => {
    const [pageTitle] = usePageTitle()

    return <SEO title={pageTitle} />
}
