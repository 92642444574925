import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useRef,
    useCallback,
} from 'react'

import { get, isEmpty, isEqual } from 'lodash-es'

import { useQuery } from 'system/network/graphql'

import ACLProvider from 'system/account/acl'

const GET_MY_ACCOUNT = /* GraphQL */ `
    {
        account {
            _id
            email
            emailConfirmedAt
            isSuperUser
            roleId
            companyId
            profile {
                _id
                firstName
                lastName
                companyRole
                mobile
                brn
                photoId
                photo {
                    resizedUrl
                    url
                    crop {
                        x
                        y
                        width
                        height
                        unit
                        aspect
                    }
                }
            }
            company {
                _id
                name
                website
                phone
                orn
                address
                licenseNumber
                trn
                logoId
            }
            role {
                name
                permissions {
                    name
                    code
                    method
                    accessModifier
                    parentId
                    parentCollection
                }
            }
        }
    }
`

const noop = {}

const DEFAULT_STATE = {
    loading: true,
    account: noop,
    registeredAutoSync: [],
}

const AccountContext = createContext(DEFAULT_STATE)

function useFetchAccount() {
    const [state, set] = useContext(AccountContext)

    const { load, loading, cacheValue } = useQuery({
        operation: { query: GET_MY_ACCOUNT },
        loadOnReload: state.registeredAutoSync.length > 0,
        loadOnMount: state.registeredAutoSync.length > 0,
    })

    const isLoaded = useRef(false)

    useEffect(() => {
        if (!isLoaded.current && isEmpty(state.account)) load()

        isLoaded.current = true
    }, [load, state.account])

    useEffect(() => {
        const account = get(cacheValue, 'data.account')
        set(state => ({
            ...state,
            loading: loading || typeof account === 'undefined',
            account: account || noop,
        }))
    }, [loading, cacheValue, set])
}

function useHandleAutoSync() {
    const [, set] = useContext(AccountContext)

    const handleAutoSync = useCallback(
        ({ autoSyncKey, autoSync }) => {
            set(previousState => {
                const state = { ...previousState }

                if (autoSync) {
                    state.registeredAutoSync = [
                        ...state.registeredAutoSync,
                        autoSyncKey.current,
                    ]
                } else {
                    state.registeredAutoSync = state.registeredAutoSync.filter(
                        key => key !== autoSyncKey.current
                    )
                }

                if (
                    !isEqual(
                        state.registeredAutoSync,
                        previousState.registeredAutoSync
                    )
                )
                    return state
                else return previousState
            })
        },
        [set]
    )

    return handleAutoSync
}

export function useAccount({ autoSync = false } = {}) {
    const [state] = useContext(AccountContext)

    const autoSyncKey = useRef({})

    const handleAutoSync = useHandleAutoSync()

    useEffect(() => {
        handleAutoSync({ autoSyncKey, autoSync })
        return () => {
            handleAutoSync({ autoSyncKey, autoSync: false })
        }
    }, [autoSync, handleAutoSync])

    return state
}

function InitialAccountCall() {
    useFetchAccount()
    return null
}

export default ({ children }) => {
    return (
        <AccountContext.Provider value={useState(DEFAULT_STATE)}>
            <InitialAccountCall />
            <ACLProvider>{children}</ACLProvider>
        </AccountContext.Provider>
    )
}
