import React from 'react'

import { GiPokecog } from 'react-icons/gi'

import { Route } from 'system/core/route'

import { MenuItem } from 'system/layout/sidebar'

import Form from './form'

export default () => {
    return (
        <>
            <MenuItem
                to="/settings"
                icon={<GiPokecog />}
                sortOrder={80}
                render="Settings"
                permission={{
                    record: 'Settings',
                    method: 'READ',
                    accessModifier: 'ALL',
                }}
            />
            <Route
                path="/settings"
                permission={{
                    record: 'Settings',
                    method: 'READ|WRITE',
                    accessModifier: 'ALL',
                }}
            >
                <Form />
            </Route>
        </>
    )
}
