import { useMemo } from 'react'

import {
    empty,
    formatValue,
    ucFirst,
    camelCaseToSentenceCase,
} from './data-helpers'

import { isString, isArray, isObject, isFunction } from 'lodash-es'

const emptyFieldValue = '---'

const emptyArray = []

function getObjectLabel({ value, ignoredKeyNames = emptyArray }) {
    if (!isObject(value)) return value

    const label = Object.keys(value)
        .filter(key => ignoredKeyNames.indexOf(key) === -1)
        .map(key => value[key])
        .filter(value => !empty(value))
        .join(', ')

    if (empty(label.trim())) {
        return emptyFieldValue
    }

    return label
}

function getFieldValue({ row, key, ignoredKeyNames, makeValue }) {
    if (ignoredKeyNames.indexOf(key) > -1) return ''

    if (isFunction(makeValue) && !empty(makeValue({ row, key })))
        return makeValue({ row, key })

    const value = row[key]

    if (empty(value) || (isString(value) && empty(value.trim()))) {
        return emptyFieldValue
    }

    if (isString(value)) return value

    if (isArray(value)) {
        const mappedValue = value
            .map(value => getObjectLabel({ value, ignoredKeyNames }))
            .join(', ')
        return mappedValue
    }

    return getObjectLabel({ value, ignoredKeyNames })
}

export const useRowFields = ({ row, ignoredKeyNames = [], makeValue }) => {
    const fields = useMemo(() => {
        if (empty(row)) return emptyArray

        return Object.keys(row).reduce((result, key) => {
            if (ignoredKeyNames.indexOf(key) > -1) return result

            let value = getFieldValue({
                row,
                key,
                ignoredKeyNames,
                makeValue,
            })

            value = formatValue(value)

            result.push({
                key,
                name: ucFirst(camelCaseToSentenceCase(key)),
                value,
            })

            return result
        }, [])
    }, [row, ignoredKeyNames, makeValue])

    return fields
}
