import React, { useMemo, useCallback } from 'react'

import List from 'crm-components/list'

import SearchField from 'crm-components/list/search-field'

import { pluralize } from 'crm-components/data-helpers'

import { get } from 'lodash-es'

const GET_TEAMS = /* GraphQL */ `
    query GetTeams(
        $search: TeamSearch
        $pagination: PaginationInput
        $sort: Sort
    ) {
        teams(search: $search, pagination: $pagination, sort: $sort) {
            totalPages
            count
            nodes {
                ... on Team {
                    _id
                    name
                    users {
                        email
                    }
                    createdAt
                }
            }
        }
    }
`

const DELETE_TEAMS = /* GraphQL */ `
    mutation DeleteTeams($ids: [ID!]!) {
        deleteTeams(ids: $ids)
    }
`
const Cell = ({ row, col }) => {
    if (col.key !== 'members') return

    const count = get(row, 'users.length') || 0

    return `${count} ${pluralize('member', count)}`
}

export default () => {
    const cols = useMemo(
        () => [
            {
                name: 'Name',
                key: 'name',
                sortKey: 'name',
            },
            {
                name: 'Members',
                key: 'members',
            },
            {
                name: 'Created at',
                key: 'createdAt',
                sortKey: 'createdAt',
            },
        ],
        []
    )

    const renderSearchSection = useCallback(
        () => (
            <>
                <SearchField name="name" label="Name" />
                <SearchField
                    name="createdAt"
                    label="Created at"
                    type="dateRangePicker"
                />
            </>
        ),
        []
    )

    return (
        <List
            getQuery={GET_TEAMS}
            deleteQuery={DELETE_TEAMS}
            pageTitle={'Teams'}
            entity="team"
            cols={cols}
            renderCell={Cell}
            renderSearchSection={renderSearchSection}
            record="Team"
        />
    )
}
