import React from 'react'

import { FaCheckCircle } from 'react-icons/fa'

import styled from 'styled-components'

import { getOptionValue } from 'crm-components/data-helpers'

import Loader from 'ui-components/loader'

const CheckedIcon = styled.div`
    color: ${props => props.theme.success};
    margin-bottom: 0.5rem;
    transition: opacity 0.3s ease;
    opacity: ${props => (props.active ? 1 : 0)};
`

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    margin: -0.5rem;
    @media ${props => props.theme.media.lg} {
        max-width: 50%;
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid currentColor;
    text-align: center;
    padding: 0.5rem;
    margin: 0.5rem;
    color: ${props => (props.active ? props.theme.dark : props.theme.gray2)};
    user-select: none;
    cursor: pointer;
    flex: 100%;
    max-width: calc(50% - 1rem);
    transition: color 0.3s ease;

    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    @media ${props => props.theme.media.lg} {
        max-width: calc(25% - 1rem);
    }
`

const TypeIcon = styled.div`
    display: flex;
    font-size: 3.5rem;
    justify-content: center;
    opacity: 0.5;
`

const TypeName = styled.div`
    margin-top: 0.5rem;
`

function isActive({ option, value }) {
    return getOptionValue({ option }) === value
}

const noopArray = []

const noopFunction = () => {}

const IconBox = ({
    name,
    icon,
    value,
    isActive,
    onChange = noopFunction,
    disabled,
}) => {
    return (
        <Box
            disabled={disabled}
            onClick={() => onChange(value)}
            active={isActive}
        >
            <CheckedIcon active={isActive}>
                <FaCheckCircle />
            </CheckedIcon>
            <TypeIcon>{icon}</TypeIcon>
            <TypeName>{name}</TypeName>
        </Box>
    )
}

export const IconBoxLoader = ({ count = 1 }) => {
    return Array.from({ length: count }).map((_, i) => (
        <IconBox key={i} name="Loading..." icon={<Loader slow />} disabled />
    ))
}

/**
 * Selector with large boxes and icon
 */
export default ({
    disabled,
    options = noopArray,
    value,
    onChange,
    renderIcon,
    isDirty,
    showSaveStatus,
    onDiscard,
}) => {
    return (
        <>
            {options.map(option => (
                <IconBox
                    disabled={disabled}
                    name={option.name}
                    value={getOptionValue({ option })}
                    key={getOptionValue({ option })}
                    isActive={isActive({ option, value })}
                    onChange={onChange}
                    icon={renderIcon(option)}
                />
            ))}
        </>
    )
}
