import React from 'react'

import List from './list'

import Form from './form'

import CRUDRouter from 'crm-components/crud-router'

import { MenuItem } from 'system/layout/sidebar'

import { FaUsers } from 'react-icons/fa'

export default () => {
    return (
        <>
            <MenuItem
                to="/user"
                render="Users"
                icon={<FaUsers />}
                sortOrder={40}
                permission={{
                    record: 'User',
                    method: 'READ',
                    accessModifier: 'COMPANY|ALL|TEAM',
                }}
            />
            <CRUDRouter
                path="/user"
                list={List}
                form={Form}
                record="User"
                accessModifier="ALL|COMPANY|TEAM"
            />
        </>
    )
}
