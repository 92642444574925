import React, { useCallback, useEffect, useState } from 'react'

import { useMutation } from 'system/network/graphql'

import styled from 'styled-components'

import { useHistory } from 'react-router-dom'

import Form from 'crm-components/form'

import FormButton from 'crm-components/form/button'

import { get, isFunction, isEqual } from 'lodash-es'

import { useAccountModuleContext } from './'

import { empty, getDataKey, prepareFormData } from 'crm-components/data-helpers'

const ButtonWrapper = styled.div`
    margin: 2rem 0 0;
    display: flex;
    justify-content: ${props =>
        props.showBackButton ? 'space-between' : 'center'};
`

function defaultIsDisabled({ saveLoading }) {
    return saveLoading
}

function defaultIsLoading({ saveLoading }) {
    return saveLoading
}

const noopObject = {}

const AccountForm = ({
    mutation,
    mutationVariableName,
    isDisabled = defaultIsDisabled,
    children,
    validationSchema,
    buttonTitle = 'Save',
    isSuccess,
    onSuccess,
    remoteData,
    onDataChange,
    shouldSyncMutationDataWithFormData = false,
    isLoading = defaultIsLoading,
    removeFields,
    onMutationDataChange,
    saveChangesToLocalStorage,
    noRemoteData,
    appendSaveData = noopObject,
}) => {
    const [data, setData] = useState({})

    const history = useHistory()

    const [accountModuleState] = useAccountModuleContext()

    const [dirty, setDirty] = useState(false)

    const {
        loading: saveLoading,
        cacheValue: saveCacheValue,
        load: saveMutation,
    } = useMutation({
        operation: {
            query: mutation,
        },
    })

    const save = useCallback(() => {
        const variableData = { ...data, ...appendSaveData }

        const variables = mutationVariableName
            ? {
                  [mutationVariableName]: prepareFormData(
                      variableData,
                      removeFields
                  ),
              }
            : prepareFormData(variableData, removeFields)
        saveMutation(variables)
    }, [data, saveMutation, mutationVariableName, removeFields, appendSaveData])

    useEffect(() => {
        const mutationData = get(
            saveCacheValue,
            `data.${getDataKey(saveCacheValue)}`
        )

        if (empty(mutationData)) return

        if (isFunction(onMutationDataChange)) {
            onMutationDataChange(mutationData)
        }

        if (isFunction(isSuccess))
            if (isSuccess({ mutationData })) {
                if (isFunction(onSuccess)) {
                    onSuccess()
                }
            }
    }, [
        saveCacheValue,
        isSuccess,
        shouldSyncMutationDataWithFormData,
        onMutationDataChange,
        history,
        onSuccess,
    ])

    const isLoadingMemoized = useCallback(() => {
        return isLoading({ saveLoading })
    }, [saveLoading, isLoading])

    const isDisabledMemoized = useCallback(() => {
        return isDisabled({
            saveLoading,
            data,
        })
    }, [isDisabled, saveLoading, data])

    const onDataChangeMemoized = useCallback(
        dataParam => {
            if (isFunction(onDataChange)) onDataChange(dataParam)

            setData(data => {
                if (!isEqual(dataParam, data)) return dataParam
                return data
            })
        },
        [onDataChange, setData]
    )

    const onDirtyChange = useCallback(dirty => setDirty(dirty), [])

    return (
        <Form
            submit={save}
            isLoading={isLoadingMemoized}
            isDisabled={isDisabledMemoized}
            validationSchema={validationSchema}
            graphQLResponse={saveCacheValue}
            onDataChange={onDataChangeMemoized}
            saveChangesToLocalStorage={saveChangesToLocalStorage}
            remoteData={remoteData}
            onDirtyChange={onDirtyChange}
            noRemoteData={noRemoteData}
        >
            {children}
            <ButtonWrapper showBackButton={accountModuleState.showBackButton}>
                {accountModuleState.showBackButton && (
                    <FormButton
                        neutral
                        type="button"
                        onClick={history.goBack}
                        enableLoading={false}
                    >
                        Cancel
                    </FormButton>
                )}
                <FormButton disabled={!dirty}>{buttonTitle}</FormButton>
            </ButtonWrapper>
        </Form>
    )
}

export default props => {
    return <AccountForm {...props} />
}
