import React, { createContext, useEffect, useState } from 'react'

const DEFAULT_STATE = {
    search: {},
    staticSearchFields: [],
    getQuery: undefined,
    deleteQuery: undefined,
    predefinedQueries: [],
    renderCell: undefined,
    renderSearchSection: undefined,
    renderStaticSearch: undefined,
    cols: [],
    rows: [],
    selectedRows: [],
    pageTitle: undefined,
    entity: undefined,
    record: undefined,
    showQuickFiltersLoader: undefined,
    actions: undefined,
    renderActions: undefined,
    renderExpandedRow: undefined,
    expandable: undefined,
    pagination: {
        pageSize: 10,
        pageNumber: 1,
    },
}

export const ListContext = createContext()

export default ({
    children,
    getQuery,
    deleteQuery,
    predefinedQueries,
    renderCell,
    renderSearchSection,
    renderStaticSearch,
    cols = [],
    pageTitle,
    entity = '',
    record,
    showQuickFiltersLoader,
    actions,
    renderActions,
    renderExpandedRow,
    expandable,
}) => {
    const [state, set] = useState(DEFAULT_STATE)

    useEffect(() => {
        set(state => ({
            ...state,
            getQuery,
            deleteQuery,
            predefinedQueries,
            renderCell,
            renderSearchSection,
            renderStaticSearch,
            cols,
            pageTitle,
            entity,
            record,
            showQuickFiltersLoader,
            actions,
            renderActions,
            renderExpandedRow,
            expandable,
        }))
    }, [
        getQuery,
        deleteQuery,
        predefinedQueries,
        renderCell,
        renderSearchSection,
        renderStaticSearch,
        cols,
        pageTitle,
        entity,
        record,
        showQuickFiltersLoader,
        actions,
        renderActions,
        renderExpandedRow,
        expandable,
    ])

    return (
        <ListContext.Provider value={[state, set]}>
            {children}
        </ListContext.Provider>
    )
}
