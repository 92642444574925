import React from 'react'

import Section from 'ui-components/section'

import { FormField, FormFields } from 'crm-components/crud-form'

import PropertySelect from 'crm-components/property-select'

export default () => {
    return (
        <Section title="Property">
            <FormFields>
                <FormField
                    component={PropertySelect}
                    name="propertyId"
                    label="Property"
                    showAddLink
                    previewBaseLink="/property/"
                />
            </FormFields>
        </Section>
    )
}
