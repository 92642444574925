import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io'

import { InputContainer as DefaultInputContainer } from 'ui-components/input'

import SaveStatus from './save-status'

import { getOptionValue } from 'crm-components/data-helpers'
import { isEqual } from 'lodash-es'

const InputContainer = styled(DefaultInputContainer)`
    flex-direction: column;
    align-items: initial;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
`

const RadioGroupContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Label = styled.label`
    display: flex;
    cursor: pointer;
    align-items: center;
    user-select: none;

    &:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 2px ${props => props.theme.secondary};
    }
`

const OptionName = styled.span`
    font-size: 0.8rem;
    font-weight: bold;
`

const Icon = styled.div`
    display: flex;
    color: ${props =>
        props.selected ? props.theme.success : 'rgba(0,0,0,.5)'};
    transition: color 0.3s ease;
    margin-right: 0.5rem;
`

const Title = styled.div`
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.theme.primary};
`

const Option = ({ option, selected, onClick }) => {
    return (
        <Label
            tabIndex={0}
            onClick={() => onClick(option)}
            onKeyUp={e => {
                if (
                    e.key === 'Enter' ||
                    e.key === 'Spacebar' ||
                    e.key === ' '
                ) {
                    e.preventDefault()
                    e.stopPropagation()
                    onClick(option)
                }
            }}
        >
            <Icon selected={selected}>
                {selected ? <IoMdRadioButtonOn /> : <IoMdRadioButtonOff />}
            </Icon>
            <OptionName>{option.name}</OptionName>
        </Label>
    )
}

const noop = []

export default ({
    label,
    name,
    value: defaultValue,
    options = noop,
    onChange: radioGroupOnChange = () => {},
    isDirty,
    onDiscard,
    showSaveStatus,
}) => {
    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
        setValue(value => {
            if (!isEqual(defaultValue, value)) {
                return defaultValue
            }
            return value
        })
    }, [defaultValue])

    return (
        <InputContainer>
            <RadioGroupContainer>
                <Title>{label}</Title>
                {options.map(option => {
                    return (
                        <Option
                            tabIndex={0}
                            key={getOptionValue({ option })}
                            option={option}
                            name={name}
                            onClick={option => {
                                setValue(getOptionValue({ option }))
                                radioGroupOnChange(getOptionValue({ option }))
                            }}
                            selected={value === getOptionValue({ option })}
                        />
                    )
                })}
            </RadioGroupContainer>
            <SaveStatus
                isDirty={isDirty}
                onDiscard={onDiscard}
                value={value}
                showSaveStatus={showSaveStatus}
            />
        </InputContainer>
    )
}
