import React, { useCallback } from 'react'

import styled, { keyframes } from 'styled-components'

import { useCloseAfterSave, useDirty } from './hooks/fields'

import { useListUrl } from './hooks/logic'

import FormButton from 'crm-components/form/button'

import Link from 'ui-components/link'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    animation: ${fadeIn} 0.2s 0.1s ease-in both;
    flex-direction: column;

    @media ${props => props.theme.media.sm} {
        align-items: center;
        flex-direction: row;
    }
`

const Button = styled(FormButton)`
    margin-bottom: 1rem;
    @media ${props => props.theme.media.sm} {
        min-width: unset;
        margin-right: 1rem;
        margin-bottom: 0;
    }
`

const OrContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`
const Or = styled.div`
    margin-right: 1rem;
    color: ${props => props.theme.gray2};
`
export default () => {
    const [dirty] = useDirty()

    const [, setCloseAfterSave] = useCloseAfterSave()

    const onSaveAndCloseClick = useCallback(() => {
        setCloseAfterSave(true)
    }, [setCloseAfterSave])

    const listUrl = useListUrl()

    return (
        <ButtonWrapper>
            <Button disabled={!dirty}>Save</Button>

            <Button
                neutral
                disabled={!dirty}
                enableLoading={false}
                onClick={onSaveAndCloseClick}
            >
                Save and close
            </Button>
            <OrContainer>
                <Or>Or</Or>
                <Link to={listUrl}>Close</Link>
            </OrContainer>
        </ButtonWrapper>
    )
}
