import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const BEDROOM_FRAGMENT = /* GraphQL */ `
    fragment bedroomFields on Bedroom {
        _id
        name
        propertyFinderValue
        bayutValue
        createdAt
    }
`

const SAVE_BEDROOM = /* GraphQL */ `
    mutation SaveBedroom($bedroom: BedroomInput) {
        saveBedroom(bedroom: $bedroom) {
            ...bedroomFields
        }
    }
    ${BEDROOM_FRAGMENT}
`

const GET_BEDROOM = /* GraphQL */ `
    query GetBedroom($id: ID!) {
        bedroom(id: $id) {
            ...bedroomFields
        }
    }
    ${BEDROOM_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_BEDROOM}
            mutation={SAVE_BEDROOM}
            mutationVariableName="bedroom"
            record="Bedroom"
        >
            <Tab title="Details">
                <Section title="Bedroom details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            name="propertyFinderValue"
                            label="Property finder value"
                        />
                        <FormField name="bayutValue" label="Bayut value" />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
