import React from 'react'

import QuickView from 'ui-components/quick-view'

import { useRowFields } from 'crm-components/label-value-maker'

import { formatValue, getUserName } from 'crm-components/data-helpers'

export default ({ row, close }) => {
    const fields = useRowFields({
        row,
        ignoredKeyNames: ['_id', 'createdBy'],
        makeValue: ({ row, key }) => {
            switch (key) {
                case 'officialDocumentExpiryDate':
                    return formatValue(row[key], { noTime: true })
                case 'assignedTo':
                case 'createdBy':
                    return getUserName(row[key])
                default:
                    return
            }
        },
    })

    return <QuickView close={close} fields={fields} />
}
