import React from 'react'

import SearchField from 'crm-components/list/search-field'

import ContactSearch from 'modules/contact/contact-search'

import LocationSelect from 'crm-components/location-select'

export default () => {
    return (
        <>
            <ContactSearch />
            <SearchField
                name="interestTypeId"
                type="collectionSelect"
                label="Interest type"
                recordName="LeadInterestType"
                queryName="leadInterestTypes"
            />
            <SearchField
                name="interestPropertyLocationId"
                component={LocationSelect}
                representScalarValue
                label="Interest location"
            />
            <SearchField
                name="interestNumberOfBedroomsId"
                type="collectionSelect"
                recordName="Bedroom"
                queryName="bedrooms"
                label="Interest number of bedrooms"
            />
            <SearchField
                name="interestBudget"
                type="numberRange"
                label="Interest budget"
            />
            <SearchField
                name="interestAmenityIds"
                type="collectionSelect"
                recordName="Amenity"
                queryName="amenities"
                isMulti
                label="Interest amenities"
            />
            <SearchField
                label="Status"
                name="leadStatusId"
                type="collectionSelect"
                recordName="LeadStatus"
                queryName="leadStatuses"
            />
        </>
    )
}
