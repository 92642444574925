import React, { useEffect, useCallback } from 'react'

import { get, isFunction } from 'lodash-es'

import { empty } from 'crm-components/data-helpers'

import { useCan } from 'system/account/acl'

import Input from 'ui-components/input'

import Checkbox from 'ui-components/checkbox'

import ImageInput from 'ui-components/image-input'

import CollectionSelect from 'crm-components/collection-select'

import Activities from 'crm-components/activities'

import Select from 'ui-components/select'

import DatePicker from 'ui-components/datepicker'

import BalloonSelector from 'ui-components/balloon-selector'

import CollectionRadioGroup from 'crm-components/collection-radio-group'

import CollectionBalloonSelector from 'crm-components/collection-balloon-selector'

import {
    useExcludeFromInput,
    useFieldDirty,
    useFieldData,
    useDisabled,
    useSaveChangesToLocalStorage,
} from './hooks/fields'

import { isExcluded } from './data-processors'

import useValidationErrors from './hooks/use-validation-errors'

import useRemoteData from './hooks/use-remote-data'

const noopObject = {}

const noopFunction = () => null

function getError({ validationErrors = [], name }) {
    return validationErrors.find(e => e.path === name)
}

export default ({
    type = 'text',
    name,
    disabled,
    hideIfCant = noopObject,
    component,
    onHideChange,
    onChange: notifyOnChange = noopFunction,
    children,
    ...props
}) => {
    const can = useCan(hideIfCant)

    const [remoteData] = useRemoteData()

    const [isDirty] = useFieldDirty(name)

    const [formDisabled] = useDisabled()

    const [fieldValue, setValue] = useFieldData(name)

    const [validationErrors] = useValidationErrors()

    const [saveChangesToLocalStorage] = useSaveChangesToLocalStorage()

    const [excludeFromInput] = useExcludeFromInput()

    const hide = !empty(hideIfCant) && !can

    let value = fieldValue || null

    if (isExcluded({ excludeFromInput, key: name })) {
        value = get(remoteData, name)
    }

    useEffect(() => {
        if (isFunction(notifyOnChange)) notifyOnChange(value)
    }, [value, notifyOnChange])

    useEffect(() => {
        if (isFunction(onHideChange)) onHideChange(hide)
    }, [hide, onHideChange])

    const onChange = useCallback(
        value => {
            if (type === 'select' || type === 'collectionSelect') return

            setValue(value)
        },
        [setValue, type]
    )

    const onValueChange = useCallback(
        value => {
            setValue(value)
        },
        [setValue]
    )

    const onDiscard = useCallback(() => {
        setValue(get(remoteData, name))
    }, [setValue, remoteData, name])

    let Component =
        component ||
        {
            text: Input,
            email: Input,
            number: Input,
            password: Input,
            tel: Input,
            textarea: Input,
            image: ImageInput,
            checkbox: Checkbox,
            collectionSelect: CollectionSelect,
            activities: Activities,
            datepicker: DatePicker,
            select: Select,
            collectionRadioGroup: CollectionRadioGroup,
            balloonSelector: BalloonSelector,
            collectionBalloonSelector: CollectionBalloonSelector,
        }[type]

    if (isFunction(children)) {
        Component = children
    }

    if (!Component) {
        console.error('Form field type not found', { name, type })
        return null
    }

    if (hide) {
        return null
    }

    const error = getError({ validationErrors, name })

    return (
        <Component
            {...props}
            value={value}
            type={type}
            disabled={formDisabled || disabled}
            onChange={onChange}
            onValueChange={onValueChange}
            error={error}
            isDirty={isDirty}
            showSaveStatus={saveChangesToLocalStorage}
            onDiscard={onDiscard}
            remoteData={remoteData}
        />
    )
}
