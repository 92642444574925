import React, { useEffect } from 'react'

import ReactDOM from 'react-dom'

function usePortal(id) {
    const rootElemRef = React.useRef(document.createElement('div'))

    useEffect(function setupElement() {
        // Look for existing target dom element to append to
        const parentElem = document.querySelector(`#${id}`)
        // Add the detached element to the parent
        parentElem.appendChild(rootElemRef.current)
        // This function is run on unmount
        return function removeElement() {
            // eslint-disable-next-line
            rootElemRef.current.remove()
        }
        // eslint-disable-next-line
    }, [])

    return rootElemRef.current
}

const Portal = ({ children }) => {
    const target = usePortal('modals')
    return ReactDOM.createPortal(children, target)
}

export default Portal
