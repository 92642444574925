import React, { useMemo } from 'react'

import DropDownMenu from 'ui-components/drop-down-menu'

import TransitionActions, { useTransitProperties } from './transition-actions'
import AssignAction from './assign-action'
import ActionsPreloader from './actions-preloader'

const emptyArray = []

export default ({ selectedRows }) => {
    const propertyIds = useMemo(
        () => (selectedRows || emptyArray).map(row => row._id),
        [selectedRows]
    )

    const transitProperties = useTransitProperties({
        propertyIds,
        showAffectedRecords: true,
        redirectOnSave: false,
    })

    return (
        <>
            <ActionsPreloader />
            <DropDownMenu>
                <TransitionActions
                    onTransitionClick={transitProperties}
                    propertyIds={propertyIds}
                />
                <AssignAction propertyIds={propertyIds} />
            </DropDownMenu>
        </>
    )
}
