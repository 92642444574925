import React from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import FileUploaderSelect from 'crm-components/file-uploader-select'

const DOCUMENT_TYPE_FRAGMENT = /* GraphQL */ `
    fragment documentTypeFields on DocumentType {
        _id
        name
        component
        createdAt
    }
`

const SAVE_DOCUMENT_TYPE = /* GraphQL */ `
    mutation SaveDocumentType($documentType: DocumentTypeInput) {
        saveDocumentType(documentType: $documentType) {
            ...documentTypeFields
        }
    }
    ${DOCUMENT_TYPE_FRAGMENT}
`

const GET_DOCUMENT_TYPE = /* GraphQL */ `
    query GetDocumentType($id: ID!) {
        documentType(id: $id) {
            ...documentTypeFields
        }
    }
    ${DOCUMENT_TYPE_FRAGMENT}
`

export default () => {
    return (
        <CRUDForm
            query={GET_DOCUMENT_TYPE}
            mutation={SAVE_DOCUMENT_TYPE}
            mutationVariableName="documentType"
            record="DocumentType"
        >
            <Tab title="Details">
                <Section title="Document type details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Ejari contract"
                        />
                        <FormField
                            name="component"
                            label="Component"
                            component={FileUploaderSelect}
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
