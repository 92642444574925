import React, { useMemo } from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

import CheckBoxOptions from 'crm-components/checkbox-options'

const OWNER_STATUS_FRAGMENT = /* GraphQL */ `
    fragment ownerStatusFields on OwnerStatus {
        _id
        name
        createdAt
    }
`

const SAVE_OWNER_STATUS = /* GraphQL */ `
    mutation SaveOwnerStatus($ownerStatus: OwnerStatusInput) {
        saveOwnerStatus(ownerStatus: $ownerStatus) {
            ...ownerStatusFields
        }
    }
    ${OWNER_STATUS_FRAGMENT}
`

const GET_OWNER_STATUS = /* GraphQL */ `
    query GetOwnerStatus($id: ID!) {
        ownerStatus(id: $id) {
            ...ownerStatusFields
        }
    }
    ${OWNER_STATUS_FRAGMENT}
`

const DELETE_OWNER_STATUSES = /* GraphQL */ `
    mutation DeleteOwnerStatuses($ids: [ID!]!) {
        deleteownerStatuses(ids: $ids)
    }
`

export default () => {
    const fields = useMemo(
        () => [
            {
                path: 'isHidden',
                name: 'Is hidden',
            },
            {
                path: 'showPredefinedQuery',
                name: 'Show predefined query',
            },
        ],
        []
    )
    return (
        <CRUDForm
            query={GET_OWNER_STATUS}
            mutation={SAVE_OWNER_STATUS}
            mutationVariableName="ownerStatus"
            record="OwnerStatus"
            deleteQuery={DELETE_OWNER_STATUSES}
        >
            <Tab title="Details">
                <Section title="Owner status details">
                    <FormFields>
                        <FormField name="name" label="Name" />
                        <FormField
                            label="Options"
                            component={CheckBoxOptions}
                            fields={fields}
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
