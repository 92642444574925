import React, { useMemo } from 'react'

import Section from 'ui-components/section'

import CRUDForm from 'crm-components/crud-form'

import { FormField, FormFields, Tab } from 'crm-components/crud-form'

const CONTACT_STATUS_FRAGMENT = /* GraphQL */ `
    fragment contactStatusFields on ContactStatus {
        _id
        name
        isWon
        isLost
        contactTypes
    }
`

const SAVE_CONTACT_STATUS = /* GraphQL */ `
    mutation SaveContactStatus($contactStatus: ContactStatusInput) {
        saveContactStatus(contactStatus: $contactStatus) {
            ...contactStatusFields
        }
    }
    ${CONTACT_STATUS_FRAGMENT}
`

const GET_CONTACT_STATUS = /* GraphQL */ `
    query GetContactStatus($id: ID!) {
        contactStatus(id: $id) {
            ...contactStatusFields
        }
    }
    ${CONTACT_STATUS_FRAGMENT}
`

export default () => {
    const contactTypeOptions = useMemo(
        () => [
            {
                value: 'CONTACT_TYPE_TENANT',
                name: 'Tenant',
            },
            {
                value: 'CONTACT_TYPE_OWNER',
                name: 'Owner',
            },
            {
                value: 'CONTACT_TYPE_LEAD',
                name: 'Lead',
            },
        ],
        []
    )

    return (
        <CRUDForm
            query={GET_CONTACT_STATUS}
            mutation={SAVE_CONTACT_STATUS}
            mutationVariableName="contactStatus"
            record="ContactStatus"
        >
            <Tab title="Details">
                <Section title="Details">
                    <FormFields>
                        <FormField
                            name="name"
                            label="Name"
                            placeholder="Closed won"
                        />
                        <FormField
                            name="contactTypes"
                            type="select"
                            label="Contact type"
                            isMulti
                            options={contactTypeOptions}
                        />
                        <FormField
                            name="isWon"
                            type="checkbox"
                            label="Is this status a closed won status?"
                        />
                        <FormField
                            name="isLost"
                            type="checkbox"
                            label="Is this status a closed lost status?"
                        />
                    </FormFields>
                </Section>
            </Tab>
        </CRUDForm>
    )
}
