import React, { useEffect, useRef } from 'react'

import Section from 'ui-components/section'

import { useQuery } from 'system/network/graphql'

import { getDataObject, empty } from 'crm-components/data-helpers'

import { FormFields, FormField } from 'crm-components/crud-form'

import { useFormData } from 'crm-components/crud-form/hooks/fields'

import { get } from 'lodash-es'

import { GiMoneyStack } from 'react-icons/gi'

const noopObject = {}

function usePropertyOffering({ id }) {
    const { load, cacheValue } = useQuery({
        operation: {
            query: /* GraphQL */ `
                query GetPropertyOffering($id: ID) {
                    propertyOffering(id: $id) {
                        _id
                        name
                        acceptsMultipleCheques
                    }
                }
            `,
            variables: { id },
        },
        loadOnMount: false,
    })

    const lastLoadedId = useRef(null)

    useEffect(() => {
        if (!empty(id) && id !== lastLoadedId.current) {
            load()
            lastLoadedId.current = id
        }
    }, [id, load])

    return getDataObject(cacheValue) || noopObject
}

export default () => {
    const [data] = useFormData()

    const propertyOffering = usePropertyOffering({
        id: data.propertyOfferingId,
    })

    const acceptsMultipleCheques = get(
        propertyOffering,
        'acceptsMultipleCheques'
    )

    return (
        <Section title="pricing" icon={GiMoneyStack}>
            <FormFields>
                <FormField
                    name="price"
                    label="Price"
                    placeholder="Price (AED)"
                    type="number"
                    min={0}
                />
                <FormField
                    name="serviceCharges"
                    label="Service charges"
                    placeholder="Service charges (AED)"
                    type="number"
                    min={0}
                />
                <FormField
                    name="projectStatusId"
                    label="Project status"
                    type="collectionSelect"
                    recordName="ProjectStatus"
                    queryName="projectStatuses"
                />
                {acceptsMultipleCheques && (
                    <FormField
                        label="Number of cheques"
                        name="numberOfChequesId"
                        type="collectionSelect"
                        queryName="cheques"
                        recordName="Cheque"
                    />
                )}
            </FormFields>
        </Section>
    )
}
