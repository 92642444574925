import React, { useCallback, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { string, object } from 'yup'

import { get } from 'lodash-es'

import AccountPage from './account-page'

import FormField from 'crm-components/form/field'

import AccountForm from './account-form'

import { useMutation } from 'system/network/graphql'

import { useAccount } from 'system/account'

import { pick } from 'lodash-es'

import { useCan } from 'system/account/acl'

const SAVE_COMPANY = /* GraphQL */ `
    mutation SaveCompany($company: CompanyInput!) {
        saveCompany(company: $company) {
            _id
            name
        }
    }
`

const SAVE_USER = /* GraphQL */ `
    mutation SaveUser($user: UserInput!) {
        saveUser(user: $user) {
            _id
        }
    }
`

const validationSchema = object().shape({
    name: string()
        .required('Company name is required')
        .typeError('Company name is required'),
    phone: string()
        .required('Phone is required')
        .typeError('Phone is required'),
    website: string().nullable(),
    address: string()
        .required('Address is required')
        .typeError('Address is required'),
    orn: string().required('ORN is required').typeError('ORN is required'),
    licenseNumber: string()
        .required('License number is required')
        .typeError('License number is required'),
})

const CompanyForm = ({ mode, onSuccess }) => {
    const { account, loading: getAccountLoading } = useAccount({
        autoSync: true,
    })

    const [mutationData, setMutationData] = useState({})

    const {
        loading: saveUserLoading,
        cacheValue: userCacheValue,
        load: saveUser,
    } = useMutation({
        operation: {
            query: SAVE_USER,
        },
    })

    const canWriteCompany = useCan({
        code: 'Company',
        method: 'WRITE',
        accessModifier: 'OWN',
    })

    const title = mode === 'register' ? `About your company` : `Company details`

    const subtitle =
        mode === 'register'
            ? `You are a step away of enjoying ${process.env.REACT_APP_APP_NAME}`
            : `Update or view your company details`

    useEffect(() => {
        const accountCompanyId = get(account, 'company._id')
        const companyId = get(mutationData, '_id')

        if (companyId && accountCompanyId !== companyId) {
            saveUser({
                user: {
                    ...pick(account, '_id', 'email', 'roleId'),
                    companyId,
                },
            })
        }
    }, [mutationData, saveUser, saveUserLoading, userCacheValue, account])

    const isSuccess = useCallback(() => {
        const accountCompanyId = get(account, 'company._id')
        const companyId = get(mutationData, '_id')
        const savedUserCompanyId = get(userCacheValue, 'data.saveUser._id')

        return (
            (companyId && savedUserCompanyId) ||
            (companyId && companyId === accountCompanyId && !getAccountLoading)
        )
    }, [mutationData, userCacheValue, account, getAccountLoading])

    const isLoading = useCallback(
        ({ saveLoading }) => {
            return saveLoading || saveUserLoading
        },
        [saveUserLoading]
    )

    const isDisabled = useCallback(
        ({ saveLoading }) => {
            if (canWriteCompany === false && mode === 'update') {
                return true
            }

            return isLoading({ saveLoading }) || getAccountLoading
        },
        [isLoading, getAccountLoading, canWriteCompany, mode]
    )

    const onMutationDataChange = useCallback(
        mutationData => setMutationData(mutationData),
        [setMutationData]
    )

    return (
        <AccountPage title={title} subtitle={subtitle}>
            <AccountForm
                mutationVariableName="company"
                mutation={SAVE_COMPANY}
                onSuccess={onSuccess}
                validationSchema={validationSchema}
                buttonTitle="Save details"
                isSuccess={isSuccess}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onMutationDataChange={onMutationDataChange}
                remoteData={account.company}
                saveChangesToLocalStorage={mode === 'update'}
                noRemoteData={mode !== 'update'}
            >
                {mode === 'update' && (
                    <FormField
                        type="image"
                        name="logoId"
                        label="Logo"
                        parentCollection="companies"
                        parentId={get(account, 'companyId')}
                    />
                )}
                <FormField
                    name="name"
                    label="Company name"
                    placeholder="The real estate company"
                />
                <FormField
                    type="tel"
                    name="phone"
                    label="Phone"
                    placeholder="Phone with country code"
                />
                <FormField
                    name="website"
                    label="Website"
                    placeholder="your-website.com"
                />
                <FormField
                    name="address"
                    label="Address"
                    placeholder="Office #, building name, street name, city"
                />
                <FormField
                    name="orn"
                    type="number"
                    label="Office registration number"
                    placeholder="12345"
                />
                <FormField
                    name="licenseNumber"
                    label="License number"
                    immediate
                    placeholder="12345"
                />
                <FormField
                    name="trn"
                    label="TRN"
                    immediate
                    pattern="\d*"
                    novalidate
                    placeholder="1110101010010"
                />
            </AccountForm>
        </AccountPage>
    )
}

CompanyForm.propTypes = {
    mode: PropTypes.oneOf(['register', 'update']),
}

export default CompanyForm
