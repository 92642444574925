import React, { useMemo } from 'react'

import CollectionSelect from 'crm-components/collection-select'

import UserName from 'crm-components/user-name'

const QUERY = /* GraphQL */ `
    query GetUsers($search: UserSearch) {
        users(pagination: { pageNumber: 1, pageSize: 100 }, search: $search) {
            nodes {
                ... on User {
                    _id
                    email
                    profile {
                        firstName
                        lastName
                    }
                    role {
                        name
                    }
                }
            }
        }
    }
`

function filterOption(option, search) {
    return JSON.stringify(option).match(search)
}

export default props => {
    const search = useMemo(() => {
        if (props.readOnly && props.value) {
            return { _id: [props.value] }
        }

        return props.search
    }, [props.readOnly, props.search, props.value])

    return (
        <CollectionSelect
            customQuery={QUERY}
            search={search}
            formatOptionLabel={user => <UserName user={user} />}
            filterOption={filterOption}
            previewBaseLink="/user/"
            {...props}
        />
    )
}
