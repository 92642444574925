import React, { useState, useCallback } from 'react'

import styled from 'styled-components'

import { empty } from 'crm-components/data-helpers'

import { IoIosRemoveCircle } from 'react-icons/io'

import ContentLoader from 'react-content-loader'

import UserSelect from 'crm-components/user-select'

import Button from 'ui-components/button'

import UserName from 'crm-components/user-name'

import DefaultSaveStatus from 'ui-components/save-status'

import { get } from 'lodash-es'

const SaveStatus = styled(DefaultSaveStatus)`
    margin-bottom: 1rem;
`

const StyledUser = styled.div`
    background-color: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
    justify-content: space-between;
    border-radius: 5px;
`

const Remove = styled.div`
    display: flex;
    color: ${props => props.theme.danger};
    cursor: pointer;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const Select = styled(UserSelect)`
    flex: 1;
    margin: 0 0 1rem 0;
    @media ${props => props.theme.media.sm} {
        margin: 0 1rem 0 0;
    }
`

const StyledAddUser = styled.div`
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    @media ${props => props.theme.media.sm} {
        flex-direction: row;
        align-items: center;
    }
`

const EmptyMessageText = styled.div`
    color: ${props => props.theme.gray2};
`

const Loader = ({ rows }) => {
    const items = Array.from({ length: rows })

    return items.map((_, i) => (
        <StyledUser key={i}>
            <ContentLoader
                speed={2}
                width={'100%'}
                height={17}
                viewBox="0 0 800 50"
                preserveAspectRatio="none"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="3" ry="3" width="800" height="50" />
            </ContentLoader>
        </StyledUser>
    ))
}

const EmptyMessage = () => {
    return (
        <StyledUser>
            <EmptyMessageText>
                This team has no users, select a user and then click on ADD
                USER.
            </EmptyMessageText>
        </StyledUser>
    )
}
const User = ({ user, onRemove, disabled }) => {
    const remove = useCallback(() => {
        onRemove(user._id)
    }, [onRemove, user])

    return (
        !!user && (
            <StyledUser>
                <UserName user={user} />
                <Remove onClick={remove} disabled={disabled}>
                    <IoIosRemoveCircle />
                </Remove>
            </StyledUser>
        )
    )
}

const AddUser = ({ onLoadingChange, onAddUser, onLoadUsers, disabled }) => {
    const [id, setId] = useState()

    const onValueChange = useCallback(
        value => {
            setId(value)
        },
        [setId]
    )

    const addUser = useCallback(() => {
        onAddUser(id)
        setId(null)
    }, [onAddUser, id])

    return (
        <StyledAddUser>
            <Select
                label="Select user"
                queryName="users"
                recordName="User"
                value={id}
                onValueChange={onValueChange}
                onLoadingChange={onLoadingChange}
                onLoadOptions={onLoadUsers}
                disabled={disabled}
            />
            <Button onClick={addUser} disabled={!id || disabled}>
                Add USER
            </Button>
        </StyledAddUser>
    )
}

const defaultValue = []

export default ({
    value = defaultValue,
    onChange,
    isDirty,
    showSaveStatus,
    onDiscard,
    disabled,
}) => {
    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([])

    const onAddUser = useCallback(
        id => {
            onChange(_currentValue => {
                const currentValue = _currentValue || []

                if (currentValue.indexOf(id) < 0) {
                    return [...currentValue, id]
                }

                return currentValue
            })
        },
        [onChange]
    )

    const onRemove = useCallback(
        id => {
            onChange(value => value.filter(_id => id !== _id))
        },
        [onChange]
    )

    return (
        <>
            {loading ? (
                <Loader rows={get(value, 'length') || 1} />
            ) : empty(value) ? (
                <EmptyMessage />
            ) : (
                value.map(id => (
                    <User
                        key={id}
                        user={users.find(user => user._id === id)}
                        onRemove={onRemove}
                        disabled={disabled}
                    />
                ))
            )}

            <AddUser
                disabled={disabled}
                onLoadingChange={setLoading}
                onLoadUsers={setUsers}
                onAddUser={onAddUser}
            />

            <SaveStatus
                isDirty={isDirty}
                value={value}
                showSaveStatus={showSaveStatus}
                onDiscard={onDiscard}
            />
        </>
    )
}
