import React, {
    createContext,
    useState,
    useContext,
    useCallback,
    useEffect,
} from 'react'

import {
    BrowserRouter as Router,
    Route as DefaultRoute,
    Switch,
    useHistory,
    useRouteMatch,
    useLocation,
} from 'react-router-dom'

import { QueryParamProvider } from 'use-query-params'

import { useAccount } from 'system/account'

import { useCan, useLogout } from 'system/account/acl'

import { get } from 'lodash-es'

const DEFAULT_STATE = {
    fullScreen: false,
}

const RouterContext = createContext(DEFAULT_STATE)

const SystemRoute = ({ fullScreen, children, permission = {} }) => {
    const [, setFullScreen] = useFullScreen()

    const { loading } = useAccount()

    const can = useCan(permission)

    const { url } = useRouteMatch()

    const location = useLocation()

    const history = useHistory()

    const logout = useLogout()

    useEffect(() => {
        if (typeof can === 'undefined' || loading) return

        if (!can) {
            const from = get(location, 'state.from')

            if (from === '/login') {
                history.replace('/dashboard')
            } else {
                logout({ replace: '/login?redirectTo=' + url })
            }
        }
    }, [can, loading, url, logout, history, location])

    useEffect(() => {
        setFullScreen(fullScreen)
    }, [fullScreen, setFullScreen])

    return children
}

export const Route = ({ path, exact, permission, fullScreen, children }) => {
    return (
        <Switch>
            <DefaultRoute path={path} exact={exact}>
                <TrailingSlashRemoval />
                <SystemRoute permission={permission} fullScreen={fullScreen}>
                    {children}
                </SystemRoute>
            </DefaultRoute>
        </Switch>
    )
}

export function useFullScreen() {
    const [state, set] = useContext(RouterContext)

    return [
        state.fullScreen,
        useCallback(fullScreen => set(state => ({ ...state, fullScreen })), [
            set,
        ]),
    ]
}

const TrailingSlashRemoval = () => {
    const { url } = useRouteMatch()
    const history = useHistory()

    useEffect(() => {
        const match = url.match(/^(.*)\/$/)
        if (match) {
            history.push(match[1])
        }
    }, [url, history])

    return null
}

export default ({ children }) => {
    return (
        <RouterContext.Provider value={useState(DEFAULT_STATE)}>
            <Router>
                <QueryParamProvider ReactRouterRoute={DefaultRoute}>
                    {children}
                </QueryParamProvider>
            </Router>
        </RouterContext.Provider>
    )
}
