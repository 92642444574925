function combineTextAndUrl({ url, text, separator = '\n' }) {
    return encodeURI(`${text ? text + separator : ''}${url}`)
}
function makeTelegramUrl({ url, text }) {
    let _url = encodeURI(url)
    let _text = encodeURI(text)
    return `https://t.me/share/url?url=${_url}&text=${_text}`
}

function makeWhatsAppLink({ text, url }) {
    const fullMessage = combineTextAndUrl({ text, url })
    return `https://wa.me/?text=${fullMessage}`
}

function makeSMSLink({ text, url }) {
    const fullMessage = combineTextAndUrl({ text, url })
    return `sms://;?&body=${fullMessage}`
}

function makeEmailLink({ text, url, subject }) {
    const fullMessage = combineTextAndUrl({ text, url, separator: '\n\n' })

    const emailSubject = subject || text

    const encodedSubject = emailSubject ? encodeURI(emailSubject) : emailSubject

    const subjectParam = encodedSubject ? `subject=${encodedSubject}&` : ''

    return `mailto:?${subjectParam}body=${fullMessage}`
}

export const WhatsAppShare = ({ text, url, render }) =>
    render({ href: makeWhatsAppLink({ text, url }) })

export const TelegramShare = ({ text, url, render }) =>
    render({ href: makeTelegramUrl({ text, url }) })

export const SMSShare = ({ text, url, render }) =>
    render({ href: makeSMSLink({ text, url }) })

export const EmailShare = ({ text, url, subject, render }) =>
    render({ href: makeEmailLink({ text, url, subject }) })
